/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Alert from '@common/components/Alert';
import Button from '@common/components/Button';
import Body from '@common/components/Body';
import Heading from '@common/components/Heading';
import AvatarUploadCard from '@modules/user/components/AvatarUploadCard';
import Checkbox from '@common/components/Checkbox';
import { User } from '@common/transforms/user';
import { Event } from '@common/transforms/event';
import { DIRECTION } from '@common/utils/consts';
import IconButton from '@common/components/IconButton';

type AvatarFormProps = {
  user: Partial<User>;
  event?: Partial<Event>;
  onSubmit: (avatarFile?: File, privacyPolicyAccepted?: boolean) => void;
  onPrev?: () => void;
  onChange: (avatarUrl?: string) => void;
  acceptedAt?: boolean;
  onUploadAvatar: (file?: File) => void;
};

const AvatarForm = ({
  user,
  onSubmit,
  onPrev,
  onChange,
  onUploadAvatar,
  event,
  acceptedAt = true,
}: AvatarFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [avatarFile, setAvatarFile] = useState<File>();

  const validationSchema = Yup.object().shape({
    privacyPolicyAccepted: Yup.bool().oneOf([true]),
  });

  const updateAvatar = useCallback(
    (file?: File) => {
      if (file) {
        onUploadAvatar(file);
        setAvatarFile(file);
        const reader = new FileReader();

        reader.onload = (e) => {
          onChange(e.target?.result as string);
        };

        reader.readAsDataURL(file);
      } else {
        onChange(undefined);
      }
    },
    [onChange, onUploadAvatar],
  );

  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      <Formik
        initialValues={{
          privacyPolicyAccepted: false,
        }}
        onSubmit={(values) => {
          onSubmit(avatarFile, values.privacyPolicyAccepted);
        }}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <Heading size='h1'>
              {acceptedAt && event?.avatarUploadEnabled
                ? t('upload_avatar_title')
                : t('privacy_policy_form')}
            </Heading>
            {event?.avatarUploadEnabled && (
              <>
                <Body
                  css={css`
                    margin-bottom: 1.875em;
                    color: ${theme.colors.textLight};
                  `}
                >
                  {t('upload_avatar_explanation')}
                </Body>
                <AvatarUploadCard user={user} onDrop={updateAvatar} />
              </>
            )}
            {submitted && !values.privacyPolicyAccepted && (
              <div
                css={css`
                  margin: 0 0 0.5em 1em;
                `}
              >
                <Alert>{t('privacy_policy_required')}</Alert>
              </div>
            )}
            <Checkbox
              name='privacyPolicyAccepted'
              css={css`
                font-size: 14px !important;
              `}
            >
              <Trans
                i18nKey='privacy_policy_text'
                values={{ privacy: t('privacy_policy_button'), terms: t('terms_of_use_button') }}
                components={[
                  <a key='link' rel='noreferrer' target='_blank' href={event?.privacyPolicyUrl}>
                    privacy policy
                  </a>,
                ]}
              />
            </Checkbox>
            {process.env.REACT_APP_ASML_SERVER === 'true' && (
              <p
                css={css`
                  letter-spacing: 0.02em;

                  p {
                    font-weight: bold;
                    margin: 2em 0 1em;
                  }

                  a {
                    color: ${theme.colors.textLight};
                  }
                `}
              >
                <p>Photos</p>I agree not to take any photos, screenshots or screen recordings of the
                web conference during the event.
                <p>Uploading profile picture</p>
                Uploading your picture is voluntary. If you choose to upload your picture, it will
                be visible during the event to other participants. In addition, it might be visible
                in pictures that will be taken by a professional photographer during the event and
                which may be used by ASML in its internal and external communications. In addition,
                a few carefully selected photos will be allowed to be used by all employees if they
                want to post them online e.g. in social media. If you do not want your picture to be
                included in the photos that will be taken during the event and that may be shared
                online, please opt-out by clicking here [ ]. Please be aware that you are not
                allowed to take pictures in the event yourself.
              </p>
            )}
            <div
              css={css`
                display: flex;
                flex-direction: row;
                flex: 1;
                margin-top: 3.375em;
                margin-bottom: 3.375em;
              `}
            >
              {acceptedAt && (
                <IconButton
                  onClick={onPrev}
                  icon='arrow'
                  iconProps={{ direction: DIRECTION.LEFT, size: 20 }}
                  size={48}
                  round
                />
              )}

              <Button
                css={css`
                  flex: 1;
                  margin-left: 1em;
                `}
                label={t('next_button')}
                disableMinWidth
                type='submit'
                onClick={() => setSubmitted(true)}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AvatarForm;
