/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { gsap } from 'gsap';
import { Transition } from 'react-transition-group';

import { useTranslation } from 'react-i18next';

import { mediaQuery } from '@common/styles/mediaQuery';
import Overlay from '@common/components/Overlay';
import Card from '@common/components/Card';
import Heading from '@common/components/Heading';
import Body from '@common/components/Body';
import Button from '@common/components/Button';
import LazyModal from '@common/components/LazyModal';

import { useAppDispatch, useAppSelector } from '@common/store';
import { setActiveItem, setFullscreen } from '@common/store/sidebar';

import { useVideoCall } from '../hooks/useVideoCall';
import {
  resetActiveCall,
  setDoNotDisturbSuggestion,
  setError,
  startIncomingCall,
  resetIncomingCall,
  resetOutgoingCall,
  setInvite,
  setIsOutGoingCallDenied,
} from '../store/videoCall';

import { useRejectCall } from '../hooks/useRejectCall';
import { useLeaveCall } from '../hooks/useLeaveCall';
import { useAcceptCall } from '../hooks/useAcceptCall';

import { ActiveCallOverlay } from './ActiveCallOverlay';
import { IncomingCallOverlay } from './IncomingCallOverlay';
import { OutgoingCallOverlay } from './OutgoingCallOverlay';
import { InviteOverlay } from './InviteOverlay';
import { DoNotDisturbOverlay } from './DoNotDisturbOverlay';

export const CallOverlay = () => {
  const { t } = useTranslation();
  const outGoingCallRef = useRef(null);
  const [isOutgoing, setIsOutgoing] = useState(false);
  const [isIncoming, setIsIncoming] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const {
    incomingCall,
    outgoingCall,
    activeCall,
    invite,
    isOutGoingCallDenied,
    doNotDisturbSuggestion,
    error,
  } = useAppSelector((state) => state.videoCall);
  const activeItem = useAppSelector((state) => state.sidebar.activeItem);

  const dispatch = useAppDispatch();

  const { data: incomingVideoCall } = useVideoCall(incomingCall?.callId, true);
  const { data: outgoingVideoCall } = useVideoCall(outgoingCall?.callId, true);
  const { data: activeVideoCall } = useVideoCall(activeCall?.callId);

  const { mutate: acceptCall } = useAcceptCall();
  const { mutate: leaveCall } = useLeaveCall();
  const { mutate: rejectCall } = useRejectCall();

  const timeline = gsap.timeline({
    onReverseComplete: () => {
      dispatch(setInvite(false));
    },
  });

  const outGoingCallTimeline = gsap.timeline();
  const incomingCallTimeline = gsap.timeline();
  const doNotDisturbSuggestionTimeline = gsap.timeline({
    onReverseComplete: () => dismissDoNotDisturb(),
  });

  const rejectFromOutgoing = useCallback(() => {
    if (outgoingCall && outgoingVideoCall) {
      rejectCall(outgoingCall.participantId);
      dispatch(resetOutgoingCall());
    }
  }, [outgoingCall, outgoingVideoCall, rejectCall, dispatch]);

  const rejectFromIncoming = useCallback(() => {
    if (incomingCall && incomingVideoCall) {
      rejectCall(incomingCall.participantId);
      dispatch(resetIncomingCall());

      if (localStorage.getItem('do_not_disturb_suggestion_completed') !== '1') {
        dispatch(setDoNotDisturbSuggestion(true));
      }
    }
  }, [incomingCall, incomingVideoCall, rejectCall, dispatch]);

  const endOutgoingCallAfterDenied = useCallback(() => {
    if (isOutGoingCallDenied) {
      dispatch(resetOutgoingCall());
    }
  }, [dispatch, isOutGoingCallDenied]);

  const dismissDoNotDisturb = useCallback(() => {
    dispatch(setDoNotDisturbSuggestion(false));
    localStorage.setItem('do_not_disturb_suggestion_completed', '1');
  }, [dispatch]);

  const acceptFromIncoming = useCallback(() => {
    if (incomingCall) {
      acceptCall(incomingCall.participantId);
      dispatch(startIncomingCall());
      setIsActive(true);
      setIsIncoming(false);
    }
  }, [incomingCall, acceptCall, dispatch]);

  const leaveFromActive = useCallback(() => {
    if (activeCall) {
      leaveCall(activeCall.participantId);
      dispatch(resetActiveCall());
      dispatch(setFullscreen(false));

      if (activeItem === 'video_call') {
        dispatch(setActiveItem(undefined));
      }
    }
  }, [activeCall, leaveCall, dispatch, activeItem]);

  // change outgoing call overlay width if call is denied
  useEffect(() => {
    if (isOutGoingCallDenied) {
      if (outGoingCallRef.current)
        outGoingCallTimeline.to(outGoingCallRef.current, {
          width: 250,
          duration: 0.7,
          ease: 'power4.inOut',
        });
    }
  }, [dispatch, isOutGoingCallDenied, outGoingCallTimeline]);

  // automatically remove the outgoing call overlay with denied call message after 10 secs
  useEffect(() => {
    if (isOutGoingCallDenied) {
      setTimeout(() => {
        endOutgoingCallAfterDenied();
      }, 10000);
    }
  }, [endOutgoingCallAfterDenied, isOutGoingCallDenied]);

  useEffect(() => {
    setIsOutgoing(!!outgoingCall);
    setIsIncoming(!!incomingCall);
    setIsActive(!!activeCall);
  }, [activeCall, incomingCall, outgoingCall]);

  if (error) {
    return (
      <Overlay openModal={!!error} closeModal={() => dispatch(setError(undefined))}>
        <Card
          css={css`
            padding: 2em;
          `}
        >
          <Heading size='h2'>{t('vle_error_video_call_failed')}</Heading>
          <Body>{t(error)}</Body>
          <Button
            css={css`
              margin-top: 2em;
            `}
            label={t('close_button')}
            onClick={() => dispatch(setError(undefined))}
          />
        </Card>
      </Overlay>
    );
  }

  return (
    <>
      <Transition
        in={isIncoming}
        unmountOnExit
        mountOnEnter
        addEndListener={(node, done) => {
          if (isIncoming)
            incomingCallTimeline.fromTo(
              node,
              { opacity: 0, x: 200 },
              { opacity: 1, duration: 0.8, x: 0, ease: 'elastic.inOut', onComplete: done },
            );
          else
            incomingCallTimeline.fromTo(
              node,
              { x: 0, opacity: 1 },
              {
                opacity: 0,
                duration: 0.8,
                x: 200,
                ease: 'elastic.inOut',
                onComplete: () => {
                  done();
                  rejectFromIncoming();
                },
              },
            );
        }}
      >
        <StyledOverlayContainer
          css={css`
            opacity: 0;
            z-index: 100;
          `}
        >
          <IncomingCallOverlay
            accept={acceptFromIncoming}
            videoCall={incomingVideoCall}
            onEnd={() => setIsIncoming(false)}
          />
        </StyledOverlayContainer>
      </Transition>
      <Transition
        in={isOutgoing}
        unmountOnExit
        mountOnEnter
        addEndListener={(node, done) => {
          if (isOutgoing)
            outGoingCallTimeline.fromTo(
              node,
              { opacity: 0, x: 200 },
              { opacity: 1, duration: 0.8, x: 0, ease: 'elastic.inOut', onComplete: done },
            );
          else
            outGoingCallTimeline.fromTo(
              node,
              { x: 0, opacity: 1 },
              {
                opacity: 0,
                duration: 0.8,
                x: 200,
                ease: 'elastic.inOut',
                onComplete: () => {
                  rejectFromOutgoing();
                  dispatch(setIsOutGoingCallDenied(false));
                  done();
                },
              },
            );
        }}
      >
        <StyledOverlayContainer
          ref={outGoingCallRef}
          css={css`
            opacity: 0;
            z-index: 100;
          `}
        >
          <OutgoingCallOverlay
            videoCall={outgoingVideoCall}
            denied={isOutGoingCallDenied}
            onEnd={() => setIsOutgoing(false)}
            endOutgoingCallAfterDenied={endOutgoingCallAfterDenied}
          />
        </StyledOverlayContainer>
      </Transition>
      <Transition
        in={doNotDisturbSuggestion}
        unmountOnExit
        mountOnEnter
        addEndListener={(node, done) => {
          if (doNotDisturbSuggestion)
            doNotDisturbSuggestionTimeline.fromTo(
              node,
              { opacity: 0, x: 200 },
              { opacity: 1, duration: 0.8, x: 0, ease: 'elastic.inOut', onComplete: done },
            );
          else
            doNotDisturbSuggestionTimeline.fromTo(
              node,
              { x: 0, opacity: 1 },
              { opacity: 0, duration: 0.8, x: 200, ease: 'elastic.inOut', onComplete: done },
            );
        }}
      >
        <StyledOverlayContainer
          css={css`
            opacity: 0;
            z-index: 100;
          `}
        >
          <DoNotDisturbOverlay />
        </StyledOverlayContainer>
      </Transition>
      <Transition
        in={isActive}
        unmountOnExit
        mountOnEnter
        addEndListener={(node, done) => {
          if (isActive)
            timeline.fromTo(
              node,
              { opacity: 0, x: -200 },
              {
                opacity: 1,
                duration: 0.8,
                x: 0,
                ease: 'elastic.inOut',
                onComplete: done,
              },
            );
          else
            timeline.fromTo(
              node,
              { x: 0, opacity: 1 },
              {
                opacity: 0,
                duration: 0.8,
                x: -200,
                ease: 'elastic.inOut',
                onComplete: () => {
                  leaveFromActive();
                  done();
                },
              },
            );
        }}
      >
        <StyledActiveOverlayContainer
          css={css`
            opacity: 0;
          `}
        >
          <ActiveCallOverlay videoCall={activeVideoCall} onEnd={() => setIsActive(false)} />
        </StyledActiveOverlayContainer>
      </Transition>
      {ReactDOM.createPortal(
        <LazyModal open={invite}>
          {(actualOpen) => (
            <InviteOverlay openModal={actualOpen} closeModal={() => dispatch(setInvite(false))} />
          )}
        </LazyModal>,
        document.querySelector('#popupHolder') ?? document.body,
      )}
    </>
  );
};

const StyledOverlayContainer = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 5em;
  width: auto;
  overflow: hidden;
  right: 1em;

  ${mediaQuery(
    's',
    css`
      right: 4em;
      bottom: 4em;
    `,
  )}
`;
const StyledActiveOverlayContainer = styled(StyledOverlayContainer)`
  left: 1em;
  width: auto;
  max-width: 360px;

  ${mediaQuery(
    's',
    css`
      left: 4em;
      width: 360px;
    `,
  )}
`;
