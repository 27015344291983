import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import sidebarReducer from '@common/store/sidebar';
import fullScreenReducer from '@common/store/fullscreen';
import sideOverlayReducer from '@common/store/sideOverlay';
import menuReducer from '@common/store/menu';
import pusherReducer from '@common/store/pusher';
import chatReducer from '@modules/chat/store/chat';
import videoCallReducer from '@modules/video-call/store/videoCall';
import authReducer from '@modules/auth/store/auth';

export const store = configureStore({
  reducer: {
    sideoverlay: sideOverlayReducer,
    sidebar: sidebarReducer,
    chat: chatReducer,
    videoCall: videoCallReducer,
    menu: menuReducer,
    auth: authReducer,
    pusher: pusherReducer,
    fullscreen: fullScreenReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
