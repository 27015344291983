import React, { useCallback, useEffect } from 'react';

import { Sidebar } from '@common/components/Sidebar';
import Bottombar from '@common/components/Bottombar';
import VideoCall from '@modules/video-call/components/VideoCall';

import useEvent from '@modules/event/hooks/useEvent';
import { Platform, usePlatform } from '@common/hooks/usePlatform';
import { useScheduledSessionsCount } from '@modules/schedule/hooks/useSessions';
import { useWindowWidth } from '@common/hooks/useWindowWidth';
import { usePrevious } from '@common/hooks/usePrevious';
import { useEventHost } from '@modules/user/hooks/useEventHost';

import { useAppDispatch, useAppSelector } from '@common/store';
import { setActiveItem, setFullscreen, SidebarItem } from '@common/store/sidebar';
import { closeOverlay } from '@common/store/sideOverlay';
import { GroupChat, PrivateChat } from '@modules/chat/components/Chat';
import AgendaPanel from '@modules/overlays/components/AgendaPanel';

import { PageResource } from '@common/transforms/page';
import { UserRolePermission } from '@common/transforms/userRole';
import {
  setChatNotificationRoomId,
  setShowGroupChatNotification,
  setShowPrivateChatNotification,
} from '@modules/chat/store/chat';

import { useGetVideoCalls } from '@modules/video-call/hooks/useVideoCall';
import { setMissedCalls, setVideoCalls } from '@modules/video-call/store/videoCall';

import * as Items from './items';

type SideBarMenuProps = {
  slidoEnabled?: boolean;
  chatEnabled?: boolean;
  onToggle?: (boolean: boolean) => void;
  page?: PageResource;
};

const SideBarMenu: React.FC<SideBarMenuProps> = ({ onToggle, page }) => {
  const event = useEvent();
  const { data: videoCalls } = useGetVideoCalls();

  const dispatch = useAppDispatch();
  const platform = usePlatform();
  const width = useWindowWidth();
  const previousPageData = usePrevious(page);
  const user = useAppSelector((state) => state.auth.user);
  const scheduledSessions = useScheduledSessionsCount();

  const { fullscreen, activeItem } = useAppSelector((state) => state.sidebar);

  const { selectedRoom, chatNotificationRoomId } = useAppSelector((state) => state.chat);

  const eventHost = useEventHost();

  const missedCallsCount = videoCalls?.reduce(
    (acc, call) =>
      acc +
      call.participants!.filter((participant) => participant.missedAt && !participant.missedSeenAt)
        .length,
    0,
  );

  useEffect(() => {
    if (missedCallsCount) dispatch(setMissedCalls(missedCallsCount));

    dispatch(setVideoCalls(videoCalls));
  }, [dispatch, missedCallsCount, videoCalls]);

  useEffect(() => {
    if (activeItem) dispatch(closeOverlay());
    if (activeItem === 'group_chat') dispatch(setShowGroupChatNotification(false));
  }, [activeItem, dispatch]);

  useEffect(() => {
    if (activeItem === 'private_chat' && selectedRoom === chatNotificationRoomId) {
      dispatch(setShowPrivateChatNotification(false));
      dispatch(setChatNotificationRoomId(undefined));
    }
  }, [activeItem, chatNotificationRoomId, selectedRoom, dispatch]);

  const toggle = useCallback(
    (key: string) => {
      if (key === 'collapse') {
        dispatch(setActiveItem(undefined));
        dispatch(setFullscreen(false));
      } else {
        if (onToggle) {
          onToggle(activeItem !== key);
        }
        dispatch(setFullscreen(false));
        dispatch(setActiveItem(activeItem === key ? undefined : key));
      }
    },
    [dispatch, onToggle, activeItem],
  );

  const { showGroupChatNotification, showPrivateChatNotification } = useAppSelector(
    (state) => state.chat,
  );

  const permissions = user?.role?.permissions ?? [];

  const itemsArray = (
    [
      event?.chatEnabled &&
      permissions.includes(UserRolePermission.SEND_GROUP_CHAT) &&
      (!page || page.groupChatEnabled)
        ? {
            key: 'group_chat',
            icon: 'chat',
            label: 'sidemenu_hover_chat_group',
            notificationIcon: showGroupChatNotification,
            render: () => <GroupChat />,
          }
        : false,
      (event?.privateChatEnabled && permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT)) ||
      (event?.hostChatEnabled && !!eventHost)
        ? {
            key: 'private_chat',
            icon: 'chats',
            label: 'sidemenu_hover_chat_private',
            notificationIcon: showPrivateChatNotification,
            render: () => <PrivateChat />,
          }
        : false,
      event?.attendeesInSidebarEnabled && user?.role?.attendeesInSidebar
        ? {
            key: 'attendees',
            icon: 'ic_attendees',
            label: 'sidemenu_hover_attendees',
            render: () => (
              <Items.AttendeesItem
                privateChatEnabled={event?.privateChatEnabled}
                videoCallEnabled={event?.videoCallEnabled}
                hostChatEnabled={event?.hostChatEnabled}
              />
            ),
          }
        : false,
      {
        key: 'support',
        icon: 'question',
        label: 'sidemenu_hover_support',
        render: () => <Items.SupportItem event={event} />,
      },
      page?.parameters.slidoUrl
        ? {
            key: 'slido',
            icon: 'ic_slido',
            label: 'sidemenu_hover_qa',
            render: () =>
              platform === Platform.MOBILE ? (
                <Items.SlidoMobileItem
                  slidoUrl={page?.parameters.slidoUrl}
                  close={() => toggle('slido')}
                />
              ) : (
                <Items.SlidoItem slidoUrl={page?.parameters.slidoUrl} />
              ),
          }
        : false,
      event?.videoCallEnabled && permissions.includes(UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS)
        ? {
            key: 'video_call',
            icon: 'ic_phone',
            label: 'sidemenu_hover_video_call',
            render: () => <VideoCall />,
          }
        : false,
      !!scheduledSessions.data &&
        scheduledSessions.data.length !== 0 && {
          key: 'agenda_panel',
          icon: 'calendar',
          label: 'my_schedule',
          render: () => <AgendaPanel />,
        },
      !!activeItem &&
        width >= 768 && {
          key: 'collapse',
          icon: 'collapse',
          label: 'collapse',
          render: null,
        },
    ] as Array<SidebarItem | false>
  ).filter(Boolean) as SidebarItem[];

  useEffect(() => {
    if (width < 768) return;
    if (page?.parameters.slidoEnabled && page?.parameters.chatEnabled)
      dispatch(setActiveItem('slido'));
    else if (page?.parameters.chatEnabled) dispatch(setActiveItem('group_chat'));
    else if (page?.parameters.slidoEnabled && page?.parameters.slidoUrl)
      dispatch(setActiveItem('slido'));
    if (
      previousPageData?.parameters.slidoEnabled &&
      activeItem === 'slido' &&
      !itemsArray.find((item) => item.key === 'slido')
    )
      dispatch(setActiveItem(undefined));
    if (activeItem === 'group_chat' && !itemsArray.find((item) => item.key === 'group_chat'))
      dispatch(setActiveItem(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return width < 768 || (width < 900 && window.matchMedia('(orientation: landscape)').matches) ? (
    <Bottombar items={itemsArray} active={activeItem} toggle={toggle} />
  ) : (
    <Sidebar items={itemsArray} active={activeItem} toggle={toggle} fullscreen={fullscreen} />
  );
};

export default SideBarMenu;
