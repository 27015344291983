/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';

import Button from '@common/components/Button';

import EventDetails from '@modules/event/components/EventDetails';
import { EventResource } from '@common/transforms/event';

type RegisterIntroProps = {
  onClickRegister: () => void;
  onClickLogin: () => void;
  destination: string;
  event?: EventResource;
  intro?: boolean;
};

const RegisterIntro: React.FC<RegisterIntroProps> = ({
  onClickRegister,
  onClickLogin,
  event,
  intro = true,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div
      css={css`
        box-sizing: border-box;
        display: flex;
        max-width: 550px;
        flex: 1;
        flex-direction: column;
        padding: 32px 56px;
        border-radius: 16px;
        backdrop-filter: blur(${theme.blurs.normal});
        background-color: ${transparentize(0.2, theme.colors.eventBackground)};
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 1em;
      `}
    >
      {event?.themeLogoUrl !== undefined && !event?.slug.includes('flow-launch') && (
        <img
          src={event?.themeLogoUrl}
          alt=''
          css={css`
            max-height: 80px;
          `}
        />
      )}

      <EventDetails event={event} align='center' intro={intro} />

      {event?.registrationEnabled && (
        <Button
          label={event.autoApproval ? t('signup') : t('register')}
          css={css`
            margin-top: 2.65625em;
          `}
          onClick={() => onClickRegister()}
        />
      )}
      {event?.loginEnabled && (
        <Button
          color='secondary'
          label={t('login_button')}
          css={css`
            margin-top: 1em;
            margin-bottom: 12px;
          `}
          onClick={() => onClickLogin()}
        />
      )}
    </div>
  );
};

export default RegisterIntro;
