/** @jsxImportSource @emotion/react */
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import Linkify from 'react-linkify';
import { css, useTheme } from '@emotion/react';

import Avatar from '@modules/user/components/Avatar';
import Body from '@common/components/Body';
import Chip from '@common/components/Chip';
import LazyModal from '@common/components/LazyModal';
import Profile from '@modules/user/components/Profile';
import { UserResource } from '@common/transforms/user';
import { MessageResource } from '@common/transforms/message';
import { UserRolePermission } from '@common/transforms/userRole';

import { useStartChat } from '@modules/chat/hooks/useStartChat';
import { closeOverlay } from '@common/store/sideOverlay';
import { useStartPrivateCall } from '@modules/video-call/hooks/useStartPrivateCall';
import { setOutgoingCall } from '@modules/video-call/store/videoCall';
import { useAppDispatch } from '@common/store';
import useEvent from '@modules/event/hooks/useEvent';

type ChatMessageProps = {
  className?: string;
  self?: UserResource;
  message: MessageResource;
  linksEnabled?: boolean;
  privateChatEnabled?: boolean;
};

const ChatMessage: React.FC<ChatMessageProps> = ({
  className,
  self,
  message,
  linksEnabled = false,
  privateChatEnabled = false,
}) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const startChat = useStartChat();

  const event = useEvent();
  const dispatch = useAppDispatch();
  const { mutateAsync: startPrivateCall } = useStartPrivateCall();

  const call = useCallback(
    async (userId) => {
      const participant = await startPrivateCall(userId);
      dispatch(setOutgoingCall({ callId: participant.videoCallId, participantId: participant.id }));
      dispatch(closeOverlay());
    },
    [startPrivateCall, dispatch],
  );

  if (!message.participant) return null;

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: row;
        cursor: pointer;
      `}
    >
      <div onClick={() => setOpenModal(true)} key={message.participant.id}>
        <Avatar
          user={message.participant}
          size={28}
          css={css`
            margin-right: 1em;
            cursor: pointer;
          `}
        />
      </div>
      {message.participant?.isOnline && (
        <div
          css={css`
            position: absolute;
            margin-top: 14pt;
            margin-left: 14pt;
            background-color: lightgreen;
            width: 6pt;
            height: 6pt;
            border-radius: 4pt;
            border-width: 1pt;
            border-style: solid;
            border-color: ${theme.colors.eventBackground};
          `}
        />
      )}

      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Body color={message.participant.isMe ? 'primary' : 'textLight'} size='s' weight='bold'>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
            `}
            onClick={() => setOpenModal(true)}
          >
            {message.participant?.fullName}
            {message.participant.role?.visible && !!message.participant.role.name && (
              <Chip
                size='xxs'
                css={css`
                  margin-left: 1em;
                  margin-top: 0.2em;
                `}
              >
                {message.participant.role.name}
              </Chip>
            )}
          </div>
        </Body>
        <Body
          size='s'
          color='textLight'
          css={css`
            margin-top: 0.5em;
            cursor: initial;
          `}
        >
          {linksEnabled ? (
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target='blank' href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {message.message}
            </Linkify>
          ) : (
            message.message
          )}
        </Body>
      </div>
      {ReactDOM.createPortal(
        <LazyModal open={openModal}>
          {(actualOpen) => (
            <Profile
              openModal={actualOpen}
              onCloseClick={() => setOpenModal(false)}
              userId={message.participant!.id}
              onOpenChat={
                (!message.participant!.role?.permissions.includes(UserRolePermission.HIDE_USERS) ||
                  self?.role?.permissions.includes(UserRolePermission.HIDE_USERS)) &&
                !message.participant!.isMe &&
                privateChatEnabled
                  ? startChat
                  : undefined
              }
              onCallUser={event?.videoCallEnabled ? call : undefined}
            />
          )}
        </LazyModal>,
        document.querySelector('#popupHolder') ?? document.body,
      )}
    </div>
  );
};

export default ChatMessage;
