/** @jsxImportSource @emotion/react */
import React, { useCallback, useState, useRef } from 'react';
import { css, useTheme } from '@emotion/react';
import { Formik, Form, FormikContextType } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

import Alert from '@common/components/Alert';
import Labeled from '@common/components/Labeled';
import Button from '@common/components/Button';
import Body from '@common/components/Body';
import Heading from '@common/components/Heading';
import Input from '@common/components/Input';
import Select from '@common/components/Select';
import FormikEffect from '@common/components/FormikEffect';

import useEvent from '@modules/event/hooks/useEvent';
import useLanguage from '@common/hooks/useLanguage';
import { User } from '@common/transforms/user';

import { languages } from '@config';
import i18n from '@common/utils/i18n';
import { MandatoryField } from '@common/transforms/mandatoryField';

type RegisterFormProps = {
  user: Partial<User & { email: string }>;
  onSubmit: (values: {
    firstName: string;
    lastName: string;
    email: string;
    recaptchaToken: string;
  }) => Promise<boolean> | boolean;
  hasEmail?: boolean;
  recaptcha?: boolean;
};

const RegisterForm: React.FC<RegisterFormProps> = ({
  user,
  onSubmit,
  hasEmail = true,
  recaptcha = true,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const event = useEvent();
  const { language, updateLanguage } = useLanguage();
  const [submitted, setSubmitted] = useState(false);
  const [attendance, setAttendance] = useState<string>('ONLINE');
  const [existingEmail, setExistingEmail] = useState<string>();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const defaultFieldOrder: MandatoryField[] = [
    { id: '0', key: 'firstname' },
    { id: '1', key: 'lastname' },
    { id: '2', key: 'email' },
    { id: '3', key: 'language' },
  ];
  const keyToName: { [key: string]: string } = { firstname: 'firstName', lastname: 'lastName' };
  const filteredLanguages = event?.languages
    ? languages.filter((item) => event?.languages?.includes(item.code))
    : [];

  const eventLanguages = filteredLanguages.map((lang) => ({
    value: lang.code,
    label: lang.nativeName,
  }));

  const validationSchema = hasEmail
    ? Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        email: Yup.string().required().email(),
        recaptchaToken: recaptcha ? Yup.string().required() : Yup.string().optional(),
      })
    : Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
      });

  const handleChange = useCallback(
    ({ values }: FormikContextType<any>) => {
      if (values?.attendance && attendance !== values.attendance) {
        setAttendance(values.attendance);
      }
    },
    [attendance, setAttendance],
  );

  const onChangeLanguage = useCallback(
    ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
      updateLanguage(target.value);
    },
    [updateLanguage],
  );

  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      <Heading size='h1'>{t('registration_title')}</Heading>
      <Body
        css={css`
          margin-bottom: 1.875em;
          color: ${theme.colors.textLight};
        `}
      >
        {t('registration_introduction')}
      </Body>
      <Formik
        initialValues={{
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.email || '',
          recaptchaToken: '',
          language: user.language || i18n.language,
          attendance: user.attendance || 'ONLINE',
        }}
        onSubmit={async (values) => {
          if (!(await onSubmit(values))) setExistingEmail(values.email);
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            {submitted && !!errors.recaptchaToken && (
              <div
                css={css`
                  margin-bottom: 1.875em;
                `}
              >
                <Alert>{t('vle_error_recaptcha')}</Alert>
              </div>
            )}
            {hasEmail && existingEmail === values.email && (
              <div
                css={css`
                  margin-bottom: 1.875em;
                `}
              >
                <Alert>{t('vle_error_user_exists')}</Alert>
              </div>
            )}
            <FormikEffect onChange={handleChange} />
            {(event?.mandatoryFieldOrder || defaultFieldOrder).map((field: MandatoryField) => {
              if (
                (field.key === 'email' && !hasEmail) ||
                (field.key === 'attendance' && !event?.attendanceEnabled)
              )
                return <></>;

              if (
                field.key === 'language' &&
                !!filteredLanguages.length &&
                filteredLanguages.length === 1
              ) {
                return <></>;
              }
              if (field.key === 'language' && !!filteredLanguages.length) {
                return (
                  <Labeled label={t('language')} key={field.id}>
                    <Select
                      name='language'
                      options={eventLanguages}
                      value={language}
                      onChange={onChangeLanguage}
                    />
                  </Labeled>
                );
              }
              if (field.key === 'attendance') {
                return (
                  <Labeled key={field.id} label={`${t(field.key)} *`}>
                    <Select
                      name='attendance'
                      options={[
                        {
                          value: 'ONLINE',
                          label: t('attendance_location.online'),
                        },
                        {
                          value: 'ONSITE',
                          label: t('attendance_location.onsite'),
                        },
                      ]}
                    />
                  </Labeled>
                );
              }

              const inputName = keyToName[field.key] ?? field.key;

              return (
                <Labeled label={`${t(field.key)} *`} key={field.id}>
                  <Input name={inputName} placeholder={t(`${field.key}_placeholder`)} />
                </Labeled>
              );
            })}
            {recaptcha && (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY! || '1'}
                onChange={(response) => {
                  setFieldValue('recaptchaToken', response);
                }}
              />
            )}
            <div>{t('registration_mandatory_description')}</div>
            <div
              css={css`
                flex: 1;
                margin-top: 3.375em;
                margin-bottom: 3.375em;
              `}
            >
              <Button type='submit' label={t('next_button')} onClick={() => setSubmitted(true)} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterForm;
