/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Icon } from '@typing/icons';

const Fullscreen: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3'
        y='3'
        width='18'
        height='18'
        rx='3'
        stroke={color}
        strokeWidth='2'
        css={css`
          fill: transparent !important;
        `}
      />
      <path
        d='M12.9167 11.0833L17.0833 6.91667'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        css={css`
          fill: transparent !important;
        `}
      />
      <path
        d='M12.9167 6.91667H17.0833V11.0833'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        css={css`
          fill: transparent !important;
        `}
      />
      <path
        d='M10.375 13.625L6.625 17.375'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        css={css`
          fill: transparent !important;
        `}
      />
      <path
        d='M10.375 17.375H6.625V13.625'
        stroke={color}
        strokeWidth='1.63636'
        strokeLinecap='round'
        strokeLinejoin='round'
        css={css`
          fill: transparent !important;
        `}
      />
    </svg>
  );
};

export default Fullscreen;
