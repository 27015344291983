import { Icon } from '@typing/icons';

const Menu: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.2308 5C21.6556 5 22 5.44772 22 6C22 6.51284 21.703 6.93551 21.3205 6.99327L21.2308 7H2.76923C2.3444 7 2 6.55228 2 6C2 5.48716 2.29695 5.06449 2.67952 5.00673L2.76923 5H21.2308Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M21.2308 11C21.6556 11 22 11.4477 22 12C22 12.5128 21.703 12.9355 21.3205 12.9933L21.2308 13H2.76923C2.3444 13 2 12.5523 2 12C2 11.4872 2.29695 11.0645 2.67952 11.0067L2.76923 11H21.2308Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M16.3478 17C16.708 17 17 17.4477 17 18C17 18.5128 16.7482 18.9355 16.4239 18.9933L16.3478 19H2.65217C2.29199 19 2 18.5523 2 18C2 17.4872 2.25177 17.0645 2.57612 17.0067L2.65217 17H16.3478Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Menu;
