/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';

import useEvent from '@modules/event/hooks/useEvent';
import RegisterForm from '@modules/auth/components/RegisterForm';
import EventBackground from '@modules/event/components/EventBackground';
import CustomFieldsForm from '@modules/user/components/CustomFieldsForm';
import TagsForm from '@modules/user/components/TagsForm';
import AvatarForm from '@modules/user/components/AvatarForm';
import RegisterCompleted from '@modules/auth/components/RegisterCompleted';
import RegistrationCard from '@modules/auth/components/RegistrationCard';
import { useCustomFields } from '@modules/event/hooks/useCustomFields';
import { useTags } from '@modules/event/hooks/useTags';
import Alert from '@common/components/Alert';
import { mediaQuery } from '@common/styles/mediaQuery';
import Icon from '@common/components/Icon';
import { useAppSelector } from '@common/store';
import { User } from '@common/transforms/user';
import { useErrorMessage } from '@common/hooks/useErrorMessage';
import { useRegister } from '@modules/auth/hooks/useRegister';
import { useUpdateUser } from '@modules/auth/hooks/useUpdateUser';
import { useBrowserLanguage } from '@modules/user/hooks/useBrowserLanguage';

export default function Onboarding() {
  const event = useEvent();
  const navigate = useNavigate();
  useBrowserLanguage();

  const { updateUser, uploadAvatar, acceptPrivacyPolicy } = useUpdateUser();
  const { startRegistration } = useRegister();
  const { isRegistered, user, error, privacyPolicyAccepted, hasRequiredFieldsFilled } =
    useAppSelector((state) => state.auth);

  const [step, setStep] = useState(privacyPolicyAccepted && !hasRequiredFieldsFilled ? 1 : 0);
  const { data: customFields } = useCustomFields();
  const { data: tags } = useTags();
  const theme = useTheme();
  const [errorMessage] = useErrorMessage(error);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => startRegistration(), []);

  useEffect(() => {
    if (error?.response?.response?.status === 409) {
      setStep(0);
    }
  }, [error, setStep]);

  const nextStep = useCallback(() => setStep((current) => current + 1), [setStep]);
  const prevStep = useCallback(() => setStep((current) => current - 1), [setStep]);

  const setUser = useCallback(
    async (raw: (current?: User) => Partial<User> | Partial<User>) => {
      if (!user) return;
      const data: Partial<User> = typeof raw === 'function' ? raw(user) : raw;
      const { ...allData } = data;
      delete allData.avatarUrl;
      await updateUser({
        ...user,
        ...allData,
        tags: data.tags?.map((tag) => tag.id),
      } as any);
    },
    [user, updateUser],
  );

  const onUploadAvatar = useCallback(
    async (avatarFile?: File) => {
      if (avatarFile) await uploadAvatar(avatarFile);
    },
    [uploadAvatar],
  );

  if (!user) return null;

  const renderCurrentStep = () => {
    if (step === 0) {
      return (
        <RegisterForm
          recaptcha={false}
          hasEmail={false}
          user={user}
          onSubmit={(data) => {
            setUser((current) => ({ ...current, ...data }));
            nextStep();
            return true;
          }}
        />
      );
    }

    const hasCustomFields = customFields && customFields.length > 0;

    if (step === 1 && hasCustomFields) {
      return (
        <CustomFieldsForm
          user={user}
          customFields={customFields!}
          onSubmit={(data) => {
            setUser((current) => ({
              ...current,
              fields: Object.keys(data).map((key) => ({
                value: data[key as keyof typeof data],
                customFieldId: key,
              })) as User['fields'],
            }));
            nextStep();
          }}
          onSkip={nextStep}
          onPrev={prevStep}
        />
      );
    }

    const hasTags = tags && tags.length > 0;

    if (hasTags && ((step === 2 && hasCustomFields) || (step === 1 && !hasCustomFields))) {
      return (
        <TagsForm
          user={user}
          tags={tags!}
          onSubmit={(data) => {
            setUser((current) => ({
              ...current,
              tags: data,
            }));
            nextStep();
          }}
          onSkip={nextStep}
          onPrev={prevStep}
        />
      );
    }

    return (
      <AvatarForm
        event={event}
        user={user}
        onSubmit={acceptPrivacyPolicy}
        onPrev={prevStep}
        onUploadAvatar={onUploadAvatar}
        onChange={(avatarUrl) => setUser({ ...user, avatarUrl } as any)}
      />
    );
  };

  const renderLeftSide = () => {
    return (
      <div
        css={css`
          display: none;
          margin: auto 2em auto 0;
          ${mediaQuery(
            's',
            css`
              display: block;
            `,
          )}
        `}
      >
        <RegistrationCard event={event} user={user} customFields={customFields} />
      </div>
    );
  };

  return (
    <EventBackground event={event} blur>
      <div
        css={css`
          position: relative;
          padding: 1em 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          margin-bottom: 1em;
          ${mediaQuery(
            's',
            css`
              display: none;
            `,
          )}
        `}
      >
        <div>{event?.name}</div>
        <div
          css={css`
            position: absolute;
            top: 1em;
            right: 1em;
            cursor: pointer;
            ${mediaQuery(
              's',
              css`
                top: 2em;
                right: 2em;
              `,
            )}
          `}
          onClick={() => navigate('/landing')}
        >
          <Icon icon='times' size={16} color={theme.colors.textLight} />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          width: 100%;
        `}
      >
        {errorMessage && (
          <div
            css={css`
              margin-bottom: 2em;
            `}
          >
            <Alert>{errorMessage}</Alert>
          </div>
        )}

        <div
          css={css`
            box-sizing: border-box;
            width: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {isRegistered ? (
            <RegisterCompleted />
          ) : (
            <div
              css={css`
                width: 100%;
                display: flex;
                justify-content: space-between;
                ${mediaQuery(
                  's',
                  css`
                    max-width: 1140px;
                    margin: 0 15% 0 0;
                  `,
                )}
              `}
            >
              {renderLeftSide()}
              <div
                css={css`
                  width: 100%;

                  ${mediaQuery(
                    's',
                    css`
                      max-width: 400px;
                      max-height: calc(100vh - 50px);
                    `,
                  )}
                `}
              >
                {renderCurrentStep()}
              </div>
            </div>
          )}
        </div>
      </div>
    </EventBackground>
  );
}
