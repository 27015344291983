/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@common/components/Button';
import { useAppSelector } from '@common/store';

export function SlidoMobileItem({ slidoUrl, close }: { slidoUrl?: string; close: () => void }) {
  const theme = useTheme();
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const { t } = useTranslation();

  return (
    <div
      css={css`
        width: 100%;
        margin-top: calc(65px - 1em);
        max-height: calc(100% - (65px - 1em));
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: ${theme.colors.eventBackground};
        ${mobileFullscreen
          ? ''
          : '@media (min-aspect-ratio: 8/7) and (max-width: 900px){  flex-direction: row;justify-content: unset;align-items: unset;}'}
      `}
    >
      <iframe
        css={css`
          border: 0;
          border-radius: 12px;
          width: 100%;
          height: 70%;
        `}
        src={slidoUrl}
        title='Slido'
      />

      <Button
        onClick={close}
        color='primary'
        label={t('back_to_livestream')}
        size='l'
        css={css`
          ${mobileFullscreen ? '' : 'margin-bottom: 70px;'}
          @media (min-aspect-ratio: 8/7) and (max-width: 900px) {
            height: fit-content;
            margin-left: 10px;
          }
        `}
      />
    </div>
  );
}
