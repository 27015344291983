/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { readableColor } from 'polished';

import Icon from '@common/components/Icon';
import { useAppSelector } from '@common/store';

interface IconSwitchProps {
  icon: string;
  size?: number;
  onChange: (value: boolean) => void;
  selected?: boolean;
  inverseColors?: boolean;
  background?: string;
}

const IconSwitch: React.FC<IconSwitchProps> = ({
  icon,
  size = 24,
  onChange,
  selected = false,
  inverseColors = false,
  background: customBackground,
  ...rest
}) => {
  const theme = useTheme();
  const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);

  const calculatedColor = customBackground ?? theme.colors.eventBackground;
  const calculatedBackgroundColor = readableColor(calculatedColor);

  const color = selected
    ? inverseColors
      ? calculatedColor
      : calculatedBackgroundColor
    : inverseColors
    ? calculatedBackgroundColor
    : calculatedColor;
  const background = selected
    ? inverseColors
      ? calculatedBackgroundColor
      : calculatedColor
    : inverseColors
    ? calculatedColor
    : calculatedBackgroundColor;

  return (
    <button
      {...rest}
      onClick={(ev) => {
        ev.stopPropagation();
        onChange?.(!selected);
      }}
      css={css`
        width: ${size}px;
        height: ${size}px;
        max-width: ${size}px;
        max-height: ${size}px;
        display: flex;
        justify-content: center;
        align-items: center;

        ${!elementFullScreen && `backdrop-filter: blur(${theme.blurs.normal});`}
        border: 0;
        cursor: pointer;
        border-radius: ${theme.borderRadius.iconButton};
        transition: 0.2s all ease-in-out;
        position: relative;

        color: ${color};
        background-color: ${background};

        path,
        circle,
        rect,
        ellipse {
          fill: ${color};
          stroke: ${color};
        }

        ${!selected &&
        `
          :hover {
            color: ${background};
            background-color: ${color};

            path, circle, rect, ellipse {
              fill: ${background};
              stroke: ${background};
            }
          }
        `}
      `}
    >
      <IconWrapper>
        <Icon icon={icon} color={color} size={size - 20} />
      </IconWrapper>
    </button>
  );
};

export default IconSwitch;

const IconWrapper = styled.div<{ hidden?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  transition: opacity 0.15s ease-in-out;
  will-change: opacity;
`;
