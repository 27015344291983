import parse, { Attributes } from '@common/utils/parse';

import { parseParticipant } from './participant';
import { parseMessage } from './message';

export const parseRoom = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    participants: attributes.custom('participants', (participant) => {
      return parse.many(participant, parseParticipant);
    }),
    messages: attributes.optional.custom('messages', (message) => {
      return parse.many(message, parseMessage);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseRoom);
}

export function many(data: any) {
  return parse.many(data, parseRoom);
}

export type RoomResource = ReturnType<typeof one>;
export type Room = Omit<RoomResource, 'id'>;

export default { one, many };
