/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import Body from '@common/components/Body';
import Heading from '@common/components/Heading';
import Button from '@common/components/Button';
import type { PushToPageResource } from '@common/store/pusher';

type PushToPageCardProps = {
  pushToPage?: PushToPageResource;
  className?: string;
  onClick?: () => void;
  redirected?: boolean;
};

const PushToPageCard: React.FC<PushToPageCardProps> = ({ pushToPage, onClick, redirected }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const nativeOnClick = () => onClick?.();

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00000099;
        width: 100vw;
        cursor: pointer;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `}
    >
      <div
        css={css`
          box-sizing: border-box;
          display: flex;
          max-width: 480px;
          flex: 1;
          flex-direction: column;
          padding: 64px 48px;
          border-radius: 16px;
          backdrop-filter: blur(${theme.blurs.normal});
          text-align: center;
          justify-content: center;
          align-items: center;
          margin: 1em;
        `}
      >
        <Heading size='h2'>{t('push_to_page_title')}</Heading>
        <Body size='m'>
          {redirected
            ? t('push_to_page_external_session')
            : `${t('push_to_page_subtitle')} ${
                pushToPage?.isVideoCall ? `${t('video_call')}: ` : ''
              }${pushToPage?.title}`}
        </Body>
        <Button
          css={css`
            margin-top: 2em;
          `}
          label={
            redirected
              ? t('go_to_session')
              : `${t('push_to_page_button')} ${
                  pushToPage?.isVideoCall ? t('video_call').toLowerCase() : pushToPage?.title
                }`
          }
          onClick={nativeOnClick}
        />
      </div>
    </div>
  );
};

export default PushToPageCard;
