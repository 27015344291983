/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import useResizeObserver from 'use-resize-observer';

import Icon from '@common/components/Icon';
import IconButton from '@common/components/IconButton';
import IconSwitch from '@common/components/IconSwitch';
import Button from '@common/components/Button';
import { SESSION_STATES } from '@modules/schedule/hooks/useSessionStatus';
import { Platform, usePlatform } from '@common/hooks/usePlatform';
import { useSessionSettings } from '@modules/schedule/hooks/useSessionSettings';
import ButtonWithHoverLabel from '@common/components/ButtonWithHoverLabel';
import { renderLocationIcon } from '@modules/event/utils';
import { SessionResource } from '@common/transforms/session';
import IconHover from '@common/components/IconHover';

interface SessionNormalProps {
  session: SessionResource;
  onNotifyClick: (enabled: boolean) => void;
  onScheduleClick: () => void;
  onRemoveScheduleClick: () => void;
  onWatchClick: () => void;
  onJoinClick: () => void;
  sessionStatus: SESSION_STATES;
  overlapping?: boolean;
  inGridView?: boolean;
  selectedDate?: Date;
}

const SessionNormal: React.FC<SessionNormalProps> = ({
  onNotifyClick,
  onScheduleClick,
  onRemoveScheduleClick,
  onWatchClick,
  onJoinClick,
  sessionStatus,
  inGridView = false,
  overlapping,
  selectedDate,
  session,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { ref: container, width = 0 } = useResizeObserver<HTMLDivElement>();
  const platform = usePlatform();
  const { duration, buttons } = useSessionSettings(session, sessionStatus, selectedDate);

  return (
    <Wrapper
      sessionDuration={duration.total}
      inGridView={inGridView}
      session={session}
      sessionStatus={sessionStatus}
      ref={container}
    >
      <MainContent sessionDuration={duration.total} inGridView={inGridView} width={width}>
        <div
          css={css`
            display: flex;
            width: 100%;
            ${sessionStatus === SESSION_STATES.PASSED && 'opacity: 0.5'}
          `}
        >
          {!!session.imageUrl?.length && (
            <Banner
              sessionDuration={duration.total}
              inGridView={inGridView}
              src={session.imageUrl}
              maxWidth={width}
              alt='banner'
            />
          )}
          <div
            css={css`
              display: block;
              width: ${session.imageUrl?.length
                ? duration.total < 30
                  ? 'calc(100% - 42px - 1rem)'
                  : 'calc(100% - 64px - 1rem)'
                : '100%'};
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                width: 100%;
              `}
            >
              <Title sessionDuration={duration.total}>{session.title}</Title>
              <span
                css={css`
                  margin: 0px 6px;
                `}
              >
                •
              </span>
              {renderLocationIcon(session.location) === 'both' ? (
                <>
                  <ButtonWithHoverLabel labelText={t('attendance_location.onsite')} detailed small>
                    <IconHover icon='pinpoint' size={18} />
                  </ButtonWithHoverLabel>
                  <ButtonWithHoverLabel labelText={t('attendance_location.online')} detailed small>
                    <IconHover icon='laptop' size={18} />
                  </ButtonWithHoverLabel>
                </>
              ) : (
                <ButtonWithHoverLabel
                  labelText={t(`attendance_location.${session.location.toLowerCase()}`)}
                  detailed
                  small
                >
                  <IconHover icon={renderLocationIcon(session.location)} size={18} />
                </ButtonWithHoverLabel>
              )}
            </div>

            {((!!session.speakers && session.speakers.length > 0 && duration.long) ||
              !inGridView) && (
              <Speakers>
                {session?.speakers &&
                  session?.speakers?.length > 0 &&
                  session.speakers
                    .map<React.ReactNode>((speaker) => <span key={speaker.id}>{speaker.name}</span>)
                    .reduce((prev, curr) => [prev, ', ', curr])}
              </Speakers>
            )}
          </div>
        </div>
      </MainContent>
      {(!inGridView || duration.long) && (
        <>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Timeslot
              overlapping={overlapping}
              css={css`
                ${sessionStatus === SESSION_STATES.PASSED && 'opacity: 0.5'}
              `}
            >
              {overlapping ? (
                <Icon icon='warning' color={theme.colors.error} size={16} />
              ) : (
                <Icon icon='clock' color={theme.colors.textLight} size={16} />
              )}

              <span
                css={css`
                  margin-left: 5px;
                `}
              >
                {duration.timeslot}
              </span>
            </Timeslot>
            <div
              css={css`
                display: flex;
                align-self: flex-start;
                align-items: flex-end;
                flex-direction: column;
                flex: none;
                ${(!inGridView || width > 250) && 'margin-left: 1rem'};
              `}
            >
              {buttons.showJoin && (
                <>
                  <Button
                    onClick={() => onJoinClick()}
                    color='secondary'
                    disableMinWidth
                    label={t('joinSession')}
                    size={duration.short ? 'xs' : 's'}
                    style={{ maxWidth: '150px', lineHeight: '1rem' }}
                  />
                </>
              )}
              {buttons.showOndemand && (
                <>
                  {platform === Platform.MOBILE ? (
                    <IconButton onClick={onWatchClick} icon='play' size={40} />
                  ) : (
                    <Button
                      onClick={() => onWatchClick()}
                      color='secondary'
                      disableMinWidth
                      label={t('watchOnDemand')}
                      size={duration.short ? 'xs' : 's'}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <ButtonContainer>
            {buttons.showReminder && (
              <ButtonWithHoverLabel reminder>
                <IconSwitch
                  onChange={onNotifyClick}
                  selected={session.reminder}
                  icon='bell'
                  size={40}
                  inverseColors
                />
              </ButtonWithHoverLabel>
            )}
            {buttons.showMandatory && (
              <ButtonWithHoverLabel mandatory>
                <IconButton icon='mandatory' type='scheduled' size={40} disabled />
              </ButtonWithHoverLabel>
            )}
            {buttons.showAddToSchedule && (
              <ButtonWithHoverLabel labelText={t('addToMySchedule')} marginLeft>
                <IconButton onClick={onScheduleClick} icon='plus' size={40} />
              </ButtonWithHoverLabel>
            )}
            {buttons.showRemoveFromSchedule && (
              <ButtonWithHoverLabel labelText={t('removeFromSchedule')} marginLeft>
                <IconButton
                  icon='checkmark'
                  hoverIcon='times'
                  type='scheduled'
                  size={40}
                  onClick={onRemoveScheduleClick}
                />
              </ButtonWithHoverLabel>
            )}
          </ButtonContainer>
        </>
      )}
    </Wrapper>
  );
};

export default SessionNormal;

const Wrapper = styled.div<{
  sessionDuration: number;
  sessionStatus: SESSION_STATES;
  session: SessionResource;
  inGridView: boolean;
}>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) =>
    !props.inGridView ? 'column' : props.sessionDuration >= 30 ? 'column' : 'row'};
  ${(props) => props.sessionDuration < 30 && props.inGridView && 'align-items: center'};
  justify-content: space-between;
  border-radius: ${(props) => props.theme.borderRadius.normal};
  backdrop-filter: blur(${(props) => props.theme.blurs.normal});
  background-color: ${(props) =>
    props.session.scheduled
      ? props.sessionStatus === SESSION_STATES.PASSED
        ? 'rgba(80, 201, 127, 0.08)'
        : 'rgba(80, 201, 127, 0.16)'
      : 'rgba(255, 255, 255, 0.1)'};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 1em;
  ${(props) =>
    props.sessionStatus !== SESSION_STATES.PASSED &&
    `
    :hover {
      background-color: ${
        props.session.scheduled ? 'rgba(80, 201, 127, 0.3)' : 'rgba(255, 255, 255, 0.15)'
      };
    }
  `};
`;

const MainContent = styled.div<{
  sessionDuration: number;
  inGridView: boolean;
  width: number;
}>`
  display: ${(props) => (props.width < 250 && props.sessionDuration >= 30 ? 'block' : 'flex')};
  justify-content: space-between;
  ${(props) => props.sessionDuration < 30 && 'width: 100%; align-items: center;'}
  ${(props) => !props.inGridView && 'margin-bottom: 1em'};
`;

const Banner = styled.img<{ sessionDuration: number; inGridView: boolean; maxWidth: number }>`
  display: ${(props) => (props.maxWidth < 300 ? 'none' : 'block')};
  object-fit: cover;
  border-radius: ${(props) => props.theme.borderRadius.normal};
  width: ${(props) => (props.sessionDuration <= 30 ? '42px' : '64px')};
  height: ${(props) => (props.sessionDuration <= 30 ? '42px' : '64px')};
  margin-right: 1em;
  flex: none;
  ${(props) => props.sessionDuration <= 15 && !props.inGridView && 'display: none'}
`;

const Title = styled.p<{
  sessionDuration: number;
}>`
  display: ${(props) => (props.sessionDuration <= 30 ? 'block' : '-webkit-box')};
  max-height: 4.6em;
  line-height: 1.2em;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.textLight};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.sessionDuration <= 30 && 'white-space: nowrap'};
`;

const Speakers = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.25em;
  padding: 0;
  color: ${(props) => props.theme.colors.textBody};
  font-size: ${(props) => props.theme.fontSizes.s};
`;

const Timeslot = styled.div<{ overlapping?: boolean }>`
  display: flex;
  border-radius: 100px;
  font-size: ${(props) => props.theme.fontSizes.s};
  color: ${(props) => props.theme.colors.textLight};
  ${(props) => props.overlapping && 'color: #fe6463;'}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;

  button:not(:first-of-type) {
    margin-left: 0.5rem;
  }
`;
