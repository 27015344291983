import React, { ForwardedRef, forwardRef } from 'react';

import { Platform, usePlatform } from '@common/hooks/usePlatform';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export type HeaderProps = {
  title?: React.ReactNode;
  liveMenu?: React.ReactNode;
  mainMenu?: React.ReactNode;
  // eslint-disable-next-line no-undef
  timeline?: GSAPTimeline | null;
};

const Header = forwardRef((props: HeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
  const platform = usePlatform();

  if (platform === Platform.MOBILE) {
    return <MobileHeader {...props} menuTimeline={props.timeline} ref={ref} />;
  }

  return <DesktopHeader {...props} />;
});

export default Header;
