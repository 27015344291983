/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Icon } from '@typing/icons';

const Times: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19 5L5 19'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
        css={css`
          fill: transparent !important;
        `}
      />
      <path
        d='M4.99994 5L18.9999 19'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
        css={css`
          fill: transparent !important;
        `}
      />
    </svg>
  );
};

export default Times;
