import { useCallback, useEffect } from 'react';

import { useAppDispatch } from '@common/store';
import { useCreateChatRoom } from '@modules/chat/hooks/useChat';
import { useOpenChat } from '@modules/chat/hooks/useOpenChat';
import { setActiveItem } from '@common/store/sidebar';

export function useStartChat() {
  const dispatch = useAppDispatch();
  const { mutate: createChat, data: createChatResult } = useCreateChatRoom();
  const openChat = useOpenChat();

  useEffect(() => {
    if (createChatResult) {
      openChat(createChatResult.body.id);
      dispatch(setActiveItem('private_chat'));
    }
  }, [createChatResult, openChat, dispatch]);

  return useCallback(
    async (userId: string) => {
      createChat([userId]);
    },
    [createChat],
  );
}
