/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { SessionResource } from '@common/transforms/session';
import { PIXELS_PER_MINUTE } from '@modules/schedule/utils';

import Session, { DISPLAY_TYPE } from './Session';

type GridViewProps = {
  session: SessionResource;
  dayStats: {
    startTime: Date;
    endTime: Date;
    beginsAtHalf: boolean;
    duration: number;
  };
  selectedDate: Date | undefined;
  stageCount: number;
  stageIndex: number;
  shortVersion: boolean;
};

const GridView: React.FC<GridViewProps> = ({
  session,
  dayStats,
  selectedDate,
  stageCount,
  stageIndex,
  shortVersion,
}) => {
  const calculateEndsDate = () => {
    const spansMultipleDays = dayjs(session.endsAt).isAfter(dayjs(selectedDate), 'day');
    const startsToday = dayjs(session.startsAt).isSame(dayjs(selectedDate), 'day');

    if (spansMultipleDays && startsToday) return dayjs(session.startsAt).endOf('day').toDate();
    if (spansMultipleDays) return dayjs(selectedDate).endOf('day').toDate();
    return new Date(session.endsAt);
  };

  return (
    <GridCard
      stageCount={shortVersion ? 1 : stageCount}
      stageIndex={stageIndex}
      startDate={new Date(session.startsAt)}
      endDate={calculateEndsDate()}
      dayStats={dayStats}
    >
      <Session
        displayType={DISPLAY_TYPE.normal}
        session={session}
        selectedDate={selectedDate}
        inGridView
      />
    </GridCard>
  );
};

export default GridView;

const GridCard = styled.div<{
  stageCount: number;
  stageIndex: number;
  startDate: Date;
  endDate: Date;
  dayStats: {
    duration: number;
    startTime: Date;
    endTime: Date;
  };
}>`
  width: ${(props) => 100 / props.stageCount}%;
  left: ${(props) => (100 / props.stageCount) * props.stageIndex}%;
  top: ${(props) =>
    dayjs(props.startDate).isBefore(props.dayStats.startTime, 'd')
      ? 0
      : Math.floor(dayjs(props.startDate).diff(props.dayStats.startTime, 's') / 60) *
        PIXELS_PER_MINUTE}px;
  bottom: ${(props) =>
    dayjs(props.startDate).isAfter(props.dayStats.endTime, 'd')
      ? 0
      : Math.ceil(
          (props.dayStats.duration -
            (dayjs(props.startDate).diff(props.dayStats.startTime, 's') / 60 -
              dayjs(props.startDate).diff(props.endDate, 'm'))) *
            PIXELS_PER_MINUTE,
        )}px;
  position: absolute;
  padding: 10px;
  box-sizing: border-box;
`;
