/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';

import Icon from '@common/components/Icon';
import Info from '@common/components/Icon/custom/Info';
import { useAppSelector } from '@common/store';
import { mediaQuery } from '@common/styles/mediaQuery';

import { useVideoCall } from '../hooks/useVideoCall';

import { VideoCallDetails } from './VideoCallDetails';

export const VideoCallActive = () => {
  const theme = useTheme();
  const call = useAppSelector((state) => state.videoCall.activeCall);

  const fullscreen = useAppSelector((state) => state.sidebar.fullscreen);
  const { data: videoCall } = useVideoCall(call?.callId);

  const [detailsOpen, setDetailsOpen] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
      `}
    >
      <iframe
        title={videoCall?.title ?? 'videoCall'}
        css={css`
          flex: 1;
          height: 100%;
          border: 0 none;
        `}
        src={videoCall?.hostRoomUrl || videoCall?.roomUrl}
        allow='camera; microphone; fullscreen; speaker; display-capture'
      />
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          padding: 1em;
          cursor: pointer;
          display: ${detailsOpen ? 'none' : 'block'};

          ${mediaQuery(
            'm',
            css`
              display: none;
            `,
          )}
        `}
        onClick={() => setDetailsOpen(true)}
      >
        <Info color={theme.colors.textLight} size={16} />
      </div>
      <div
        css={css`
          position: absolute;
          background-color: ${theme.colors.eventBackground};
          top: 0;
          right: 0;
          padding: 1em;
          cursor: pointer;
          z-index: 9999;
          display: ${detailsOpen ? 'block' : 'none'};

          ${mediaQuery(
            'm',
            css`
              display: none;
            `,
          )}
        `}
        onClick={() => setDetailsOpen(false)}
      >
        <Icon icon='times' size={16} color={theme.colors.textLight} />
      </div>
      {fullscreen && videoCall?.title && (
        <div
          css={css`
            position: fixed;
            background-color: ${theme.colors.eventBackground};
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 2em;
            z-index: ${detailsOpen ? '0' : '99999'};
            display: ${detailsOpen ? 'block' : 'none'};

            ${mediaQuery(
              'm',
              css`
                position: relative;
                flex: 0.3;
                margin-left: 2em;
                padding: 0;
                z-index: auto;
                display: block;
              `,
            )}
          `}
        >
          <VideoCallDetails videoCall={videoCall} />
        </div>
      )}
    </div>
  );
};

export default VideoCallActive;
