import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@common/store';
import { setActiveItem, setFullscreen } from '@common/store/sidebar';
import { VideoCallResource } from '@common/transforms/videoCall';

import { setInvite } from '../store/videoCall';

import { CallCard } from './CallCard';

interface Props {
  videoCall?: VideoCallResource;
  onEnd?: () => void;
}

export const ActiveCallOverlay = ({ videoCall, onEnd }: Props) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  const dispatch = useAppDispatch();
  const activeItem = useAppSelector((state) => state.sidebar.activeItem);
  const fullscreen = useAppSelector((state) => state.sidebar.fullscreen);

  const caller = useMemo(() => {
    if (!videoCall?.participants) {
      return undefined;
    }

    if (videoCall.participants.length > 2) {
      return t('group_call');
    }

    return videoCall.participants.find((participant) => participant.user?.id !== user?.id)?.user
      ?.fullName;
  }, [videoCall, user, t]);

  const setFullScreen = useCallback(() => {
    if (activeItem !== 'video_call') {
      dispatch(setActiveItem('video_call'));
      dispatch(setFullscreen(true));
    } else {
      dispatch(setFullscreen(!fullscreen));
    }
  }, [dispatch, activeItem, fullscreen]);

  const addUser = useCallback(() => {
    dispatch(setInvite(true));
  }, [dispatch]);

  return (
    <CallCard
      small
      title={videoCall?.title || caller}
      subtitle={t('active_call_text')}
      addUser={!videoCall?.title ? addUser : undefined}
      fullscreen={fullscreen}
      setFullScreen={setFullScreen}
      onEnd={onEnd}
    />
  );
};
