import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '@common/components/Loader';

import useEvent from '@modules/event/hooks/useEvent';

export default function Saml() {
  const event = useEvent();
  const navigate = useNavigate();

  useEffect(() => {
    if (!event?.samlEnabled) {
      navigate('/');
      return;
    }

    document.location.href = `${process.env.REACT_APP_API_URL}/auth/${event.id}/saml`;
  }, [event, navigate]);

  return <Loader />;
}
