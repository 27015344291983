import { register } from 'timeago.js';
import nl from 'timeago.js/lib/lang/nl';
import en from 'timeago.js/lib/lang/en_US';
import es from 'timeago.js/lib/lang/es';
import de from 'timeago.js/lib/lang/de';
import fr from 'timeago.js/lib/lang/fr';

register('de', de);
register('en', en);
register('es', es);
register('fr', fr);
register('nl-be', nl);
register('nl', nl);
