/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import {
  ScheduleTemplate,
  AttendeesTemplate,
  IFrameTemplate,
  // LandingTemplate,
  LiveTemplate,
  VideoOnDemandTemplate,
  VirtualVenueTemplate,
  BreakOutTemplate,
  BasicTemplate,
} from '@modules/event/components/PageTemplate';
import Loader from '@common/components/Loader';

import { useEvent } from '@modules/event/hooks/useEvent';
import { usePage } from '@modules/event/hooks/usePages';
import { useUpdateLocation } from '@modules/event/hooks/useLocation';

import { PageTemplate } from '@common/transforms/page';
import { UserRolePermission } from '@common/transforms/userRole';
import { useAppSelector } from '@common/store';
import PageWrapper from '@common/components/PageWrapper';
import { CanvasTemplate } from '@modules/event/components/PageTemplate/Canvas/Canvas';

import { useSessionContext } from '@modules/schedule/hooks/useSessionContext';
import { useOpenChat } from '@modules/chat/hooks/useOpenChat';
import { removeQueryParamsFromRouter } from '@modules/event/utils';
import { useClapHands } from '@common/hooks/usePusher';

export default function Page() {
  const { slug, chat } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const pageIdFromUrl = query.get('page');

  const event = useEvent();
  const setChat = useOpenChat();
  const { data: page } = usePage(slug as string);
  const user = useAppSelector((state) => state.auth.user);
  const fullscreen = useAppSelector((state) => state.sidebar.fullscreen);
  const { mutate: updateLocation } = useUpdateLocation();
  const { joinSession } = useSessionContext();
  const { count, clap } = useClapHands();

  const userCanPrivateChat = user?.role?.permissions.includes(
    UserRolePermission.CREATE_PRIVATE_CHAT,
  );

  useEffect(() => {
    if (!chat?.length || !event?.privateChatEnabled || !userCanPrivateChat) return;
    setChat(chat as string);
    removeQueryParamsFromRouter(navigate, ['chat']);
  }, [chat, event?.privateChatEnabled, userCanPrivateChat, navigate, setChat]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (page?.id) {
      updateLocation([page.id]);
      interval = setInterval(() => {
        updateLocation([page.id]);
      }, 300_000); // Run every 5 minutes
    }
    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?.id]);

  useEffect(() => {
    if (pageIdFromUrl && typeof pageIdFromUrl === 'string') {
      joinSession(`?page=${pageIdFromUrl}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIdFromUrl]);

  const renderTemplate = () => {
    switch (page?.template) {
      case PageTemplate.SCHEDULE:
        return <ScheduleTemplate page={page} />;
      case PageTemplate.ATTENDEES:
        return (
          <AttendeesTemplate
            page={page}
            privateChatEnabled={event?.privateChatEnabled && userCanPrivateChat}
            videoCallEnabled={event?.videoCallEnabled}
            hostChatEnabled={event?.hostChatEnabled}
          />
        );
      case PageTemplate.IFRAME:
        return <IFrameTemplate page={page} />;
      // case PageTemplate.LANDING:
      //   return <LandingTemplate page={page} />;
      case PageTemplate.LIVE:
        return <LiveTemplate page={page} count={count} clap={clap} />;
      case PageTemplate.VIDEO_ON_DEMAND:
        return <VideoOnDemandTemplate page={page} />;
      case PageTemplate.VIRTUAL_VENUE:
        return <VirtualVenueTemplate page={page} />;
      case PageTemplate.BREAKOUT:
        return <BreakOutTemplate page={page} />;
      case PageTemplate.BASIC:
        return <BasicTemplate page={page} />;
      case PageTemplate.CANVAS:
        return <CanvasTemplate page={page} />;
      default:
        return <Loader />;
    }
  };

  return (
    <PageWrapper>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          width: 0;
          overflow: ${fullscreen && 'hidden'};
        `}
      >
        {renderTemplate()}
      </div>
    </PageWrapper>
  );
}
