import { useEffect, useState } from 'react';
import { PusherProvider as Provider } from '@harelpls/use-pusher';

import { pusherConfig } from '@config';
import { getAccessToken } from '@modules/auth/hooks/useAuth';
import { useAppSelector } from '@common/store';
import { PusherInitializer } from '@common/hooks/usePusher';

const PusherProvider: React.FC = ({ children }) => {
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  const [refreshing, setRefreshing] = useState(false);
  useEffect(() => {
    document.addEventListener('vle-login', () => setRefreshing(true));
  }, []);
  useEffect(() => {
    if (refreshing) setRefreshing(false);
  }, [refreshing]);

  if (!isAuthorized || !accessToken || refreshing) {
    return <>{children}</>;
  }

  return (
    <Provider {...pusherConfig(accessToken)}>
      <PusherInitializer>{children}</PusherInitializer>
    </Provider>
  );
};

export default PusherProvider;
