import { Icon } from '@typing/icons';

const Live: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Live;
