import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import fetch from '@common/utils/fetch';
import { useAppDispatch } from '@common/store';
import transform from '@common/transforms/user';
import transformToken from '@common/transforms/token';

import { useEvent } from '@modules/event/hooks/useEvent';
import { getTokens, setTokens } from '@modules/auth/hooks/useAuth';
import { checkRequiredFieldsFilled, readAccessTokenExpiry } from '@modules/auth/utils';
import {
  setAccepted,
  setAuthorized,
  setError,
  setPrivacyPolicyAccepted,
  setRequiredFieldsFilled,
  setUser,
} from '@modules/auth/store/auth';
import { Beams } from '@common/hooks/useBeams';

export const useRefresh = () => {
  const event = useEvent();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const asPath = location.pathname + location.search;
  return useCallback(async () => {
    if (!event) return;
    if (/^\/auth\/login\/[\w-]+$/.test(asPath)) {
      setTokens({});
      return;
    }
    let tokens = getTokens();
    if (!tokens.refreshToken) return;

    try {
      if (tokens.accessToken) {
        const expiry = readAccessTokenExpiry();
        // if expiry date is in less than a week, refresh token
        if (expiry && expiry.getTime() - new Date().getTime() < 604_800_000) {
          try {
            const response = await fetch(`/auth/${event.id}/refresh`, {
              method: 'POST',
              body: { refreshToken: tokens.refreshToken },
            });

            const newTokens = transformToken(response.body);
            tokens = setTokens(newTokens);
          } catch (err: any) {
            if (err?.cause?.response?.body?.message !== 'Unauthorized') {
              throw err;
            }
          }
        }
      }

      try {
        const userResponse = await fetch('/users/me', { token: tokens.accessToken });
        const userTransform = transform.one(userResponse.body);

        dispatch(setAuthorized(true));
        dispatch(setAccepted(!!userTransform.accepted));
        dispatch(setPrivacyPolicyAccepted(!!userTransform.privacyPolicyAccepted));
        dispatch(setRequiredFieldsFilled(checkRequiredFieldsFilled(userTransform, event)));
        if (tokens.accessToken) {
          try {
            Beams().then(async (client) => {
              await client.setUserId(tokens.accessToken!, userTransform.id);
              await client.setEventId(event.id);
            });
            // eslint-disable-next-line no-empty
          } catch {}
        }
        dispatch(setUser(userTransform));
      } catch (err: any) {
        if (err?.cause?.response?.body?.message !== 'Unauthorized') {
          dispatch(
            setError(
              err?.cause?.response?.body?.message ||
                err?.response?.body?.message ||
                err?.response?.body?.error ||
                err?.message,
            ),
          );
        }
      }
    } catch (err: any) {
      Beams().then((client) => {
        client.unregister();
      });
      dispatch(
        setError(
          err?.cause?.response?.body?.message ||
            err?.response?.body?.message ||
            err?.response?.body?.error ||
            err?.message,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- do not want to re-run on path change
  }, [dispatch, event]);
};
