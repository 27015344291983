/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';
import { Field, FieldAttributes, FormikState } from 'formik';

import FieldWrapper from '@common/components/FieldWrapper';
import { DIRECTION } from '@common/utils/consts';

type SelectOption = {
  value: string;
  label: string;
};

type SelectProps = {
  className?: string;
  icon?: string;
  size?: 'm' | 's';
  options: SelectOption[];
  value?: string;
};

const SelectComponent: React.FC<
  {
    field: FieldAttributes<any>;
    form: FormikState<any>;
  } & SelectProps
> = ({ field, form: { touched, errors }, className, icon, size = 'm', options, ...props }) => {
  const theme = useTheme();
  const error = touched[field.name] && errors[field.name];

  return (
    <FieldWrapper
      border
      className={className}
      leftIcon={icon}
      rightIcon='chevron'
      rightIconProps={{ direction: DIRECTION.DOWN }}
      error={!!error}
    >
      <select
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          border: 0;
          font-size: ${theme.fontSizes[size]};
          color: ${error ? theme.colors.errorText : theme.colors.textLight};
          user-select: none;
          -webkit-appearance: none;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          box-sizing: border-box;
          padding: ${theme.paddings.input};
          padding-right: 32px;
          padding-top: 0;
          padding-bottom: 0;

          &:focus {
            outline: none;
            color: ${theme.colors.textLight};
          }

          > option {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        `}
        {...field}
        {...props}
      >
        {options.map((option, index) => (
          <option
            css={css`
              color: #000;
            `}
            key={option.value ?? index}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </FieldWrapper>
  );
};

const Select: React.FC<
  FieldAttributes<React.SelectHTMLAttributes<HTMLSelectElement>> & SelectProps
> = (props) => <Field component={SelectComponent} {...props} />;

export default Select;
