/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { readableColor } from 'polished';

import Avatar from '@modules/user/components/Avatar';
import Body from '@common/components/Body';
import Card from '@common/components/Card';
import Chip from '@common/components/Chip';
import Button from '@common/components/Button';

import { CallStatus } from '@common/transforms/user';
import { UserRolePermission } from '@common/transforms/userRole';
import { LocationResource } from '@common/transforms/location';
import { useAppSelector } from '@common/store';
import Icon from '@common/components/Icon';

type BoothCardProps = {
  location: LocationResource;
  onOpenChat?: (participantId: string) => void;
  onCallUser?: (participantId: string) => void;
};

const BoothCard: React.FC<BoothCardProps> = ({ location, onOpenChat, onCallUser }) => {
  const self = useAppSelector((state) => state.auth.user);
  const theme = useTheme();

  const chatVisible =
    !!onOpenChat &&
    (!location.user?.role?.permissions.includes(UserRolePermission.HIDE_USERS) ||
      self?.role?.permissions.includes(UserRolePermission.HIDE_USERS));
  const callVisible =
    location.user?.isOnline && location.user?.callStatus === CallStatus.AVAILABLE && onCallUser;

  return (
    <Card
      opacity={0.3}
      appearance='dark'
      css={css`
        padding: 1em;
        border-color: ${theme.colors.primary};
        border-width: 2px;
        border-style: solid;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Avatar user={location.user} size={56} />
          {location.user?.isOnline && (
            <div
              css={css`
                position: absolute;
                margin-top: -24px;
                margin-left: 24px;
                background-color: lightgreen;
                width: 8pt;
                height: 8pt;
                border-radius: 10pt;
                border-width: 2pt;
                border-style: solid;
                border-color: ${theme.colors.eventBackground};
              `}
            />
          )}
        </div>

        <div
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-left: 1.5em;
            position: relative;
          `}
        >
          <Body
            weight='bold'
            color='textLight'
            size='s'
            css={css`
              margin: 0.5em 0;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
            >
              <Body
                weight='bold'
                color='textLight'
                size='m'
                css={css`
                  margin-bottom: 0.5em;
                `}
              >
                {location.user?.fullName}
              </Body>
              {location.user.role?.visible && !!location.user.role?.name && (
                <Chip size='xs'>{location.user.role.name}</Chip>
              )}
            </div>
          </Body>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          margin-top: 1em;
        `}
      >
        {chatVisible && (
          <Button
            label={<Icon icon='chat' color={readableColor(theme.colors.primary)} />}
            size='xs'
            css={css`
              flex: 1;
              margin-right: 0.5em;
            `}
            disableMinWidth
            onClick={() => onOpenChat!(location.user.id)}
          />
        )}
        {callVisible && (
          <Button
            label={<Icon icon='phone' color={readableColor(theme.colors.primary)} />}
            size='xs'
            css={css`
              flex: 1;
              margin-left: 0.5em;
            `}
            disableMinWidth
            onClick={() => onCallUser!(location.user.id)}
          />
        )}
      </div>
    </Card>
  );
};

export default BoothCard;
