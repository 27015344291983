/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Transition } from 'react-transition-group';

import { mediaQuery } from '@common/styles/mediaQuery';
import { useAppSelector } from '@common/store';
import type { SidebarItem } from '@common/store/sidebar';

import { SidebarButton } from './SidebarButton';

type SidebarProps = {
  fullscreen?: boolean;
  items: SidebarItem[];
  active: string | undefined;
  toggle: (key: string) => void;
};

function KeepTransition({
  actual,
  duration = 1000,
}: {
  actual: React.ReactNode | null;
  duration?: number;
}) {
  const [content, setContent] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    if (actual) {
      setContent(actual);
      return () => null;
    }

    const timeout = setTimeout(() => setContent(null), duration);
    return () => clearTimeout(timeout);
  }, [actual, duration]);

  return content as JSX.Element;
}

export const Sidebar: React.FC<SidebarProps> = ({ fullscreen, items, active, toggle }) => {
  const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);

  const barRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const hasActiveCall = useAppSelector((state) => state.videoCall.activeCall !== undefined);

  useEffect(() => {
    if (fullscreen) setIsOpen(true);
  }, [fullscreen]);

  // re-open sidebar when switching from mobile to desktop
  useEffect(() => {
    const resizer = () => {
      if (active && active !== 'video_call') {
        setIsOpen(true);
      }
    };
    window.addEventListener('resize', resizer);
    return () => window.removeEventListener('resize', resizer);
  }, [active]);

  const activeItem = useMemo(
    () => items.find((item) => item.key === active)?.render(),
    [active, items],
  );

  return (
    <Container fullscreen={fullscreen} elementFullScreen={elementFullScreen}>
      {elementFullScreen && <BackgroundBlur $active={!!active} />}
      <Transition
        in={!!active}
        mountOnEnter
        unmountOnExit
        addEndListener={() => {
          if (active) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
        }}
      >
        <ActiveItemContainer
          ref={barRef}
          $isOpen={isOpen}
          $elementFullScreen={elementFullScreen}
          $fullscreen={!!fullscreen}
          $hasActiveCall={hasActiveCall}
        >
          <ActiveItem
            $elementFullScreen={elementFullScreen}
            $fullScreen={fullscreen}
            $active={active}
            $hasActiveCall={hasActiveCall}
          >
            <KeepTransition actual={activeItem} />
          </ActiveItem>
        </ActiveItemContainer>
      </Transition>
      <Items $elementFullScreen={elementFullScreen}>
        {Array.isArray(items) &&
          items.length > 0 &&
          items.map((item: SidebarItem) => (
            <SidebarButton
              key={item.key}
              item={item}
              active={item.key === active}
              toggle={toggle}
              setIsOpen={setIsOpen}
            />
          ))}
      </Items>
    </Container>
  );
};

const Container = styled.div<{
  fullscreen?: boolean;
  elementFullScreen: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-right: 2em;
  padding-left: 2em;
  padding-bottom: 2em;
  justify-content: flex-end;
  ${(props) => props.fullscreen && 'width: 100%;'}
  background-color: ${(props) =>
    !props.elementFullScreen ? props.theme.colors.eventBackground : 'unset'};
  z-index: 1;
  ${(props) =>
    props.elementFullScreen
      ? `
      position: absolute;
      right: 0;
      margin-top: 2rem;
      padding-right: 1em;
      padding-top: 1rem;
      min-height: 60%;`
      : ''}
`;

const BackgroundBlur = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 1rem;
  left: 2rem;
  right: 6rem;
  bottom: -2rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.05);
  pointer-events: none;
  z-index: 1;

  will-change: opacity;
  opacity: ${(props) => (props.$active ? '1' : '0')};
  transition: opacity 0.6s ease-in-out;
`;

const getWidth = (props: any) => {
  if (props.$fullscreen && props.$hasActiveCall) {
    return '100%';
  }
  if (props.$isOpen || props.$elementFullScreen) {
    return '384px';
  }
  return '0px';
};

const ActiveItemContainer = styled.div<{
  $isOpen: boolean;
  $elementFullScreen: boolean;
  $fullscreen: boolean;
  $hasActiveCall: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  z-index: 2;

  will-change: width, opacity;
  width: ${(props) => getWidth(props)};
  opacity: ${(props) => (props.$isOpen ? '1' : '0')};
  transition: width 0.8s cubic-bezier(0.75, -0.5, 0, 1.75),
    opacity ${(props) => (props.$isOpen ? '0.8s' : '0.4s')} ease-in-out
      ${(props) => (props.$isOpen && !props.$elementFullScreen ? '0.5s' : '0s')};
`;

const ActiveItem = styled.div<{
  $elementFullScreen: boolean;
  $fullScreen?: boolean;
  $active?: string;
  $hasActiveCall: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  margin-right: 1.5em;
  flex-direction: column;
  ${(props) =>
    props.$elementFullScreen && props.$active !== 'slido'
      ? css`
          backdrop-filter: blur(30px);
          opacity: 0.9;
          padding: 2rem;
          border-radius: 1rem;
        `
      : ''}
  ${(props) =>
    mediaQuery(
      'm',
      css`
        width: ${props.$fullScreen && props.$hasActiveCall ? '100%' : '360px'};
        ${props.$elementFullScreen && props.$active !== 'slido'
          ? css`
              width: 320px;
            `
          : ''}
      `,
    )}
`;

const Items = styled.div<{ $elementFullScreen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  padding-top: 5px;
  z-index: 2;
  ${(props) =>
    props.$elementFullScreen
      ? css`
          padding: 1rem;
          border-radius: 1rem;
          height: fit-content;
          background-color: #ffffff26;
          backdrop-filter: blur(30px);
          width: 24px;
          padding-bottom: 0rem;
        `
      : ''};
`;
