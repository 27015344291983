import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  createContext,
  useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import dayjs from 'dayjs';

import {
  useGetSession,
  useSessionsCount,
  useScheduledSessionsCount,
} from '@modules/schedule/hooks/useSessions';
import { PageResource } from '@common/transforms/page';
import { SessionLocation, SessionResource } from '@common/transforms/session';
import SessionDetails from '@modules/schedule/components/SessionDetail';
import useEvent from '@modules/event/hooks/useEvent';
import Loader from '@common/components/Loader';
import Overlay from '@common/components/Overlay';
import { Platform, usePlatform } from '@common/hooks/usePlatform';

import { useAppSelector } from '@common/store';

import Desktop from './desktop';
import Mobile from './mobile/index';

interface WindowWithSession extends Window {
  sessionOpen?: true;
}

export type ScheduleContext = {
  selectedDate?: Date;
  setSelectedDate: Dispatch<SetStateAction<Date | undefined>>;
  viewType: 'list' | 'grid';
  setViewType: Dispatch<SetStateAction<'list' | 'grid'>>;
  scheduled: boolean;
  setScheduled: Dispatch<SetStateAction<boolean>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  selectedLocation: SessionLocation;
  setSelectedLocation: Dispatch<SetStateAction<SessionLocation>>;
};

// eslint-disable-next-line no-redeclare
const ScheduleContext = createContext<ScheduleContext>({
  selectedDate: undefined,
  setSelectedDate: () => null,
  viewType: 'grid',
  setViewType: () => null,
  scheduled: false,
  setScheduled: () => false,
  query: '',
  setQuery: () => '',
  selectedLocation: SessionLocation?.ONLINE || 'ONLINE',
  setSelectedLocation: () => SessionLocation,
});

const RegularView: React.FC<{ platform: Platform; page: PageResource }> = ({ platform, page }) => {
  const rawDates = useSessionsCount();
  const dates = useMemo(() => rawDates?.data, [rawDates?.data]);
  const event = useEvent();

  if (!dates) return <Loader />;

  return platform === Platform.MOBILE ? (
    <Mobile page={page} dates={dates} context={ScheduleContext} />
  ) : (
    <Desktop
      disableDateSelector={event?.id === '38cdb25c-8f67-4673-82cf-6e5e06cfeea4'}
      page={page}
      dates={dates}
      context={ScheduleContext}
    />
  );
};

const ScheduledView: React.FC<{ platform: Platform; page: PageResource }> = ({
  platform,
  page,
}) => {
  const rawDates = useScheduledSessionsCount();
  const dates = useMemo(() => rawDates?.data, [rawDates?.data]);
  const event = useEvent();

  if (!dates) return <Loader />;

  return platform === Platform.MOBILE ? (
    <Mobile page={page} dates={dates} context={ScheduleContext} />
  ) : (
    <Desktop
      disableDateSelector={event?.id === '38cdb25c-8f67-4673-82cf-6e5e06cfeea4'}
      page={page}
      dates={dates}
      context={ScheduleContext}
    />
  );
};

export function ScheduleTemplate({ page }: { page: PageResource }) {
  const platform = usePlatform();

  const [viewType, setViewType] = useState<'list' | 'grid'>('grid');
  const [scheduled, setScheduled] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(dayjs().toDate());
  const [query, setQuery] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<SessionLocation>(SessionLocation.ONLINE);

  const [openModal, setOpenModal] = useState<SessionResource>();
  const closeModal = () => setOpenModal(undefined);
  const getSession = useGetSession();

  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    setSelectedLocation(user!.attendance as SessionLocation);
  }, [user]);

  useEffect(() => {
    if ((window as WindowWithSession).sessionOpen) return;
    const sessionId = queryString.parse(window.location.search).show as string | undefined;
    if (!sessionId) return;
    getSession(sessionId)
      .then(({ body: session }) => {
        setOpenModal(session);
        (window as WindowWithSession).sessionOpen = true;
      })
      .catch((error) => {
        console.error('Unable to retrieve session', error);
      });
  }, [getSession]);

  return (
    <>
      <ScheduleContext.Provider
        value={{
          selectedDate,
          setSelectedDate,
          viewType,
          setViewType,
          scheduled,
          setScheduled,
          query,
          setQuery,
          selectedLocation,
          setSelectedLocation,
        }}
      >
        {scheduled ? (
          <ScheduledView platform={platform} page={page} />
        ) : (
          <RegularView platform={platform} page={page} />
        )}
      </ScheduleContext.Provider>
      {ReactDOM.createPortal(
        <Overlay closeModal={closeModal} openModal={!!openModal}>
          {openModal && <SessionDetails session={openModal} onClose={closeModal} />}
        </Overlay>,
        document.querySelector('#popupHolder') ?? document.body,
      )}
    </>
  );
}
