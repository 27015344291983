import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '@common/components/Loader';

import useProtected from '@modules/auth/hooks/useProtected';
import { useAppSelector } from '@common/store';

export default function RedirectPage() {
  useProtected();
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { redirect_to: redirectTo } = useParams();

  useEffect(() => {
    if (redirectTo) {
      window.location.replace(`${redirectTo}?userId=${user?.id}` as string);
    } else {
      navigate('/landing');
    }
  }, [navigate, redirectTo, user]);

  return <Loader />;
}
