export const isExternalUrl = (link: string) => {
  return link.indexOf('http://') === 0 || link.indexOf('https://') === 0;
};

export const isLocalUrl = (link: string) => {
  return !isExternalUrl(link);
};

export const isQueryUrl = (link: string) => {
  return link.startsWith('?');
};

export const openExternalWebsite = (link?: string) => {
  if (link && !isExternalUrl(link)) {
    window.open(`https://${link}`, '_blank');
  } else {
    window.open(link, '_blank');
  }
};
