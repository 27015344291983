import { Icon } from '@typing/icons';

const Warning: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 22C13.9779 22 15.9113 21.4135 17.5558 20.3147C19.2003 19.2159 20.4821 17.654 21.2389 15.8268C21.9958 13.9995 22.1936 11.9888 21.8078 10.049C21.4219 8.1092 20.4698 6.32745 19.0713 4.92893C17.6728 3.5304 15.8909 2.57796 13.951 2.19211C12.0112 1.80626 10.0004 2.00423 8.17314 2.76111C6.34588 3.51799 4.78404 4.79978 3.68523 6.44428C2.58641 8.08877 2.00004 10.0222 2.00004 12C2.00302 14.6513 3.05745 17.193 4.93217 19.0677C6.80689 20.9424 9.3488 21.997 12 22V22ZM12 7.58991C12.199 7.58991 12.3898 7.66898 12.5304 7.80963C12.6711 7.95029 12.7502 8.14112 12.7502 8.34003V12.9337C12.7502 13.1326 12.6711 13.3234 12.5304 13.4641C12.3898 13.6047 12.199 13.6837 12 13.6837C11.8011 13.6837 11.6103 13.6047 11.4697 13.4641C11.329 13.3234 11.2499 13.1326 11.2499 12.9337V8.34003C11.2499 8.14112 11.329 7.95029 11.4697 7.80963C11.6103 7.66898 11.8011 7.58991 12 7.58991ZM11.2499 15.79C11.2499 15.5911 11.329 15.4002 11.4697 15.2596C11.6103 15.1189 11.8011 15.04 12 15.04C12.199 15.04 12.3898 15.1189 12.5304 15.2596C12.6711 15.4002 12.7502 15.5911 12.7502 15.79V16.1649C12.7502 16.3638 12.6711 16.5546 12.5304 16.6953C12.3898 16.8359 12.199 16.915 12 16.915C11.8011 16.915 11.6103 16.8359 11.4697 16.6953C11.329 16.5546 11.2499 16.3638 11.2499 16.1649V15.79Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Warning;
