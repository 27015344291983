/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import Scrollable from '@common/components/Scrollable';

import ChatInputBar from '@modules/chat/components/ChatInputBar';
import ChatMessage from '@modules/chat/components/ChatMessage';

import { MessageResource } from '@common/transforms/message';
import { UserResource } from '@common/transforms/user';
import { useAppSelector } from '@common/store';

type ChatConversationProps = {
  messages?: MessageResource[];
  user?: UserResource;
  linksEnabled?: boolean;
  privateChatEnabled?: boolean;
  onSubmit: (message: string) => void;
};

const ChatConversation: React.FC<ChatConversationProps> = ({
  messages,
  user,
  onSubmit,
  linksEnabled = false,
  privateChatEnabled = false,
}) => {
  const self = useAppSelector((state) => state.auth.user);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
      `}
    >
      <Scrollable
        startAtBottom
        css={css`
          flex: 1;
        `}
      >
        {messages
          ?.filter(
            (message, index, _self) => index === _self.findIndex((msg) => msg.id === message.id),
          )
          .map((message) => (
            <ChatMessage
              key={message.id}
              self={self}
              message={message}
              linksEnabled={linksEnabled}
              privateChatEnabled={privateChatEnabled}
              css={css`
                margin-top: 1em;
              `}
            />
          ))}
      </Scrollable>
      {user && (
        <ChatInputBar
          user={user}
          css={css`
            margin-top: 1em;
          `}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default ChatConversation;
