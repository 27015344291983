/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';

type VixyPlayerProps = {
  token?: string; // Kaltura token from backend
  playerId: number; // Player ID
  entryId: string; // Entry ID
  partnerId: string; // Partner ID
  position?: number;
  startedPlaying?: () => void;
  stoppedPlaying?: () => void;
  pageTemplate?: string;
};

const VixyPlayer: React.FC<VixyPlayerProps> = ({
  token,
  playerId,
  entryId,
  partnerId,
  pageTemplate,
  position = 0,
  startedPlaying,
  stoppedPlaying,
}) => {
  const location = useLocation();

  useEffect(() => {
    stoppedPlaying?.();
  }, [location, stoppedPlaying]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://platform.vixyvideo.com/p/${partnerId}/sp/54700/embedIframeJs/uiconf_id/${playerId}/partner_id/${partnerId}`;
    script.id = 'vixyVideo';
    document.body.appendChild(script);

    const playerPlaceholder = document.getElementById('vixyPlayer');

    const disableFullscreenIfLive = pageTemplate === 'LIVE' && {
      EmbedPlayer: {
        EnableFullscreen: false,
      },
    };

    script?.addEventListener?.('load', () => {
      if (!window.kWidget?.embed) {
        console.error('Vixy script not loaded (yet)');
        return;
      }

      window.kWidget.embed({
        targetId: 'vixyplayer-video',
        wid: `_${partnerId}`,
        uiconf_id: playerId,
        flashvars: {
          ks: token,
          ...disableFullscreenIfLive,
        },
        cache_st: 1606750813,
        entry_id: `${entryId}`,
        readyCallback: (targetId: string) => {
          const instance = document.getElementById(targetId) as unknown as {
            kBind: (event: string, callback: () => void) => void;
          };

          if (startedPlaying) instance.kBind('playerPlayed', startedPlaying);
          if (stoppedPlaying) instance.kBind('playerPaused', stoppedPlaying);

          // this handles most browser/tab close cases, except for force close or power loss
          document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
              stoppedPlaying?.();
            }
          });
        },
      });

      const player = document.getElementById('vixyplayer-video');
      playerPlaceholder?.appendChild(player!.children[0]);
    });

    return () => script.remove();
  }, [partnerId, playerId, token, position, entryId, pageTemplate, startedPlaying, stoppedPlaying]);

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `}
      id='vixyPlayer'
      key={playerId}
    />
  );
};

export default VixyPlayer;
