/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import { useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';

import Button from '@common/components/Button';
import { useEvents } from '@modules/event/hooks/useEvent';

export type Filter = {
  label: string;
  key: string;
  type: 'tag' | 'role';
};

type FilterRowProps = {
  filters: Filter[];
  selected?: Filter[];
  onFilterClick?: (filter: Filter) => void;
  onMoreClick?: (enabled: boolean) => void;
} & React.HTMLAttributes<HTMLDivElement>;

function getTextWidth(txt: string, font: string) {
  const element = document.createElement('canvas');
  const context = element.getContext('2d')!;
  context.font = font;
  return context.measureText(txt).width;
}

const BUTTON_PADDING = 20; // in px
const BUTTON_MARGIN = 20; // in px
const FONT_SIZE = '16px';
const FONT_FAMILY = 'SofiaPro, Helvetica, Arial, sans-serif';

const FilterRow = ({ filters, selected, onFilterClick, onMoreClick }: FilterRowProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [filtersLimit, setFiltersLimit] = useState(10000);

  const { event } = useEvents();

  useEffect(() => {
    function calculateFiltersLimit() {
      if (!filters || !containerRef.current) return;
      const maxWidth = containerRef.current.getBoundingClientRect().width - 80; // 80px space for +[overflow] button

      let totalWidth = 0;
      let totalFilters = 0;

      for (let i = 0; i < filters.length; i += 1) {
        if (totalWidth >= maxWidth) break;

        const newWidth =
          BUTTON_PADDING +
          getTextWidth(filters[i].label, `${FONT_SIZE} ${FONT_FAMILY}`) +
          BUTTON_PADDING +
          BUTTON_MARGIN;

        if (totalWidth + newWidth > maxWidth) break;
        else {
          totalWidth += newWidth;
          totalFilters += 1;
        }
      }

      setFiltersLimit(totalFilters);
    }

    calculateFiltersLimit();
    window.addEventListener('resize', calculateFiltersLimit);

    return () => {
      window.removeEventListener('resize', calculateFiltersLimit);
    };
  }, [filters, containerRef]);

  let filteredRows = filters.filter((f) => f.key !== 'GUEST'); // TODO: replace with dynamic roles
  if (!event?.rolesEnabled) filteredRows = filteredRows?.filter((f) => f.type !== 'role');

  return (
    <div
      ref={containerRef}
      css={css`
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: -0.2em;

        > * {
          margin-left: ${BUTTON_MARGIN}px;

          :first-of-type {
            margin-right: 0;
          }
        }
      `}
    >
      {filteredRows.map((filter, index) => (
        <Button
          key={filter.key}
          outline={!selected?.includes(filter)}
          label={filter.label}
          size='xs'
          disableMinWidth
          css={css`
            ${index + 1 > filtersLimit ? 'display: none;' : ''}
          `}
          onClick={() => onFilterClick?.(filter)}
        />
      ))}
      {filtersLimit !== 10000 && (
        <Button
          outline
          key='expand'
          label={`+${filters.length - filtersLimit}`}
          size='s'
          disableMinWidth
          css={css`
            ${filtersLimit === filters.length ? 'display: none;' : ''}
          `}
          onClick={() => onMoreClick?.(true)}
        />
      )}
    </div>
  );
};

export default FilterRow;
