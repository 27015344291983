/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import dayjs from 'dayjs';

import Body from '@common/components/Body';
import Heading from '@common/components/Heading';
import Clock from '@common/components/Icon/custom/Clock';
import { TimeIndicator } from '@common/components/TimeIndicator';
import { UrlCopyButton } from '@common/components/UrlCopyButton';
import { filterNull } from '@common/utils/arrays';
import AttendeesIndicator from '@modules/user/components/AttendeesIndicator';
import { VideoCallResource } from '@common/transforms/videoCall';

interface Props {
  readonly videoCall: VideoCallResource;
}

export const VideoCallDetails = ({ videoCall }: Props) => {
  const attendees = useMemo(
    () => filterNull(videoCall?.participants?.map((participant) => participant.user) || []),
    [videoCall],
  );

  const url = useMemo(() => `${window.location.origin}/?videoCall=${videoCall?.id}`, [videoCall]);
  const date = useMemo(() => dayjs(videoCall?.startDate).format('MMMM D'), [videoCall]);
  const times = useMemo(
    () =>
      `${dayjs(videoCall?.startDate).format('HH:mm')} - ${dayjs(videoCall?.endDate).format(
        'HH:mm',
      )}`,
    [videoCall],
  );

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <Heading size='h2' color='textLight'>
        {videoCall.title}
      </Heading>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          margin-bottom: 1em;
          align-items: center;
        `}
      >
        <TimeIndicator
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <Clock color='#fff' size={16} />
          <div
            css={css`
              margin-left: 0.5em;
            `}
          >
            {times}
          </div>
        </TimeIndicator>
        <Body
          css={css`
            margin-left: 1em;
          `}
        >
          {date}
        </Body>
      </div>
      <AttendeesIndicator attendees={attendees} size='s' color='textLight' />
      {videoCall.description && (
        <Body
          css={css`
            margin-top: 1em;
            display: block;
          `}
        >
          {videoCall.description}
        </Body>
      )}
      <UrlCopyButton url={url} />
    </div>
  );
};
