import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

export function useThrottle(callback: () => any, delay: number) {
  const cbRef = useRef(callback);

  // use mutable ref to make useCallback not depend on `callback` dependency
  useEffect(() => {
    cbRef.current = callback;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle((...args) => cbRef.current(...(args as [])), delay),
    [delay],
  );
}

function useIsMounted() {
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return () => isMountedRef.current;
}

export function useDebounce(callback: () => any, delay: number) {
  const inputsRef = useRef({ callback, delay });
  const isMounted = useIsMounted();

  // use mutable ref to make useCallback not depend on `callback` dependency
  useEffect(() => {
    inputsRef.current = { callback, delay };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((...args) => {
      // Debounce is an async callback. Cancel it, if in the meanwhile component has been unmounted or delay has changed
      if (inputsRef.current.delay === delay && isMounted()) {
        inputsRef.current.callback(...(args as []));
      }
    }, delay),
    [delay],
  );
}

export function useDebouncedValue(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}
