export function transformStringArray(data: any) {
  if (!Array.isArray(data)) return [];
  return data.filter((value: any) => typeof value === 'string') as string[];
}

export function transformEnumArray<E extends Record<string, any>>(enumerator: E) {
  return (data: any) => {
    if (!Array.isArray(data)) return [];
    if (typeof enumerator !== 'object') return [];
    const enumValues = Object.values(enumerator as Record<string, string>);
    return data.filter((value) => enumValues.includes(value)) as Array<E[keyof E]>;
  };
}

export function transformInitialPagesArray(data: any): Array<{ startDate: Date; pageId?: string }> {
  if (!Array.isArray(data)) return [];
  return data.filter((value: any) => !!value.startDate && !!value.pageId);
}
