/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';
import { readableColor } from 'polished';

type ChipProps = {
  className?: string;
  color?: 'primary';
  size?: 'xs' | 'xxs';
};

const Chip: React.FC<ChipProps> = ({ className, children, color = 'primary', size = 'xs' }) => {
  const theme = useTheme();
  return (
    <span
      className={className}
      css={css`
        background: ${theme.colors[color]};
        border-radius: 100px;
        font-size: ${size === 'xs' ? theme.fontSizes.s : theme.fontSizes.xs};
        padding: ${theme.paddings[size]};
        color: ${readableColor(theme.colors[color])};
        font-weight: ${theme.fontWeights.normal};
        padding-bottom: 0.3em;
      `}
    >
      {children}
    </span>
  );
};

export default Chip;
