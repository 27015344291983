import parse, { Attributes } from '@common/utils/parse';

export const parseCustomFieldOption = (attributes: Attributes) => {
  return {
    key: attributes.string('key'),
    label: attributes.string('label'),
  };
};

export function one(data: any) {
  return parse.one(data, parseCustomFieldOption);
}

export function many(data: any) {
  return parse.many(data, parseCustomFieldOption);
}

export type CustomFieldOption = ReturnType<typeof one>;

export default { one, many };
