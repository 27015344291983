/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import { Icon as IconProps } from '@typing/icons';

import Icon from '@common/components/Icon';

type FieldWrapperProps = {
  className?: string;
  leftIcon?: string;
  leftIconProps?: Partial<IconProps>;
  rightIcon?: string;
  rightIconProps?: Partial<IconProps>;
  error?: boolean;
  border?: boolean;
};

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  className,
  leftIcon,
  leftIconProps,
  rightIcon,
  rightIconProps,
  error = false,
  border = false,
  children,
}) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      css={css`
        position: relative;
        display: flex !important;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        border-radius: 12px;
        justify-content: space-between;
        ${border && `border: 1px solid ${error ? theme.colors.error : theme.colors.border};`}
        padding: ${theme.paddings.input};
        transition: 0.2s ease-in-out all;

        &:focus-within {
          background: rgba(255, 255, 255, 0.1);
          border-color: transparent;
        }
      `}
    >
      {leftIcon && (
        <div
          css={css`
            margin-right: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Icon icon={leftIcon} size={22} color={theme.colors.textLight} {...leftIconProps} />
        </div>
      )}
      <div
        css={
          !error
            ? css`
                display: flex;
                flex: 1;
              `
            : undefined
        }
      >
        {children}
      </div>
      {rightIcon && (
        <div
          css={css`
            pointer-events: none;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Icon
            icon={rightIcon}
            size={16}
            color={error ? theme.colors.error : theme.colors.textLight}
            {...rightIconProps}
          />
        </div>
      )}
    </div>
  );
};

export default FieldWrapper;
