import parse, { Attributes } from '@common/utils/parse';

import { parseVideo } from './video';

export const VideoType = {
  VIXY: 'VIXY',
  VIMEO: 'VIMEO',
};

export const parseCategory = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    title: attributes.string('title'),
    videos: attributes.custom('videos', (videos) => {
      return parse.many(videos, parseVideo);
    }),
    pageId: attributes.optional.id('pageId'),
    position: attributes.optional.number('position'),
  };
};

export function one(data: any) {
  return parse.one(data, parseCategory);
}

export function many(data: any) {
  return parse.many(data, parseCategory);
}

export type VideoCategoryResource = ReturnType<typeof one>;
export type VideoCategory = Omit<VideoCategoryResource, 'id'>;

export default { one, many };
