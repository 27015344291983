/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import Icon from '@common/components/Icon';

type ToastProps = {
  text: string;
  type?: string;
  onUndo?: () => void;
};

const Toast: React.FC<ToastProps> = ({ text, type = 'success', onUndo }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div
      css={css`
        float: left;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: ${onUndo ? 300 : 100}px;
        background-color: ${theme.colors.alerts.background};
        backdrop-filter: blur(${theme.blurs.normal});
        border-radius: ${theme.borderRadius.iconButton};
        padding: 7px 15px;
      `}
    >
      <div
        css={css`
          border-radius: 100%;
          background-color: ${type === 'remove' ? theme.colors.alerts.text : theme.colors.success};
          margin-right: 10px;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Icon
          icon={type === 'remove' ? 'times' : 'checkmark'}
          size={13}
          color={type === 'remove' ? theme.colors.alerts.iconDark : theme.colors.alerts.text}
        />
      </div>
      <span
        css={css`
          font-size: ${theme.fontSizes.m};
          color: ${theme.colors.alerts.text};
          flex: 1;
        `}
      >
        {text}
      </span>
      {!!onUndo && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={() => onUndo?.()}
          css={css`
            color: ${theme.colors.alerts.text};
            text-decoration: underline;
            cursor: pointer;
            margin-left: 10px;
          `}
        >
          {t('undo_button')}
        </a>
      )}
    </div>
  );
};

export default Toast;
