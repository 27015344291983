/** @jsxImportSource @emotion/react */
import React from 'react';

import styled from '@emotion/styled';

import Overlay from '@common/components/Overlay';

import { Vodafone } from '../svg/vodafone';
import { CloseIcon } from '../svg/close';

type CanvasOverlayProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  content: React.ReactNode;
  extra?: React.ReactNode;
  openModal?: boolean;
  closeModal?: () => void;
};

const CanvasOverlay: React.FC<CanvasOverlayProps> = ({
  title,
  subtitle,
  content,
  extra,
  openModal,
  closeModal,
}) => {
  const Container = styled.div`
    display: flex;
    background: #700000;
    width: 100vw;
    height: 100vh;
    cursor: auto;
  `;

  const Close = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    width: 60px;
    height: 60px;
    background: #970000;
    border-radius: 30px;
    cursor: pointer;
    padding: 15px;
    box-sizing: border-box;
  `;

  const VodafoneContainer = styled.div`
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 170px;
  `;

  const InfoContainer = styled.div`
    display: block;
    width: 50%;
    margin: 150px auto;
    font-family: SofiaPro, Helvetica, Arial, sans-serif;
  `;

  const Title = styled.div`
    font-size: 70px;
    margin-bottom: 50px;
    text-transform: uppercase;
    font-weight: bold;
  `;

  const SubTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  `;

  const Description = styled.div`
    font-size: 20px;
    line-height: 26px;
  `;

  return (
    <Overlay openModal={openModal}>
      <Container>
        <InfoContainer>
          <Title>{title}</Title>
          {extra && <Description>{extra}</Description>}
          <SubTitle>{subtitle}</SubTitle>
          <Description>{content}</Description>
        </InfoContainer>
        <Close onClick={closeModal}>{CloseIcon}</Close>
        <VodafoneContainer>{Vodafone}</VodafoneContainer>
      </Container>
    </Overlay>
  );
};

export default CanvasOverlay;
