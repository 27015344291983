import parse from '@common/utils/parse';

export default function transform(data: any) {
  return parse.one(data, (attributes) => {
    return {
      totalItems: attributes.number('totalItems'),
      itemCount: attributes.number('itemCount'),
      itemsPerPage: attributes.number('itemsPerPage'),
      totalPages: attributes.number('totalPages'),
      currentPage: attributes.number('currentPage'),
    };
  });
}

export type MetaResource = ReturnType<typeof transform>;
