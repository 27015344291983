/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VideoCallResource } from '@common/transforms/videoCall';

interface Call {
  callId: string;
  participantId: string;
}

export interface VideoCallState {
  incomingCall?: Call;
  outgoingCall?: Call;
  activeCall?: Call;
  isOutGoingCallDenied: boolean;
  invite: boolean;
  doNotDisturbSuggestion: boolean;
  error?: string;
  videoCalls?: VideoCallResource[];
  missedCalls: number;
}

const initialState: VideoCallState = {
  isOutGoingCallDenied: false,
  invite: false,
  doNotDisturbSuggestion: false,
  videoCalls: [],
  missedCalls: 0,
};

export const videoCallSlice = createSlice({
  name: 'videoCall',
  initialState,
  reducers: {
    setVideoCalls: (state, action: PayloadAction<VideoCallResource[] | undefined>) => {
      state.videoCalls = action.payload;
    },
    setIncomingCall: (state, action: PayloadAction<Call | undefined>) => {
      state.incomingCall = action.payload;
    },
    resetIncomingCall: (state) => {
      state.incomingCall = undefined;
    },
    setOutgoingCall: (state, action: PayloadAction<Call | undefined>) => {
      state.outgoingCall = action.payload;
    },
    resetOutgoingCall: (state) => {
      state.outgoingCall = undefined;
    },
    startIncomingCall: (state) => {
      if (state.incomingCall) {
        state.activeCall = state.incomingCall;
        state.incomingCall = undefined;
      }
    },
    startOutgoingCall: (state) => {
      if (state.outgoingCall) {
        state.activeCall = state.outgoingCall;
        state.outgoingCall = undefined;
      }
    },
    setActiveCall: (state, action: PayloadAction<Call | undefined>) => {
      state.activeCall = action.payload;
    },
    resetActiveCall: (state) => {
      state.activeCall = undefined;
    },
    setInvite: (state, action: PayloadAction<boolean>) => {
      state.invite = action.payload;
    },
    setIsOutGoingCallDenied: (state, action: PayloadAction<boolean>) => {
      state.isOutGoingCallDenied = action.payload;
    },
    setDoNotDisturbSuggestion: (state, action: PayloadAction<boolean>) => {
      state.doNotDisturbSuggestion = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setMissedCalls: (state, action: PayloadAction<number>) => {
      state.missedCalls = action.payload;
    },
  },
});

export const {
  setVideoCalls,
  setIncomingCall,
  resetIncomingCall,
  setOutgoingCall,
  resetOutgoingCall,
  startIncomingCall,
  startOutgoingCall,
  setActiveCall,
  resetActiveCall,
  setInvite,
  setIsOutGoingCallDenied,
  setDoNotDisturbSuggestion,
  setError,
  setMissedCalls,
} = videoCallSlice.actions;

export default videoCallSlice.reducer;
