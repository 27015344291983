import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { useEffect, useState } from 'react';

let beamsClient: PusherPushNotifications.Client;
let hasSetUserId = false;
let initialized = false;

export const Beams = async () => {
  // make sure beams client is initialized
  if (!beamsClient) {
    if (typeof window !== 'undefined') {
      if ('PushManager' in window) {
        beamsClient = new PusherPushNotifications.Client({
          instanceId: process.env.REACT_APP_PUSHER_BEAM_INSTANCE_ID ?? '',
        });
        await beamsClient.start();
      }
    }
  }

  const hasNotificationSupport = () => {
    if (!beamsClient) return false;
    if (typeof window === 'undefined') return false;
    return 'PushManager' in window;
  };

  const hasBrowserPermission = async () => {
    if (!hasNotificationSupport()) return false;
    const permissionState = await beamsClient.getRegistrationState();
    return permissionState !== PusherPushNotifications.RegistrationState.PERMISSION_DENIED;
  };

  const areNotificationsEnabled = async () => {
    if (!hasNotificationSupport()) return false;

    const permissionState = await beamsClient.getRegistrationState();
    if (
      permissionState ===
      PusherPushNotifications.RegistrationState.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS
    ) {
      return true;
    }
    return false;
  };

  const setInterest = async (interest: string) => {
    if (!beamsClient) return;
    try {
      await beamsClient?.addDeviceInterest(interest);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const setUserId = async (accessToken: string, userId: string) => {
    if (!beamsClient || hasSetUserId) return;
    hasSetUserId = true;
    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: `${process.env.REACT_APP_API_URL}/notifications/auth`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    await beamsClient.stop();
    await beamsClient.clearAllState();
    await beamsClient.start();
    await beamsClient?.setUserId(userId, beamsTokenProvider);
    initialized = true;
  };

  const setEventId = async (eventId: string) => {
    setInterest(eventId);
  };

  const unregister = async () => {
    await beamsClient?.stop();
    await beamsClient?.clearAllState();
    hasSetUserId = false;
  };

  return {
    hasNotificationSupport,
    hasBrowserPermission,
    areNotificationsEnabled,
    setUserId,
    setEventId,
    unregister,
  };
};

export const useBeams = () => {
  const [localInitialized, setLocalInitialized] = useState(initialized);

  useEffect(() => {
    const interval = setInterval(() => {
      if (initialized !== localInitialized) {
        setLocalInitialized(initialized);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [localInitialized]);

  return {
    initialized: localInitialized,
  };
};
