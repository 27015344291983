/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { css } from '@emotion/react';

import { useAppSelector } from '@common/store';
import { PageResource } from '@common/transforms/page';
import { SessionLocation } from '@common/transforms/session';
import { useSessionsDetails } from '@modules/schedule/hooks/useSessions';
import ScheduleHeader from '@modules/schedule/components/ScheduleHeader';
import TabControl from '@modules/schedule/components/TabControl';

import SearchBar from '@common/components/SearchBar';
import Select from '@common/components/Select';
import Carousel from '@common/components/Carousel';
import Container, { Orientation } from '@common/components/Container';

import DaySelector from './components/DaySelector';
import Day from './components/Day';
import ExploreView from './components/ExploreView';
import SearchView from './components/SearchView';

import type { ScheduleContext } from '.';

interface DayFilter {
  count: string;
  date: string;
}
interface ScheduleDesktopProps {
  page: PageResource;
  context: React.Context<ScheduleContext>;
  dates: DayFilter[];
  disableDateSelector?: boolean;
}

const ScheduleDesktop: React.FC<ScheduleDesktopProps> = ({
  page,
  context,
  dates,
  disableDateSelector = false,
}) => {
  const { t } = useTranslation();
  const { data: sessionsDetails } = useSessionsDetails();
  const {
    selectedDate,
    setSelectedDate,
    scheduled,
    setScheduled,
    query,
    setQuery,
    selectedLocation,
    setSelectedLocation,
  } = useContext(context);

  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    setScheduled((old) => (sessionsDetails?.allMandatory && !old ? true : old));
  }, [setScheduled, sessionsDetails]);

  useEffect(() => {
    const selectedDateIndex = dates.findIndex((date) => dayjs(date.date).isSame(selectedDate, 'd'));
    if (selectedDateIndex === -1) {
      setSelectedDate(undefined);
    }
  }, [dates, selectedDate, setSelectedDate]);

  const handleLocationChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setSelectedLocation(event.target.value as SessionLocation);

  const header = <ScheduleHeader text={page.title} />;
  const searchBar = (
    <SearchBar
      onSearch={(input) => setQuery(input)}
      value={query}
      placeholder={scheduled ? t('placeholders.scheduled.search') : t('placeholders.search')}
    />
  );

  return (
    <Container orientation={Orientation.VERTICAL}>
      {sessionsDetails?.allMandatory !== false ? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {header}
          <div
            style={{
              flex: 1,
              display: 'flex',
              maxWidth: '600px',
              marginTop: '-7px',
              marginLeft: '10px',
            }}
          >
            {searchBar}
          </div>
        </div>
      ) : (
        header
      )}
      {sessionsDetails?.allMandatory === false && (
        <Header>
          <TabControl
            tabs={[
              { key: 'overview', label: t('overview') },
              { key: 'schedule', label: t('schedule') },
            ]}
            onChange={(selectedPage: string) => {
              setScheduled(selectedPage === 'schedule');
            }}
            selectedTab={scheduled ? 'schedule' : 'overview'}
          />
          <div
            css={css`
              margin-right: 1.5em;
              width: 200px;
              margin-top: 5px;
            `}
          >
            <Formik initialValues={{ locationSelect: selectedLocation }} onSubmit={() => {}}>
              <Select
                name='locationSelect'
                options={Object.keys(SessionLocation).map((location) => ({
                  label: t(`attendance_location.${location.toLowerCase()}`),
                  value: location,
                }))}
                onChange={handleLocationChange}
                value={selectedLocation}
                css={css`
                  height: 50px;
                `}
              />
            </Formik>
          </div>
          <Search>{searchBar}</Search>
        </Header>
      )}

      {query ? (
        <SearchView context={context} useInfiniteLoading />
      ) : (
        <>
          <Dates>
            <DaySelector
              onClick={() => {
                setSelectedDate(undefined);
                setSelectedLocation(SessionLocation.BOTH);
              }}
              isExplore
              selected={!selectedDate}
            />
            {!disableDateSelector && (
              <Carousel
                itemWidth={180}
                items={[
                  ...(dates?.map((day: DayFilter) => ({
                    ...day,
                    onClick: () => {
                      setSelectedDate(dayjs(day.date).toDate());
                      setSelectedLocation(user!.attendance as SessionLocation);
                    },
                    isExplore: false,
                    selected:
                      selectedDate && dayjs(selectedDate).isSame(dayjs(day.date).toDate(), 'd'),
                  })) ?? []),
                ]}
                renderItem={(item) => <DaySelector {...item} />}
                onChange={(index) =>
                  setSelectedDate(index <= 0 ? undefined : dayjs(dates[index - 1].date).toDate())
                }
                selectedIndex={
                  !selectedDate
                    ? 0
                    : dates.findIndex((day: DayFilter) =>
                        dayjs(selectedDate).isSame(dayjs(day.date).toDate(), 'd'),
                      ) + 1
                }
              />
            )}
          </Dates>
          <Block>
            {selectedDate ? <Day context={context} /> : <ExploreView context={context} />}
          </Block>
        </>
      )}
    </Container>
  );
};

export default ScheduleDesktop;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2em;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  margin-bottom: 2em;
`;

const Search = styled.div`
  width: 600px;
`;

const Dates = styled.div`
  width: 100%;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;

  > button:first-of-type {
    margin-right: 50px;
  }
`;

const Block = styled.div`
  width: 100%;
  position: relative;
`;
