/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';

import Container, { Orientation } from '@common/components/Container';
import Heading from '@common/components/Heading';
import CardButton from '@common/components/CardButton';
import { usePageVideoCategories } from '@modules/event/hooks/useVideos';
import { PageResource } from '@common/transforms/page';

export function VideoOnDemandTemplate({ page }: { page: PageResource }) {
  const { data: videoCategories } = usePageVideoCategories(page.id);
  const navigate = useNavigate();
  const { slug } = useParams();

  return (
    <Container orientation={Orientation.VERTICAL}>
      <Heading>{page.title}</Heading>
      {videoCategories
        ?.filter((category) => category.videos.length > 0)
        ?.map((videoCategory) => (
          <div key={videoCategory.id}>
            <Heading size='h2'>{videoCategory.title}</Heading>
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
              `}
            >
              {videoCategory.videos.map((video) => (
                <div
                  key={video.id}
                  css={css`
                    margin-right: 1em;
                  `}
                >
                  <CardButton
                    title={video.title}
                    subtitle={video.subtitle || ''}
                    duration={video.duration || 0}
                    vimeoVideoId={video.vimeoVideoId}
                    onClick={() => navigate(`/pages/${slug}/${video.id}`)}
                    imageUrl={
                      video.imageUrl ||
                      (video.type === 'VIXY'
                        ? `https://static.cdn.vixyvideo.com/p/${video.vixyPartnerId}/sp/54700/thumbnail/entry_id/${video.vixyEntryId}/version/100002/width/720`
                        : '')
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
    </Container>
  );
}
