/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';

import Input from '@common/components/Input';
import { mediaQuery } from '@common/styles/mediaQuery';

type SearchBarProps = {
  onSearch: (query: string) => void;
  placeholder?: string;
  value?: string;
};

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, placeholder, value }) => {
  return (
    <div
      css={css`
        width: 100%;
        margin: 1em 0;
        ${mediaQuery(
          's',
          css`
            /* max-width: 400px; */
            margin: 1em 1.25em 0.75em 0;
          `,
        )}
      `}
    >
      <Formik
        initialValues={{ search: value ?? '' }}
        onSubmit={(values) => onSearch(values.search)}
      >
        <Form>
          <Input
            name='search'
            icon='search'
            placeholder={placeholder}
            size='s'
            onKeyUp={(text: any) => onSearch(text.target.value)}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default SearchBar;
