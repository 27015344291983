/** @jsxImportSource @emotion/react */
import { useMemo, useState, useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Formik } from 'formik';

import Heading from '@common/components/Heading';
import { useUsers } from '@modules/user/hooks/useUsers';
import AttendeesList from '@modules/user/components/AttendeesList';
import SearchBar from '@common/components/SearchBar';
import { useDebouncedValue } from '@common/hooks/useDebounce';
import { useStartPrivateCall } from '@modules/video-call/hooks/useStartPrivateCall';
import { setOutgoingCall } from '@modules/video-call/store/videoCall';
import { useAppDispatch } from '@common/store';
import { useStartChat } from '@modules/chat/hooks/useStartChat';
import Select from '@common/components/Select';
import useEvent from '@modules/event/hooks/useEvent';
import { SessionLocation } from '@common/transforms/session';
import { UserResource } from '@common/transforms/user';

export function AttendeesItem({
  privateChatEnabled,
  videoCallEnabled,
  hostChatEnabled,
}: {
  privateChatEnabled?: boolean;
  videoCallEnabled?: boolean;
  hostChatEnabled?: boolean;
}) {
  const event = useEvent();
  let timer: any = null;
  const [selectedLocation, setSelectedLocation] = useState<SessionLocation | undefined>(
    SessionLocation.BOTH,
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(query, 300);
  const startChat = useStartChat();

  const {
    data: rawUsers,
    fetchNextPage,
    hasNextPage,
  } = useUsers(
    debouncedSearchTerm,
    undefined,
    undefined,
    false,
    false,
    10,
    'attendance',
    selectedLocation === SessionLocation.BOTH ? undefined : selectedLocation,
  );

  const users = useMemo(() => {
    const paginatedUsers: UserResource[] = [];
    rawUsers?.pages?.map((userData) => {
      return paginatedUsers.push(...userData.items);
    });

    return paginatedUsers;
  }, [rawUsers]);

  const dispatch = useAppDispatch();
  const { mutateAsync: startPrivateCall } = useStartPrivateCall();

  const call = useCallback(
    async (userId) => {
      const participant = await startPrivateCall(userId);
      dispatch(setOutgoingCall({ callId: participant.videoCallId, participantId: participant.id }));
    },
    [startPrivateCall, dispatch],
  );

  const onOpenChat = (userId: string) => {
    startChat(userId);
  };

  const handleOnScroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => hasNextPage && fetchNextPage(), 200);
    return () => clearTimeout(timer);
  };

  const handleLocationChange = (tempEvent: ChangeEvent<HTMLSelectElement>) => {
    const location =
      tempEvent.target.value === 'BOTH' ? undefined : (tempEvent.target.value as SessionLocation);
    setSelectedLocation(location);
  };

  return (
    <>
      <Heading size='h3' color='textLight'>
        {t('attendees_item')}
      </Heading>
      <SearchBar
        onSearch={(input) => setQuery(input)}
        value={query}
        placeholder={t('attendees_searchplaceholder')}
      />
      {event?.attendanceEnabled && (
        <Formik initialValues={{ locationSelect: selectedLocation }} onSubmit={() => {}}>
          <Select
            name='locationSelect'
            options={Object.keys(SessionLocation).map((location) => ({
              label: t(`attendance_location.${location.toLowerCase()}`),
              value: location,
            }))}
            onChange={handleLocationChange}
            value={selectedLocation}
            css={css`
              height: 50px;
            `}
          />
        </Formik>
      )}
      <AttendeesList
        attendees={users}
        onOpenChat={onOpenChat}
        onScrolledToBottom={handleOnScroll}
        onCallUser={videoCallEnabled ? call : undefined}
        privateChatEnabled={privateChatEnabled}
        hostChatEnabled={hostChatEnabled}
      />
    </>
  );
}
