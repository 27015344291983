/** @jsxImportSource @emotion/react */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import SessionCard, { DISPLAY_TYPE } from '@modules/schedule/components/Session';
import Heading from '@common/components/Heading';
import Loader from '@common/components/Loader';
import { useInfiniteSessions } from '@modules/schedule/hooks/useSessions';
import { useDebouncedValue } from '@common/hooks/useDebounce';

import type { ScheduleContext } from '..';

interface SearchViewProps {
  useInfiniteLoading?: boolean;
  context: React.Context<ScheduleContext>;
}

const SearchView: React.FC<SearchViewProps> = ({ useInfiniteLoading, context }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { query, scheduled } = useContext(context);

  const debouncedSearchTerm = useDebouncedValue(query, 300);

  const { data, ...api } = useInfiniteSessions({ limit: 10 }, debouncedSearchTerm, scheduled);

  const [sentryRef] = useInfiniteScroll({
    loading: api.isFetchingNextPage || api.isLoading,
    hasNextPage: !!api.hasNextPage,
    onLoadMore: api.fetchNextPage,
    disabled: api.isError,
    rootMargin: '0px 0px 400px 0px',
  });

  const sessions = data?.pages.map((page) => page.items.map((item) => ({ ...item }))).flat();

  if (api.isLoading) return <Loader />;
  if (!sessions?.length) return <>{t('vle_error_no_sessions_found')}</>;

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div>
        <Heading size='h2' color='textLight'>
          {`${t('search_results_for')} ${query}`}
        </Heading>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -1em;
            margin-top: -1em;
          `}
        >
          {sessions?.length ? (
            <>
              {sessions?.map((session) => (
                <div
                  css={css`
                    width: 380px;
                    margin: 1em;
                  `}
                  key={session.id}
                >
                  <SessionCard displayType={DISPLAY_TYPE.detailed} session={session} />
                </div>
              ))}
              {useInfiniteLoading && (
                <div
                  css={css`
                    margin-top: 4em;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                  `}
                >
                  {api.isFetchingNextPage ? (
                    <Loader />
                  ) : api.hasNextPage ? (
                    <div ref={sentryRef} />
                  ) : null}
                </div>
              )}
            </>
          ) : (
            <p
              css={css`
                margin: 0;
                padding: 0;
                margin-left: 1em;
                margin-top: 0.5em;
                color: ${theme.colors.textBody};
              `}
            >
              {t('no_sessions_found')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchView;
