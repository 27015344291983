/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ChatTabTypes {
  ROOM = 'ROOM',
  ROOMS = 'ROOMS',
}
export interface ChatState {
  selectedTab?: ChatTabTypes;
  selectedRoom?: string | undefined;
  chatNotificationRoomId?: string;
  showGroupChatNotification: boolean;
  showPrivateChatNotification: boolean;
}

const initialState: ChatState = {
  selectedTab: ChatTabTypes.ROOMS,
  showGroupChatNotification: false,
  showPrivateChatNotification: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<ChatTabTypes>) => {
      state.selectedTab = action.payload;
    },
    setSelectedRoom: (state, action: PayloadAction<string | undefined>) => {
      state.selectedRoom = action.payload;
    },
    setChatNotificationRoomId: (state, action: PayloadAction<string | undefined>) => {
      state.chatNotificationRoomId = action.payload;
    },
    setShowGroupChatNotification: (state, action: PayloadAction<boolean>) => {
      state.showGroupChatNotification = action.payload;
    },
    setShowPrivateChatNotification: (state, action: PayloadAction<boolean>) => {
      state.showPrivateChatNotification = action.payload;
    },
  },
});

export const {
  setSelectedTab,
  setSelectedRoom,
  setChatNotificationRoomId,
  setShowGroupChatNotification,
  setShowPrivateChatNotification,
} = chatSlice.actions;

export default chatSlice.reducer;
