/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Body from '@common/components/Body';
import Card from '@common/components/Card';

import { getUserId } from '@modules/auth/hooks/useAuth';
import Avatar from '@modules/user/components/Avatar';
import IconButton from '@common/components/IconButton';

import { CallStatus } from '@common/transforms/user';
import { VideoCallResource } from '@common/transforms/videoCall';

interface CallHistoryCardProps {
  call?: VideoCallResource;
  onCallUser?: (participantId: string) => void;
}

export const CallHistoryCard: React.FC<CallHistoryCardProps> = ({ call, onCallUser }) => {
  const { t } = useTranslation();

  const renderTime = useCallback((date) => {
    if (dayjs(date).isSame(dayjs(), 'day')) {
      return `${dayjs(date).format('HH:mm')}, today`;
    }
    return dayjs(date).format('HH:mm, DD MMMM YYYY');
  }, []);

  const otherParticipants = useMemo(() => {
    if (call!.participants && call!.participants.length > 0) {
      return call!.participants.filter((participant) => participant?.user?.id !== getUserId());
    }
    return undefined;
  }, [call]);

  const currentParticipant = useMemo(() => {
    if (call!.participants && call!.participants.length > 0) {
      return call!.participants.find((participant) => participant?.user?.id === getUserId());
    }
    return undefined;
  }, [call]);

  const renderCallBody = useCallback(() => {
    if (otherParticipants && otherParticipants.length < 2 && currentParticipant) {
      if (currentParticipant.missedAt) {
        return (
          <Body
            size='s'
            css={css`
              margin-left: ${otherParticipants.length > 1 ? '1.3em' : '0.65em'};
            `}
          >
            {t('video_call_missed', { time: renderTime(currentParticipant.missedAt) })}
          </Body>
        );
      }

      return (
        <Body
          size='s'
          css={css`
            margin-left: ${otherParticipants.length > 1 ? '1.3em' : '0.65em'};
          `}
        >
          {t('video_call_ended', { time: renderTime(call!.endDate) })}
        </Body>
      );
    }

    return (
      <Body
        size='s'
        css={css`
          margin-left: ${otherParticipants!.length > 1 ? '1.3em' : '0.65em'};
        `}
      >
        {t('video_call_ended', { time: renderTime(call!.endDate) })}
      </Body>
    );

    // TODO: do something with group call
  }, [call, currentParticipant, otherParticipants, renderTime, t]);

  if (otherParticipants === undefined || !otherParticipants[0]?.user || call?.title) {
    return null;
  }

  return (
    <Card
      key={call!.id}
      css={css`
        padding: 1em;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5em;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Body
          weight='bold'
          color='textLight'
          css={css`
            margin-bottom: 0.5em;
          `}
        >
          {otherParticipants.length > 1
            ? t('video_call_participants', {
                name: otherParticipants[0].user!.fullName,
                amount: otherParticipants.length - 1,
              })
            : otherParticipants[0].user!.fullName}
        </Body>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {otherParticipants &&
              otherParticipants.slice(0, 3).map((participant) => {
                return (
                  <Avatar
                    key={participant.id}
                    user={participant.user}
                    size={28}
                    emptyBackground
                    css={css`
                      margin-right: ${otherParticipants!.length > 1 ? '-0.65em' : '0'};
                    `}
                  />
                );
              })}
            {otherParticipants.length > 3 && (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                  background-color: #a32020;
                  color: white;
                `}
              >
                +{otherParticipants.length - 3}
              </div>
            )}
          </div>

          {renderCallBody()}
        </div>
        {!!onCallUser && otherParticipants && otherParticipants.length < 2 && (
          <IconButton
            onClick={() => onCallUser(otherParticipants[0].user!.id)}
            icon='phone'
            size={30}
            disabled={
              !otherParticipants[0].user!.isOnline ||
              otherParticipants[0].user!.callStatus !== CallStatus.AVAILABLE
            }
            iconProps={{
              size: 14,
            }}
            round
          />
        )}
      </div>
    </Card>
  );
};

export default CallHistoryCard;
