/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import Icon from '@common/components/Icon';
import Input from '@common/components/Input';

import { UserResource } from '@common/transforms/user';
import RoundButton from '@common/components/RoundButton';

type ChatInputBarProps = {
  className?: string;
  user: UserResource;
  onSubmit: (message: string) => void;
};

const ChatInputBar: React.FC<ChatInputBarProps> = ({ className, onSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Formik
      initialValues={{ message: '' }}
      onSubmit={({ message }, { resetForm }) => {
        if (message === '') {
          return;
        }
        onSubmit(message);
        resetForm();
      }}
    >
      <Form autoComplete='false' autoCapitalize='true'>
        <div
          className={className}
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <Input
            name='message'
            placeholder={t('chat_placeholder')}
            size='s'
            autoComplete='off'
            css={css`
              flex: 1;
              margin-right: 1em;
              border-color: ${theme.colors.primary};
              border-width: 2px;
            `}
          />
          <RoundButton type='submit'>
            <Icon icon='send' size={22} color={theme.colors.primary} />
          </RoundButton>
        </div>
      </Form>
    </Formik>
  );
};

export default ChatInputBar;
