import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';

import { AuthInitializer } from '@modules/auth/hooks/useAuth';

import { store } from '@common/store';

import { QueryProvider } from '@common/components/QueryProvider';
import PusherProvider from '@common/components/PusherProvider';
import { SessionContextProvider } from '@modules/schedule/hooks/useSessionContext';
import { LanguageProvider } from '@common/hooks/useLanguage';
import { EventProvider } from '@modules/event/hooks/useEvent';

import App from './app';

ReactDOM.render(
  <Provider store={store}>
    <EventProvider>
      <QueryProvider>
        <Router>
          <AuthInitializer>
            <PusherProvider>
              <SessionContextProvider>
                <LanguageProvider>
                  <App />
                </LanguageProvider>
              </SessionContextProvider>
            </PusherProvider>
          </AuthInitializer>
        </Router>
      </QueryProvider>
    </EventProvider>
  </Provider>,

  document.getElementById('__next'),
);
