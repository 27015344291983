/* eslint-disable no-param-reassign */
import { ReactNode } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  setActiveCall,
  startIncomingCall,
  startOutgoingCall,
} from '@modules/video-call/store/videoCall';

export interface SidebarItem {
  key: string;
  icon: string;
  label?: string;
  notificationIcon?: boolean;
  fullscreen?: boolean;
  render: () => ReactNode;
}

export interface SidebarState {
  activeItem?: string;
  fullscreen?: boolean;
}

const initialState: SidebarState = {};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setActiveItem: (state, action: PayloadAction<string | undefined>) => {
      state.activeItem = action.payload;
    },
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      state.fullscreen = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(startIncomingCall, (state) => {
        state.activeItem = 'video_call';
      })
      .addCase(startOutgoingCall, (state) => {
        state.activeItem = 'video_call';
      })
      .addCase(setActiveCall, (state) => {
        state.activeItem = 'video_call';
      }),
});

export const { setActiveItem, setFullscreen } = sidebarSlice.actions;

export default sidebarSlice.reducer;
