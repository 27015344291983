/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useState, useRef, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Formik } from 'formik';

import Container, { Orientation } from '@common/components/Container';
import Heading from '@common/components/Heading';
import AttendeesList from '@modules/user/components/AttendeesList';
import AttendeesIndicator from '@modules/user/components/AttendeesIndicator';
import SearchBar from '@common/components/SearchBar';
import Button from '@common/components/Button';
import FilterRow, { Filter } from '@common/components/FilterRow';
import FilterCloud from '@common/components/FilterCloud';

import { useTags } from '@modules/event/hooks/useTags';
import { useUsers, useUserRoleCount } from '@modules/user/hooks/useUsers';
import { mediaQuery } from '@common/styles/mediaQuery';
import { UserResource } from '@common/transforms/user';
import { PageResource } from '@common/transforms/page';
import { SessionLocation } from '@common/transforms/session';
import { useAppDispatch } from '@common/store';
import { useStartPrivateCall } from '@modules/video-call/hooks/useStartPrivateCall';
import { setOutgoingCall } from '@modules/video-call/store/videoCall';
import theme from '@common/styles/theme';
import { closeOverlay } from '@common/store/sideOverlay';
import { useStartChat } from '@modules/chat/hooks/useStartChat';
import { useEvent } from '@modules/event/hooks/useEvent';
import Select from '@common/components/Select';

export function AttendeesTemplate({
  page,
  privateChatEnabled,
  videoCallEnabled,
  hostChatEnabled,
}: {
  page: PageResource;
  privateChatEnabled?: boolean;
  videoCallEnabled?: boolean;
  hostChatEnabled?: boolean;
}) {
  const { t } = useTranslation();
  const event = useEvent();
  const [query, setQuery] = useState<string>();
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<SessionLocation | undefined>(
    SessionLocation.BOTH,
  );
  const { data: tags } = useTags();
  const {
    data: rawUsers,
    fetchNextPage,
    hasNextPage,
  } = useUsers(
    query,
    selectedFilters.filter((filter) => filter.type === 'tag').map((filter) => filter.key),
    selectedFilters.filter((filter) => filter.type === 'role').map((filter) => filter.key),
    false,
    false,
    undefined,
    'attendance',
    selectedLocation === SessionLocation.BOTH ? undefined : selectedLocation,
  );
  const { data: userRoleCount } = useUserRoleCount();
  const [filtersRender, setfiltersRender] = useState<'row' | 'cloud'>('row');
  const dispatch = useAppDispatch();
  const startChat = useStartChat();

  const { mutateAsync: startPrivateCall } = useStartPrivateCall();

  const call = useCallback(
    async (userId) => {
      const participant = await startPrivateCall(userId);
      dispatch(setOutgoingCall({ callId: participant.videoCallId, participantId: participant.id }));
      dispatch(closeOverlay());
    },
    [startPrivateCall, dispatch],
  );

  let timer: any = null;

  const users = useMemo(() => {
    const paginatedUsers: UserResource[] = [];
    rawUsers?.pages?.map((userData) => {
      return paginatedUsers.push(...userData.items);
    });

    return paginatedUsers;
  }, [rawUsers]);

  const userCount = useMemo(() => {
    const count = rawUsers?.pages[0]?.meta.totalItems ?? 0;
    const filteredUserAmount = users.filter((user) => !user.firstName || !user.firstName).length;
    const total = count - filteredUserAmount - 1;
    return total < 0 ? 0 : total;
  }, [users, rawUsers]);

  const filters = useMemo(() => {
    const rawFilters: Filter[] = [];
    tags?.map((tag) => {
      return rawFilters.push({ type: 'tag', key: tag.key, label: tag.label });
    });
    userRoleCount?.map((userRole) => {
      return rawFilters.push({
        type: 'role',
        key: userRole.id,
        label: userRole.name,
      });
    });

    return rawFilters;
  }, [tags, userRoleCount]);

  const handleLocationChange = (tempEvent: ChangeEvent<HTMLSelectElement>) => {
    const location =
      tempEvent.target.value === 'BOTH' ? undefined : (tempEvent.target.value as SessionLocation);
    setSelectedLocation(location);
  };

  const toggleFilter = useCallback(
    (filter: Filter) => {
      setSelectedFilters((current) => {
        if (current.includes(filter)) {
          return current.filter(({ key }) => key !== filter.key);
        }

        return [...current, filter];
      });
    },
    [setSelectedFilters],
  );

  const toggleMore = useCallback((enabled: boolean) => {
    setfiltersRender(enabled ? 'cloud' : 'row');
  }, []);

  const handleOnScroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => hasNextPage && fetchNextPage(), 200);
    return () => clearTimeout(timer);
  };

  const onOpenChat = (userId: string) => {
    startChat(userId);
  };
  const refSearchFilterContainer = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (refSearchFilterContainer?.current) {
      new ResizeObserver(reziseSearchFilterContainer).observe(refSearchFilterContainer.current);
    }
  }, []);

  function reziseSearchFilterContainer() {
    if (
      !refSearchFilterContainer?.current?.scrollWidth ||
      !refSearchFilterContainer?.current?.offsetWidth
    ) {
      return;
    }
    if (document.body.clientWidth < 767) {
      refSearchFilterContainer.current.style.flexDirection = 'column';
      return;
    }
    refSearchFilterContainer.current.style.flexDirection = 'row';
    if (
      refSearchFilterContainer.current.scrollWidth > refSearchFilterContainer.current.offsetWidth
    ) {
      refSearchFilterContainer.current.style.flexDirection = 'column';
    }
  }

  return (
    <Container orientation={Orientation.VERTICAL} scrollable={false}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        `}
      >
        <Heading
          css={css`
            ${mediaQuery(
              's',
              css`
                font-size: ${theme.fontSizes.mobile.h1};
                margin-bottom: 1em;
              `,
            )}
            margin-bottom: 0;
            font-size: ${theme.fontSizes.mobile.h3};
          `}
        >
          {page.title}
        </Heading>
        <AttendeesIndicator
          css={css`
            display: none;
            ${mediaQuery(
              's',
              css`
                display: flex;
              `,
            )}
          `}
          attendees={users}
          count={userCount}
        />
      </div>
      <div
        ref={refSearchFilterContainer}
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: ${filtersRender === 'row' ? 'center' : 'flex-start'};
          ${mediaQuery(
            's',
            css`
              flex-direction: row;
            `,
          )}
        `}
      >
        <SearchBar
          onSearch={(searchQuery) => {
            setQuery(searchQuery);
          }}
          placeholder={t('attendees_searchplaceholder')}
        />

        {event?.attendanceEnabled && (
          <div
            css={css`
              display: flex;
              width: 100%;
              margin-bottom: 10px;
              ${mediaQuery(
                's',
                css`
                  width: auto;
                  margin-bottom: 0px;
                `,
              )}
            `}
          >
            <p>{t('attendance')}:</p>

            <div
              css={css`
                margin-left: 1.5em;
                width: 200px;
                margin-top: 5px;
              `}
            >
              <Formik initialValues={{ locationSelect: selectedLocation }} onSubmit={() => {}}>
                <Select
                  name='locationSelect'
                  options={Object.keys(SessionLocation).map((location) => ({
                    label: t(`attendance_location.${location.toLowerCase()}`),
                    value: location,
                  }))}
                  onChange={handleLocationChange}
                  value={selectedLocation}
                  css={css`
                    height: 50px;
                  `}
                />
              </Formik>
            </div>
          </div>
        )}
        {!!filters?.length &&
          (filtersRender === 'row' ? (
            <FilterRow
              filters={filters}
              selected={selectedFilters}
              onFilterClick={toggleFilter}
              onMoreClick={toggleMore}
            />
          ) : (
            <FilterCloud
              inRow
              filters={filters}
              selected={selectedFilters}
              onFilterClick={toggleFilter}
            />
          ))}
        {filtersRender === 'cloud' && (
          <Button
            outline
            key='minify'
            label='―'
            size='s'
            disableMinWidth
            onClick={() => toggleMore(false)}
            css={css`
              margin: 0.9em 0 0 1.25em;
            `}
          />
        )}
      </div>
      <AttendeesList
        onOpenChat={onOpenChat}
        onCallUser={videoCallEnabled ? call : undefined}
        attendees={users.filter((user) => user.firstName && user.firstName)}
        rows
        onScrolledToBottom={() => handleOnScroll()}
        privateChatEnabled={privateChatEnabled}
        hostChatEnabled={hostChatEnabled}
      />
    </Container>
  );
}
