/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Scrollbars, positionValues } from 'react-custom-scrollbars';
import { css, useTheme } from '@emotion/react';

import { useAppSelector } from '@common/store';

type ScrollableProps = {
  className?: string;
  startAtBottom?: boolean;
  onScrolledToBottom?: () => void;
};

const Thumb = (props: any) => (
  <div
    {...props}
    css={css`
      background: #fff;
      opacity: 0.2;
      border-radius: 100px;
    `}
  />
);

const Scrollable: React.FC<ScrollableProps> = ({
  className,
  startAtBottom = false,
  children,
  onScrolledToBottom,
}) => {
  const theme = useTheme();
  const scrollRef = useRef<Scrollbars | null>(null);
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null);
  const [shadowTopOpacity, setShadowTopOpacity] = useState(0);
  const [shadowBottomOpacity, setShadowBottomOpacity] = useState(0);
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);

  const handleUpdate = useCallback(
    (values: positionValues) => {
      const { scrollTop, scrollHeight, clientHeight } = values;
      const bottomScrollTop = scrollHeight - clientHeight;
      setShadowTopOpacity((1 / 20) * Math.min(scrollTop, 20));
      setShadowBottomOpacity(
        (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20)),
      );
    },
    [setShadowTopOpacity],
  );

  useEffect(() => {
    if (startAtBottom) {
      scrollRef.current?.scrollToBottom();
    }
  }, [scrollRef, startAtBottom, children]);

  return (
    <div
      className={className}
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 32px;
          pointer-events: none;
          ${mobileFullscreen
            ? ''
            : `background: linear-gradient(
            to bottom,
            ${elementFullScreen ? 'black' : theme.colors.eventBackground},
            ${theme.colors.eventBackground}00
          );`}

          opacity: ${shadowTopOpacity};
          z-index: 100;
        `}
      />
      <div
        css={css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 32px;
          pointer-events: none;
          ${mobileFullscreen
            ? ''
            : `background: linear-gradient(
            to top,
            ${elementFullScreen ? 'black' : theme.colors.eventBackground},
            ${theme.colors.eventBackground}00
          );`}
          opacity: ${shadowBottomOpacity};
          z-index: 100;
        `}
      />
      <Scrollbars
        ref={scrollRef}
        autoHide
        renderThumbVertical={Thumb}
        onUpdate={handleUpdate}
        onScroll={() => {
          const scrollTop = scrollRef.current?.getScrollTop() || 0;
          const containerHeight = scrollWrapperRef?.current?.offsetHeight || 0;
          const clientHeight = scrollRef?.current?.getClientHeight() || 0;
          if (
            scrollTop &&
            scrollRef &&
            containerHeight &&
            (scrollTop / (containerHeight - clientHeight)) * 100 > 80 &&
            onScrolledToBottom
          ) {
            onScrolledToBottom();
          }
        }}
      >
        <div ref={scrollWrapperRef}>{children}</div>
      </Scrollbars>
    </div>
  );
};

export default Scrollable;
