export const RefreshIcon = (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 25.42 26.94'
    width='100%'
    height='100%'
  >
    <path
      d='M4.58,6a11.65,11.65,0,1,1,0,16.53A12,12,0,0,1,1,14.42'
      fill='none'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <polyline
      points='3.52 1 2.94 7.06 9 7.64'
      fill='none'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
  </svg>
);
