/** @jsxImportSource @emotion/react */
import ReactDOM from 'react-dom';
import { css, useTheme } from '@emotion/react';

import Icon from '@common/components/Icon';
import { useAppSelector } from '@common/store';

type BottombarItem = {
  key: string;
  icon: string;
  notificationIcon?: boolean;
  render: () => React.ReactNode;
};

type BottombarProps = {
  items: BottombarItem[];
  active?: string;
  toggle: (key: string) => void;
};

type BottombarButtonProps = {
  item: BottombarItem;
  toggle: (key: string) => void;
};

const BottombarButton: React.FC<BottombarButtonProps> = ({ item, toggle }) => {
  const theme = useTheme();

  return (
    <div
      onClick={() => toggle(item.key)}
      css={css`
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(${theme.blurs.normal});
        border-radius: 12px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1em;
        cursor: pointer;
        pointer-events: all;
        &:first-of-type {
          margin-left: 0;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      `}
    >
      <Icon icon={item.icon} color='#fff' size={20} />
      {item.notificationIcon && (
        <div
          css={css`
            position: absolute;
            top: 4pt;
            margin-left: 7pt;
            background-color: ${theme.colors.primary};
            width: 8pt;
            height: 8pt;
            border-radius: 4pt;
            border-width: 1pt;
            border-style: solid;
            border-color: ${theme.colors.eventBackground};
          `}
        />
      )}
    </div>
  );
};

const Bottombar: React.FC<BottombarProps> = ({ items, active, toggle }) => {
  const theme = useTheme();
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const sidebarItem = useAppSelector((state) => state.sidebar.activeItem);
  return ReactDOM.createPortal(
    <>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: ${theme.colors.eventBackground};
          padding: 1em;
          z-index: 10000;
          display: ${active === 'video_call' ? 'block' : 'none'};
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 1em;
            right: 1em;
            cursor: pointer;
            z-index: 9999;
          `}
          onClick={() => toggle('video_call')}
        >
          <Icon icon='times' size={16} color={theme.colors.textLight} />
        </div>
        {items.find((item) => item.key === 'video_call')?.render()}
      </div>
      {active && active !== 'video_call' && (
        <div
          css={css`
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            background: ${active !== 'slido' && theme.colors.eventBackground};
            ${mobileFullscreen && sidebarItem ? `width: 40% !important;` : `left: 0;`}
            ${mobileFullscreen ? `overflow:hidden; width: 0px;` : ``}
            transition: all 0.3s ease;
            padding: 1em;
            box-sizing: border-box;
            z-index: 10000;
            display: flex;
            flex-direction: column;
          `}
        >
          {active && active !== 'slido' && (
            <div
              css={css`
                position: absolute;
                top: 1em;
                right: 1em;
                cursor: pointer;
                z-index: 9999;
              `}
              onClick={() => toggle(active)}
            >
              <Icon icon='times' size={16} color={theme.colors.textLight} />
            </div>
          )}
          {items.find((item) => item.key === active)?.render()}
        </div>
      )}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-bottom: 2em;
          position: fixed;
          left: 0;
          bottom: 0;
          pointer-events: none;

          ${mobileFullscreen && sidebarItem ? `width: 60%;` : `right: 0;`}
          z-index: ${active === 'slido' ? 11000 : 999};
        `}
      >
        {Array.isArray(items) &&
          items.length > 0 &&
          items.map((item: BottombarItem) => (
            <BottombarButton key={item.key} item={item} toggle={toggle} />
          ))}
      </div>
    </>,
    document.body,
  );
};

export default Bottombar;
