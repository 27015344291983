/** @jsxImportSource @emotion/react */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { css } from '@emotion/react';
import useResizeObserver from 'use-resize-observer';

import { UserResource } from '@common/transforms/user';
import { UserRolePermission } from '@common/transforms/userRole';

import Scrollable from '@common/components/Scrollable';
import UserCard from '@modules/user/components/UserCard';

import Profile from '@modules/user/components/Profile';
import { mediaQuery } from '@common/styles/mediaQuery';
import { useAppSelector } from '@common/store';

import { openExternalWebsite } from '@common/utils/checkUrl';
import { useChatRooms } from '@modules/chat/hooks/useChat';

type AttendeesListProps = {
  attendees?: UserResource[];
  rows?: boolean;
  onOpenChat?: (participantId: string) => void;
  onCallUser?: (participantId: string) => void;
  onScrolledToBottom?: () => void;
  privateChatEnabled?: boolean;
  hostChatEnabled?: boolean;
};

const AttendeesList: React.FC<AttendeesListProps> = ({
  attendees,
  rows = false,
  onOpenChat,
  onCallUser,
  onScrolledToBottom,
  privateChatEnabled,
  hostChatEnabled,
}) => {
  const self = useAppSelector((state) => state.auth.user);
  const { ref: container, width = 0 } = useResizeObserver<HTMLDivElement>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedAttendee, setSelectedAttendee] = useState<UserResource>();
  const { data: rooms } = useChatRooms();

  const chatEnabled = (attendee?: UserResource) =>
    (privateChatEnabled &&
      self?.role?.permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT) &&
      (!attendee?.role?.permissions.includes(UserRolePermission.HIDE_USERS) ||
        self?.role?.permissions.includes(UserRolePermission.HIDE_USERS))) ||
    (hostChatEnabled
      ? (self?.role?.permissions.includes(UserRolePermission.HOST_CHAT) &&
          rooms?.some((room) =>
            room.participants.some((participant) => participant.id === attendee?.id),
          )) ||
        attendee?.role?.permissions.includes(UserRolePermission.HOST_CHAT)
      : false);

  return (
    <div
      ref={container}
      css={css`
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-top: 1em;
        `}
      >
        <Scrollable
          css={css`
            flex: 1;
          `}
          onScrolledToBottom={() => onScrolledToBottom && onScrolledToBottom()}
        >
          <div
            css={
              rows
                ? css`
                    ${mediaQuery(
                      'm',
                      css`
                        display: grid;
                        grid-column-gap: ${width < 900 ? '20px' : '40px'};
                        grid-row-gap: 0.75em;
                        grid-template-columns: ${width < 450
                          ? '1fr'
                          : width < 900
                          ? 'repeat(2, 1fr)'
                          : 'repeat(3, 1fr)'};
                      `,
                    )}
                  `
                : undefined
            }
          >
            {attendees?.map((attendee) => (
              <React.Fragment key={attendee.id}>
                <div
                  onClick={() => {
                    setSelectedAttendee(attendee);
                    setOpenModal(true);
                  }}
                  css={css`
                    margin-top: 0.75em;
                    cursor: pointer;
                    flex: 0 0 29%;
                    width: 100%;
                    ${rows &&
                    mediaQuery(
                      'm',
                      css`
                        width: auto;
                        margin-top: 0;
                      `,
                    )}
                  `}
                >
                  <UserCard
                    css={css`
                      height: 100%;
                      box-sizing: border-box;
                    `}
                    user={attendee}
                    onOpenLinkedIn={() => openExternalWebsite(attendee.linkedIn)}
                    onOpenVideoLink={() => openExternalWebsite(attendee.videoLink)}
                    onOpenChat={chatEnabled(attendee) ? onOpenChat : undefined}
                    onCallUser={
                      (!attendee.role?.permissions.includes(UserRolePermission.HIDE_USERS) ||
                        self?.role?.permissions.includes(UserRolePermission.HIDE_USERS)) &&
                      self?.role?.permissions.includes(
                        UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS,
                      )
                        ? onCallUser
                        : undefined
                    }
                    showCallStatus
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </Scrollable>
        {ReactDOM.createPortal(
          <Profile
            userId={selectedAttendee?.id}
            openModal={openModal}
            onCloseClick={() => setOpenModal(false)}
            onOpenChat={chatEnabled(selectedAttendee) ? onOpenChat : undefined}
            onCallUser={onCallUser}
          />,
          document.querySelector('#popupHolder') ?? document.body,
        )}
      </div>
    </div>
  );
};

export default AttendeesList;
