/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Icon from '@common/components/Icon';

interface PillProps {
  highlighted?: boolean;
  className?: string;
  icon?: string;
}

const Pill: React.FC<PillProps> = ({ className, children, highlighted, icon }) => {
  const theme = useTheme();
  return (
    <Base className={className} highlighted={highlighted}>
      {!!icon && <Icon size={16} color={theme.colors.textLight} icon={icon} />}
      <p>{children}</p>
    </Base>
  );
};

export default Pill;

const Base = styled.div<{ highlighted?: boolean }>`
  float: left;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.highlighted ? props.theme.colors.live : 'rgba(0, 0, 0, 0.3)'};
  backdrop-filter: blur(${(props) => props.theme.blurs.normal});
  border-radius: 100px;
  ${(props) =>
    props.highlighted &&
    css`
      border: 1.5px solid ${props.theme.colors.liveBorder};
    `}
  padding: 5px 12px;
  margin-right: 10px;
  font-size: ${(props) => props.theme.fontSizes.m};
  font-weight: ${(props) =>
    props.highlighted ? props.theme.fontWeights.semibold : props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.textLight};
  overflow: hidden;

  i,
  svg {
    margin-right: 5px;
    color: ${(props) => props.theme.colors.textLight};
    align-self: center;
  }

  > p {
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
