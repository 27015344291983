/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';
import ReactDOM from 'react-dom';

import { getUserId } from '@modules/auth/hooks/useAuth';
import { useStartPrivateCall } from '@modules/video-call/hooks/useStartPrivateCall';

import Button from '@common/components/Button';
import Heading from '@common/components/Heading';
import LazyModal from '@common/components/LazyModal';
import Scrollable from '@common/components/Scrollable';

import { mediaQuery } from '@common/styles/mediaQuery';
import { useAppDispatch, useAppSelector } from '@common/store';
import { VideoCallResource } from '@common/transforms/videoCall';

import { useSeenMissedCalls } from '../hooks/useVideoCall';
import { setOutgoingCall, setMissedCalls as setMissingCalls } from '../store/videoCall';

import CallHistoryCard from './CallHistoryCard';
import { InviteOverlay } from './InviteOverlay';

export const VideoCallHistory = ({ videoCallEnabled }: { videoCallEnabled: boolean }) => {
  const { t } = useTranslation();
  const { mutate: setMissedCalls } = useSeenMissedCalls();
  const theme = useTheme();

  const { mutateAsync: startPrivateCall } = useStartPrivateCall();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = React.useState(false);

  const videoCalls = useAppSelector((state) => state.videoCall.videoCalls);

  const calllUser = useCallback(
    async (participantId: string) => {
      if (videoCallEnabled) {
        const participant = await startPrivateCall(participantId);
        dispatch(
          setOutgoingCall({ callId: participant.videoCallId, participantId: participant.id }),
        );
      }
      return undefined;
    },
    [dispatch, startPrivateCall, videoCallEnabled],
  );

  const missedCalls = useMemo(() => {
    if (videoCalls && videoCalls.length > 0) {
      const otherParticipants = videoCalls.map((call) => {
        return call.participants!.filter((participant) => participant?.user?.id === getUserId())[0];
      });

      return otherParticipants.filter((participant) => participant?.missedAt);
    }
    return [];
  }, [videoCalls]);

  useEffect(() => {
    if (missedCalls.length > 0) {
      setMissedCalls(missedCalls.map((calls) => calls.id));
      dispatch(setMissingCalls(0));
    }
  }, [dispatch, missedCalls, setMissedCalls]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
      `}
    >
      <Scrollable
        css={css`
          position: fixed;
          background-color: ${theme.colors.eventBackground};
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 2em;
          z-index: 0;
          display: block;

          ${mediaQuery(
            'm',
            css`
              position: relative;
              padding: 0;
              z-index: auto;
              display: block;
              width: 100%;
            `,
          )}
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 1.5em;
            `}
          >
            <Heading size='h3' color='textLight'>
              {t('sidemenu_hover_video_call')}
            </Heading>
            <Button
              onClick={() => setOpenModal(true)}
              icon='phone'
              iconSize={20}
              label={t('start_call')}
              size='xs'
              color='secondary'
              type='button'
              css={css`
                min-width: 100px !important;
                height: 40px;
              `}
            />
          </div>

          {videoCalls?.map((call: VideoCallResource) => {
            return <CallHistoryCard call={call} key={call.id} onCallUser={calllUser} />;
          })}
        </div>
      </Scrollable>

      {ReactDOM.createPortal(
        <LazyModal open={openModal}>
          {(actualOpen) => (
            <InviteOverlay
              onCallUser={calllUser}
              openModal={actualOpen}
              closeModal={() => setOpenModal(false)}
            />
          )}
        </LazyModal>,
        document.querySelector('#popupHolder') ?? document.body,
      )}
    </div>
  );
};

export default VideoCallHistory;
