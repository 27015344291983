/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Button from '@common/components/Button';
import Body from '@common/components/Body';
import Heading from '@common/components/Heading';
import CustomField from '@common/components/forms/CustomField';
import { User } from '@common/transforms/user';
import { CustomFieldResource, CustomFieldType } from '@common/transforms/customField';
import { DIRECTION } from '@common/utils/consts';
import { mediaQuery } from '@common/styles/mediaQuery';
import IconButton from '@common/components/IconButton';

type CustomFieldsFormProps = {
  user: Partial<User>;
  customFields: CustomFieldResource[];
  onSubmit: (values: Record<string, string>) => void;
  onSkip?: () => void;
  onPrev?: () => void;
};

const CustomFieldsForm = ({
  user,
  customFields,
  onSubmit,
  onSkip,
  onPrev,
}: CustomFieldsFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const hasRequiredFields = !!customFields.find((customField) => customField.required);

  const validationSchema = Yup.object().shape(
    customFields?.reduce((result, field) => {
      if (field.required) {
        return { ...result, [field.id]: Yup.string().required() };
      }

      return { ...result, [field.id]: Yup.string().optional() };
    }, {}) || {},
  );

  const initialValues =
    customFields?.reduce(
      (result, customField) => ({
        ...result,
        [customField.id]:
          user.fields?.find((field) => field.customFieldId === customField.id)?.value ??
          (customField.type === CustomFieldType.DROPDOWN ? customField.options[0]?.label : ''),
      }),
      {},
    ) || {};

  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      <Heading size='h1'>
        {t('registration_hello')} {user.firstName}
      </Heading>
      <Body
        css={css`
          margin-bottom: 1.875em;
          color: ${theme.colors.textLight};
        `}
      >
        {t('registration_introduction')}
      </Body>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validationSchema}
      >
        <Form>
          <div
            css={css`
              overflow-y: auto;
              max-height: calc(100vh - 325px);

              ${mediaQuery(
                's',
                css`
                  overflow-y: unset;
                  max-height: unset;
                `,
              )}
            `}
          >
            {customFields?.map((customField) => (
              <CustomField key={customField.id} customField={customField} />
            ))}
          </div>
          <div>{t('registration_mandatory_description')}</div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              flex: 1;
              margin-top: 3.375em;
              margin-bottom: 3.375em;
            `}
          >
            {!!onPrev && (
              <IconButton
                onClick={onPrev}
                icon='arrow'
                iconProps={{ direction: DIRECTION.LEFT, size: 20 }}
                size={48}
                round
              />
            )}
            <Button
              css={css`
                flex: 1;
                margin-left: ${onPrev ? '1' : '0'}em;
              `}
              type='submit'
              label={t('next_button')}
              disableMinWidth
            />
            {!hasRequiredFields && !!onSkip && (
              <Button
                css={css`
                  flex: 1;
                  margin-left: 1em;
                `}
                label={t('skip_button')}
                color='secondary'
                onClick={onSkip}
                plain
                disableMinWidth
              />
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default CustomFieldsForm;
