import parse, { Attributes } from '@common/utils/parse';

import { parseUser } from './user';

export const parseLocation = (attributes: Attributes) => {
  return {
    boothId: attributes.string('boothId'),
    user: attributes.custom('boothHolder', (user) => {
      return parse.one(user, parseUser);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseLocation);
}

export function many(data: any) {
  return parse.many(data, parseLocation);
}

export type LocationResource = ReturnType<typeof one>;
export type Location = Omit<LocationResource, 'id'>;

export default { one, many };
