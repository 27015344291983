/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { Icon as IconType } from '@typing/icons';

import Icon from '@common/components/Icon';

interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: string;
  hoverIcon?: string;
  size?: number;
  onClick?: () => void;
  round?: boolean;
  iconProps?: Partial<IconType>;
  hoverIconProps?: Partial<IconType>;
  type?: 'default' | 'defaultDark' | 'transparent' | 'transparentDark' | 'schedule' | 'scheduled';
  disabled?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  round = false,
  icon,
  hoverIcon,
  iconProps,
  hoverIconProps,
  size,
  onClick,
  type = 'default',
  disabled = false,
  ...rest
}) => {
  const theme = useTheme();
  const [hovering, setHovering] = useState(false);
  const nativeOnClick = (event: any) => {
    onClick?.();
    event.stopPropagation();
  };

  const nativeOnMouseEnter = (ev: any) => {
    rest?.onMouseEnter?.(ev);
    setHovering(true);
    ev.stopPropagation();
  };

  const nativeOnMouseLeave = (ev: any) => {
    rest?.onMouseLeave?.(ev);
    setHovering(false);
    ev.stopPropagation();
  };

  return (
    <button
      css={css`
        width: ${size ?? 24}px;
        height: ${size ?? 24}px;
        max-width: ${size ?? 24}px;
        max-height: ${size ?? 24}px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${disabled
          ? theme.colors.buttons[type].backgroundDisabled ?? theme.colors.buttons[type].background
          : theme.colors.buttons[type].background};
        color: ${disabled
          ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
          : theme.colors.buttons[type].color};
        backdrop-filter: blur(${theme.blurs.normal});
        border: 0;
        cursor: ${disabled ? 'default' : 'pointer'};
        border-radius: ${round ? '100px' : theme.borderRadius.iconButton};
        transition: 0.2s all ease-in-out;
        position: relative;

        path,
        circle,
        rect {
          fill: ${disabled
            ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
            : theme.colors.buttons[type].color};
          stroke: ${disabled
            ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
            : theme.colors.buttons[type].color};
        }

        :hover {
          background-color: ${disabled
            ? theme.colors.buttons[type].backgroundDisabled ?? theme.colors.buttons[type].background
            : theme.colors.buttons[type].backgroundHover ?? theme.colors.buttons[type].background};
          color: ${theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color};

          path,
          circle,
          rect {
            fill: ${disabled
              ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
              : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color};
            stroke: ${disabled
              ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
              : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color};
          }
        }
      `}
      {...rest}
      onClick={(e: any) => !disabled && nativeOnClick(e)}
      disabled={disabled ?? false}
      onMouseLeave={disabled ? undefined : nativeOnMouseLeave}
      onMouseEnter={disabled ? undefined : nativeOnMouseEnter}
    >
      {!!hoverIcon && (
        <IconWrapper hidden={!hovering || disabled}>
          <Icon
            icon={hoverIcon}
            color={
              disabled
                ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
                : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color
            }
            size={(size ?? 24) - 20}
            {...iconProps}
            {...hoverIconProps}
          />
        </IconWrapper>
      )}
      <IconWrapper hidden={!!hoverIcon && hovering && !disabled}>
        <Icon
          icon={icon}
          color={
            disabled
              ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
              : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color
          }
          size={(size ?? 24) - 20}
          {...iconProps}
        />
      </IconWrapper>
    </button>
  );
};

export default IconButton;

const IconWrapper = styled.div<{ hidden: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  transition: opacity 0.15s ease-in-out;
  will-change: opacity;
`;
