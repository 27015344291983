import { Icon } from '@typing/icons';

const Play: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.2281 9.7101L7.94412 2.46229C7.51348 2.18587 7.01301 2.02712 6.49612 2.00318C5.97924 1.97923 5.4654 2.09102 5.00922 2.32635C4.55303 2.56169 4.17167 2.91216 3.90588 3.33986C3.64009 3.76757 3.49983 4.25669 3.5 4.75517V19.2468C3.50009 19.7452 3.64051 20.2339 3.9064 20.6614C4.17229 21.089 4.5536 21.4391 5.00974 21.6743C5.46587 21.9095 5.97971 22.0208 6.49647 21.9968C7.01323 21.9728 7.51358 21.814 7.94412 21.5377L19.2281 14.2899C19.6193 14.0385 19.9402 13.6985 20.1621 13.299C20.384 12.8996 20.5 12.4534 20.5 12.0003C20.5 11.5473 20.384 11.1011 20.1621 10.7016C19.9402 10.3022 19.6193 9.96149 19.2281 9.7101V9.7101Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Play;
