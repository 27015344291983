import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CallStatus, UserResource } from '@common/transforms/user';
import { useAppDispatch, useAppSelector } from '@common/store';
import { useUpdateUser } from '@modules/auth/hooks/useUpdateUser';

import { setDoNotDisturbSuggestion } from '../store/videoCall';

import { CallCard } from './CallCard';

export const DoNotDisturbOverlay = () => {
  const { t } = useTranslation();
  const { updateUser } = useUpdateUser();
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const accept = useCallback(() => {
    dispatch(setDoNotDisturbSuggestion(false));
    if (user) {
      updateUser({ id: user.id, callStatus: CallStatus.DO_NOT_DISTURB } as UserResource);
    }
  }, [dispatch, user, updateUser]);

  return (
    <CallCard
      title={t('do_not_disturb_suggestion_title')}
      subtitle={t('do_not_disturb_suggestion_subtitle')}
      positiveIcon='checkmark'
      negativeIcon='times'
      accept={accept}
      onEnd={() => dispatch(setDoNotDisturbSuggestion(false))}
    />
  );
};
