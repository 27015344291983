/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import dayjs from 'dayjs';

import i18n from '@common/utils/i18n';

import Body from '@common/components/Body';
import Heading from '@common/components/Heading';
import { EventResource } from '@common/transforms/event';

type EventDetailsProps = {
  event?: EventResource;
  align?: 'center' | 'left';
  intro?: boolean;
};

const EventDetails: React.FC<EventDetailsProps> = ({ event, align = 'left', intro }) => {
  const theme = useTheme();
  const startsAt = dayjs.tz(event?.startsAt, 'Europe/Amsterdam');
  const endsAt = dayjs.tz(event?.endsAt, 'Europe/Amsterdam');

  const eventDate = startsAt.isSame(endsAt, 'day')
    ? startsAt
        .toDate()
        .toLocaleDateString(i18n.language.replace('_', '-'), { day: 'numeric', month: 'long' })
    : `${startsAt.toDate().toLocaleDateString(i18n.language.replace('_', '-'), {
        day: 'numeric',
        month: 'long',
      })} - ${endsAt
        .toDate()
        .toLocaleDateString(i18n.language.replace('_', '-'), { day: 'numeric', month: 'long' })}`;

  return (
    <div
      css={css`
        box-sizing: border-box;
        display: flex;
        flex: 1;
        flex-direction: column;
        text-align: ${align};
        align-items: ${align};
        justify-content: center;
      `}
    >
      <div
        css={css`
          line-height: 1.1;
          margin-top: ${intro ? 1.125 : 0}em;
        `}
      >
        <Heading
          size='h2'
          color='textLight'
          css={css`
            margin: 0;
          `}
        >
          {event?.name}
        </Heading>
      </div>
      <div
        css={css`
          margin-top: ${intro ? 0.875 : 0.5625}em;
        `}
      >
        <Heading size='subtitle'>{eventDate}</Heading>
      </div>
      <Body
        css={css`
          color: ${theme.colors.textLight};
          margin-top: ${intro ? 1 : 2}em;

          > div > * {
            margin: 5px 0;
            line-height: 1.2;
          }
        `}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: event?.description || '' }} />
      </Body>
    </div>
  );
};

export default EventDetails;
