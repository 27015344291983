import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

type RoundInputProps = {
  style: object;
};

export function RoundInput({ style }: RoundInputProps) {
  const [text, setText] = useState<string>('');
  return (
    <div className='round-input'>
      <div className='container' style={{ ...style }}>
        <TextareaAutosize
          maxRows={5}
          spellCheck={false}
          className='input'
          placeholder='THEME'
          value={text}
          onChange={(e) => {
            e.target.setAttribute('value', e.target.value);
            setText(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
