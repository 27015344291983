import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import TextareaAutosize from 'react-textarea-autosize';

import CanvasOverlay from '../components/CanvasOverlay';

import { InfoButton } from '../components/InfoButton';
import { LongInput } from '../components/LongInput';
import { RoundInput } from '../components/RoundInput';

import { Vodafone } from '../svg/vodafone';

export function Vision() {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [text, setText] = useState<string>('');

  return (
    <>
      <div className='vision-container'>
        <div>
          <div className='title'>
            5 BOLD STEPS
            <br />
            <span>
              VISION CANVAS
              <InfoButton
                description={
                  <>
                    Het <b>5 Bold Steps Vision Canvas</b> helpt u bij het ontwerpen van de
                    toekomstvisie (een ambitieuze beschrijving van wat uw bedrijf wil bereiken). Het
                    canvas visualiseert die visie (Vision) inclusief acties (5 Bold Steps),
                    ondersteuning (Supports), waarden (Values) en uitdagingen (Challenges).
                  </>
                }
              />
            </span>
          </div>

          <div
            className='sub-title'
            style={{
              left: '27.083vw',
              top: '1.563vw',
            }}
          >
            THEMES
            <InfoButton
              description={
                <>
                  Wat zijn de essentiële thema’s die de visie ondersteunen? Beschrijf ze in 1 of 2
                  woorden.
                </>
              }
            />
          </div>

          <RoundInput style={{ left: '25.990vw', top: '3.542vw' }} />
          <RoundInput style={{ left: '19.010vw', top: '5.833vw' }} />
          <RoundInput style={{ right: '18.906vw', top: '5.833vw' }} />
          <RoundInput style={{ top: '12.240vw', left: '15.677vw' }} />
          <RoundInput style={{ top: '12.240vw', right: '15.469vw' }} />

          <div
            className='sub-title vision-sub-title-container'
            style={{
              color: '#740102',
              top: '14vw',
              left: '25.45vw',
              borderColor: '#740102',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              marginTop: 0,
              height: '5vw',
            }}
          >
            <InfoButton
              description={
                <>
                  Wat is de toekomstige staat van uw bedrijf en hoe gaat u uw klanten beter helpen?
                </>
              }
            />
            <TextareaAutosize
              maxRows={7}
              spellCheck={false}
              className='vision-input'
              placeholder='VISION'
              value={text}
              onChange={(e) => {
                e.target.setAttribute('value', e.target.value);
                setText(e.target.value);
              }}
            />
          </div>

          <LongInput
            width='5.729vw'
            height='1.042vw'
            style={{ left: '25.521vw', top: '20.833vw' }}
          />
          <LongInput width='6.771vw' height='1.042vw' style={{ left: '25vw', top: '23.229vw' }} />
          <LongInput
            width='8.333vw'
            height='1.042vw'
            style={{ left: '24.219vw', top: '25.781vw' }}
          />
          <LongInput
            width='9.375vw'
            height='1.042vw'
            style={{ left: '23.750vw', top: '28.490vw' }}
          />
          <LongInput
            width='10.938vw'
            height='1.302vw'
            style={{ left: '22.917vw', top: '31.250vw' }}
          />

          <div className='slogan'>5 BOLD STEPS</div>

          <div className='description'>
            Wat zijn de gewaagde stappen die we moeten <br /> nemen om de toekomstvisie te
            verwezenlijken?
          </div>

          <div className='sub-title' style={{ left: '3.719vw', bottom: '10.417vw' }}>
            SUPPORTS
            <InfoButton
              description={
                <>
                  Welke hulp (intern of extern) van mensen of ontwikkelingen (markt/maatschappij)
                  helpt om het gezamenlijke doel te bereiken?
                </>
              }
            />
          </div>

          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ left: '3.719vw', bottom: '13.1vw' }}
          />
          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ left: '3.719vw', bottom: '12vw' }}
          />
          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ left: '3.719vw', bottom: '10.9vw' }}
          />

          <div className='sub-title' style={{ left: '3.719vw', bottom: '5.208vw' }}>
            VALUES
            <InfoButton
              inverse
              description={
                <>
                  Wat zijn de kernwaarden die het fundament vormen voor de gezamenlijke visie en de
                  stappen om daar te komen?
                </>
              }
            />
          </div>

          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ left: '3.719vw', bottom: '7.9vw' }}
          />
          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ left: '3.719vw', bottom: '6.8vw' }}
          />
          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ left: '3.719vw', bottom: '5.7vw' }}
          />

          <div className='sub-title' style={{ right: '11.979vw', bottom: '10.417vw' }}>
            CHALLENGES
            <InfoButton
              description={
                <>Wat zijn de uitdagingen die ons mogelijk belemmeren om het doel te bereiken? </>
              }
            />
          </div>
          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ right: '19.7vw', bottom: '13.1vw' }}
          />
          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ right: '19.7vw', bottom: '12vw' }}
          />
          <LongInput
            bullet
            width='16.5vw'
            height='1vw'
            style={{ right: '19.7vw', bottom: '10.9vw' }}
          />
        </div>

        <div className='vodafone'>{Vodafone}</div>
      </div>
      {ReactDOM.createPortal(
        <CanvasOverlay
          openModal={showOverlay}
          closeModal={() => setShowOverlay(false)}
          title={
            <>
              5 BOLD STEPS
              <br />
              VISION CANVAS
            </>
          }
          subtitle='Wat is het?'
          content={
            <>
              Het <b>5 bold steps vision canvas</b> helpt u bij het ontwerpen van de toekomstvisie
              (een ambitieuze beschrijving van wat uw bedrijf wil bereiken). Het canvas visualiseert
              die visie (Vision) inclusief acties (5 Bold Steps), ondersteuning (Supports), waarden
              (Values) en uitdagingen (Challenges).
            </>
          }
        />,
        document.body,
      )}
    </>
  );
}
