export const InfoIcon = (
  <svg
    width='100%'
    height='100%'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 29 29'
  >
    <path
      d='M14.5,1a13.64,13.64,0,0,1,2.63.26A13.29,13.29,0,0,1,19.67,2,13.07,13.07,0,0,1,22,3.28,13,13,0,0,1,25.72,7,13.07,13.07,0,0,1,27,9.33a13.29,13.29,0,0,1,.77,2.54,13.43,13.43,0,0,1,0,5.26A13.29,13.29,0,0,1,27,19.67,13.07,13.07,0,0,1,25.72,22,13,13,0,0,1,22,25.72,13.07,13.07,0,0,1,19.67,27a13.29,13.29,0,0,1-2.54.77,13.43,13.43,0,0,1-5.26,0A13.29,13.29,0,0,1,9.33,27,13.07,13.07,0,0,1,7,25.72,13,13,0,0,1,3.28,22,13.07,13.07,0,0,1,2,19.67a13.29,13.29,0,0,1-.77-2.54,13.43,13.43,0,0,1,0-5.26A13.29,13.29,0,0,1,2,9.33,13.07,13.07,0,0,1,3.28,7,13,13,0,0,1,7,3.28,13.07,13.07,0,0,1,9.33,2a13.29,13.29,0,0,1,2.54-.77A13.64,13.64,0,0,1,14.5,1Z'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    />
    <line
      x1='14.36'
      y1='19.59'
      x2='14.36'
      y2='13.02'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    />
    <line
      x1='14.36'
      y1='9.66'
      x2='14.36'
      y2='9.13'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    />
  </svg>
);
