import { useMutation } from 'react-query';

import { getAccessToken } from '@modules/auth/hooks/useAuth';
import useEvent from '@modules/event/hooks/useEvent';
import fetch from '@common/utils/fetch';

export const useAddToCall = () => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(({ userId, videoCallId }: { userId: string; videoCallId: string }) => {
    return fetch(`/events/${event?.id}/video-calls/${videoCallId}/add`, {
      method: 'POST',
      body: { userId },
      token: accessToken,
    });
  });
};
