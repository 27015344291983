import { Icon } from '@typing/icons';

const Bell: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 11C6 7.68629 8.68629 5 12 5C15.3137 5 18 7.68629 18 11V15V15C18.8284 15 19.5 15.6716 19.5 16.5V16.5C19.5 17.3284 18.8284 18 18 18H6C5.17157 18 4.5 17.3284 4.5 16.5V16.5C4.5 15.6716 5.17157 15 6 15V15V11Z'
        fill={color}
        strokeWidth={0}
      />
      <circle cx='12' cy='3.5' r='1.5' fill={color} strokeWidth={0} />
      <path
        d='M15 19C15 20.6569 13.6569 22 12 22C10.3431 22 9 20.6569 9 19H15Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Bell;
