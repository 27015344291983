/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface FullScreenState {
  isMobileFullScreen: boolean;
  isElementFullScreen: boolean;
}

const initialState: FullScreenState = {
  isMobileFullScreen: false,
  isElementFullScreen: false,
};

export const FullScreenSlice = createSlice({
  name: 'fullscreen',
  initialState,
  reducers: {
    openMobileFullScreen: (state) => {
      state.isMobileFullScreen = true;
    },
    closeMobileFullScreen: (state) => {
      state.isMobileFullScreen = false;
    },
    openElementFullScreen: (state) => {
      state.isElementFullScreen = true;
    },

    closeElementFullScreen: (state) => {
      state.isElementFullScreen = false;
    },
  },
});

export const {
  openMobileFullScreen,
  closeMobileFullScreen,
  openElementFullScreen,
  closeElementFullScreen,
} = FullScreenSlice.actions;
export default FullScreenSlice.reducer;
