/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { format as formatTime } from 'timeago.js';

import Card from '@common/components/Card';
import Avatar from '@modules/user/components/Avatar';
import Body from '@common/components/Body';

import { RoomResource } from '@common/transforms/room';
import { MessageResource } from '@common/transforms/message';
import { useAppSelector } from '@common/store';

type ChatRoomCardProps = {
  room: RoomResource;
  onClick?: () => void;
  unread?: boolean;
};

const ChatRoomCard: React.FC<ChatRoomCardProps> = ({ room, onClick, unread = false }) => {
  const user = useAppSelector((state) => state.auth.user);
  const { t, i18n } = useTranslation();
  const formattedLang = i18n.language === 'nl_BE' ? 'nl' : i18n.language;
  const theme = useTheme();

  const [firstParticipant, lastMessage] = useMemo(() => {
    const participants = room.participants.filter((props) => props.id !== user?.id);
    if (!room.messages?.[0]) return [participants[0], undefined];

    const message: MessageResource = room.messages[0];
    return [
      participants[0],
      { ...message, participant: room.participants.find((props) => props.id === message.userId) },
    ];
  }, [room.participants, room.messages, user?.id]);

  const name = lastMessage?.userId === user?.id ? t('you') : lastMessage?.participant?.fullName;

  return (
    <Card
      css={css`
        flex-direction: row;
        padding: 1em;
        margin-top: 1em;
      `}
      key={room.id}
      onClick={onClick}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          > div {
            cursor: pointer;
          }
        `}
      >
        <Avatar user={firstParticipant} size={50} emptyBackground />

        {firstParticipant?.isOnline && (
          <div
            css={css`
              position: absolute;
              margin-top: -25pt;
              margin-left: 30pt;
              background-color: lightgreen;
              width: 10pt;
              height: 10pt;
              border-radius: 10pt;
              border-width: 2pt;
              border-style: solid;
              border-color: ${theme.colors.eventBackground};
            `}
          />
        )}
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 1em;
          max-width: calc(100% - 50px - 1em);
        `}
      >
        <Body
          weight='bold'
          color='textLight'
          size='s'
          css={css`
            margin-bottom: 0.5em;
          `}
        >
          {firstParticipant?.fullName}
        </Body>
        {lastMessage && (
          <Body
            size='s'
            css={css`
              max-width: 100%;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
            `}
          >
            <span
              css={css`
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              `}
            >
              {[name ? `${name}: ` : '', lastMessage.message].join('')}
            </span>
            <span style={{ marginLeft: '1rem' }}>
              {formatTime(lastMessage.createdAt, formattedLang)}
            </span>
          </Body>
        )}
      </div>
      {unread && (
        <div
          css={css`
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
          `}
        >
          <div
            css={css`
              float: right;
              right: 0;
              background-color: ${theme.colors.primary};
              width: 8pt;
              height: 8pt;
              border-radius: 4pt;
              border-width: 1pt;
              border-style: solid;
              border-color: ${theme.colors.eventBackground};
            `}
          />
        </div>
      )}
    </Card>
  );
};

export default ChatRoomCard;
