import React, { useContext } from 'react';

import { SessionLocation } from '@common/transforms/session';

import { useInfiniteSessions } from '@modules/schedule/hooks/useSessions';

import { filterSessionsByLocation } from '@modules/event/utils';

import type { ScheduleContext } from '../..';

import Base from './base';

interface ExploreViewProps {
  context: React.Context<ScheduleContext>;
}

const ScheduledView = ({ selectedLocation }: { selectedLocation: SessionLocation }) => {
  const { data, ...rest } = useInfiniteSessions({ limit: 10 }, undefined, true);
  const sessions = data?.pages.map((page) => page.items.map((item) => ({ ...item }))).flat();

  return (
    <Base
      sessionProps={rest}
      useInfiniteLoading
      sessions={filterSessionsByLocation(selectedLocation, sessions)}
    />
  );
};

const OverviewView = ({ selectedLocation }: { selectedLocation: SessionLocation }) => {
  const { data, ...rest } = useInfiniteSessions({ limit: 10 }, undefined);
  const sessions = data?.pages.map((page) => page.items.map((item) => ({ ...item }))).flat();

  return (
    <Base
      sessionProps={rest}
      useInfiniteLoading
      sessions={filterSessionsByLocation(selectedLocation, sessions)}
    />
  );
};

const ExploreView: React.FC<ExploreViewProps> = ({ context }) => {
  const { scheduled, selectedLocation } = useContext(context);

  return scheduled ? (
    <ScheduledView selectedLocation={selectedLocation} />
  ) : (
    <OverviewView selectedLocation={selectedLocation} />
  );
};

export default ExploreView;
