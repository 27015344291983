import { useCallback } from 'react';

import fetch from '@common/utils/fetch';
import { useEvent } from '@modules/event/hooks/useEvent';
import { setError, setRegistered } from '@modules/auth/store/auth';
import { useAppDispatch } from '@common/store';

interface RegisterProps {
  email?: string;
  firstName?: string;
  lastName?: string;
  privacyPolicyAccepted?: boolean;
  tags?: string[];
  fields?: Array<{ value: string; customFieldId: string }>;
}

export function useRegister() {
  const event = useEvent();
  const dispatch = useAppDispatch();

  const startRegistration = useCallback(() => {
    dispatch(setRegistered(false));
  }, [dispatch]);

  const register = useCallback(
    async (data: RegisterProps, image?: File) => {
      if (!event) return;

      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      if (image) formData.append('image', image);

      try {
        await fetch(`/auth/${event.id}/register`, {
          method: 'POST',
          body: formData,
        });
        dispatch(setRegistered(true));
      } catch (err: any) {
        dispatch(
          setError(
            err?.cause?.response?.body?.message ||
              err?.response?.body?.message ||
              err?.response?.body?.error ||
              err?.message,
          ),
        );
      }
    },
    [dispatch, event],
  );

  return { startRegistration, register };
}
