/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Icon from '@common/components/Icon';
import Button from '@common/components/Button';

type DoNotDisturbProps = Record<string, unknown>;

const DoNotDisturb: React.FC<DoNotDisturbProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClick = () => {
    // TODO: handle implementation
  };

  return (
    <div
      css={css`
        float: left;
        width: auto;
        box-sizing: border-box;
        background-color: ${theme.colors.alerts.background};
        backdrop-filter: blur(${theme.blurs.normal});
        border-radius: ${theme.borderRadius.iconButton};
        padding: 15px 15px;
        color: ${theme.colors.alerts.text};
      `}
    >
      <p
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          margin: 0;
          margin-bottom: 5px;
          padding: 0;
          font-weight: ${theme.fontWeights.bold};
          font-size: ${theme.fontSizes.m};

          > svg {
            margin-right: 7px;
          }
        `}
      >
        <Icon icon='moon' size={16} color={theme.colors.alerts.text} />
        {t('doNotDisturb.title')}
      </p>
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <p
          css={css`
            margin: 0;
            padding: 0;
            margin-right: 20px;
            font-size: ${theme.fontSizes.m};
          `}
        >
          {t('doNotDisturb.text')}
        </p>
        <Button disableMinWidth onClick={handleClick} label={t('enable')} color='secondary' />
      </div>
    </div>
  );
};

export default DoNotDisturb;
