import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChannel, useEvent, usePusher } from '@harelpls/use-pusher';
import { QueryClient, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Channel } from 'pusher-js';

import {
  PUSHER_ANNOUNCEMENT,
  PUSHER_PUSH_TO_PAGE,
  PUSHER_BOOTH,
  PUSHER_EVENT_UPDATE,
  PUSHER_ANNOUNCEMENT_DELETED,
  PUSHER_MESSAGE,
  PUSHER_GLOBAL_CHAT,
  PUSHER_PRIVATE_CHAT,
  PUSHER_TOGGLE_CHAT,
  PUSHER_REDIRECT,
  PUSHER_CALL_REQUEST,
  PUSHER_CALL_RESPONSE,
  PUSHER_CALL_UPDATE,
  PUSHER_USER_UPDATED,
  PUSHER_CLAP_HAND,
  PUSHER_OPEN_SLIDO,
} from '@config';
import parseAnnouncement, { AnnouncementResource } from '@common/transforms/announcement';
import parseLocation, { LocationResource } from '@common/transforms/location';
import parseMessage, { MessageResource } from '@common/transforms/message';
import { useThrottle } from '@common/hooks/useDebounce';
import fetch from '@common/utils/fetch';

import useCurrentEvent, { useEvents, useEventId } from '@modules/event/hooks/useEvent';
import { usePage, usePages } from '@modules/event/hooks/usePages';
import {
  resetIncomingCall,
  resetOutgoingCall,
  setIncomingCall,
  setIsOutGoingCallDenied,
  startOutgoingCall,
} from '@modules/video-call/store/videoCall';
import { useJoinCall } from '@modules/video-call/hooks/useJoinCall';
import { AppDispatch, useAppDispatch, useAppSelector } from '@common/store';
import { setActiveItem, setFullscreen } from '@common/store/sidebar';
import { useSessionId } from '@modules/auth/hooks/useSessionId';
import { setAnnouncement, setLocation, setPushToPage } from '@common/store/pusher';
import { useLastAnnouncement } from '@modules/event/hooks/useAnnouncements';
import { EventResource } from '@common/transforms/event';
import { UserResource } from '@common/transforms/user';
import {
  setChatNotificationRoomId,
  setShowGroupChatNotification,
  setShowPrivateChatNotification,
} from '@modules/chat/store/chat';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

const useInitAnnouncements = (dispatch: AppDispatch, globalChannel?: Channel) => {
  const { data: lastAnnouncement } = useLastAnnouncement();

  useEffect(() => {
    dispatch(setAnnouncement(lastAnnouncement));
  }, [dispatch, lastAnnouncement]);

  useEvent<AnnouncementResource>(globalChannel, PUSHER_ANNOUNCEMENT, (data) => {
    dispatch(setAnnouncement(parseAnnouncement.one(data)));
  });

  useEvent(globalChannel, PUSHER_ANNOUNCEMENT_DELETED, () => {
    dispatch(setAnnouncement(undefined));
  });
};

const useInitPushToPage = (dispatch: AppDispatch, globalChannel?: Channel) => {
  const { data: pages } = usePages();
  const user = useAppSelector((state) => state.auth.user);

  const pushToPageHandler = (data?: {
    roles: string[];
    pageId?: string;
    videoCallId?: string;
    videoCallTitle?: string;
  }) => {
    if (!pages || !data?.roles || !user?.role?.id) return;

    if (data.roles.length && !data.roles.includes(user.role.id)) return;

    if (data.pageId) {
      const page = pages.find((props) => props.id === data.pageId);
      if (!page) return;
      dispatch(
        setPushToPage({ id: page.id, title: page.title, slug: page.slug, isVideoCall: false }),
      );
    } else if (data.videoCallId) {
      dispatch(
        setPushToPage({
          id: data.videoCallId,
          title: data.videoCallTitle ?? 'Call',
          isVideoCall: true,
        }),
      );
    }
  };

  useEvent(globalChannel, PUSHER_PUSH_TO_PAGE, pushToPageHandler);
};

const useInitChat = (
  queryClient: QueryClient,
  dispatch: AppDispatch,
  user?: UserResource,
  globalChannel?: Channel,
  privateChannel?: Channel,
) => {
  const activeSidebarItem = useAppSelector((state) => state.sidebar.activeItem);

  useEvent(privateChannel, PUSHER_TOGGLE_CHAT, (data: any) => {
    if (data.open) {
      dispatch(setActiveItem('private_chat'));
    } else if (activeSidebarItem === 'private_chat') {
      dispatch(setActiveItem(undefined));
    }
  });

  const onReceiveChat = async (data: {
    roomId?: string;
    type: typeof PUSHER_GLOBAL_CHAT | typeof PUSHER_PRIVATE_CHAT;
    message: MessageResource;
  }) => {
    switch (data.type) {
      case PUSHER_GLOBAL_CHAT:
        await queryClient.cancelQueries(['chat', 'global']);
        queryClient.setQueryData(['chat', 'global'], (messages: any) => [
          ...(messages || []),
          parseMessage.one(data.message),
        ]);
        dispatch(setShowGroupChatNotification(true));
        break;
      case PUSHER_PRIVATE_CHAT:
        await queryClient.cancelQueries(['chat', 'rooms', data.roomId]);
        queryClient.setQueryData(['chat', 'rooms', data.roomId, 'messages'], (messages: any) => [
          ...(messages || []),
          parseMessage.one(data.message),
        ]);
        if (user?.id !== data.message.userId && data.roomId) {
          dispatch(setShowPrivateChatNotification(true));
          dispatch(setChatNotificationRoomId(data.roomId));
        }
        break;
      default:
        break;
    }
  };

  useEvent(globalChannel, PUSHER_MESSAGE, (data: any) => onReceiveChat(data));
  useEvent(privateChannel, PUSHER_MESSAGE, (data: any) => onReceiveChat(data));
};

const useInitRedirect = (privateChannel?: Channel) => {
  const sessionId = useSessionId();
  const navigate = useNavigate();

  useEvent(privateChannel, PUSHER_REDIRECT, (data: any) => {
    if (!data.sessionId || data.sessionId === sessionId) {
      navigate(`${data.slug}`);
    }
  });
};

const useInitCall = (
  queryClient: QueryClient,
  dispatch: AppDispatch,
  event?: EventResource,
  privateChannel?: Channel,
) => {
  const outgoingCall = useAppSelector((state) => state.videoCall.outgoingCall);
  const { mutate: joinCall } = useJoinCall();

  useEvent(
    privateChannel,
    PUSHER_CALL_REQUEST,
    (data?: { callId: string; participantId: string }) => dispatch(setIncomingCall(data)),
  );

  useEvent(
    privateChannel,
    PUSHER_CALL_RESPONSE,
    (data?: { accepted: boolean; participantId: string }) => {
      if (data && data.accepted) {
        joinCall(data.participantId);
        dispatch(startOutgoingCall());
      }
      if (data && !data.accepted && data.participantId === outgoingCall?.participantId) {
        dispatch(setIsOutGoingCallDenied(true));
      } else {
        dispatch(resetIncomingCall());
        dispatch(resetOutgoingCall());
      }
    },
  );

  useEvent(privateChannel, PUSHER_CALL_UPDATE, async (data?: { videoCallId: string }) => {
    if (data && data?.videoCallId) {
      await queryClient.invalidateQueries(['video-call', event?.id, data.videoCallId]);
    }
  });
};

const usePusherEvents = (
  globalChannel: Channel | undefined,
  privateChannel: Channel | undefined,
) => {
  const event = useCurrentEvent();
  const user = useAppSelector((state) => state.auth.user);
  const location = useLocation();
  const currentPageSlug = location.pathname.split('/').pop();
  const { data: page } = usePage(currentPageSlug as string);

  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const { refreshEvent } = useEvents();

  useInitAnnouncements(dispatch, globalChannel);
  useInitPushToPage(dispatch, globalChannel);
  useInitChat(queryClient, dispatch, user, globalChannel, privateChannel);
  useInitCall(queryClient, dispatch, event, privateChannel);
  useInitRedirect(privateChannel);

  useEvent(globalChannel, PUSHER_EVENT_UPDATE, () => refreshEvent(i18n.language));
  useEvent(globalChannel, PUSHER_USER_UPDATED, () => queryClient.invalidateQueries('users'));
  useEvent<LocationResource>(privateChannel, PUSHER_BOOTH, (data) => {
    dispatch(setLocation(parseLocation.one(data)));
  });

  useEvent(globalChannel, PUSHER_OPEN_SLIDO, (data: any) => {
    if (page?.id !== data?.pageId) return;
    dispatch(setFullscreen(false));
    dispatch(setActiveItem('slido'));
  });
};

const useInitPusher = () => {
  const event = useCurrentEvent();
  const user = useAppSelector((state) => state.auth.user);
  const Pusher = usePusher();
  const [globalChannel, setGlobalChannel] = useState<Channel>();
  const [privateChannel, setPrivateChannel] = useState<Channel>();

  useEffect(() => {
    if (!Pusher.client || !event?.id) return;

    const globalChnl = Pusher.client.subscribe(`private-${event.id}`);
    setGlobalChannel(globalChnl);

    let privateChnl: Channel | undefined;
    if (user?.id) {
      privateChnl = Pusher.client.subscribe(`private-${event.id}_${user.id}`);
      setPrivateChannel(privateChnl);
    }

    const cleanup = () => {
      globalChnl.unsubscribe();
      privateChnl?.unsubscribe();
      setGlobalChannel(undefined);
      setPrivateChannel(undefined);
    };

    setTimeout(() => {
      if (!globalChnl.subscribed) cleanup();
    }, 1000);

    // eslint-disable-next-line consistent-return
    return cleanup;
  }, [Pusher.client, event?.id, user?.id]);

  return { globalChannel, privateChannel };
};

export const useClapHands = () => {
  const [count, setCount] = useState(0);
  const eventId = useEventId();
  const accessToken = getAccessToken();
  const globalChannel = useChannel(`private-${eventId}`);

  const onClap = useThrottle(async () => {
    await fetch(`/events/${eventId}/clap`, {
      method: 'POST',
      token: accessToken,
    });
  }, 1500);

  useEvent(globalChannel, PUSHER_CLAP_HAND, () => {
    setCount((prev) => prev + 1);
  });

  return { count, clap: onClap };
};

export const PusherInitializer: React.FC = ({ children }) => {
  const { globalChannel, privateChannel } = useInitPusher();
  usePusherEvents(globalChannel, privateChannel);
  return <>{children}</>;
};
