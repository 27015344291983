import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { parse } from 'query-string';

import { isLocalUrl, isQueryUrl } from '@common/utils/checkUrl';
import { useJoinVideoCall } from '@modules/video-call/hooks/useInitVideoCall';
import { PageTemplate } from '@common/transforms/page';
import { SessionResource } from '@common/transforms/session';
import { usePages } from '@modules/event/hooks/usePages';

export function useUrl() {
  const navigate = useNavigate();
  const joinVideoCall = useJoinVideoCall();
  const pages = usePages();

  const redirect = useCallback(
    (url: SessionResource['url']) => {
      if (!url) return;
      const pageWithId = pages.data?.find((page) => page.id === url);
      if (pageWithId) {
        navigate(`/pages/${pageWithId.slug}`, { replace: true });
        return;
      }
      if (isQueryUrl(url)) {
        const query = parse(url);
        if (query.videoCall) {
          joinVideoCall(query.videoCall as string);
        } else if (query.page) {
          const foundPage = pages.data?.find((page) => page.id === query.page);
          if (foundPage) {
            if (foundPage.template === PageTemplate.VIDEO_ON_DEMAND && query.video) {
              navigate(`/pages/${foundPage.slug}/${query.video}`, { replace: true });
            } else {
              navigate(`/pages/${foundPage.slug}`, { replace: true });
            }
          }
        }
      } else if (isLocalUrl(url)) {
        navigate(url, { replace: true });
      } else {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        window.open(url, '_blank');
      }
    },
    [navigate, joinVideoCall, pages],
  );

  return { redirect };
}
