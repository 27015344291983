/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import Button from '@common/components/Button';
import { Platform, usePlatform } from '@common/hooks/usePlatform';
import Card from '@common/components/Card';
import Icon from '@common/components/Icon';
import { mediaQuery } from '@common/styles/mediaQuery';
import { DIRECTION } from '@common/utils/consts';

type MenuItem = {
  key: string;
  label: string;
  active: boolean;
};

type MenuProps = {
  live?: boolean;
  items: MenuItem[];
  onItemClick: (key: string) => void;
};

type MobileMenuItemProps = {
  live: boolean;
  item: MenuItem;
  onClick: () => void;
};

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({ live, item, onClick }) => (
  <Card
    onClick={onClick}
    css={css`
      margin: 0.5em 2em;
      padding: 0.75em 1.5em;
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #fff;
      border-color: ${live ? '#fff' : 'transparent'};
    `}
  >
    {item.label}
    <Icon icon='chevron' direction={DIRECTION.RIGHT} size={16} color='#fff' />
  </Card>
);

const Menu: React.FC<MenuProps> = ({ live = false, items, onItemClick }) => {
  const platform = usePlatform();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        ${mediaQuery(
          'l',
          css`
            flex-direction: row;
            align-items: center;
          `,
        )}
      `}
    >
      {items.map((item) => {
        if (platform === Platform.MOBILE) {
          return (
            <MobileMenuItem
              key={item.key}
              live={live}
              item={item}
              onClick={() => {
                onItemClick(item.key);
              }}
            />
          );
        }

        return (
          <Button
            disableMinWidth
            key={item.key}
            label={item.label}
            color='secondary'
            size='xs'
            outline={live && !item.active}
            plain={!live && !item.active}
            icon={live ? 'live' : undefined}
            onClick={() => onItemClick(item.key)}
            css={css`
              margin-left: 0.3em;
              padding-bottom: 0.4em;
              &:first-of-type {
                margin-left: 0;
              }
              ${mediaQuery(
                'l',
                css`
                  height: fit-content;
                `,
              )}
            `}
          />
        );
      })}
    </div>
  );
};

export default Menu;
