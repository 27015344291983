/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import Body from '@common/components/Body';
import Button from '@common/components/Button';
import Heading from '@common/components/Heading';

import { useStartChat } from '@modules/chat/hooks/useStartChat';
import { useRoles, useUsers } from '@modules/user/hooks/useUsers';
import { EventResource } from '@common/transforms/event';
import { UserRolePermission } from '@common/transforms/userRole';

export function SupportItem({ event }: { event?: EventResource }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data: roles } = useRoles();
  const { data: rawUsers } = useUsers(
    '',
    [],
    roles
      ?.filter((role) => role.permissions.includes(UserRolePermission.HOST_CHAT))
      .map((role) => role.id) ?? [],
    false,
    false,
  );
  const eventHost = rawUsers?.pages[0]?.items[0]?.id;
  const startChat = useStartChat();

  return (
    <>
      {/* TODO Make new component */}
      <Heading size='h3' color='textLight'>
        {t('support_title')}
      </Heading>
      <Body
        css={css`
          padding-top: 2em;
          line-height: 1.5em;
        `}
      >
        <Trans
          i18nKey='support_description'
          values={{ email: event?.organiserContact }}
          components={[
            <a
              key='link'
              href={`mailto:${event?.organiserContact}`}
              css={css`
                color: ${theme.colors.primary};
              `}
            >
              {event?.organiserContact}
            </a>,
          ]}
        />
      </Body>
      <Body>{event?.organiser}</Body>
      {eventHost && event?.hostChatEnabled && (
        <div
          css={css`
            margin-top: 1rem;
          `}
        >
          <Button
            size='m'
            onClick={() => startChat(eventHost)}
            label={t('start_host_chat_button')}
          />
        </div>
      )}
    </>
  );
}
