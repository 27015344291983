/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import Body from '@common/components/Body';
import Heading from '@common/components/Heading';

import Button from './Button';
import { TimeIndicator } from './TimeIndicator';

type CardButtonProps = {
  title: string;
  subtitle: string;
  vimeoVideoId?: string;
  imageUrl?: string;
  duration?: number;
  buttonText?: string;
  buttonEnabled?: boolean;
  onClick?: () => void;
};

const CardButton: React.FC<CardButtonProps> = ({
  title,
  subtitle,
  vimeoVideoId,
  imageUrl,
  duration,
  buttonText,
  buttonEnabled,
  onClick,
}) => {
  const nativeOnClick = () => onClick?.();
  const theme = useTheme();

  const imageUrlForVideo = () => {
    // Fallback to find the vimeo thumbnail
    if (imageUrl === '') {
      return `https://vumbnail.com/${vimeoVideoId}.jpg`;
    }
    return imageUrl;
  };

  return (
    <div
      css={css`
        margin-top: 1em;
        margin-bottom: 1em;
        border-radius: ${theme.borderRadius.normal};
        -webkit-border-radius: ${theme.borderRadius.normal};
        -webkit-mask-image: -webkit-radial-gradient(circle, white, white);
        overflow: hidden;
        position: relative;
        padding: 1em;
        padding-top: 16em;
        ${!buttonText && onClick && 'cursor: pointer;'}
        width: calc(100vw - 4em);

        @media (min-width: 320px) {
          width: initial;
          max-width: 290px;
          min-width: 290px;
        }

        :hover div:first-of-type {
          ${!buttonText && onClick && 'transform: scale(1.05);'}
        }
      `}
      onClick={!buttonText ? nativeOnClick : undefined}
      aria-hidden='true'
    >
      <div
        css={css`
          transition: all 0.2s;
          background-image: url(${imageUrl || imageUrlForVideo()});
          background-size: cover;
          background-position: center;
          background-color: ${theme.colors.secondary};
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          -webkit-mask-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(0, 0, 0, 1)),
            to(rgba(0, 0, 0, 0))
          );
          mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        `}
      />
      {duration && duration !== 0 && (
        <TimeIndicator
          css={css`
            position: absolute;
            top: 0.5em;
            right: 0.5em;
          `}
        >
          {duration} min
        </TimeIndicator>
      )}
      <Body
        css={css`
          position: relative;
        `}
        color='textLight'
      >
        {subtitle}
      </Body>
      <Heading
        css={css`
          position: relative;
        `}
        size='h2'
        color='textLight'
      >
        {title}
      </Heading>
      {buttonText && (
        <Button
          css={css`
            position: relative;
            width: 100%;
          `}
          label={buttonText}
          disabled={!buttonEnabled}
          onClick={nativeOnClick}
        />
      )}
    </div>
  );
};

export default CardButton;
