/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import dayjs from 'dayjs';

import Container, { Orientation } from '@common/components/Container';
import Heading from '@common/components/Heading';
import CardButton from '@common/components/CardButton';

import { usePageItemCategories } from '@modules/event/hooks/usePageItemCategories';
import { PageResource } from '@common/transforms/page';
import { useJoinVideoCall } from '@modules/video-call/hooks/useInitVideoCall';
import { useUrl } from '@modules/schedule/hooks/useUrl';

export function BreakOutTemplate({ page }: { page: PageResource }) {
  const { t } = useTranslation();
  const { redirect } = useUrl();
  const { data: categories } = usePageItemCategories(page?.id);
  const joinVideoCall = useJoinVideoCall();
  const now = new Date();

  if (!categories?.length) {
    return (
      <Container orientation={Orientation.VERTICAL}>
        <Heading>{page.title}</Heading>
        <Empty>{t('no_categories_found')}</Empty>
      </Container>
    );
  }

  return (
    <Container orientation={Orientation.VERTICAL}>
      <Heading>{page.title}</Heading>
      {categories?.map((category) => (
        <div key={category.id}>
          <Heading size='h2'>{category.title}</Heading>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            {category.rooms.length > 0 || category.videoCalls.length > 0 ? (
              <>
                {category.rooms.map((breakoutRoom) => (
                  <div
                    key={breakoutRoom.id}
                    css={css`
                      margin-right: 1em;
                    `}
                  >
                    <CardButton
                      title={breakoutRoom.title}
                      subtitle={breakoutRoom.subtitle || ''}
                      onClick={() => redirect(breakoutRoom.url)}
                      imageUrl={breakoutRoom.imageUrl || ''}
                    />
                  </div>
                ))}
                {category.videoCalls.map((videoCall) => {
                  const isPassed = now > dayjs(videoCall.endDate).toDate();

                  const renderButtonText = () => {
                    if (videoCall.full) return t('video_call_full');
                    if (isPassed) return t('finished');
                    return t('join_video_call');
                  };
                  return (
                    <div
                      key={videoCall.id}
                      css={css`
                        margin-right: 1em;
                      `}
                    >
                      <CardButton
                        title={videoCall.title || ''}
                        subtitle={videoCall.description || ''}
                        imageUrl={videoCall.imageUrl || ''}
                        buttonText={renderButtonText()}
                        buttonEnabled={!videoCall.full && !isPassed}
                        onClick={
                          !videoCall.full && !isPassed
                            ? () => joinVideoCall(videoCall.id)
                            : undefined
                        }
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <Empty>{t('no_breakout_rooms_found')}</Empty>
            )}
          </div>
        </div>
      ))}
    </Container>
  );
}

const Empty = styled.p`
  color: ${(props) => props.theme.colors.textLight};
  font-size: ${(props) => props.theme.fontSizes.m};
`;
