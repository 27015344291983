/** @jsxImportSource @emotion/react */
/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { InfiniteData } from 'react-query';

import Container, { Orientation } from '@common/components/Container';
import Select from '@common/components/Select';
import Loader from '@common/components/Loader';
import i18n from '@common/utils/i18n';
import Heading from '@common/components/Heading';
import { MetaResource } from '@common/transforms/meta';
import { PageResource } from '@common/transforms/page';
import { SessionLocation, SessionResource } from '@common/transforms/session';
import ScheduleHeader from '@modules/schedule/components/ScheduleHeader';
import Session, { DISPLAY_TYPE } from '@modules/schedule/components/Session';
import TabControl from '@modules/schedule/components/TabControl';
import { INTERNAL_SESSION } from '@modules/schedule/utils';
import SearchBar from '@common/components/SearchBar';

import type { ScheduleContext } from '..';

interface ScheduleMobileProps {
  page: PageResource;
  context: React.Context<ScheduleContext>;
  dates: {
    count: string;
    date: string;
  }[];
  sessions?: InfiniteData<{
    items: SessionResource[];
    meta: MetaResource;
  }>;
  sessionProps?: any;
}

interface DateRowItem {
  date: Date;
  sessions?: SessionResource[];
}

const ScheduleMobile: React.FC<ScheduleMobileProps> = ({
  page,
  context,
  dates,
  sessions,
  sessionProps,
}) => {
  const { t } = useTranslation();

  const {
    selectedDate,
    setSelectedDate,
    scheduled,
    setScheduled,
    query,
    setQuery,
    selectedLocation,
    setSelectedLocation,
  } = useContext(context);

  const [selectedStage, setSelectedStage] = useState<string | undefined>(undefined);

  const handleLocationChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setSelectedLocation(event.target.value as SessionLocation);

  useEffect(() => {
    const selectedDateIndex = dates.findIndex((d) => d.date === selectedDate?.toISOString());
    if (selectedDateIndex === -1) {
      setSelectedDate(undefined);
    }
  }, [dates, selectedDate, setSelectedDate]);

  const [sentryRef] = useInfiniteScroll({
    loading: sessionProps.isFetchingNextPage || sessionProps.isLoading,
    hasNextPage: !!sessionProps.hasNextPage,
    onLoadMore: sessionProps.fetchNextPage,
    disabled: sessionProps.isError,
    rootMargin: '0px 0px 400px 0px',
  });

  const infiniteSessions = sessions?.pages
    .map((scheduledPage) => scheduledPage.items.map((item) => ({ ...item })))
    .flat();

  if (sessionProps.isLoading) return <Loader />;

  const uniqueStages = new Set();
  const stages = infiniteSessions?.length
    ? [...infiniteSessions.map((session) => session.stage)]
        ?.filter?.((stage) => {
          if (!stage?.id) return false;
          const duplicate = uniqueStages.has(stage?.id);
          uniqueStages.add(stage?.id);
          return !duplicate;
        })
        .sort((a, b) => new Date(a!.createdAt).getTime() - new Date(b!.createdAt).getTime())
    : [];
  if (
    !stages?.length ||
    (stages?.length && !!infiniteSessions?.filter((session) => !session.stage)?.length)
  ) {
    stages.push(INTERNAL_SESSION);
  }

  const dateRows = (() => {
    const d: DateRowItem[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const date of dates) {
      const obj = {
        date: dayjs(date.date).toDate(),
        sessions: infiniteSessions?.filter(
          (session) =>
            (selectedStage
              ? selectedStage === 'internal'
                ? !session.stage
                : session.stage?.id === selectedStage
              : true) &&
            (dayjs(date.date).isSame(session.startsAt, 'd') ||
              dayjs(date.date).isSame(session.endsAt, 'd') ||
              dayjs(date.date).isBetween(session.startsAt, session.endsAt, 'd')),
        ),
      };
      if (obj.sessions?.length) d.push(obj);
    }
    return d;
  })();

  const handleSelectedStageChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setSelectedStage(event.target.value === 'all' ? undefined : event.target.value);

  const handleSelectedDateChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setSelectedDate(event.target.value === 'all' ? undefined : dayjs(event.target.value).toDate());

  return (
    <Container orientation={Orientation.VERTICAL}>
      <ScheduleHeader text={page.title} />
      <Header>
        <TabControl
          tabs={[
            { key: 'overview', label: t('overview') },
            { key: 'schedule', label: t('schedule') },
          ]}
          onChange={(selectedPage: string) => setScheduled(selectedPage === 'schedule')}
          selectedTab={scheduled ? 'schedule' : 'overview'}
        />
      </Header>
      <SearchBar
        onSearch={(input) => setQuery(input)}
        value={query}
        placeholder={scheduled ? t('placeholders.scheduled.search') : t('placeholders.search')}
      />

      <div
        css={css`
          margin-bottom: 1em;
        `}
      >
        <Formik initialValues={{ locationSelect: selectedLocation }} onSubmit={() => {}}>
          <Select
            name='locationSelect'
            options={Object.keys(SessionLocation).map((location) => ({
              label: t(`attendance_location.${location.toLowerCase()}`),
              value: location,
            }))}
            onChange={handleLocationChange}
            value={selectedLocation}
          />
        </Formik>
      </div>
      <Formik initialValues={{ selectedStage, selectedDate }} onSubmit={() => {}}>
        <Actions>
          <Select
            name='selectedStage'
            options={[
              { value: 'all', label: t('all_venues') },
              ...stages
                ?.filter((stage) => !!stage)
                .map((stage) => ({ value: stage!.id, label: stage!.name })),
            ]}
            onChange={handleSelectedStageChange}
            value={selectedStage}
          />
          <Select
            name='selectedDate'
            options={[
              { value: 'all', label: t('all_dates') },
              ...dates
                ?.filter((stage) => parseInt(stage.count, 10) > 0)
                .map((stage) => ({
                  value: dayjs(stage.date).toDate().toString(),
                  label: dayjs(stage.date)
                    .toDate()
                    .toLocaleDateString(i18n.language.replace('_', '-'), {
                      day: '2-digit',
                      weekday: 'short',
                      month: 'short',
                    }),
                })),
            ]}
            onChange={handleSelectedDateChange}
            value={selectedDate?.toString()}
          />
        </Actions>
      </Formik>
      <Block>
        {infiniteSessions?.length ? (
          dateRows.map((date: DateRowItem) => (
            <div
              key={date.date.toISOString()}
              css={css`
                margin-bottom: 30px;
              `}
            >
              <DayHeading size='h3' color='textLight'>
                {date.date.toLocaleDateString(i18n.language.replace('_', '-'), {
                  weekday: 'short',
                  day: '2-digit',
                  month: 'long',
                })}
              </DayHeading>
              {date.sessions?.map((session) => (
                <div
                  key={session.id}
                  css={css`
                    margin-bottom: 15px;
                  `}
                >
                  <Session
                    displayType={DISPLAY_TYPE.normal}
                    session={session}
                    selectedDate={date.date}
                  />
                </div>
              ))}
              {sessionProps.isFetchingNextPage ? (
                <Loader />
              ) : sessionProps.hasNextPage ? (
                <div ref={sentryRef} />
              ) : null}
            </div>
          ))
        ) : (
          <Empty>{t('vle_error_no_sessions_found')}</Empty>
        )}
      </Block>
    </Container>
  );
};

export default ScheduleMobile;

const Empty = styled.p`
  color: ${(props) => props.theme.colors.textLight};
  font-size: ${(props) => props.theme.fontSizes.m};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2em;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};

  > div {
    flex: 1;

    > select {
      width: 100%;
    }

    &:last-of-type {
      margin-left: 1em;
    }
  }
`;

const Block = styled.div`
  width: 100%;
  position: relative;
`;

const DayHeading = styled(Heading)`
  margin-bottom: 1em;
`;
