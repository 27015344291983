import { useAppSelector } from '@common/store';
import { SessionReminders } from '@modules/schedule/hooks/useSessionReminders';

const AuthScheduleWrapper: React.FC = ({ children }) => {
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  if (!isAuthorized) {
    return <>{children}</>;
  }

  return <SessionReminders />;
};

export default AuthScheduleWrapper;
