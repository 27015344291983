import { Icon } from '@typing/icons';

const Speaker: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.863 11.6308L19.2128 11.3708C18.9941 11.2913 18.7902 11.176 18.6093 11.0296C18.5074 10.9425 18.4173 10.8423 18.3415 10.7317L15.4952 5.82612C15.4018 5.61267 15.2844 5.41058 15.1452 5.22376L15.1029 5.15145C15.0673 5.11589 15.0318 5.0914 14.9918 5.06028C14.7888 4.83424 14.5313 4.66403 14.2438 4.56578C12.3644 3.96785 9.7427 6.87528 8.38792 11.0585C7.03314 15.2418 7.45882 19.1182 9.33928 19.715C9.60764 19.7859 9.88738 19.8028 10.1623 19.7647C10.4372 19.7265 10.7019 19.6341 10.9408 19.4928L16.0754 17.43C16.2002 17.3978 16.3287 17.3814 16.4577 17.3811C16.6875 17.3862 16.915 17.4287 17.1312 17.5068L17.6124 17.7057C18.4239 18.0022 19.3197 17.966 20.1047 17.605C20.8897 17.244 21.5003 16.5876 21.8034 15.7785C21.9496 15.3789 22.0154 14.9542 21.9969 14.529C21.9785 14.1039 21.8761 13.6866 21.6958 13.3011C21.5154 12.9156 21.2607 12.5697 20.9461 12.2831C20.6316 11.9964 20.2635 11.7747 19.863 11.6308V11.6308ZM13.7303 10.1861C12.919 10.1994 12.0177 11.1063 11.5976 12.4088C11.3834 12.8907 11.3038 13.4216 11.3674 13.9451C11.431 14.4686 11.6354 14.9651 11.9588 15.3817C11.2209 16.6042 10.4162 17.3011 9.82158 17.1121C8.8669 16.8087 8.82136 14.3337 9.71047 11.5785C10.5996 8.82342 12.0988 6.83964 13.0535 7.14304C13.7003 7.34865 13.9304 8.5668 13.7303 10.1905V10.1861Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M6.62083 9.96817L2.88321 8.89124C2.70636 8.8404 2.51654 8.86183 2.35553 8.95093C2.19453 9.04004 2.07556 9.18949 2.02471 9.36635C1.97386 9.5432 1.9953 9.73295 2.0844 9.89396C2.1735 10.055 2.32296 10.174 2.49982 10.2248L6.23737 11.3007C6.29985 11.3187 6.36464 11.3278 6.42968 11.3274C6.59674 11.3276 6.75821 11.2673 6.88437 11.1578C7.01052 11.0483 7.0928 10.897 7.11609 10.7316C7.13937 10.5661 7.10211 10.3978 7.01108 10.2577C6.92005 10.1176 6.78146 10.0152 6.62083 9.96925V9.96817Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M8.24455 6.67504L7.0131 4.35894C6.92546 4.19944 6.77854 4.08086 6.60414 4.02886C6.42973 3.97686 6.24189 3.99564 6.08121 4.08109C5.92053 4.16655 5.79995 4.31184 5.74557 4.48552C5.69118 4.65919 5.70736 4.84722 5.79061 5.00905L7.02199 7.32516C7.10964 7.48466 7.25655 7.60324 7.43096 7.65524C7.60536 7.70724 7.79327 7.6886 7.95395 7.60314C8.11464 7.51769 8.23521 7.37239 8.28959 7.19872C8.34398 7.02504 8.3278 6.83688 8.24455 6.67504V6.67504Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M5.14041 13.8646L2.80653 14.7415C2.63469 14.8063 2.4956 14.9368 2.41995 15.1041C2.34429 15.2715 2.33821 15.4621 2.40306 15.6339C2.4679 15.8058 2.5984 15.9448 2.76576 16.0204C2.93313 16.0961 3.1237 16.1022 3.29554 16.0374L5.62942 15.1594C5.71451 15.1272 5.79244 15.0787 5.85876 15.0165C5.92509 14.9543 5.97854 14.8796 6.016 14.7967C6.05346 14.7138 6.07422 14.6244 6.07712 14.5335C6.08002 14.4426 6.065 14.352 6.03289 14.2669C6.00078 14.1819 5.95219 14.1038 5.88997 14.0375C5.82774 13.9712 5.75306 13.9179 5.67019 13.8804C5.58732 13.843 5.49789 13.8221 5.40699 13.8192C5.31609 13.8163 5.2255 13.8314 5.14041 13.8635V13.8646Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Speaker;
