import parse, { Attributes } from '@common/utils/parse';

import parseMeta from './meta';
import { parseSession } from './session';

export const parseStage = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    name: attributes.string('name'),
    createdAt: attributes.string('createdAt'),
    sessions: attributes.optional.custom('sessions', (session) => {
      return parse.many(session, parseSession);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseStage);
}

export function many(data: any) {
  return {
    items: parse.many(data.items, parseStage),
    meta: parseMeta(data.meta),
  };
}

export type StageResource = ReturnType<typeof one>;
export type Stage = Omit<StageResource, 'id'>;

export default { one, many };
