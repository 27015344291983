import { usePages } from '@modules/event/hooks/usePages';

export const useMenu = () => {
  const { data: pages } = usePages();

  const livePages = pages?.filter((page) => page.visibleInMenu && page.template === 'LIVE');
  const otherPages = pages?.filter((page) => page.visibleInMenu && page.template !== 'LIVE');

  return { livePages, otherPages };
};
