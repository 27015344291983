import React, { createContext, ProviderProps, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useEvent, useEvents } from '@modules/event/hooks/useEvent';
import { queryClient } from '@common/components/QueryProvider';

interface LanguageState {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

export const languageContext = createContext<LanguageState>({
  language: 'en',
  setLanguage: () => {},
});

export const LanguageProvider = (props: Omit<ProviderProps<LanguageState>, 'value'>) => {
  const event = useEvent();
  const [language, setLanguage] = useState(event?.defaultLanguage || 'en');

  return <languageContext.Provider {...props} value={{ language, setLanguage }} />;
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const { refreshEvent } = useEvents();
  const { language, setLanguage } = useContext(languageContext);

  const setDateLocale = useCallback((lang: string) => {
    const index = lang.indexOf('_');
    let locale = lang;

    if (index !== -1) {
      locale = lang.substring(0, index);
    }

    import(`dayjs/locale/${locale}.js`).then(() => {
      dayjs.locale(locale);
    });
  }, []);

  const updateLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
      refreshEvent(lang);
      setLanguage(lang);
      setDateLocale(lang);
      queryClient.resetQueries('page');
      queryClient.resetQueries('pages');
      queryClient.resetQueries('tags');
      queryClient.resetQueries('customFields');
    },
    [i18n, refreshEvent, setLanguage, setDateLocale],
  );

  return { language, updateLanguage };
};

export default useLanguage;
