/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { VideoCallResource } from '@common/transforms/videoCall';

import { CallCard } from './CallCard';

interface Props {
  videoCall?: VideoCallResource;
  denied?: boolean;
  endOutgoingCallAfterDenied?: () => void;
  onEnd?: () => void;
}

export const OutgoingCallOverlay = ({
  videoCall,
  denied,
  endOutgoingCallAfterDenied,
  onEnd,
}: Props) => {
  const { t } = useTranslation();
  const cardRef = useRef<HTMLDivElement | null>(null);

  const calling = useMemo(
    () => videoCall?.participants?.find((participant) => !participant.initiator),
    [videoCall],
  );

  return (
    <div ref={cardRef}>
      {!denied && <audio src='/audio/outgoing.mp3' autoPlay loop />}
      <CallCard
        user={calling?.user}
        title={calling?.user?.fullName}
        subtitle={t('outgoing_call_text')}
        denied={denied}
        endOutgoingCallAfterDenied={endOutgoingCallAfterDenied}
        onEnd={onEnd}
      />
    </div>
  );
};
