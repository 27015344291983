/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom';

import RegisterIntro from '@modules/auth/components/RegisterIntro';
import useEvent from '@modules/event/hooks/useEvent';
import EventBackground from '@modules/event/components/EventBackground';
import { useBrowserLanguage } from '@modules/user/hooks/useBrowserLanguage';

export function Landing() {
  const event = useEvent();
  const navigate = useNavigate();
  useBrowserLanguage();

  return (
    <EventBackground event={event} autoResize>
      <RegisterIntro
        onClickRegister={() => navigate('/auth/register')}
        onClickLogin={() =>
          navigate(`/auth/${process.env.REACT_APP_SAML_AUTH === 'true' ? 'saml' : 'login'}`)
        }
        destination={`/auth/${process.env.REACT_APP_SAML_AUTH === 'true' ? 'saml' : 'login'}`}
        event={event}
        intro
      />
    </EventBackground>
  );
}
