import { useQuery } from 'react-query';

import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import transform from '@common/transforms/announcement';
import { getStaleTime } from '@common/utils/staleTime';
import fetch, { retry } from '@common/utils/fetch';
import { useAppSelector } from '@common/store';

export const useLastAnnouncement = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['announcement', event?.id, 'last'],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/announcements/last`, {
        token: accessToken,
      });

      return transform.one(body);
    },
    {
      enabled: !!event && isAuthorized,
      ...getStaleTime(5),
      retry,
    },
  );
};

export const useAllAnnouncements = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['announcements', event?.id],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/announcements`, { token: accessToken });

      return transform.many(body);
    },
    {
      enabled: !!event && isAuthorized,
      ...getStaleTime(1),
    },
  );
};
