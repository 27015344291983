/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import React, { PropsWithChildren, useEffect, useRef } from 'react';

import useResizeObserver from 'use-resize-observer';

import VixyPlayer from '@common/components/VixyPlayer';

import { VideoType } from '@common/transforms/video';

import {
  closeElementFullScreen,
  closeMobileFullScreen,
  openElementFullScreen,
  openMobileFullScreen,
} from '@common/store/fullscreen';
import { useAppDispatch, useAppSelector } from '@common/store';

import HLSPlayer from './HLSPlayer';

import Icon from './Icon';

export type videoDefaultProps = {
  type?: string;
  vixyPlayerId?: string;
  vixyEntryId?: string;
  vixyPartnerId?: string;
  vixyToken?: string;
  vimeoVideoId?: string;
  title?: string;
  hlsUrl?: string;
  embedUrl?: string;
};

export type VideoProps = {
  video: videoDefaultProps;
  pageTemplate?: string;
  startedPlaying?: () => void;
  stoppedPlaying?: () => void;
};

const Video = React.forwardRef<HTMLDivElement, PropsWithChildren<VideoProps>>(
  ({ video, startedPlaying, stoppedPlaying, pageTemplate, children }, ref) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
    const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);
    const sidebarItem = useAppSelector((state) => state.sidebar.activeItem);

    const {
      ref: container,
      width: containerWidth = 0,
      height: containerHeight = 0,
    } = useResizeObserver<HTMLDivElement>();

    const containerRatio = containerWidth / containerHeight;
    const iframeRatio = 16 / 9;
    const height = containerRatio < iframeRatio ? containerWidth / iframeRatio : containerHeight;

    useEffect(() => {
      function handelResize() {
        if (window.matchMedia('(orientation:portrait)').matches) {
          dispatch(closeMobileFullScreen());
        }
      }
      window.addEventListener('resize', handelResize);
      return () => {
        dispatch(closeMobileFullScreen());
        window.removeEventListener('resize', handelResize);
      };
    }, [dispatch]);

    const hlsRef = useRef<HTMLVideoElement | null>(null);

    const getVideoType = () => {
      switch (video?.type) {
        case VideoType.VIXY:
          return (
            <VixyPlayer
              playerId={Number(video.vixyPlayerId) || 0}
              entryId={video.vixyEntryId || ''}
              partnerId={video.vixyPartnerId || ''}
              token={video.vixyToken}
              startedPlaying={startedPlaying}
              stoppedPlaying={stoppedPlaying}
              css={css`
                z-index: 0;
              `}
              pageTemplate={pageTemplate}
            />
          );
        case VideoType.VIMEO:
          return (
            <iframe
              title={video.title}
              src={`https://player.vimeo.com/video/${video.vimeoVideoId}?color=F7CB15&title=0&byline=0&portrait=0`}
              frameBorder='0'
              allowFullScreen
              width='100%'
              height='100%'
              allow='autoplay; fullscreen'
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              `}
            />
          );
        case VideoType.HLS:
          return (
            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                > div {
                  padding-top: unset !important;
                }
              `}
            >
              <video
                css={css`
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                `}
                ref={hlsRef}
                controls
              >
                <track kind='captions' />
              </video>
              <HLSPlayer src={video.hlsUrl} video={hlsRef.current} />
            </div>
          );
        case VideoType.OTHER:
          return (
            <iframe
              title={video.title}
              src={video.embedUrl}
              frameBorder='0'
              allowFullScreen
              width='100%'
              height='100%'
              allow='autoplay'
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              `}
            />
          );
        default:
          return null;
      }
    };

    return (
      <div
        ref={container}
        css={css`
          ${mobileFullscreen ? `position: fixed;` : `position: relative;`}
          width: 100%;
          flex: 1;
          height: 100%;
        `}
      >
        <div
          ref={ref}
          css={css`
            position: relative;
            ${mobileFullscreen && sidebarItem ? `width: 60%;` : `width: 100%;`}
            background: black;
            transition: all 0.1s ease;
            ${mobileFullscreen || elementFullScreen
              ? 'height: 100%;'
              : `border-radius: 12px; height: ${height}px;`}
            overflow: hidden;
            display: flex;
          `}
        >
          <div
            css={css`
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1;
              margin: 5px;
              padding: 5px;
              display: none;
              background: rgba(0, 0, 0, 0.25);
              backdrop-filter: blur(${theme.blurs.normal});
              border-radius: 12px;
              cursor: pointer;

              @media (min-aspect-ratio: 8/7) and (max-width: 900px) {
                display: block;
              }
            `}
            onClick={() => {
              if (mobileFullscreen) {
                dispatch(closeMobileFullScreen());
              } else {
                dispatch(openMobileFullScreen());
              }
            }}
          >
            <Icon icon={mobileFullscreen ? 'times' : 'fullscreen'} color='#fff' size={35} />
          </div>

          {/* new fullscreen button */}
          {video?.type === VideoType.VIXY && pageTemplate === 'LIVE' && (
            <div
              css={css`
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
                display: block;
                margin: 5px;
                padding: 5px;
                background: rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(${theme.blurs.normal});
                border-radius: 12px;
                cursor: pointer;

                @media (min-aspect-ratio: 8/7) and (max-width: 900px) {
                  display: none;
                }
              `}
              onClick={() => {
                const element = document.getElementById('contentWrapper');
                if (element) {
                  if (elementFullScreen) {
                    // add support for other browsers
                    if (document.exitFullscreen) document.exitFullscreen();
                    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
                    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
                    else if (document.msExitFullscreen) document.msExitFullscreen();

                    dispatch(closeElementFullScreen());
                  } else {
                    if (element.requestFullscreen) element.requestFullscreen();
                    else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
                    else if (element.webkitRequestFullScreen) element.webkitRequestFullScreen();
                    else if (element.msRequestFullscreen) element.msRequestFullscreen();
                    dispatch(openElementFullScreen());
                  }
                }
              }}
            >
              <Icon icon={elementFullScreen ? 'times' : 'fullscreen'} color='#fff' size={35} />
            </div>
          )}

          {getVideoType()}
          {children}
        </div>
      </div>
    );
  },
);

export default Video;
