import { useMemo } from 'react';

import { UserRolePermission } from '@common/transforms/userRole';
import { useRoles, useUsers } from '@modules/user/hooks/useUsers';

export const useEventHost = () => {
  const { data: roles } = useRoles();
  const { data: rawUsers } = useUsers(
    '',
    [],
    roles
      ?.filter((role) => role.permissions.includes(UserRolePermission.HOST_CHAT))
      .map((role) => role.id) ?? [],
    false,
    false,
  );
  const eventHost = useMemo(() => rawUsers?.pages[0]?.items[0], [rawUsers]);

  return eventHost;
};
