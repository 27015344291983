import { useCallback } from 'react';

import { useEvent } from '@modules/event/hooks/useEvent';
import transform, { UserResource } from '@common/transforms/user';
import fetch from '@common/utils/fetch';
import { useAppDispatch } from '@common/store';

import {
  setUser,
  setError,
  setRequiredFieldsFilled,
  setAccepted,
  setPrivacyPolicyAccepted,
} from '../store/auth';
import { checkRequiredFieldsFilled } from '../utils';

import { getAccessToken } from './useAuth';

export function useUpdateUser() {
  const event = useEvent();
  const dispatch = useAppDispatch();

  const updateUser = useCallback(
    async (data: UserResource) => {
      if (!event) return;
      const accessToken = getAccessToken();

      const { id, ...restData } = data;

      try {
        const response = await fetch(`/users/${id}`, {
          token: accessToken,
          method: 'PUT',
          body: restData,
        });
        const userTransform = transform.one(response.body);

        dispatch(setUser(userTransform));
        dispatch(setRequiredFieldsFilled(checkRequiredFieldsFilled(userTransform, event)));
      } catch (err) {
        dispatch(setError(err));
      }
    },
    [dispatch, event],
  );

  const uploadAvatar = useCallback(
    async (file: File) => {
      if (!event) return;
      const accessToken = getAccessToken();

      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await fetch('/users/image', {
          token: accessToken,
          method: 'PUT',
          body: formData,
        });
        const userTransform = transform.one(response.body);

        dispatch(setUser(userTransform));
      } catch (err) {
        dispatch(setError((err as any).response?.body?.message ?? err));
      }
    },
    [dispatch, event],
  );

  const acceptPrivacyPolicy = useCallback(async () => {
    if (!event) return;
    const accessToken = getAccessToken();

    try {
      const response = await fetch('/users/accept-privacy-policy', {
        method: 'POST',
        token: accessToken,
      });
      const userTransform = transform.one(response.body);

      dispatch(setAccepted(!!userTransform.accepted));
      dispatch(setPrivacyPolicyAccepted(!!userTransform.privacyPolicyAccepted));
      dispatch(setRequiredFieldsFilled(checkRequiredFieldsFilled(userTransform, event)));
      dispatch(setUser(userTransform));
    } catch (err: any) {
      dispatch(
        setError(
          err?.cause?.response?.body?.message ||
            err?.response?.body?.message ||
            err?.response?.body?.error ||
            err?.message,
        ),
      );
    }
  }, [dispatch, event]);

  return { updateUser, uploadAvatar, acceptPrivacyPolicy };
}
