import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '@common/store';

export const useProtected = () => {
  const navigate = useNavigate();

  const { isAuthorized, isAccepted } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthorized || (isAuthorized && !isAccepted)) {
      navigate(`/landing`);
    }
  }, [isAuthorized, isAccepted, navigate]);
};

export default useProtected;
