import Container, { Orientation } from '@common/components/Container';

import { PageResource } from '@common/transforms/page';
import Video from '@common/components/Video';
import { VideoType } from '@common/transforms/video';

export function IFrameTemplate({ page }: { page: PageResource }) {
  return (
    <Container orientation={Orientation.VERTICAL} scrollable={false}>
      <Video
        video={{ type: VideoType.OTHER, embedUrl: page.parameters?.iFrameUrl, title: page.title }}
      />
    </Container>
  );
}
