import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import fetch from '@common/utils/fetch';
import transformToken from '@common/transforms/token';
import transform from '@common/transforms/user';
import { useAppDispatch } from '@common/store';
import { Beams } from '@common/hooks/useBeams';
import { useEvent } from '@modules/event/hooks/useEvent';

import { checkRequiredFieldsFilled } from '../utils';
import {
  setAuthorized,
  setAccepted,
  setPrivacyPolicyAccepted,
  setRequiredFieldsFilled,
  setUser,
  setError,
  setLoading,
} from '../store/auth';

import { setTokens } from './useAuth';

export function useLogin() {
  const event = useEvent();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useCallback(
    async (loginToken: string) => {
      if (!event) return;
      dispatch(setLoading(true));
      dispatch(setError(undefined));
      try {
        const response = await fetch(`/auth/${event.id}/login`, {
          method: 'POST',
          body: { loginToken },
        });

        const newTokens = transformToken(response.body);
        setTokens(newTokens);

        try {
          const userResponse = await fetch('/users/me', { token: newTokens.accessToken });
          const userTransform = transform.one(userResponse.body);

          dispatch(setAuthorized(true));
          dispatch(setAccepted(!!userTransform.accepted));
          dispatch(setPrivacyPolicyAccepted(!!userTransform.privacyPolicyAccepted));
          dispatch(setRequiredFieldsFilled(checkRequiredFieldsFilled(userTransform, event)));
          dispatch(setUser(userTransform));
          try {
            Beams().then(async (client) => {
              await client.setUserId(newTokens.accessToken, userTransform.id);
              await client.setEventId(event.id);
            });
            // eslint-disable-next-line no-empty
          } catch {}
          dispatch(setLoading(false));
          document.dispatchEvent(new Event('vle-login'));
          navigate('/landing');
        } catch (err: any) {
          dispatch(
            setError(
              err?.cause?.response?.body?.message ||
                err?.response?.body?.message ||
                err?.response?.body?.error ||
                err?.message,
            ),
          );
          dispatch(setLoading(false));
        }
      } catch (err: any) {
        dispatch(
          setError(
            err?.cause?.response?.body?.message ||
              err?.response?.body?.message ||
              err?.response?.body?.error ||
              err?.message,
          ),
        );
        dispatch(setLoading(false));
      }
    },
    [event, dispatch, navigate],
  );
}
