import debug from 'debug';

const log = debug('vle');

export default function createLogger(scope: string) {
  return {
    info: log.extend(`${scope}:info`),
    warning: log.extend(`${scope}:warning`),
    error: log.extend(`${scope}:error`),
  };
}
