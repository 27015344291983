import React, { ChangeEvent, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { SessionResource } from '@common/transforms/session';
import { StageResource } from '@common/transforms/stage';

import { useWindowWidth } from '@common/hooks/useWindowWidth';
import {
  getDayOverview,
  getStagesFromSessions,
  PIXELS_PER_MINUTE,
  SIZE_PER_COLUMN,
} from '@modules/schedule/utils';

import Select from '@common/components/Select';

import GridCard from './GridCard';

import TimeRow from './GridView/TimeRow';

type GridViewProps = {
  sessions: SessionResource[];
  selectedDate?: Date;
};

const GridView: React.FC<GridViewProps> = ({ sessions, selectedDate }) => {
  const windowWidth = useWindowWidth();
  const stages = getStagesFromSessions(sessions);
  const dayStats = getDayOverview(dayjs(selectedDate).toDate(), sessions);

  const [selectedStage, setSelectedStage] = useState<StageResource>(stages?.[0]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!stages.filter((stage) => stage.id === selectedStage.id).length)
      setSelectedStage(stages?.[0]);
  }, [sessions, stages, selectedStage, setSelectedStage]);

  if (!sessions?.length || !dayStats) return null;

  const renderShortVersion = SIZE_PER_COLUMN * stages.length > windowWidth;
  const sessionsToRender = !renderShortVersion
    ? sessions
    : sessions?.filter((session) =>
        selectedStage.id === 'internal'
          ? !session.stage?.id
          : session.stage?.id === selectedStage.id,
      );

  const onSelectedStageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedStage(stages.find((stage) => stage.id === event.target.value) as StageResource);
  };

  return (
    <Container totalMinutesOfDay={dayStats.duration}>
      {renderShortVersion && (
        <StageForm>
          <SelectLabel>{`${t('select_stage')}:`}</SelectLabel>
          <Formik initialValues={{ stageSelect: stages[0].id }} onSubmit={() => {}}>
            <Select
              name='stageSelect'
              options={stages.map((stage) => ({ value: stage.id, label: stage.name }))}
              onChange={onSelectedStageChange}
              value={selectedStage?.id ?? stages[0]?.id}
            />
          </Formik>
        </StageForm>
      )}
      <Table cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <TableSpace width='60px' />
            <TableSpace width={renderShortVersion ? 0 : '40px'} />
            {renderShortVersion ? (
              <TableSpace width='100%' />
            ) : (
              stages.map((stage) => <StageName key={stage.id}>{stage.name}</StageName>)
            )}
          </tr>
        </thead>
        <tbody>
          {[...new Array(Math.max(Math.ceil(dayStats.duration / 30), 0))].map((___, index) => {
            const date = new Date(
              new Date(dayStats?.startTime).setMinutes(
                30 * (index + (dayStats.beginsAtHalf ? 1 : 0)),
              ),
            );
            return <TimeRow time={date} key={date.toISOString()} stageLength={stages.length} />;
          })}
        </tbody>
      </Table>
      <Content renderShortVersion={renderShortVersion} totalMinutesOfDay={dayStats.duration}>
        {sessionsToRender.map((session) => (
          <GridCard
            key={session.id}
            stageCount={renderShortVersion ? 1 : stages.length}
            stageIndex={
              renderShortVersion
                ? 0
                : stages.findIndex((stage) =>
                    !session.stage?.id ? stage.id === 'internal' : stage.id === session.stage.id,
                  )
            }
            session={session}
            selectedDate={selectedDate}
            shortVersion={renderShortVersion}
            dayStats={dayStats}
          />
        ))}
      </Content>
    </Container>
  );
};

export default GridView;

const Container = styled.div<{ totalMinutesOfDay: number }>`
  width: 100%;
  height: ${(props) => PIXELS_PER_MINUTE * props.totalMinutesOfDay}px;
`;

const StageForm = styled.div`
  margin-bottom: 40px;
`;

const Table = styled.table`
  width: 100%;
  color: ${(props) => props.theme.colors.textLight};
  table-layout: fixed;
`;

const TableSpace = styled.td<{ width: number | string }>`
  width: ${(props) => props.width};
`;

const SelectLabel = styled.th<{ centered?: boolean }>`
  text-align: start;
  font-size: ${(props) => props.theme.fontSizes.m};
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  position: absolute;
  top: 1rem;
`;

const StageName = styled.th<{ centered?: boolean }>`
  text-align: start;
  padding-bottom: 20px;
  font-size: ${(props) => props.theme.fontSizes.m};
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  width: 100%;
`;

const Content = styled.div<{ renderShortVersion: boolean; totalMinutesOfDay: number }>`
  position: absolute;
  right: 0;
  left: ${(props) => (props.renderShortVersion ? 60 : 100)}px;
  top: ${(props) => (props.renderShortVersion ? 155 : 107)}px;
  height: ${(props) => PIXELS_PER_MINUTE * props.totalMinutesOfDay}px;
`;
