/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@common/components/Button';
import Heading from '@common/components/Heading';
import Icon from '@common/components/Icon';
import RoundButton from '@common/components/RoundButton';
import TagCloud from '@modules/event/components/TagCloud';
import { User } from '@common/transforms/user';
import { TagResource } from '@common/transforms/tag';
import { DIRECTION } from '@common/utils/consts';

type TagsFormProps = {
  user: Partial<User>;
  tags: TagResource[];
  onSubmit: (values: TagResource[]) => void;
  onSkip: () => void;
  onPrev: () => void;
};

const TagsForm = ({ user, tags, onSubmit, onSkip, onPrev }: TagsFormProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<TagResource[]>([]);

  useEffect(
    () =>
      setSelected((current) => {
        if (current.length === 0) {
          return user.tags || [];
        }

        return current;
      }),
    [user, setSelected],
  );

  const toggleTag = useCallback(
    (tag: TagResource) =>
      setSelected((current) => {
        if (current.find((filterTag) => filterTag.id === tag.id)) {
          return current.filter((filterTag) => filterTag.id !== tag.id);
        }

        return [...current, tag];
      }),
    [setSelected],
  );

  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      <Heading size='h1'>{t('own_tags_title')}</Heading>
      <TagCloud left tags={tags} selected={selected} onTagClick={toggleTag} />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex: 1;
          margin-top: 3.375em;
          margin-bottom: 3.375em;
        `}
      >
        <RoundButton onClick={onPrev}>
          <Icon icon='arrow' direction={DIRECTION.LEFT} size={20} color='#fff' />
        </RoundButton>
        <Button
          css={css`
            flex: 1;
            margin-left: 1em;
          `}
          label={t('next_button')}
          disableMinWidth
          onClick={() => onSubmit(selected)}
        />
        <Button
          css={css`
            flex: 1;
            margin-left: 1em;
          `}
          label={t('skip_button')}
          color='secondary'
          onClick={onSkip}
          plain
          disableMinWidth
        />
      </div>
    </div>
  );
};

export default TagsForm;
