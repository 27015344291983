import { useQuery, useMutation, useQueryClient } from 'react-query';

import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import transformRoom from '@common/transforms/room';
import transformMessage from '@common/transforms/message';
import { useAppSelector } from '@common/store';
import { getStaleTime } from '@common/utils/staleTime';
import fetch from '@common/utils/fetch';

export const useGlobalChat = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['chat', 'global'],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/chats`, { token: accessToken });

      return transformMessage.many(body);
    },
    {
      enabled: isAuthorized,
    },
  );
};

export const useSendGlobalChat = () => {
  const queryClient = useQueryClient();
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(
    (message: string) => {
      return fetch(`/events/${event?.id}/chats`, {
        method: 'POST',
        body: { message },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['chat', 'global']);
      },
      onError: () => {
        queryClient.invalidateQueries(['chat', 'global']);
      },
    },
  );
};

export const useChatRoom = (roomId?: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['chat', 'rooms', roomId],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/rooms/${roomId}`, { token: accessToken });

      return transformRoom.one(body);
    },
    {
      enabled: !!roomId && isAuthorized,
      ...getStaleTime(5),
    },
  );
};

export const useChatRooms = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['chat', 'rooms'],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/rooms`, { token: accessToken });

      return transformRoom.many(body);
    },
    {
      ...getStaleTime(0.3),
      enabled: isAuthorized,
    },
  );
};

export const useCreateChatRoom = () => {
  const queryClient = useQueryClient();
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(
    (participants: string[]) => {
      return fetch(`/events/${event?.id}/rooms`, {
        method: 'POST',
        body: { participants },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['chat', 'rooms']);
      },
    },
  );
};

export const useSendChat = (roomId?: string) => {
  const queryClient = useQueryClient();
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(
    (message: string) => {
      return fetch(`/events/${event?.id}/chats/${roomId}`, {
        method: 'POST',
        body: { message },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['chat', 'rooms', roomId]);
      },
    },
  );
};

export const useChatRoomMessages = (roomId?: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['chat', 'rooms', roomId, 'messages'],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/chats/${roomId}`, { token: accessToken });

      return transformMessage.many(body);
    },
    {
      enabled: !!roomId && isAuthorized,
    },
  );
};

export default {
  useGlobalChat,
  useSendGlobalChat,
  useSendChat,
  useChatRoom,
  useChatRooms,
  useCreateChatRoom,
  useChatRoomMessages,
};
