/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import useEvent from '@modules/event/hooks/useEvent';
import RegisterForm from '@modules/auth/components/RegisterForm';
import EventBackground from '@modules/event/components/EventBackground';
import CustomFieldsForm from '@modules/user/components/CustomFieldsForm';
import TagsForm from '@modules/user/components/TagsForm';
import AvatarForm from '@modules/user/components/AvatarForm';
import RegisterCompleted from '@modules/auth/components/RegisterCompleted';
import RegistrationCard from '@modules/auth/components/RegistrationCard';
import { useCustomFields } from '@modules/event/hooks/useCustomFields';
import { useTags } from '@modules/event/hooks/useTags';
import Body from '@common/components/Body';
import Alert from '@common/components/Alert';
import { mediaQuery } from '@common/styles/mediaQuery';
import Icon from '@common/components/Icon';
import { useUserExists } from '@modules/user/hooks/useUsers';
import { useAppSelector } from '@common/store';
import { User } from '@common/transforms/user';
import { useErrorMessage } from '@common/hooks/useErrorMessage';
import { useRegister } from '@modules/auth/hooks/useRegister';

export default function Register() {
  const [step, setStep] = useState(0);
  const [user, setUser] = useState<Partial<User>>({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { startRegistration, register } = useRegister();
  const { isRegistered, error } = useAppSelector((state) => state.auth);
  const { data: customFields } = useCustomFields();
  const { data: tags } = useTags();
  const checkEmail = useUserExists();
  const event = useEvent();
  const theme = useTheme();
  const [errorMessage] = useErrorMessage(error);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => startRegistration(), []);

  useEffect(() => {
    if (error?.response?.response?.status === 409) {
      setStep(0);
    }
  }, [error, setStep]);

  const nextStep = useCallback(() => setStep((current) => current + 1), [setStep]);
  const prevStep = useCallback(() => setStep((current) => current - 1), [setStep]);

  const doRegister = useCallback(
    (avatarFile?: File, privacyPolicyAccepted?: boolean) => {
      const { ...data } = user;
      delete data.avatarUrl;
      register(
        {
          ...data,
          privacyPolicyAccepted,
          tags: user.tags?.map((tag) => tag.id),
        },
        avatarFile,
      );
    },
    [user, register],
  );

  const renderCurrentStep = () => {
    if (step === 0) {
      return (
        <RegisterForm
          user={user}
          onSubmit={async (data) => {
            if (await checkEmail(data.email)) return false;
            setUser((current) => ({ ...current, ...data }));
            nextStep();
            return true;
          }}
        />
      );
    }

    const hasCustomFields = customFields && customFields.length > 0;

    if (step === 1 && hasCustomFields) {
      return (
        <CustomFieldsForm
          customFields={customFields!}
          user={user}
          onSubmit={(data) => {
            setUser((current) => ({
              ...current,
              fields: Object.keys(data).map((id) => ({
                // eslint-disable-next-line security/detect-object-injection
                value: data[id],
                customFieldId: id,
              })) as User['fields'],
            }));
            nextStep();
          }}
          onSkip={nextStep}
          onPrev={prevStep}
        />
      );
    }

    const hasTags = tags && tags.length > 0;

    if (hasTags && ((step === 2 && hasCustomFields) || (step === 1 && !hasCustomFields))) {
      return (
        <TagsForm
          user={user}
          tags={tags!}
          onSubmit={(data) => {
            setUser((current) => ({
              ...current,
              tags: data,
            }));
            nextStep();
          }}
          onSkip={nextStep}
          onPrev={prevStep}
        />
      );
    }

    return (
      <AvatarForm
        event={event}
        user={user}
        onSubmit={doRegister}
        onPrev={prevStep}
        onUploadAvatar={() => true}
        onChange={(url) => setUser({ ...user, avatarUrl: url })}
      />
    );
  };

  const renderLeftSide = () => {
    return (
      <>
        <div
          css={css`
            display: none;
            min-width: calc(400px + 2em);
            ${mediaQuery(
              's',
              css`
                display: block;
              `,
            )}
          `}
        />
        <div
          css={css`
            position: absolute;
            top: calc(50vh - 175px);
            display: none;
            margin-right: 2em;
            ${mediaQuery(
              's',
              css`
                display: block;
              `,
            )}
          `}
        >
          <RegistrationCard event={event} user={user} customFields={customFields} />
          {step === 0 && (
            <div
              css={css`
                display: flex;
                margin-top: 1.65625em;
                align-items: center;
              `}
            >
              {event?.loginEnabled && (
                <Body
                  css={css`
                    color: ${theme.colors.textLight};
                  `}
                >
                  <Trans
                    i18nKey='orlogintext'
                    values={{ login: t('login') }}
                    components={[
                      <a
                        key='link'
                        href={`/auth/${
                          process.env.REACT_APP_SAML_AUTH === 'true' ? 'saml' : 'login'
                        }`}
                        css={css`
                          color: ${theme.colors.primary} !important;
                        `}
                      >
                        login
                      </a>,
                    ]}
                  />
                </Body>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <EventBackground event={event} blur>
      <div
        css={css`
          position: relative;
          padding: 1em 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          margin-bottom: 1em;
          ${mediaQuery(
            's',
            css`
              display: none;
            `,
          )}
        `}
      >
        <div>{event?.name}</div>
        <div
          css={css`
            position: absolute;
            top: 1em;
            right: 1em;
            cursor: pointer;
            ${mediaQuery(
              's',
              css`
                top: 2em;
                right: 2em;
              `,
            )}
          `}
          onClick={() => navigate('/landing')}
        >
          <Icon icon='times' size={16} color={theme.colors.textLight} />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          width: 100%;
        `}
      >
        <div
          css={css`
            box-sizing: border-box;
            width: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {isRegistered ? (
            <RegisterCompleted />
          ) : (
            <div
              css={css`
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                ${mediaQuery(
                  's',
                  css`
                    max-width: 1140px;
                    margin: 0 15% 0 0;
                  `,
                )}
              `}
            >
              {renderLeftSide()}
              <div
                css={css`
                  width: 100%;

                  ${mediaQuery(
                    's',
                    css`
                      padding: 1em 0;
                      max-width: 400px;
                    `,
                  )}
                `}
              >
                {errorMessage && (
                  <div
                    css={css`
                      margin-bottom: 2em;
                    `}
                  >
                    <Alert>{errorMessage}</Alert>
                  </div>
                )}
                {renderCurrentStep()}
              </div>
            </div>
          )}
        </div>
      </div>
    </EventBackground>
  );
}
