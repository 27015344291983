/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Button from '@common/components/Button';
import Overlay from '@common/components/Overlay';
import Heading from '@common/components/Heading';
import Icon from '@common/components/Icon';
import SearchBar from '@common/components/SearchBar';
import { mediaQuery } from '@common/styles/mediaQuery';

import { useRoles, useUsers } from '@modules/user/hooks/useUsers';
import UserCard from '@modules/user/components/UserCard';
import Scrollable from '@common/components/Scrollable';

import { useAppSelector } from '@common/store';
import { UserResource, CallStatus } from '@common/transforms/user';
import { UserRolePermission } from '@common/transforms/userRole';

import { useAddToCall } from '../hooks/useAddToCall';

interface Props {
  openModal?: boolean;
  closeModal?: () => void;
  onCallUser?: (participantId: string) => void;
}

export const InviteOverlay = ({ openModal, closeModal, onCallUser }: Props) => {
  const [query, setQuery] = useState<string>();
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const call = useAppSelector((state) => state.videoCall.activeCall);

  const { data: roles } = useRoles();
  const { data: rawUsers } = useUsers(
    query,
    undefined,
    roles
      ?.filter((role) => role.permissions.includes(UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS))
      .map((role) => role.id) ?? [],
    false,
    false,
  );
  const { mutate: addToCall } = useAddToCall();

  const users = useMemo(() => {
    const paginatedUsers: UserResource[] = [];
    rawUsers?.pages?.map((userData) => {
      return paginatedUsers.push(...userData.items);
    });

    return paginatedUsers.filter(
      (user) => user.isOnline && user.callStatus === CallStatus.AVAILABLE,
    );
  }, [rawUsers]);

  const handleSelectedUsers = (userId: string) => {
    let selectedUserIds: string[] = [...selectedUsers];
    if (selectedUserIds.includes(userId)) {
      selectedUserIds.splice(selectedUserIds.indexOf(userId), 1);
    } else if (onCallUser) {
      selectedUserIds = [userId];
    } else {
      selectedUserIds.push(userId);
    }
    setSelectedUsers(selectedUserIds);
  };

  const handleSendInvites = () => {
    // eslint-disable-next-line array-callback-return
    selectedUsers.map((userId: string) => {
      if (onCallUser) {
        onCallUser(userId);
      } else {
        addToCall({ userId, videoCallId: call?.callId as string });
      }
    });
    setSelectedUsers([]);

    closeModal?.();
  };

  const renderButtonLabel = () => {
    if (onCallUser && selectedUsers.length !== 0) {
      return `${t('call')} ${users.find((user) => user.id === selectedUsers[0])?.firstName}  ${
        users.find((user) => user.id === selectedUsers[0])?.lastName
      }`;
    }
    if (onCallUser && selectedUsers.length === 0) {
      return t('select_attendee_to_call');
    }
    return t('invite_amount', { amount: selectedUsers.length });
  };

  return (
    <Overlay openModal={openModal} closeModal={closeModal}>
      <div
        css={css`
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: stretch;
          width: 100vw;
          min-height: 80vh;
          background-color: ${theme.colors.eventBackground};
          padding: ${theme.paddings.l};
          box-sizing: border-box;
          backdrop-filter: blur(${theme.blurs.normal});
          @supports not (
            (backdrop-filter: blur(${theme.blurs.normal})) or
              (-webkit-backdrop-filter: blur(${theme.blurs.normal}))
          ) {
            background-color: ${theme.colors.eventBackground};
            border-color: #feddc6;
            border-width: 3px;
            border-style: solid;
          }
          @media only screen and (max-width: 768px) {
            padding: 14px 24px 8px 24px;
          }
          ${mediaQuery(
            's',
            css`
              border-radius: ${theme.borderRadius.normal};
              width: 40vw;
              max-width: 512px;
              padding: ${theme.paddings.l};
              height: 70vh;
            `,
          )}
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            padding: 2em 0;
            position: relative;
            width: 100%;
            justify-content: space-between;
          `}
        >
          <Heading size='h3' color='textLight'>
            {t('invite_attendees_header')}
          </Heading>
          <div
            css={css`
              right: 0em;
              cursor: pointer;
            `}
            onClick={() => {
              closeModal?.();
            }}
          >
            <Icon icon='times' size={24} color={theme.colors.textLight} />
          </div>
        </div>

        <SearchBar
          onSearch={(searchQuery) => {
            setQuery(searchQuery);
          }}
          placeholder={t('attendees_searchplaceholder')}
        />

        <Scrollable
          css={css`
            flex: 1;
            margin-top: 1em;
          `}
        >
          {users?.map((user) => (
            <React.Fragment key={user.id}>
              <div
                css={css`
                  margin-top: 0.75em;
                  cursor: pointer;

                  flex: 0 0 29%;
                  width: 100%;
                  ${false &&
                  mediaQuery(
                    'm',
                    css`
                      width: auto;
                      margin-top: 0;
                    `,
                  )}
                `}
              >
                <UserCard
                  css={css`
                    height: 100%;
                    box-sizing: border-box;
                  `}
                  user={user}
                  invite
                  selected={selectedUsers.includes(user.id)}
                  inviteClick={() => handleSelectedUsers(user.id)}
                />
              </div>
            </React.Fragment>
          ))}
        </Scrollable>
        <Button
          color='primary'
          size='l'
          onClick={() => handleSendInvites()}
          css={css`
            margin: 1em 0;
          `}
          disabled={selectedUsers.length === 0}
          label={renderButtonLabel()}
        />
      </div>
    </Overlay>
  );
};
