import { createContext, useContext } from 'react';

import theme from '@common/styles/theme';

export enum Platform {
  DESKTOP,
  MOBILE,
}

const PlatformContext = createContext(0);
export const PlatformContextProvider = PlatformContext.Provider;

export function usePlatform(): Platform {
  const width = useContext(PlatformContext);
  return width > theme.deviceWidth.l ? Platform.DESKTOP : Platform.MOBILE;
}
