/** @jsxImportSource @emotion/react */
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';

import { css } from '@emotion/react';

import { SpeakerResource } from '@common/transforms/speaker';
import { UserRolePermission } from '@common/transforms/userRole';
import { useAppDispatch, useAppSelector } from '@common/store';
import { useStartPrivateCall } from '@modules/video-call/hooks/useStartPrivateCall';
import { setOutgoingCall } from '@modules/video-call/store/videoCall';
import { useStartChat } from '@modules/chat/hooks/useStartChat';
import UserCard from '@modules/user/components/UserCard';
import BioModal from '@modules/schedule/components/BioModal';

interface SpeakerProps {
  event: any;
  speaker: SpeakerResource;
  onClose: () => void;
}

const Speaker: React.FC<SpeakerProps> = ({ event, speaker, onClose }) => {
  const dispatch = useAppDispatch();
  const startChat = useStartChat();
  const user = useAppSelector((state) => state.auth.user);

  const [bioPopup, setBioPopup] = useState(false);

  const { mutateAsync: startPrivateCall } = useStartPrivateCall();

  const call = useCallback(
    async (userId) => {
      const participant = await startPrivateCall(userId);
      dispatch(setOutgoingCall({ callId: participant.videoCallId, participantId: participant.id }));
    },
    [startPrivateCall, dispatch],
  );

  const handleLinkedinClick = () => {
    if (speaker?.user?.linkedIn) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(speaker.user.linkedIn, '_blank');
    }
  };

  const handlePhoneClick = () => {
    call(speaker?.user?.id);
    onClose();
  };

  const onOpenChat = () => {
    const userId = speaker.user?.id;
    if (userId) {
      startChat(userId);
      onClose();
    }
  };

  return (
    <>
      <UserCard
        css={css`
          margin-top: 20px;
        `}
        speaker={speaker}
        onOpenChat={
          !!speaker?.user?.id?.length &&
          event?.privateChatEnabled &&
          speaker.chatEnabled &&
          user?.role?.permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT)
            ? onOpenChat
            : undefined
        }
        onCallUser={
          event?.videoCallEnabled &&
          !!speaker.user &&
          speaker.videoCallEnabled &&
          user?.role?.permissions.includes(UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS)
            ? handlePhoneClick
            : undefined
        }
        onOpenLinkedIn={speaker?.user?.linkedIn ? handleLinkedinClick : undefined}
        onClick={() => {
          setBioPopup(true);
        }}
      />
      {ReactDOM.createPortal(
        bioPopup ? (
          <BioModal
            speaker={speaker}
            onClose={() => setBioPopup(false)}
            onOpenChat={
              !!speaker?.user?.id?.length &&
              event?.privateChatEnabled &&
              speaker.chatEnabled &&
              user?.role?.permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT)
                ? onOpenChat
                : undefined
            }
            onCallUser={
              event?.videoCallEnabled &&
              !!speaker.user &&
              speaker.videoCallEnabled &&
              user?.role?.permissions.includes(UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS)
                ? handlePhoneClick
                : undefined
            }
            onOpenLinkedIn={handleLinkedinClick}
          />
        ) : null,
        document.querySelector('#popupHolder > div') ?? document.body,
      )}
    </>
  );
};

export default Speaker;
