/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, css } from '@emotion/react';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';

import Avatar from '@modules/user/components/Avatar';
import { UserResource } from '@common/transforms/user';
import Icon from '@common/components/Icon';
import Card from '@common/components/Card';
import Body from '@common/components/Body';
import useEvent from '@modules/event/hooks/useEvent';
import ButtonWithHoverLabel from '@common/components/ButtonWithHoverLabel';
import { renderLocationIcon } from '@modules/event/utils';

interface Props {
  user?: UserResource;
  title?: string;
  subtitle?: string;
  small?: boolean;
  positiveIcon?: string;
  negativeIcon?: string;
  addUser?: () => void;
  fullscreen?: boolean;
  setFullScreen?: () => void;
  accept?: () => void;
  denied?: boolean;
  endOutgoingCallAfterDenied?: () => void;
  onEnd?: () => void;
}

export const CallCard = ({
  user,
  title,
  subtitle,
  small = false,
  positiveIcon = 'ic_phone',
  negativeIcon = 'ic_declinecall',
  addUser,
  fullscreen,
  setFullScreen,
  accept,
  denied,
  endOutgoingCallAfterDenied,
  onEnd,
}: Props) => {
  const theme = useTheme();
  const event = useEvent();
  const { t } = useTranslation();

  const onEndCall = () => {
    if (!denied) {
      onEnd?.();
    } else if (endOutgoingCallAfterDenied) {
      endOutgoingCallAfterDenied();
    }
  };
  return (
    <Card
      css={css`
        flex-direction: row;
        padding: ${small ? '0.5em' : '1em'};
        align-items: center;
        background: #000;
      `}
    >
      {!small && user && (
        <Transition
          in={!denied}
          unmountOnExit
          mountOnEnter
          addEndListener={(node, done) => {
            if (denied) {
              gsap.to(node, { opacity: 0, duration: 0.4, ease: 'power4.inOut', onComplete: done });
            }
          }}
        >
          <Avatar user={user} />
        </Transition>
      )}

      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: ${small || !user ? '0.5em' : '1.5em'};
          margin-right: 1.5em;
          position: relative;
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          {event?.attendanceEnabled && user ? (
            <ButtonWithHoverLabel labelText={user.attendance}>
              <Icon icon={renderLocationIcon(user.attendance)} />
            </ButtonWithHoverLabel>
          ) : null}

          {title && (
            <Body
              css={css`
                margin-left: ${event?.attendanceEnabled ? '0.5em' : '0'};
              `}
              weight='bold'
              color='eventBackground'
              size='m'
            >
              {!denied && title}
            </Body>
          )}
        </div>

        {!denied ? (
          subtitle && (
            <Body size='m' color='eventBackground'>
              {subtitle}
            </Body>
          )
        ) : (
          <Body size='m' color='eventBackground'>
            {t('call_denied')}
          </Body>
        )}
      </div>
      {accept && (
        <div
          css={css`
            background: ${theme.colors.success};
            width: 48px;
            height: 48px;
            cursor: pointer;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              filter: brightness(90%);
            }
          `}
          onClick={accept}
        >
          <Icon icon={positiveIcon} size={24} />
        </div>
      )}
      {addUser && (
        <div
          css={css`
            background: ${theme.colors.alerts.butttonDark};
            width: 48px;
            height: 48px;
            cursor: pointer;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: ${accept ? '0.5em' : '0'};
            &:hover {
              filter: brightness(90%);
            }
          `}
          onClick={addUser}
        >
          <Icon icon='plus' size={24} />
        </div>
      )}
      {setFullScreen && (
        <div
          css={css`
            background: ${theme.colors.alerts.butttonDark};
            width: 48px;
            height: 48px;
            cursor: pointer;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: ${accept || addUser ? '0.5em' : '0'};
            &:hover {
              filter: brightness(90%);
            }
          `}
          onClick={setFullScreen}
        >
          <Icon icon={fullscreen ? 'minimize' : 'fullscreen'} size={24} />
        </div>
      )}
      <div
        css={css`
          background: ${theme.colors.error};
          width: 48px;
          height: 48px;
          cursor: pointer;
          margin-left: ${accept || addUser || setFullScreen ? '0.5em' : '0'};
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            filter: brightness(90%);
          }
        `}
        onClick={() => onEndCall()}
      >
        <Icon icon={negativeIcon} size={24} />
      </div>
    </Card>
  );
};
