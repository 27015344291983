export enum ALERT_TYPES {
  TOAST = 'TOAST',
  REMINDER = 'REMINDER',
  CALL = 'CALL',
  DO_NOT_DISTURB = 'DO_NOT_DISTURB',
}

interface Alert {
  type: ALERT_TYPES;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any;
  pinned?: boolean;
  decay?: boolean;
  decayTime?: number;
}

export interface ConvertedAlert {
  type?: string;
  props?: any;
  pinned?: boolean;
  decay?: boolean;
  decayTime?: number;
}

function dispatchAlertEvent(name: string, alert: Alert) {
  const notificatonEvent = new CustomEvent(name, {
    detail: {
      type: alert.type,
      props: alert.props,
      pinned: alert.pinned ?? false,
      decay: alert.decay ?? true,
      decayTime: alert.decayTime ?? 5000,
    } as ConvertedAlert,
  });
  document.dispatchEvent(notificatonEvent);
}

export const createAlert = (alert: Alert) => {
  dispatchAlertEvent('add-notification', alert);
};

export const updateAlert = (alert: Alert) => {
  dispatchAlertEvent('update-notification', alert);
};

export default {
  createAlert,
  updateAlert,
};
