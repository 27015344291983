/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Icon as IconType } from '@typing/icons';

import Icon from '@common/components/Icon';

interface IconHoverProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: string;
  hoverIcon?: string;
  size?: number;
  onClick?: () => void;
  round?: boolean;
  iconProps?: Partial<IconType>;
  hoverIconProps?: Partial<IconType>;
  type?: 'default' | 'defaultDark' | 'transparent' | 'transparentDark' | 'schedule' | 'scheduled';
  disabled?: boolean;
}

const IconHover: React.FC<IconHoverProps> = ({ icon, iconProps, size, onClick, ...rest }) => {
  const nativeOnClick = (event: any) => {
    onClick?.();
    event.stopPropagation();
  };

  const nativeOnMouseEnter = (ev: any) => {
    rest?.onMouseEnter?.(ev);
    ev.stopPropagation();
  };

  const nativeOnMouseLeave = (ev: any) => {
    rest?.onMouseLeave?.(ev);
    ev.stopPropagation();
  };

  return (
    <div
      css={css`
        width: ${size}px;
        height: ${size}px;
        max-width: ${size}px;
        max-height: ${size}px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      `}
      {...rest}
      onClick={(e: any) => nativeOnClick(e)}
      onMouseLeave={nativeOnMouseLeave}
      onMouseEnter={nativeOnMouseEnter}
    >
      <IconWrapper>
        <Icon icon={icon} size={size} {...iconProps} />
      </IconWrapper>
    </div>
  );
};

export default IconHover;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
