import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import useEvent from '@modules/event/hooks/useEvent';
import { usePages } from '@modules/event/hooks/usePages';
import { useAppSelector } from '@common/store';

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthorized, isAccepted, privacyPolicyAccepted, hasRequiredFieldsFilled } =
    useAppSelector((state) => state.auth);
  const event = useEvent();
  const { data: pages } = usePages();

  useEffect(() => {
    if (isAuthorized && isAccepted && privacyPolicyAccepted && hasRequiredFieldsFilled && pages) {
      if (event?.initialPageSlug) {
        navigate({ pathname: `/pages/${event.initialPageSlug}`, search: location.search });
      } else {
        const firstSlug = pages.find((page) => page.slug)?.slug;
        if (firstSlug) navigate({ pathname: `/pages/${firstSlug}`, search: location.search });
      }
    }
  }, [
    isAuthorized,
    isAccepted,
    privacyPolicyAccepted,
    hasRequiredFieldsFilled,
    pages,
    event,
    navigate,
    location.search,
  ]);

  if (isAuthorized && !isAccepted && privacyPolicyAccepted) return <p>Not yet accepted</p>;

  if (!isAuthorized) {
    if (process.env.REACT_APP_SAML_AUTH === 'true') {
      navigate({ pathname: '/auth/saml' });
      return null;
    }
  }
  navigate({ pathname: '/landing' });
  return null;
}
