import { Icon } from '@typing/icons';

const Grid: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='2' y='3' width='10' height='18' rx='1.5' fill={color} strokeWidth={0} />
      <rect x='13' y='3' width='9' height='6' rx='1.5' fill={color} strokeWidth={0} />
      <rect x='13' y='10' width='9' height='11' rx='1.5' fill={color} strokeWidth={0} />
    </svg>
  );
};

export default Grid;
