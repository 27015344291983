import { useCallback } from 'react';

import { useAppDispatch } from '@common/store';
import { ChatTabTypes, setSelectedTab, setSelectedRoom } from '@modules/chat/store/chat';

export function useCloseChat() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(setSelectedRoom(undefined));
    dispatch(setSelectedTab(ChatTabTypes.ROOMS));
  }, [dispatch]);
}
