import { useMutation } from 'react-query';

import { getAccessToken } from '@modules/auth/hooks/useAuth';
import useEvent from '@modules/event/hooks/useEvent';
import fetch from '@common/utils/fetch';
import { queryClient } from '@common/components/QueryProvider';

export const useRejectCall = () => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(
    (participantId: string) => {
      return fetch(`/events/${event?.id}/video-calls/reject`, {
        method: 'POST',
        body: { participantId },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCalls');
      },
    },
  );
};
