/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface MenuState {
  showMenu: boolean;
}

const initialState: MenuState = {
  showMenu: false,
};

export const MenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.showMenu = true;
    },
    closeMenu: (state) => {
      state.showMenu = false;
    },
  },
});

export const { openMenu, closeMenu } = MenuSlice.actions;
export default MenuSlice.reducer;
