/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Container, { Orientation } from '@common/components/Container';
import { PageResource } from '@common/transforms/page';
import Button from '@common/components/Button';
import Body from '@common/components/Body';
import { closeElementFullScreen, closeMobileFullScreen } from '@common/store/fullscreen';
import { useAppDispatch, useAppSelector } from '@common/store';
import Video, { videoDefaultProps } from '@common/components/Video';
import { useStartVideoProgress, useStopVideoProgress } from '@modules/event/hooks/useVideos';
import { Platform, usePlatform } from '@common/hooks/usePlatform';

interface LivePageProps {
  page: PageResource;
  count: number;
  clap: () => void;
}

export function LiveTemplate({ page, count, clap }: LivePageProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const clappingHandsRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<HTMLImageElement>(null);
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);
  const platform = usePlatform();

  const startVideoProgress = useStartVideoProgress(undefined, page?.id);
  const stopVideoProgress = useStopVideoProgress(undefined, page?.id);

  const [, setProgressId] = useState<string | null>(null);
  const [videoProps, setVideoProps] = useState<videoDefaultProps>({
    ...page?.parameters,
    type: page?.parameters?.videoType,
  });

  useEffect(() => {
    function exitHandler() {
      if (
        elementFullScreen &&
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        if (document.exitFullscreen) document.exitFullscreen();
        else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
        else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
        else if (document.msExitFullscreen) document.msExitFullscreen();
        dispatch(closeElementFullScreen());
      }
    }

    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);
    return () => {};
  }, [dispatch, elementFullScreen]);

  useEffect(() => {
    setVideoProps({
      ...page?.parameters,
      type: page?.parameters?.videoType,
    });
    function handelResize() {
      if (window.matchMedia('(orientation:portrait)').matches) {
        dispatch(closeMobileFullScreen());
      }
    }
    window.addEventListener('resize', handelResize);
    return () => {
      dispatch(closeMobileFullScreen());
      window.removeEventListener('resize', handelResize);
    };
  }, [dispatch, page]);

  const clapAnimation = () => {
    if (!clappingHandsRef.current) return;
    if (!videoRef.current) return;

    const rect = clappingHandsRef.current.getBoundingClientRect();
    const hand = clappingHandsRef.current.cloneNode() as HTMLImageElement;
    const xOffset = Math.floor(Math.random() * 101) - 50;

    hand.style.position = 'absolute';
    hand.style.left = `${rect.left}px`;
    hand.style.top = `${platform === Platform.MOBILE ? rect.top - 110 : rect.top - 90}px`;
    hand.style.pointerEvents = 'none';
    hand.style.opacity = '1';
    hand.style.cssText += `
      will-change: transform, opacity;
      transform: translate3d(0px, ${
        // eslint-disable-next-line no-nested-ternary
        window.innerWidth < 768 ? 40 : window.innerWidth < 900 ? 20 : 0
      }px, 0px);
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    `;

    const container = document.querySelector('#popupHolder') ?? document.body;
    container.appendChild(hand);

    setTimeout(() => {
      hand.style.opacity = '0';
      hand.style.cssText += `transform: translate3d(${xOffset}px, ${
        // eslint-disable-next-line no-nested-ternary
        window.innerWidth < 768 ? -20 : window.innerWidth < 900 ? -50 : -100
      }px, 100000px)`;
      setTimeout(() => {
        hand.remove();
      }, 1000); // make sure this is the same as the transition time set above but in ms
    }, 1000 / 60);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this when count changes
  };

  const startedPlaying = useCallback(async () => {
    const { id } = await startVideoProgress();
    setProgressId(id);
  }, [startVideoProgress]);

  const stoppedPlaying = useCallback(() => {
    // somehow the result of `setProgressId` above is not being updated here, so we use the callback
    setProgressId((prev) => {
      if (prev) stopVideoProgress(prev);
      return null;
    });
  }, [stopVideoProgress]);

  const virtualAudienceDescription = (
    <>
      <Body
        color='textLight'
        css={css`
          padding-bottom: 12pt;
          font-size: ${page.parameters?.embedCode ? '14pt' : '20pt'};
          max-width: 70%;
          padding-bottom: 2em;
        `}
      >
        {page.parameters?.virtualAudienceDescription}
      </Body>
    </>
  );

  const virtualAudienceButton = (
    <a
      style={{ textDecoration: 'none' }}
      target='_blank'
      rel='noreferrer'
      href={page.parameters?.virtualAudienceURL}
    >
      <Button
        label={page.parameters?.virtualAudienceButton ?? 'Open website'}
        css={css`
          margin-left: 8pt;
        `}
      />
    </a>
  );

  const virtualAudienceLink = () => {
    if (!page.parameters?.virtualAudienceURL) {
      return null;
    }

    return (
      <div
        css={css`
          position: relative;
          padding-top: 8pt;
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
            @media (max-width: 768px) {
              margin-bottom: 70px;
            }
          `}
        >
          {virtualAudienceDescription}
          {virtualAudienceButton}
        </div>
      </div>
    );
  };

  return (
    <Container orientation={Orientation.HORIZONTAL} scrollable={false}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;

          @media (min-width: 900px) {
            ${mobileFullscreen ? '' : 'padding-right: 2em;'}
            ${elementFullScreen && 'padding-right: 0'}
          }
        `}
      >
        <Video
          ref={videoRef}
          video={videoProps}
          pageTemplate={page.template}
          startedPlaying={startedPlaying}
          stoppedPlaying={stoppedPlaying}
        >
          {page.clapHandsEnabled && (
            <div
              css={css`
                position: absolute;
                left: 20px;
                bottom: 60px;
                z-index: 1;
                display: flex;
                align-items: center;
              `}
            >
              <div
                onClick={() => {
                  clapAnimation();
                  clap();
                }}
                css={css`
                  width: 100px;
                  height: 100px;
                  border-radius: 50%;
                  border: 1px solid white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  user-select: none;
                  background: rgb(255 255 255 / 5%);

                  @media screen and (max-width: 900px) {
                    width: 60px;
                    height: 60px;
                  }

                  @media screen and (max-width: 768px) {
                    width: 50px;
                    height: 50px;
                  }
                `}
              >
                <img
                  ref={clappingHandsRef}
                  alt='clapping hands'
                  css={css`
                    width: 50px;

                    @media screen and (max-width: 900px) {
                      width: 40px;
                    }

                    @media screen and (max-width: 768px) {
                      width: 30px;
                    }
                  `}
                  src='/img/clapping_hands.png'
                />
              </div>
              <div
                css={css`
                  margin-left: 20px;
                  font-size: 16px;
                  text-transform: uppercase;

                  @media screen and (max-width: 900px) {
                    font-size: 14px;
                  }
                `}
              >
                {count}
                <br />
                {t('claps_so_far')}
              </div>
            </div>
          )}
        </Video>
        {process.env.REACT_APP_ASML_SERVER !== 'true' ? (
          <div
            css={css`
              @media (min-aspect-ratio: 8/7) and (max-width: 900px) {
                display: none;
              }
            `}
          >
            {virtualAudienceLink()}
          </div>
        ) : null}
      </div>
    </Container>
  );
}
