/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { VideoCallResource } from '@common/transforms/videoCall';

import { CallCard } from './CallCard';

interface Props {
  videoCall?: VideoCallResource;
  onEnd?: () => void;
  accept: () => void;
}

export const IncomingCallOverlay = ({ videoCall, accept, onEnd }: Props) => {
  const { t } = useTranslation();
  const cardRef = useRef<HTMLDivElement | null>(null);

  const caller = useMemo(
    () => videoCall?.participants?.find((participant) => participant.initiator),
    [videoCall],
  );

  return (
    <div ref={cardRef}>
      <audio src='/audio/incoming.mp3' autoPlay loop />
      <CallCard
        user={caller?.user}
        title={caller?.user?.fullName}
        subtitle={t('incoming_call_text')}
        accept={accept}
        onEnd={onEnd}
      />
    </div>
  );
};
