/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ReactNode } from 'react';

import Body from './Body';

interface Props {
  readonly className?: string;
  readonly children: ReactNode;
}

export const TimeIndicator = ({ className, children }: Props) => (
  <Body
    className={className}
    css={css`
      background-color: rgba(0, 0, 0, 0.3);
      padding: 0.5em;
      border-radius: 0.5em;
    `}
    color='textLight'
  >
    {children}
  </Body>
);
