/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';

import { Global, ThemeProvider, css } from '@emotion/react';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';

import eventTheme from '@common/styles/eventTheme';
import '@assets/fonts/fontello/css/fontello.css';
import '@assets/fonts/sofia-pro/sofia-pro.css';
import '@assets/canvas.css';

import { useEvents } from '@modules/event/hooks/useEvent';

import ErrorPage from '@pages/_error';

import '@common/utils/i18n';
import '@common/utils/timeago';
import { isClient } from '@config';
import { PlatformContextProvider } from '@common/hooks/usePlatform';
import useLanguage from '@common/hooks/useLanguage';
import { mediaQuery } from '@common/styles/mediaQuery';
import { ConvertedAlert } from '@common/utils/alerts';

import AuthScheduleWrapper from '@common/components/AuthScheduleWrapper';
import GlobalTheme from '@common/components/GlobalTheme';
import CookieConsent from '@common/components/CookieConsent';
import NotificationBase from '@common/components/alerts/NotificationBase';
import Error500 from '@pages/500';
import Onboarding from '@pages/onboarding';
import User from '@pages/users/[userId]';
import Login from '@pages/auth/login';
import LoginToken from '@pages/auth/login/[loginToken]';
import Profile from '@pages/auth/profile';
import Register from '@pages/auth/register';
import Saml from '@pages/auth/saml';
import VideoPage from '@pages/pages/[slug]/[videoId]';
import Page from '@pages/pages/[slug]';
import Pages from '@pages/pages';
import Landing from '@pages/landing';
import Home from '@pages/index';
import RedirectPage from '@pages/redirect/page';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

export default function App() {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState((isClient && window.innerWidth) || 0);
  const { language } = useLanguage();
  const [notifications, setNotifications] = useState<ConvertedAlert[]>([]);

  const { event, error } = useEvents();

  const notificationListener = (evnt: any) => {
    setNotifications((previous) => [...previous, evnt.detail]);
  };

  const notificationUpdateListener = (evnt: any) => {
    const session = evnt.detail?.props?.session;
    if (!session?.id) return;

    setNotifications((previous) => {
      const exists = previous.some((alert) => alert.props?.session?.id === session.id);
      return exists
        ? [
            ...previous.filter((alert: ConvertedAlert) => alert.props?.session?.id !== session.id),
            evnt.detail,
          ]
        : previous;
    });
  };

  useEffect(() => {
    document.addEventListener('add-notification', notificationListener);
    document.addEventListener('update-notification', notificationUpdateListener);
    return () => {
      document.removeEventListener('add-notification', notificationListener);
      document.removeEventListener('update-notification', notificationUpdateListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {}, [language]);

  useEffect(() => {
    if (event?.googleTrackingId) {
      ReactGA.initialize(event.googleTrackingId);
    }
  }, [event?.googleTrackingId]);

  if (event?.googleTrackingId && typeof window) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  useEffect(() => {
    const listener = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [setWindowWidth]);

  useEffect(() => {
    if (event) {
      const base = document.location.origin;
      const manifest = {
        name: event.name,
        description: event.description,
        theme_color: event.themeSecondaryColor,
        background_color: event.themeBackgroundColor,
        display: 'standalone',
        start_url: `${base}/`,
        short_name: event.name,
        icons: [
          {
            src: `${base}/VIR_Icon_Only_192x192.png`,
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: `${base}/VIR_Icon_Only_256x256.png`,
            sizes: '256x256',
            type: 'image/png',
          },
          {
            src: `${base}/VIR_Icon_Only_384x384.png`,
            sizes: '384x384',
            type: 'image/png',
          },
          {
            src: `${base}/VIR_Icon_Only_512x512.png`,
            sizes: '512x512',
            type: 'image/png',
          },
        ],
        url_handlers: [
          {
            origin: base,
          },
        ],
      };

      const content = encodeURIComponent(JSON.stringify(manifest));
      const url = `data:application/manifest+json,${content}`;
      document.querySelector('#manifest')!.setAttribute('href', url);
    }
  }, [event]);

  if (error) {
    return <ErrorPage statusCode={error?.status || 404} err={error} />;
  }

  const key = location.pathname;

  return (
    <>
      <Global
        styles={css`
          html,
          body,
          #__next {
            height: 100%;
          }
        `}
      />
      <ThemeProvider theme={eventTheme(event)}>
        <GlobalTheme />
        {process.env.REACT_APP_ASML_SERVER !== 'true' && <CookieConsent />}
        <AuthScheduleWrapper />
        <Helmet>
          <title>{event?.name}</title>
          <link rel='icon' type='image/png' href={event?.themeFaviconUrl} />
        </Helmet>
        <PlatformContextProvider value={windowWidth}>
          <>
            <Routes>
              {/* Global routes */}
              <Route key={key} path='/' element={<Home />} />
              <Route key={key} path='/500' element={<Error500 />} />
              <Route key={key} path='/landing' element={<Landing />} />
              <Route key={key} path='/onboarding' element={<Onboarding />} />

              <Route key={key} path='/users/:userId' element={<User />} />

              <Route key={key} path='/redirect' element={<RedirectPage />} />

              <Route key={key} path='/auth/profile' element={<Profile />} />
              <Route key={key} path='/auth/register' element={<Register />} />
              <Route key={key} path='/auth/saml' element={<Saml />} />
              <Route key={key} path='/auth/login' element={<Login />} />
              <Route key={key} path='/auth/login/:loginToken' element={<LoginToken />} />

              <Route key={key} path='/pages' element={<Pages />} />
              <Route key={key} path='/pages/:slug' element={<Page />} />
              <Route key={key} path='/pages/:slug/:videoId' element={<VideoPage />} />
            </Routes>
          </>
          <div
            css={css`
              position: fixed;
              top: 65px;
              right: 15px;
              left: 15px;
              display: flex;
              flex-direction: column-reverse;
              justify-content: flex-end;
              z-index: 99999;

              ${mediaQuery(
                's',
                css`
                  left: auto;
                  top: auto;
                  bottom: 20px;
                  width: 100%;
                  flex-direction: column;
                  max-width: 550px;
                `,
              )}
            `}
          >
            {notifications
              ?.filter((notification) => !notification.pinned)
              ?.map?.((notification, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <NotificationBase key={index} {...notification} />
              ))}
            {notifications
              ?.filter((notification) => notification.pinned)
              ?.map?.((notification, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <NotificationBase key={index} {...notification} />
              ))}
          </div>
        </PlatformContextProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}
