import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import { LocationResource } from '@common/transforms/location';

import fetch from '@common/utils/fetch';

export const useLatestLocation = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const [location, setLocation] = useState<LocationResource>();

  useEffect(() => {
    (async () => {
      try {
        const { body } = await fetch(`/events/${event?.id}/location/latest`, {
          token: accessToken,
        });

        setLocation(typeof body?.boothId === 'string' ? body.boothId : undefined);
      } catch (error) {
        setLocation(undefined);
      }
    })();
  }, [accessToken, event?.id]);

  return location;
};

export const useUpdateLocation = () => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(([id, isVideo = false]: [string] | [string, boolean]) => {
    return fetch(`/events/${event?.id}/location/page`, {
      method: 'POST',
      token: accessToken,
      body: { pageId: id, isVideo },
    });
  });
};

export default { useLatestLocation, useUpdateLocation };
