import { useState } from 'react';

import { InfoIcon } from '../svg/info';

type InfoButtonProps = {
  description?: React.ReactNode;
  onClick?: () => void;
  inverse?: boolean;
};

export function InfoButton({ description, onClick, inverse }: InfoButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={`info-button-container ${inverse ? 'inverse' : ''}`}>
      <div
        className='info-button'
        onMouseEnter={() => {
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
        onClick={onClick}
      >
        {InfoIcon}
      </div>
      {!!description && (
        <div className={`info-button-description ${isOpen ? 'open' : ''}`}>{description}</div>
      )}
    </div>
  );
}
