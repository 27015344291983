import { useCallback } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQuery } from 'react-query';

import useEvent, { useEventId } from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import fetch from '@common/utils/fetch';
import transform from '@common/transforms/user';
import transformUserRole from '@common/transforms/userRole';
import { useAppSelector } from '@common/store';
import { languageUrl } from '@common/utils/language';
import { getStaleTime } from '@common/utils/staleTime';

export const useUser = (userId?: string, getSessions = false) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['user', event?.id, userId],
    async () => {
      const { body } = await fetch(
        `/events/${event?.id}/users/${userId}?lang=${i18n.language}&getSessions=${getSessions}`,
        {
          token: accessToken,
        },
      );

      return transform.one(body);
    },
    {
      enabled: !!userId && isAuthorized,
      staleTime: 5000,
    },
  );
};

export const useUsers = (
  search?: string,
  tags?: string[],
  roles?: string[],
  getFields?: boolean,
  getTags?: boolean,
  limit = 50,
  filterField?: string,
  filterFieldSearch?: string,
) => {
  const event = useEvent();
  const currentUser = useAppSelector((state) => state.auth.user);
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();

  return useInfiniteQuery(
    [
      'users',
      event?.id,
      search,
      tags?.join(','),
      roles?.join(','),
      getFields,
      getTags,
      limit,
      filterField,
      filterFieldSearch,
    ].filter(Boolean),
    async ({ pageParam = 1 }) => {
      const query = queryString.stringify({
        search: search || undefined,
        tags: tags?.join(',') || undefined,
        roles: roles?.join(',') || undefined,
        filterField: filterField || undefined,
        page: pageParam,
        limit: limit.toString(),
        getFields,
        getTags,
        filterFieldSearch,
      });
      const { body } = await fetch(`/events/${event?.id}/users?${query}&lang=${i18n.language}`, {
        token: accessToken,
      });
      const userData = transform.many(body);

      return {
        items: userData?.items.filter((user) => currentUser && user.id !== currentUser.id),
        meta: userData.meta,
      };
    },
    {
      ...getStaleTime(0.1),
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) return undefined;
        return lastPage.meta.currentPage + 1;
      },
    },
  );
};

export const useUserRoleCount = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    'usersRoleCount',
    async () => {
      const { body } = await fetch(`/events/${event?.id}/users/role-count?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transformUserRole.many(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};

export const useUserExists = () => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useCallback(
    async (email: string): Promise<boolean> => {
      try {
        await fetch(`/events/${event?.id}/users/validate-email`, {
          token: accessToken,
          method: 'POST',
          body: JSON.stringify({ email }),
        });

        return false;
      } catch (exeption) {
        return true;
      }
    },
    [event, accessToken],
  );
};

export function useRoles() {
  const eventId = useEventId();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();

  return useQuery(
    ['roles', eventId, i18n.language].filter(Boolean),
    async () => {
      const { body } = await fetch(languageUrl(`/events/${eventId}/roles`, i18n.language), {
        token: accessToken,
      });

      return transformUserRole.many(body);
    },
    {
      keepPreviousData: true,
      enabled: !!eventId,
    },
  );
}

export default { useUser, useUsers, useUserRoleCount, useUserExists, useRoles };
