export const languages = [
  {
    code: 'en',
    name: 'English',
    nativeName: 'English',
  },
  {
    code: 'nl',
    name: 'Dutch',
    nativeName: 'Nederlands',
  },
  {
    code: 'de',
    name: 'German',
    nativeName: 'Deutsch',
  },
  {
    code: 'nl_BE',
    name: 'Flemish',
    nativeName: 'Vlaams',
  },
  {
    code: 'fr',
    name: 'French',
    nativeName: 'Français',
  },
  {
    code: 'es',
    name: 'Spanish',
    nativeName: 'Español',
  },
  {
    code: 'it',
    name: 'Italian',
    nativeName: 'Italiano',
  },
];
