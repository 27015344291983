import parse, { Attributes } from '@common/utils/parse';

import parseMeta from './meta';
import { parseUser } from './user';
import { parseSession } from './session';

const parseRelations = (attributes: Attributes) => {
  return {
    user: attributes.optional.custom('user', (user) => {
      return parse.one(user, parseUser);
    }),
    sessions: attributes.optional.custom('sessions', (session) => {
      return parse.many(session, parseSession);
    }),
  };
};

export const parseSpeaker = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    name: attributes.string('name'),
    jobTitle: attributes.optional.string('jobTitle'),
    companyName: attributes.optional.string('companyName'),
    bio: attributes.optional.string('bio'),
    imageUrl: attributes.optional.string('imageUrl'),
    chatEnabled: attributes.boolean('chatEnabled'),
    videoCallEnabled: attributes.boolean('videoCallEnabled'),
  };
};

export const parseData = (attributes: Attributes) => {
  return {
    ...parseRelations(attributes),
    ...parseSpeaker(attributes),
  };
};

export function one(data: any) {
  return parse.one(data, parseData);
}

export function many(data: any) {
  return {
    items: parse.many(data.items, parseData),
    meta: parseMeta(data.meta),
  };
}

export type SpeakerResource = ReturnType<typeof one>;
export type Speaker = Omit<SpeakerResource, 'id'>;

export default { one, many };
