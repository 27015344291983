/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { mediaQuery } from '@common/styles/mediaQuery';
import ScrollView from '@common/components/Scrollable';
import { useAppSelector } from '@common/store';

export enum Orientation {
  HORIZONTAL,
  VERTICAL,
}

type ContainerProps = {
  className?: any;
  orientation: Orientation;
  scrollable?: boolean;
};

const Container: React.FC<ContainerProps> = ({
  className,
  orientation,
  scrollable = true,
  children,
}) => {
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);
  const content = (
    <div
      css={css`
        flex: 1;
        display: flex;
        height: 100%;
        flex-direction: ${orientation === Orientation.HORIZONTAL ? 'row' : 'column'};
        align-items: stretch;
        box-sizing: border-box;
        ${mobileFullscreen ? '' : 'padding-bottom: 1em;'}
        ${elementFullScreen ? 'padding-bottom: 0;' : ''}
      `}
    >
      {children}
    </div>
  );

  return scrollable ? (
    <ScrollView
      className={className}
      css={css`
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        > div > div > div {
          display: inline;
          min-height: 100%;
          position: relative;
        }
      `}
    >
      <div
        css={css`
          margin-bottom: 5em;
          ${mobileFullscreen ? '' : 'padding: 0 1em;'}
          ${mediaQuery(
            'm',
            css`
              ${mobileFullscreen ? '' : 'padding: 0 2em;'}
            `,
          )}
        `}
      >
        {content}
      </div>
    </ScrollView>
  ) : (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        ${!mobileFullscreen ? '' : 'padding: 0 1em;'}
        ${elementFullScreen && 'padding: 0;'}
        position: relative;
        ${mediaQuery(
          'm',
          css`
            ${mobileFullscreen ? '' : 'padding: 0 2em;'}
            ${elementFullScreen && 'padding: 0;'}
          `,
        )}
      `}
    >
      {content}
    </div>
  );
};

export default Container;
