import dayjs from 'dayjs';

export const formatTime = (date: Date) => {
  if (!date) return 'invalid';
  return `${`0${date?.getHours?.()}`.slice(-2)}:${`0${date?.getMinutes?.()}`.slice(-2)}`;
};

export const convertDatesToTimeSlot = (startDate: Date, endDate: Date, selectedDate?: Date) => {
  let times = { start: startDate, end: endDate };
  if (selectedDate) {
    times = calculateTimes(times, selectedDate);
  }
  return `${formatTime(times.start)} - ${formatTime(times.end)}`;
};

const calculateTimes = (times: { start: Date; end: Date }, selectedDate: Date) => {
  const newTimes = times;
  const startedBeforeToday = dayjs(times.start).isBefore(dayjs(selectedDate), 'day');
  const endsAfterToday = dayjs(times.end).isAfter(dayjs(selectedDate), 'day');
  const isToday = dayjs(times.start).isSame(dayjs(selectedDate), 'day');
  const endsToday = dayjs(times.end).isSame(dayjs(selectedDate), 'day');

  if (startedBeforeToday && (endsAfterToday || endsToday)) {
    newTimes.start = dayjs(selectedDate).startOf('day').toDate();
  }
  if (endsAfterToday && (startedBeforeToday || isToday))
    newTimes.end = dayjs(selectedDate).endOf('day').toDate();
  return newTimes;
};

export const parseISO = (iso: string | Date) => {
  if (iso instanceof Date) return iso;
  return new Date(iso);
};

export const getMonth = (startDate: Date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames[startDate.getMonth()];
};

export const getShortDay = (startDate: Date) => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Son'];

  return days[startDate.getDay()];
};

export const displayDate = (startDate: Date) => {
  const today = new Date();

  if (today.getDate() === startDate.getDate() && today.getMonth() === startDate.getMonth())
    return 'Today';
  return `${startDate.getDate()} ${getMonth(startDate)}`;
};

export const isSameDay = (tstamp1: number, tstamp2: number) => {
  const firstDate = new Date(tstamp1);
  const secondDate = new Date(tstamp2);

  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
};

export default {
  formatTime,
  convertDatesToTimeSlot,
  getMonth,
  displayDate,
  getShortDay,
  isSameDay,
  parseISO,
};
