import { useCallback } from 'react';

import { useAppDispatch } from '@common/store';
import { ChatTabTypes, setSelectedTab, setSelectedRoom } from '@modules/chat/store/chat';
import { setActiveItem } from '@common/store/sidebar';

export function useOpenChat() {
  const dispatch = useAppDispatch();

  return useCallback(
    (roomId: string) => {
      dispatch(setActiveItem('private_chat'));
      dispatch(setSelectedRoom(roomId));
      dispatch(setSelectedTab(ChatTabTypes.ROOM));
    },
    [dispatch],
  );
}
