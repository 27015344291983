import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { PageResource } from '@common/transforms/page';

import Loader from '@common/components/Loader';

import { RefreshIcon } from './svg/refresh';
import { PrintIcon } from './svg/print';

import { Trends } from './pages/Trends';
import { Vision } from './pages/Vision';
import CanvasOverlay from './components/CanvasOverlay';
import { CloseIcon } from './svg/close';
import { InfoButton } from './components/InfoButton';

const ActionContainer = styled.div`
  position: absolute;
  right: 0;
  padding: 1.042vw 1.302vw 0 0;
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.521vw;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  box-sizing: border-box;
`;

const ActionButton = styled.div`
  border: none;
  background-color: #600403;
  color: white;
  padding: 0.521vw 1.042vw;
  border-radius: 2.604vw;
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.208vw;
  cursor: pointer;
  align-items: center;
  font-size: 0.833vw;
`;

const TRENDS_HOW_IT_WORKS =
  'Tijdens de interactieve co-creatiesessie worden de trends in de vier kwadranten gesleept om de mogelijkheden en voordelen van Connected Working of IoT voor uw bedrijf te bespreken. ';
const VISION_HOW_IT_WORKS = (
  <>
    Definieer achtereenvolgens Vision, Themes, Supports, Challenges, Values en 5 Bold Steps om uw
    visie te realiseren. Vul ieder onderdeel kort en krachtig in.
  </>
);

export function CanvasTemplate({ page }: { page: PageResource }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [reset, updateState] = React.useState<number>(0);
  const forceUpdate = React.useCallback(() => updateState((i) => i + 1), []);
  const container = React.createRef<HTMLDivElement>();
  const canvasHtmlContainer = React.createRef<HTMLDivElement>();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const navigate = useNavigate();

  const pageContent = useMemo(() => {
    return (
      <>
        <div className='vodafone-canvas' key={reset}>
          <div className='canvas-container'>
            {page.parameters.type === 'TRENDS' ? (
              <Trends images={page?.parameters?.images ?? []} />
            ) : (
              <Vision />
            )}
          </div>
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reset]);

  return ReactDOM.createPortal(
    <div>
      <div className='page'>
        <div className='vodafone-container' ref={container}>
          <ActionContainer className='action-buttons'>
            <ActionButton
              onClick={() => {
                forceUpdate();
              }}
            >
              <div style={{ width: '0.833vw', display: 'flex', alignItems: 'center' }}>
                {RefreshIcon}
              </div>
              Reset
            </ActionButton>
            <ActionButton>
              <div
                style={{
                  width: '1.05vw',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InfoButton
                  description={
                    page?.parameters?.type === 'TRENDS' ? (
                      <>{TRENDS_HOW_IT_WORKS}</>
                    ) : (
                      <>{VISION_HOW_IT_WORKS}</>
                    )
                  }
                />
              </div>
            </ActionButton>
            <ActionButton
              onClick={async () => {
                if (loading) {
                  return;
                }

                setLoading(true);

                // eslint-disable-next-line new-cap
                const pdf = new jsPDF({
                  unit: 'pt',
                  orientation: 'landscape',
                });

                const containerContent = container.current;
                const content = canvasHtmlContainer.current;

                if (containerContent) {
                  containerContent.style.transform = 'scale(1)';
                }

                if (content) {
                  await html2canvas(content as HTMLElement, {
                    allowTaint: true,
                    useCORS: true,
                  }).then((canvas) => {
                    const img = canvas.toDataURL('image/png');
                    const { width, height } = pdf.getImageProperties(img);
                    const aspectRatio = height / width;

                    pdf.addImage(img, 'JPEG', 0, 0, 842, 842 * aspectRatio);
                  });
                }

                if (containerContent) {
                  containerContent.style.transform = 'scale(1.2)';
                }

                pdf.save();
                setLoading(false);
              }}
            >
              <div style={{ height: '0.990vw', textAlign: 'center' }}>
                {!loading ? PrintIcon : <Loader />}
              </div>
            </ActionButton>
            <ActionButton
              onClick={async () => {
                setLoading(true);

                // eslint-disable-next-line new-cap
                const pdf = new jsPDF({
                  unit: 'pt',
                  orientation: 'landscape',
                });

                const containerContent = container.current;
                const content = canvasHtmlContainer.current;

                if (containerContent) {
                  containerContent.style.transform = 'scale(1)';
                }

                if (content) {
                  await html2canvas(content as HTMLElement, {
                    allowTaint: true,
                    useCORS: true,
                  }).then((canvas) => {
                    const img = canvas.toDataURL('image/png');
                    const { width, height } = pdf.getImageProperties(img);
                    const aspectRatio = height / width;

                    pdf.addImage(img, 'JPEG', 0, 0, 842, 842 * aspectRatio);
                  });
                }

                if (containerContent) {
                  containerContent.style.transform = 'scale(1.2)';
                }

                pdf.save();
                navigate('/pages/center');
                setLoading(false);
              }}
            >
              <div style={{ width: '0.990vw', display: 'grid', placeItems: 'center' }}>
                {CloseIcon}
              </div>
            </ActionButton>
          </ActionContainer>
          <div ref={canvasHtmlContainer} id='canvas'>
            {pageContent}
          </div>
        </div>
      </div>
      <CanvasOverlay
        openModal={showOverlay}
        closeModal={() => setShowOverlay(false)}
        title={
          page.parameters.type === 'TRENDS' ? (
            'CONNECTED WORKPLACE TRENDS'
          ) : (
            <>
              5 BOLD STEPS
              <br />
              VISION CANVAS
            </>
          )
        }
        subtitle='Hoe werkt het?'
        content={page.parameters.type === 'TRENDS' ? TRENDS_HOW_IT_WORKS : VISION_HOW_IT_WORKS}
      />
    </div>,
    document.body,
  );
}
