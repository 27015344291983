import { useMutation, useQuery } from 'react-query';
import queryString from 'query-string';
import dayjs from 'dayjs';

import {
  useInfiniteResources,
  useInit,
  useResource,
  useResources,
} from '@common/hooks/useResources';
import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';
import fetch, { APIError } from '@common/utils/fetch';
import { languageUrl } from '@common/utils/language';
import { getStaleTime } from '@common/utils/staleTime';
import { queryClient } from '@common/components/QueryProvider';
import transform, { SessionResource } from '@common/transforms/session';

export const useSession = (sessionId: string) => {
  return useResource<SessionResource>('session', sessionId, transform.one);
};

export const useScheduledSessions = (
  pagination: { page: number; limit: number },
  search?: string,
  startDate?: string,
  endDate?: string,
) => {
  return useResources<SessionResource>('session', transform.many, {
    path: 'sessions/scheduled',
    pagination,
    startDate,
    endDate,
    search,
  });
};

export const useSessions = (
  pagination: { page: number; limit: number },
  search?: string,
  date?: Date,
  scheduled?: boolean,
) => {
  const sessions = useResources<SessionResource>('session', transform.many, {
    path: scheduled ? 'sessions/scheduled' : undefined,
    pagination,
    date: date && dayjs(date).format('YYYY-MM-DD'),
    timezone: dayjs.tz.guess(),
    search,
  });

  return sessions;
};

export const useSessionsCount = () => {
  const { accessToken, language, eventId, isAuthorized } = useInit();
  return useQuery<
    {
      count: string;
      date: string;
    }[],
    APIError
  >(
    ['sessionsCount', eventId].filter(Boolean),
    async () => {
      const query = queryString.stringify({ timezone: dayjs.tz.guess() });
      const { body } = await fetch(
        languageUrl(`/events/${eventId}/sessions/count?${query}`, language),
        {
          token: accessToken,
        },
      );
      return body;
    },
    {
      keepPreviousData: true,
      enabled: !!eventId && isAuthorized,
      ...getStaleTime(5),
    },
  );
};

export const useSessionsDetails = () => {
  const { accessToken, eventId, isAuthorized } = useInit();
  return useQuery<{ allMandatory: boolean }, APIError>(
    ['sessionsDetails', eventId].filter(Boolean),
    async () => {
      const { body } = await fetch(`/events/${eventId}/sessions/details`, {
        token: accessToken,
      });
      return body;
    },
    {
      keepPreviousData: true,
      enabled: !!eventId && isAuthorized,
    },
  );
};

export const useScheduledSessionsCount = () => {
  const { accessToken, language, eventId, isAuthorized } = useInit();

  return useQuery<
    {
      count: string;
      date: string;
    }[],
    APIError
  >(
    ['scheduledSessionsCount', eventId].filter(Boolean),
    async () => {
      const query = queryString.stringify({ timezone: dayjs.tz.guess() });
      const { body } = await fetch(
        languageUrl(`/events/${eventId}/sessions/scheduled/count?${query}`, language),
        {
          token: accessToken,
        },
      );
      return body;
    },
    {
      keepPreviousData: true,
      enabled: !!eventId && isAuthorized,
      ...getStaleTime(5),
    },
  );
};

export const useInfiniteHighlightedSessions = (pagination: { limit: number }, search?: string) => {
  return useInfiniteResources<SessionResource>('session', transform.many, {
    path: 'sessions/highlighted',
    pagination,
    search,
  });
};

export const useInfiniteSessions = (
  pagination: { limit: number },
  search?: string,
  scheduled?: boolean,
) => {
  const sessions = useInfiniteResources<SessionResource>('session', transform.many, {
    path: scheduled ? 'sessions/scheduled' : undefined,
    pagination,
    search,
  });
  return sessions;
};

export const useGetSession = () => {
  const { accessToken, language, eventId } = useInit();

  return (sessionId: string) => {
    return fetch(languageUrl(`/events/${eventId}/sessions/${sessionId}`, language), {
      method: 'GET',
      token: accessToken,
    });
  };
};

export const useAddScheduledSession = (sessionId?: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(
    (reminder: boolean) => {
      return fetch(`/events/${event?.id}/sessions/${sessionId}/scheduled`, {
        method: 'POST',
        body: { reminder },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('session');
        queryClient.invalidateQueries('sessions');
        queryClient.invalidateQueries('sessionsCount');
        queryClient.invalidateQueries('scheduledSessionsCount');
      },
    },
  );
};

export const useUpdateScheduledSession = (sessionId?: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(
    (reminder: boolean) => {
      return fetch(`/events/${event?.id}/sessions/${sessionId}/scheduled`, {
        method: 'PUT',
        body: { reminder },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('session');
        queryClient.invalidateQueries('sessions');
        queryClient.invalidateQueries('sessionsCount');
        queryClient.invalidateQueries('scheduledSessionsCount');
      },
    },
  );
};

export const useRemoveScheduledSession = (sessionId?: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(
    () => {
      return fetch(`/events/${event?.id}/sessions/${sessionId}/scheduled`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('session');
        queryClient.invalidateQueries('sessions');
        queryClient.invalidateQueries('sessionsCount');
        queryClient.invalidateQueries('scheduledSessionsCount');
      },
    },
  );
};

export default {
  useSession,
  useSessions,
  useInfiniteSessions,
  useScheduledSessions,
  useSessionsCount,
  useSessionsDetails,
  useInfiniteHighlightedSessions,
  useGetSession,
  useAddScheduledSession,
  useRemoveScheduledSession,
};
