import dayjs from 'dayjs';

import i18n from '@common/utils/i18n';
import { SessionResource } from '@common/transforms/session';
import { StageResource } from '@common/transforms/stage';

export const INTERNAL_SESSION: StageResource = {
  id: 'internal',
  name: i18n.t('unnamed_stage'),
  createdAt: new Date().toJSON(),
  sessions: undefined,
};

// Retrieve all the unique stages found within the given sessions
export const getStagesFromSessions = (sessions?: SessionResource[]): StageResource[] => {
  if (!sessions?.length) return [INTERNAL_SESSION];

  const allStages = (
    sessions.map((session) => session.stage).filter(Boolean) as StageResource[]
  ).sort((a, b) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime());

  if (!allStages.length) return [INTERNAL_SESSION];

  const uniques = new Set();
  const result = allStages.filter((stage) => {
    if (!stage.id) return false;
    const duplicate = uniques.has(stage.id);
    uniques.add(stage.id);
    return !duplicate;
  });

  // check if there are any stages or if there are events without a stage, if so, add an empty named stage
  if (!result.length || (result.length && !!sessions.filter((session) => !session.stage)?.length)) {
    result.push(INTERNAL_SESSION);
  }

  return result as StageResource[];
};

// Get the total amount of minutes the day is with the events on that date
export const getDayOverview = (day: Date, sessions?: SessionResource[]) => {
  if (!sessions?.length) return undefined;
  let startTime = dayjs(day).endOf('day').toDate();
  let endTime = dayjs(day).startOf('day').toDate();
  sessions.forEach((session) => {
    if (dayjs(session.startsAt).isBefore(day, 'd')) {
      startTime = new Date(dayjs(day).toDate().setHours(0, 0, 0));
    } else if (
      dayjs(session.startsAt).isSame(day, 'd') &&
      dayjs(session.startsAt).isBefore(startTime)
    ) {
      startTime = dayjs(session.startsAt).toDate();
    }

    if (dayjs(session.endsAt).isAfter(day, 'd'))
      endTime = new Date(dayjs(day).toDate().setHours(24, 0, 0));
    else if (dayjs(session.endsAt).isSame(day, 'd') && dayjs(session.endsAt).isAfter(endTime))
      endTime = dayjs(session.endsAt).toDate();
  });

  if (startTime.getMinutes() >= 30) startTime.setMinutes(30, 0, 0);
  else startTime.setMinutes(0, 0, 0);
  if (endTime.getMinutes() <= 30) endTime.setMinutes(30, 0, 0);
  else endTime.setHours(endTime.getHours() + 1, 0, 0, 0);

  const duration = dayjs(endTime).diff(startTime, 'm');

  return {
    startTime,
    endTime,
    beginsAtHalf: startTime.getMinutes() >= 30,
    duration: duration < 0 ? duration * -1 : duration,
  };
};

export const PIXELS_PER_MINUTE = 4;
export const SIZE_PER_COLUMN = 400;

export default {
  PIXELS_PER_MINUTE,
  SIZE_PER_COLUMN,
  getStagesFromSessions,
  getDayOverview,
};
