/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export function SlidoItem({ slidoUrl }: { slidoUrl?: string }) {
  return (
    <iframe
      css={css`
        border: 0;
        border-radius: 12px;
        width: 100%;
        height: 100%;
      `}
      src={slidoUrl}
      title='Slido'
    />
  );
}
