import parse, { Attributes } from '@common/utils/parse';

import { parseUser } from './user';

export const parseParticipant = (attributes: Attributes) => {
  return {
    participantId: attributes.id('id'),
    ...attributes.custom('user', (user) => {
      return parse.one(user, parseUser);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseParticipant);
}

export function many(data: any) {
  return parse.many(data, parseParticipant);
}

export type ParticipantResource = ReturnType<typeof one>;
export type Participant = Omit<ParticipantResource, 'id'>;

export default { one, many };
