import { Icon } from '@typing/icons';

import Arrow from './Arrow';
import Bell from './Bell';
import Calendar from './Calendar';
import Chat from './Chat';
import Chats from './Chats';
import Checkmark from './Checkmark';
import Chevron from './Chevron';
import Clock from './Clock';
import Collapse from './Collapse';
import DeclineCall from './DeclineCall';
import Faq from './Faq';
import Fullscreen from './Fullscreen';
import Google from './Google';
import Grid from './Grid';
import Laptop from './Laptop';
import LinkedIn from './LinkedIn';
import List from './List';
import Live from './Live';
import Mandatory from './Mandatory';
import Menu from './Menu';
import Moon from './Moon';
import NoPic from './NoPic';
import Phone from './Phone';
import Pinpoint from './Pinpoint';
import Play from './Play';
import Plus from './Plus';
import Question from './Question';
import Search from './Search';
import Send from './Send';
import Speaker from './Speaker';
import Times from './Times';
import Users from './Users';
import VideoCall from './VideoCall';
import Warning from './Warning';
import Minus from './Minus';
import Minimize from './Minimize';
import Wifi from './Wifi';
import User from './User';

const getCustomIcon = (icon: string, props: Icon) => {
  switch (icon) {
    case 'arrow':
      return <Arrow {...props} />;
    case 'bell':
      return <Bell {...props} />;
    case 'calendar':
      return <Calendar {...props} />;
    case 'chat':
      return <Chat {...props} />;
    case 'chats':
      return <Chats {...props} />;
    case 'checkmark':
      return <Checkmark {...props} />;
    case 'chevron':
      return <Chevron {...props} />;
    case 'clock':
      return <Clock {...props} />;
    case 'collapse':
      return <Collapse {...props} />;
    case 'decline-call':
      return <DeclineCall {...props} />;
    case 'faq':
      return <Faq {...props} />;
    case 'fullscreen':
      return <Fullscreen {...props} />;
    case 'minimize':
      return <Minimize {...props} />;
    case 'google':
      return <Google {...props} />;
    case 'grid':
      return <Grid {...props} />;
    case 'linkedin':
      return <LinkedIn {...props} />;
    case 'laptop':
      return <Laptop {...props} />;
    case 'list':
      return <List {...props} />;
    case 'live':
      return <Live {...props} />;
    case 'mandatory':
      return <Mandatory {...props} />;
    case 'menu':
      return <Menu {...props} />;
    case 'moon':
      return <Moon {...props} />;
    case 'no-pic':
      return <NoPic {...props} />;
    case 'phone':
      return <Phone {...props} />;
    case 'pinpoint':
      return <Pinpoint {...props} />;
    case 'play':
      return <Play {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'question':
      return <Question {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'send':
      return <Send {...props} />;
    case 'speaker':
      return <Speaker {...props} />;
    case 'times':
      return <Times {...props} />;
    case 'users':
      return <Users {...props} />;
    case 'video-call':
      return <VideoCall {...props} />;
    case 'warning':
      return <Warning {...props} />;
    case 'minus':
      return <Minus {...props} />;
    case 'user':
      return <User {...props} />;
    case 'wifi':
      return <Wifi {...props} />;
    default:
      return null;
  }
};

export default getCustomIcon;
