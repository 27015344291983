import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { SESSION_STATES } from '@modules/schedule/hooks/useSessionStatus';
import { SessionResource } from '@common/transforms/session';
import { useCurrentTime } from '@common/hooks/useCurrentTime';
import { convertDatesToTimeSlot, parseISO } from '@common/utils/time';

export function useSessionSettings(
  session: SessionResource,
  sessionStatus: SESSION_STATES,
  selectedDate?: Date,
) {
  const [afterEndTime, setAfterEndTime] = useState(false);
  const [sessionJoin, setSessionJoin] = useState(false);

  const endTime = session.endsAt;
  const currentTime = useCurrentTime();
  const DEFAULT_REMINDER_TIME = 15;
  const reminderTime = session.reminderTime || DEFAULT_REMINDER_TIME;

  useEffect(() => {
    if (dayjs(currentTime).isAfter(dayjs(endTime))) {
      setAfterEndTime(true);
    }
    if (
      dayjs(currentTime).isAfter(dayjs(session.startsAt)) &&
      dayjs(currentTime).isBefore(dayjs(session.endsAt))
    ) {
      setSessionJoin(true);
    }
  }, [currentTime, endTime, reminderTime, session.endsAt, session.startsAt]);

  const sessionDuration = dayjs(session.endsAt).diff(session.startsAt, 'minutes');
  const shortDuration = sessionDuration <= 30;

  const status = {
    notStarted: sessionStatus === SESSION_STATES.NOT_STARTED,
    live: sessionStatus === SESSION_STATES.LIVE,
    passed: sessionStatus === SESSION_STATES.PASSED,
  };

  const duration = {
    timeslot: convertDatesToTimeSlot(
      parseISO(session.startsAt),
      parseISO(session.endsAt),
      selectedDate,
    ),
    total: sessionDuration,
    short: shortDuration,
    long: !shortDuration,
  };

  const buttons = {
    showJoin: sessionJoin,
    showLive: status.live,
    showMandatory: session.mandatory,
    showReminder: !session.mandatory && !afterEndTime,
    showAddToSchedule: !afterEndTime && !session.mandatory && !session.scheduled,
    showRemoveFromSchedule: !afterEndTime && !session.mandatory && session.scheduled,
    showOndemand: status.passed && session.onDemandUrl,
    showFinished: status.passed && !session.onDemandUrl,
  };

  return {
    status,
    duration,
    buttons,
  };
}
