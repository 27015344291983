import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '@common/store';
import { UserRolePermission } from '@common/transforms/userRole';
import Loader from '@common/components/Loader';
import useEvent from '@modules/event/hooks/useEvent';
import { useOpenChat } from '@modules/chat/hooks/useOpenChat';
import { removeQueryParamsFromRouter } from '@modules/event/utils';

export default function Pages() {
  const { chat } = useParams();
  const navigate = useNavigate();
  const event = useEvent();
  const setChat = useOpenChat();
  const user = useAppSelector((state) => state.auth.user);

  const userCanPrivateChat = user?.role?.permissions.includes(
    UserRolePermission.CREATE_PRIVATE_CHAT,
  );

  useEffect(() => {
    if (!chat?.length || !event?.privateChatEnabled || !userCanPrivateChat) return;
    setChat(chat as string);
    removeQueryParamsFromRouter(navigate, ['chat']);
  }, [chat, event?.privateChatEnabled, userCanPrivateChat, navigate, setChat]);

  useEffect(() => {
    navigate('/landing');
  }, [navigate]);

  return <Loader />;
}
