import Labeled from '@common/components/Labeled';
import Input from '@common/components/Input';
import Select from '@common/components/Select';

import { CustomFieldResource, CustomFieldType } from '@common/transforms/customField';

type CustomFieldProps = {
  customField: CustomFieldResource;
};

const CustomField: React.FC<CustomFieldProps> = ({ customField }) => {
  const { id, label, type, options, required, placeholder } = customField;

  const returnCustomField = () => {
    if (type === CustomFieldType.DROPDOWN) {
      if (customField.key === 'country-select') {
        const sortedOptions = options.sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
        );
        return (
          <Select
            name={id}
            options={sortedOptions.map((option) => ({
              value: option.key,
              label: option.label,
            }))}
          />
        );
      }

      return (
        <Select
          name={id}
          options={options.map((option) => ({
            value: option.key,
            label: option.label,
          }))}
        />
      );
    }
    return <Input name={id} placeholder={placeholder} />;
  };

  return (
    <Labeled key={id} label={required ? `${label} *` : label}>
      {returnCustomField()}
    </Labeled>
  );
};

export default CustomField;
