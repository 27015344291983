import { useCallback } from 'react';

import fetch from '@common/utils/fetch';
import { useEvent } from '@modules/event/hooks/useEvent';
import { useAppDispatch } from '@common/store';

import { setError } from '../store/auth';

export function useGetMagicLink() {
  const event = useEvent();
  const dispatch = useAppDispatch();

  return useCallback(
    async (body: { email: string; recaptchaToken: string }) => {
      if (!event) return;
      dispatch(setError(null));

      try {
        await fetch(`/auth/${event.id}/request_login_token`, {
          method: 'POST',
          body,
        });
      } catch (err: any) {
        dispatch(
          setError(
            err?.cause?.response?.body?.message ||
              err?.response?.body?.message ||
              err?.response?.body?.error ||
              err?.message,
          ),
        );
        throw new Error(err);
      }
    },
    [dispatch, event],
  );
}
