/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { readableColor } from 'polished';

import i18n from '@common/utils/i18n';
import { renderLocationIcon } from '@modules/event/utils';
import { displayDate, parseISO } from '@common/utils/time';
import { SessionResource } from '@common/transforms/session';
import { SESSION_STATES } from '@modules/schedule/hooks/useSessionStatus';
import { useSessionSettings } from '@modules/schedule/hooks/useSessionSettings';

import Pill from '@common/components/Pill';
import Icon from '@common/components/Icon';
import IconSwitch from '@common/components/IconSwitch';
import ButtonWithHoverLabel from '@common/components/ButtonWithHoverLabel';
import IconHover from '@common/components/IconHover';

interface SessionHighlightedProps {
  session: SessionResource;
  onNotifyClick: (enabled: boolean) => void;
  sessionStatus: SESSION_STATES;
}

const SessionHighlighted: React.FC<SessionHighlightedProps> = ({
  onNotifyClick,
  sessionStatus,
  session,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { duration, buttons } = useSessionSettings(session, sessionStatus);

  const startDate =
    displayDate(parseISO(session.startsAt)) === 'Today'
      ? t('today')
      : parseISO(session.startsAt).toLocaleDateString(i18n.language.replace('_', '-'), {
          day: 'numeric',
          month: 'long',
        });

  const textColor = session.imageUrl
    ? theme.colors.textLight
    : readableColor(theme.colors.secondary);

  return (
    <div
      css={css`
        border-radius: ${theme.borderRadius.normal};
        overflow: hidden;
        position: relative;
        width: 250px;
        height: 370px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.2s;
        cursor: pointer;
        background-color: ${theme.colors.secondary};
        padding: 20px;
        transform: translateZ(0);

        :hover {
          > .highlighted-background-image {
            transform: scale(1.05);
          }
        }
      `}
      aria-hidden='true'
    >
      <div
        className='highlighted-background-image'
        css={css`
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
          border-radius: ${theme.borderRadius.normal};
          background-image: url(${session.imageUrl});
          background-size: cover;
          background-position: center;
          background-color: ${theme.colors.secondary};
          transition: all 0.2s;

          &:after {
            content: '';
            clear: both;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 50%;
            background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
          }
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            min-width: 0;
            flex-wrap: wrap;
            width: 100%;
            margin-top: -10px;

            div {
              margin-top: 10px;
              max-width: 100%;
            }
            z-index: 1;
            color: ${textColor};
          `}
        >
          {buttons.showLive && (
            <Pill
              highlighted
              icon='live'
              css={css`
                color: inherit;
                flex-shrink: 0;
              `}
            >
              {t('live.now')}
            </Pill>
          )}
          {buttons.showOndemand && (
            <Pill
              css={css`
                color: inherit;
              `}
            >
              {t('onDemand')}
            </Pill>
          )}
          {buttons.showFinished && (
            <Pill
              css={css`
                color: inherit;
              `}
            >
              {t('finished')}
            </Pill>
          )}
          {!!session.stage?.id && sessionStatus !== SESSION_STATES.PASSED && (
            <Pill
              css={css`
                color: inherit;
              `}
            >
              {session.stage.name}
            </Pill>
          )}
        </div>
        {buttons.showReminder && (
          <ButtonWithHoverLabel reminder>
            <IconSwitch
              onChange={onNotifyClick}
              selected={session.reminder}
              icon='bell'
              size={50}
            />
          </ButtonWithHoverLabel>
        )}
      </div>
      <div
        css={css`
          z-index: 1;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 100px;
            font-size: ${theme.fontSizes.m};
            color: ${textColor};
          `}
        >
          <Icon icon='clock' size={16} />
          <span
            css={css`
              margin-left: 5px;
              display: flex;
              align-items: center;
            `}
          >
            {duration.timeslot}{' '}
            <span
              css={css`
                font-size: 16px;
                line-height: 8px;
                margin: 0px 6px;
              `}
            >
              •
            </span>
            <span>{startDate}</span>
            <span
              css={css`
                font-size: 16px;
                line-height: 8px;
                margin: 0px 6px;
              `}
            >
              •
            </span>
            {renderLocationIcon(session.location) === 'both' ? (
              <>
                <ButtonWithHoverLabel labelText={t('attendance_location.onsite')} small>
                  <IconHover icon='pinpoint' size={18} />
                </ButtonWithHoverLabel>
                <ButtonWithHoverLabel labelText={t('attendance_location.online')} small>
                  <IconHover icon='laptop' size={18} />
                </ButtonWithHoverLabel>
              </>
            ) : (
              <ButtonWithHoverLabel
                labelText={t(`attendance_location.${session.location.toLowerCase()}`)}
                small
              >
                <IconHover icon={renderLocationIcon(session.location)} size={18} />
              </ButtonWithHoverLabel>
            )}
          </span>
        </div>
        <p
          css={css`
            font-size: ${theme.fontSizes.h3};
            font-weight: ${theme.fontWeights.bold};
            color: ${textColor};
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 115px;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin: 0;
            padding: 0;
          `}
        >
          {session.title}
        </p>
      </div>
    </div>
  );
};

export default SessionHighlighted;
