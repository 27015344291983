import { Icon } from '@typing/icons';

const Clock: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C10.0222 2 8.08879 2.58644 6.4443 3.68526C4.79981 4.78408 3.51809 6.34592 2.76121 8.17318C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53042 17.6726 4.92895 19.0711C6.32747 20.4696 8.1093 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8269 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34785 20.9464 6.8043 19.0711 4.92893C17.1957 3.05356 14.6522 2 12 2ZM14.9038 16.6737C14.8203 16.7399 14.7247 16.7889 14.6222 16.8181C14.5198 16.8472 14.4127 16.8559 14.3069 16.8436C14.2011 16.8313 14.0988 16.7982 14.0058 16.7464C13.9129 16.6945 13.831 16.6248 13.765 16.5413L11.285 13.405C11.1724 13.2626 11.1108 13.0866 11.11 12.905V6.53751C11.11 6.32203 11.1956 6.11532 11.348 5.96294C11.5003 5.81057 11.707 5.72498 11.9225 5.72498C12.138 5.72498 12.3447 5.81057 12.497 5.96294C12.6494 6.11532 12.735 6.32203 12.735 6.53751V12.6225L15.04 15.5388C15.1717 15.7076 15.2313 15.9217 15.2058 16.1343C15.1802 16.3469 15.0717 16.5408 14.9038 16.6737V16.6737Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Clock;
