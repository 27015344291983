import styled from '@emotion/styled';

import { formatTime } from '@common/utils/time';
import { PIXELS_PER_MINUTE } from '@modules/schedule/utils';

export interface TimeRowProps {
  time: Date;
  stageLength: number;
}

const TimeRow: React.FC<TimeRowProps> = ({ time, stageLength }) => {
  return (
    <Container>
      <TimeValue>{formatTime(new Date(time))}</TimeValue>
      <LineWrapper colSpan={stageLength + 1}>
        <Line />
      </LineWrapper>
    </Container>
  );
};

export default TimeRow;

const Container = styled.tr`
  width: 100%;
  height: ${PIXELS_PER_MINUTE * 30}px;
  max-height: ${PIXELS_PER_MINUTE * 30}px;
`;

const TimeValue = styled.td`
  width: 60px;
  vertical-align: top;
  font-size: ${(props) => props.theme.fontSizes.m};
`;

const LineWrapper = styled.td`
  vertical-align: top;
`;

const Line = styled.hr`
  border-color: ${(props) => props.theme.colors.disabledText};
`;
