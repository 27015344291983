/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import { useTranslation, Trans } from 'react-i18next';

import EventDetails from '@modules/event/components/EventDetails';
import Alert from '@common/components/Alert';
import Body from '@common/components/Body';
import LoginForm from '@modules/auth/components/LoginForm';
import Heading from '@common/components/Heading';
import EventBackground from '@modules/event/components/EventBackground';
import useEvent from '@modules/event/hooks/useEvent';

import Icon from '@common/components/Icon';
import { mediaQuery } from '@common/styles/mediaQuery';
import { useAppSelector } from '@common/store';
import { useGetMagicLink } from '@modules/auth/hooks/useGetMagicLink';
import { useErrorMessage } from '@common/hooks/useErrorMessage';

export default function Login() {
  const getMagicLink = useGetMagicLink();
  const navigate = useNavigate();
  const event = useEvent();
  const theme = useTheme();
  const error = useAppSelector((state) => state.auth.error);
  const [isRequested, setIsRequested] = useState(false);
  const [, errorKey] = useErrorMessage(error);

  const { t } = useTranslation();
  const onSubmit = (data: { email: string; recaptchaToken: string }) => {
    getMagicLink(data)
      .then(() => {
        setIsRequested(true);
      })
      .catch(() => {
        setIsRequested(false);
      });
  };

  return (
    <EventBackground event={event} blur>
      <div
        css={css`
          position: relative;
          padding: 1em 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          margin-bottom: 1em;
          ${mediaQuery(
            's',
            css`
              display: none;
            `,
          )}
        `}
      >
        <div>{event?.name}</div>
        <div
          css={css`
            position: absolute;
            top: 1em;
            right: 1em;
            cursor: pointer;
            ${mediaQuery(
              's',
              css`
                top: 2em;
                right: 2em;
              `,
            )}
          `}
          onClick={() => navigate('/landing')}
        >
          <Icon icon='times' size={16} color={theme.colors.textLight} />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          width: 100%;
        `}
      >
        <div
          css={css`
            box-sizing: border-box;
            width: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: space-between;
              ${mediaQuery(
                's',
                css`
                  max-width: 1140px;
                  margin: 0 15% 0 0;
                `,
              )}
            `}
          >
            <div
              css={css`
                display: none;
                max-width: 400px;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 2em;
                ${mediaQuery(
                  's',
                  css`
                    display: flex;
                  `,
                )}
              `}
            >
              <EventDetails event={event} />
              <div
                css={css`
                  display: flex;
                  margin-top: 1.65625em;
                  align-items: center;
                `}
              >
                {!isRequested && event?.registrationEnabled && (
                  <Body
                    css={css`
                      color: ${theme.colors.textLight};
                    `}
                  >
                    <Trans
                      i18nKey='orregister'
                      values={{ register: t('register') }}
                      components={[
                        <a
                          key='link'
                          href='/auth/register'
                          css={css`
                            color: ${theme.colors.primary};
                          `}
                        >
                          register
                        </a>,
                      ]}
                    />
                  </Body>
                )}
              </div>
            </div>
            <div
              css={css`
                ${mediaQuery(
                  's',
                  css`
                    max-width: 400px;
                  `,
                )}
              `}
            >
              {errorKey && (
                <div
                  css={css`
                    margin-bottom: 2em;
                  `}
                >
                  <Alert>
                    <Trans
                      i18nKey={errorKey}
                      values={{
                        organiser: event?.organiser,
                        organiserContact: event?.organiserContact,
                      }}
                      components={[
                        <span key='organiser' />,
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          key='organiserContact'
                          href={`mailto:${event?.organiserContact}`}
                          css={css`
                            color: ${theme.colors.error};
                          `}
                        />,
                      ]}
                    />
                  </Alert>
                </div>
              )}
              {isRequested ? (
                <div>
                  <Heading size='h1'>{t('magiclink_title')}</Heading>
                  <div
                    css={css`
                      margin-top: 1.1778em;
                      margin-bottom: 1.875em;
                    `}
                  >
                    <Body
                      css={css`
                        color: ${theme.colors.textLight};
                      `}
                    >
                      {t('magiclink_explanation')}{' '}
                      <Trans
                        i18nKey='support_description'
                        values={{ email: event?.organiserContact }}
                        components={[
                          <a key='link' href={`mailto:${event?.organiserContact}`}>
                            {event?.organiserContact}
                          </a>,
                        ]}
                      />
                    </Body>
                    <div
                      css={css`
                        display: block;
                        margin-top: 1.1778em;
                        color: ${theme.colors.primary};
                      `}
                    >
                      {t('email_check_spam')}
                    </div>
                  </div>
                </div>
              ) : (
                <LoginForm onSubmit={onSubmit} />
              )}
            </div>
          </div>
        </div>
      </div>
    </EventBackground>
  );
}
