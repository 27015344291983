import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import CanvasOverlay from '../components/CanvasOverlay';

import { InfoButton } from '../components/InfoButton';

import { TrendItem } from '../components/TrendItem';
import { Vodafone } from '../svg/vodafone';

type TrendsProps = {
  images: string[];
};

export function Trends({ images }: TrendsProps) {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const AMOUNT_OF_IMAGES = 12;
  const [zIndex, setIndex] = useState<number>(1);
  const [convertedImagesState, setConvertedImagesState] = useState<Array<string>>([]);

  useEffect(() => {
    const temp = Array(AMOUNT_OF_IMAGES).fill('');
    temp.splice(0, images.length);
    images.splice(AMOUNT_OF_IMAGES, images.length - AMOUNT_OF_IMAGES);

    setConvertedImagesState([...images, ...temp]);
  }, [images]);

  return (
    <>
      <div className='trends-container'>
        <div>
          <div className='title'>
            DIGITAL WORKPLACE
            <br />
            <span>
              AND IoT TRENDS
              <InfoButton
                description={
                  <>
                    In dit digital whiteboard staan de meest recente{' '}
                    <b>Connect Workplace en IoT trends</b> centraal. We gebruiken deze trends om
                    samen te verkennen in hoeverre zij kansrijk en relevant zijn voor uw
                    organisatie.
                  </>
                }
              />
            </span>
          </div>

          <div className='sub-title'>‎‎‏‏‎ ‎</div>

          <div className='trend-images-container'>
            {convertedImagesState.map((image, index) => {
              return (
                <TrendItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  number={index + 1}
                  image={image}
                  onStart={(setCurrentIndex) => {
                    setCurrentIndex(zIndex + 1);
                    setIndex(zIndex + 1);
                  }}
                  currentIndex={zIndex}
                />
              );
            })}
          </div>
        </div>
        <div className='content' />

        <div className='vodafone'>{Vodafone}</div>
      </div>
      {ReactDOM.createPortal(
        <CanvasOverlay
          openModal={showOverlay}
          closeModal={() => setShowOverlay(false)}
          title='CONNECTED WORKPLACE TRENDS'
          subtitle='Wat is het?'
          content={
            <>
              In dit digital whiteboard staan de meest recente <b>connected workplace trends</b>{' '}
              centraal. We gebruiken deze trends om samen te verkennen in hoeverre zij kansrijk en
              relevant zijn voor uw organisatie. Naast de 6 basistrends kunt u ook andere trends
              definiëren die op uw bedrijf van toepassing is.
            </>
          }
        />,
        document.body,
      )}
    </>
  );
}
