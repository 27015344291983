/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';

import Container, { Orientation } from '@common/components/Container';
import Loader from '@common/components/Loader';
import Video from '@common/components/Video';

import useEvent from '@modules/event/hooks/useEvent';
import { useUpdateLocation } from '@modules/event/hooks/useLocation';
import {
  useVideo,
  useStartVideoProgress,
  useStopVideoProgress,
} from '@modules/event/hooks/useVideos';

import Body from '@common/components/Body';
import { Platform, usePlatform } from '@common/hooks/usePlatform';

import { useAppSelector } from '@common/store';
import { UserRolePermission } from '@common/transforms/userRole';
import PageWrapper from '@common/components/PageWrapper';
import { useOpenChat } from '@modules/chat/hooks/useOpenChat';
import { removeQueryParamsFromRouter } from '@modules/event/utils';
import { usePage } from '@modules/event/hooks/usePages';

export default function VideoPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { videoId, chat, slug } = useParams();
  const event = useEvent();
  const theme = useTheme();
  const setChat = useOpenChat();
  const { data: page } = usePage(slug as string);
  const { data: video, isError } = useVideo(typeof videoId === 'string' ? videoId : '');
  const startVideoProgress = useStartVideoProgress(
    typeof videoId === 'string' ? videoId : undefined,
    page?.id,
  );
  const stopVideoProgress = useStopVideoProgress(
    typeof videoId === 'string' ? videoId : undefined,
    page?.id,
  );

  const [, setProgressId] = useState<string | null>(null);
  const user = useAppSelector((state) => state.auth.user);
  const fullscreen = useAppSelector((state) => state.sidebar.fullscreen);
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const { mutate: updateLocation } = useUpdateLocation();

  const userCanPrivateChat = user?.role?.permissions.includes(
    UserRolePermission.CREATE_PRIVATE_CHAT,
  );

  useEffect(() => {
    if (!chat?.length || !event?.privateChatEnabled || !userCanPrivateChat) return;
    setChat(chat as string);
    removeQueryParamsFromRouter(navigate, ['chat']);
  }, [chat, event?.privateChatEnabled, userCanPrivateChat, navigate, setChat]);

  useEffect(() => {
    if (isError && !video) {
      navigate(location.pathname.replace(/\/pages\/([A-Z0-9-]+)\/[A-Z0-9-]+/i, '/pages/$1'));
    }
  }, [isError, video, navigate, location.pathname]);

  useEffect(() => {
    if (video?.id) {
      updateLocation([video.id, true]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video?.id]);

  const platform = usePlatform();

  const startedPlaying = useCallback(async () => {
    const { id } = await startVideoProgress();
    setProgressId(id);
  }, [startVideoProgress]);

  const stoppedPlaying = useCallback(() => {
    // somehow the result of `setProgressId` above is not being updated here, so we use the callback
    setProgressId((prev) => {
      if (prev) stopVideoProgress(prev);
      return null;
    });
  }, [stopVideoProgress]);

  return (
    <PageWrapper>
      {video && typeof videoId === 'string' ? (
        <Container
          orientation={platform === Platform.MOBILE ? Orientation.VERTICAL : Orientation.HORIZONTAL}
          css={css`
            > div {
              background: transparent !important;
            }
          `}
        >
          <div
            css={css`
              min-width: ${platform === Platform.MOBILE ? '100%' : '80%'};
              border: 0;
              border-radius: 12px;
              overflow: ${fullscreen && 'hidden'};
              z-index: 0;
              aspect-ratio: 16/9;

              @media (max-width: 900px) {
                ${!mobileFullscreen &&
                `
                  max-height: 70vh;
                  > div {
                    max-height: 70vh;
                  }
                `}
              }
            `}
          >
            <Video
              key={videoId}
              video={video}
              startedPlaying={startedPlaying}
              stoppedPlaying={stoppedPlaying}
            />
          </div>
          <div
            css={css`
              width: ${platform === Platform.MOBILE ? '100%' : '20%'};
              padding: 0 20pt;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
            `}
          >
            <Body
              css={css`
                padding-top: 12px;
              `}
            >
              {video.subtitle}
            </Body>
            <div
              css={css`
                font-size: 32px;
                padding: 22px 0 12px;
              `}
            >
              {video.title}
            </div>
            <Body
              css={css`
                background-color: rgba(0, 0, 0, 0.5);
                padding: ${theme.paddings.s};
                border-radius: 0.5em;
                margin-bottom: 8px;
              `}
              color='textLight'
            >
              {video.duration} min
            </Body>
            <div>
              <Body>{video.description}</Body>
            </div>
          </div>
        </Container>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
}
