import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useTranslation } from 'react-i18next';

import useEvent, { useEventId } from '@modules/event/hooks/useEvent';
import { getAccessToken, tokens } from '@modules/auth/hooks/useAuth';

import transform from '@common/transforms/video';
import transformVideoProgress from '@common/transforms/videoProgress';
import transformCategory from '@common/transforms/videoCategory';
import { useAppSelector } from '@common/store';
import { getStaleTime } from '@common/utils/staleTime';
import fetch from '@common/utils/fetch';

export const useVideo = (videoId: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['videoProgress', event?.id, videoId],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/videos/${videoId}?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transform.one(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};

export const useVideos = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['videos', event?.id],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/videos?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};

export const useStartVideoProgress = (videoId?: string, pageId?: string) => {
  const eventId = useEventId();

  const startVideoProgress = useCallback(async () => {
    if (!pageId) throw new Error('No page');
    const response = await fetch(`/events/${eventId}/videos/${pageId}/${videoId}/progress`, {
      method: 'GET',
      token: tokens.accessToken,
    });

    return transformVideoProgress.one(response.body);
  }, [eventId, pageId, videoId]);

  return startVideoProgress;
};

export const useStopVideoProgress = (videoId?: string, pageId?: string) => {
  const eventId = useEventId();

  const stopVideoProgress = useCallback(
    async (progressId: string) => {
      if (!pageId) throw new Error('No page');
      const response = await fetch(
        `/events/${eventId}/videos/${pageId}/${videoId}/progress/${progressId}`,
        {
          method: 'GET',
          token: tokens.accessToken,
        },
      );

      return transformVideoProgress.one(response.body);
    },
    [eventId, pageId, videoId],
  );

  return stopVideoProgress;
};

export const useVideoCategory = (videoCategoryId: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['videoCategory', event?.id, videoCategoryId],
    async () => {
      const { body } = await fetch(
        `/events/${event?.id}/videos/categories/${videoCategoryId}?lang=${i18n.language}`,
        {
          token: accessToken,
        },
      );

      return transformCategory.one(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};

export const useVideoCategories = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['videoCategories', event?.id],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/videos/categories?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transformCategory.many(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};

export const usePageVideoCategories = (pageId: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['videoCategories', event?.id, pageId],
    async () => {
      const { body } = await fetch(
        `/events/${event?.id}/pages/${pageId}/videos?lang=${i18n.language}`,
        {
          token: accessToken,
        },
      );

      return transformCategory.many(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};

export default {
  useVideo,
  useVideos,
  useStartVideoProgress,
  useStopVideoProgress,
  useVideoCategory,
  useVideoCategories,
};
