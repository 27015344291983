/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Heading from '@common/components/Heading';
import { UserResource } from '@common/transforms/user';
import Avatar from '@modules/user/components/Avatar';

type ScheduleHeaderProps = {
  text?: string;
  attendees?: Partial<UserResource>[];
};

const ScheduleHeader: React.FC<ScheduleHeaderProps> = ({ text, attendees }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <Heading
        css={css`
          color: ${theme.colors.textLight};
        `}
      >
        {text}
      </Heading>

      {!!attendees && attendees?.length > 0 && (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          {attendees.slice(0, 5).map?.((user) => (
            <Avatar
              key={user.id}
              user={user}
              size={40}
              css={css`
                :not(:first-of-type) {
                  margin-left: -7px;
                }
              `}
            />
          ))}
          <p
            css={css`
              margin: 0;
              margin-left: 15px;
              font-size: ${theme.fontSizes.m};
              color: ${theme.colors.textLight};
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            `}
          >
            {attendees.length > 1
              ? t('attendeesCount.multiple', { amount: attendees.length })
              : t('attendeesCount.single')}
          </p>
        </div>
      )}
    </div>
  );
};

export default ScheduleHeader;
