import { useMutation } from 'react-query';

import { getAccessToken } from '@modules/auth/hooks/useAuth';
import useEvent from '@modules/event/hooks/useEvent';
import fetch from '@common/utils/fetch';
import transform from '@common/transforms/videoCallParticipant';

export const useParticipateCall = () => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation(async (videoCallId: string) => {
    const { body } = await fetch(`/events/${event?.id}/video-calls/${videoCallId}/participate`, {
      method: 'POST',
      token: accessToken,
    });
    return transform.one(body);
  });
};
