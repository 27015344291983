/** @jsxImportSource @emotion/react */
import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { gsap } from 'gsap';
import { Transition } from 'react-transition-group';

import ReactDOM from 'react-dom';

import useProtected from '@modules/auth/hooks/useProtected';
import { useInitVideoCall } from '@modules/video-call/hooks/useInitVideoCall';
import { closeMenu } from '@common/store/menu';
import { setAnnouncement, setLocation, setPushToPage } from '@common/store/pusher';
import { setFullscreen } from '@common/store/sidebar';
import { closeElementFullScreen } from '@common/store/fullscreen';
import { mediaQuery } from '@common/styles/mediaQuery';
import { useAppDispatch, useAppSelector } from '@common/store';
import useEvent from '@modules/event/hooks/useEvent';
import { useMenu } from '@common/hooks/useMenu';
import { usePage } from '@modules/event/hooks/usePages';
import { CallOverlay } from '@modules/video-call/components/CallOverlay';
import { isEventActive } from '@modules/event/utils';
import Announcement from '@modules/event/components/Announcement';
import AgendaPanel from '@modules/overlays/components/AgendaPanel';
import ErrorPage from '@pages/_error';

import Header from './Header';
import Menu from './Menu';
import SideBarMenu from './SideBarMenu';
import PushToPageModal from './PushToPageModal';

interface Props {
  readonly children: ReactNode;
}

const PageWrapper: FC<Props> = ({ children }) => {
  useProtected();
  useInitVideoCall();

  const navigate = useNavigate();
  const event = useEvent();
  const menuRef = useRef<HTMLDivElement>(null);
  const announcementsRef = useRef(null);
  const theme = useTheme();
  const { livePages, otherPages } = useMenu();

  const { slug } = useParams();
  const { data: page, error } = usePage(slug as string);

  const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);
  const showMenu = useAppSelector((state) => state.menu.showMenu);
  const sideContent = useAppSelector((state) => state.sideoverlay.showOverlay);
  const { announcement, pushToPage, location } = useAppSelector((state) => state.pusher);
  const { isAuthorized, privacyPolicyAccepted, hasRequiredFieldsFilled } = useAppSelector(
    (state) => state.auth,
  );
  const dispatch = useAppDispatch();

  const menuTimeline = useMemo(
    () =>
      menuRef.current &&
      menuRef.current.firstChild &&
      gsap
        .timeline({
          paused: true,
        })

        .fromTo(
          menuRef.current,
          { left: '-100%', top: '-100%', borderBottomRightRadius: '300px' },
          {
            top: '65px',
            left: 0,
            borderBottomRightRadius: '300px',
            duration: 0.7,
            ease: 'circ.out',
          },
          'start',
        )
        .fromTo(
          menuRef.current,
          { borderBottomRightRadius: '300px' },
          {
            borderBottomRightRadius: '0px',
            duration: 0.5,
            ease: 'circ.out',
          },
          'start+=0.5',
        )
        .fromTo(
          menuRef.current.children[0]?.childNodes,
          { opacity: 0, y: -45 },
          { opacity: 1, stagger: 0.2, y: 0, ease: 'power4.inOut' },
          'start+=0.7',
        )
        .fromTo(
          menuRef.current.children[1]?.childNodes,
          { opacity: 0, y: -45 },
          { opacity: 1, stagger: 0.2, y: 0, ease: 'power4.inOut' },
          '-=0.2',
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showMenu], // dependancy required to rerender when needed
  );

  useEffect(() => {
    if (pushToPage?.slug === slug) {
      dispatch(setPushToPage(undefined));
    }
  }, [pushToPage, slug, dispatch]);

  useEffect(() => {
    // Link to pages for specified boothIds
    switch (location?.boothId) {
      case 'LIVE':
        dispatch(setLocation(undefined));
        navigate('/pages/live');
        break;
      case 'BREAKOUT':
        dispatch(setLocation(undefined));
        navigate('/pages/breakout');
        break;
      case 'LIVE2':
        dispatch(setLocation(undefined));
        navigate('/pages/live2');
        break;
      case 'VIDEO':
        dispatch(setLocation(undefined));
        navigate('/pages/video');
        break;
      case 'ATTENDEES':
        dispatch(setLocation(undefined));
        navigate('/pages/attendees');
        break;
      case 'PAGE':
        dispatch(setLocation(undefined));
        navigate('/pages/page');
        break;
      case 'ROOM':
        dispatch(setLocation(undefined));
        navigate('/pages/room');
        break;
      case 'SITE':
        dispatch(setLocation(undefined));
        navigate('/pages/site');
        break;
      case 'BOOTH':
        dispatch(setLocation(undefined));
        navigate('/pages/booth');
        break;
      case 'EVENT':
        dispatch(setLocation(undefined));
        navigate('/pages/event');
        break;
      case 'JOIN':
        dispatch(setLocation(undefined));
        navigate('/pages/join');
        break;
      case 'WATCH':
        dispatch(setLocation(undefined));
        navigate('/pages/watch');
        break;
      case 'VIEW':
        dispatch(setLocation(undefined));
        navigate('/pages/view');
        break;
      case 'MEETING':
        dispatch(setLocation(undefined));
        navigate('/pages/meeting');
        break;
      case 'SESSION':
        dispatch(setLocation(undefined));
        navigate('/pages/session');
        break;

      // opera
      case 'musicsessions_1':
        dispatch(setLocation(undefined));
        navigate('/pages/musicsessions_1');
        break;
      case 'musicsessions_2':
        dispatch(setLocation(undefined));
        navigate('/pages/musicsessions_2');
        break;
      case 'musicsessions_3':
        dispatch(setLocation(undefined));
        navigate('/pages/musicsessions_3');
        break;

      case 'talks_1':
        dispatch(setLocation(undefined));
        navigate('/pages/talks_1');
        break;
      case 'spring_2':
        dispatch(setLocation(undefined));
        navigate('/pages/spring_2');
        break;
      case 'talks_3':
        dispatch(setLocation(undefined));
        navigate('/pages/talks_3');
        break;

      case 'spokenword_1':
        dispatch(setLocation(undefined));
        navigate('/pages/spokenword_1');
        break;
      case 'openstage_2':
        dispatch(setLocation(undefined));
        navigate('/pages/openstage_2');
        break;
      case 'openstage_3':
        dispatch(setLocation(undefined));
        navigate('/pages/openstage_3');
        break;

      case 'sponsors':
        dispatch(setLocation(undefined));
        navigate('/pages/sponsors');
        break;
      case 'mainstage':
        dispatch(setLocation(undefined));
        navigate('/pages/mainstage');
        break;
      case 'livestudio':
        dispatch(setLocation(undefined));
        navigate('/pages/livestudio');
        break;

      case 'night_1':
        dispatch(setLocation(undefined));
        navigate('/pages/night_1');
        break;
      case 'night_2':
        dispatch(setLocation(undefined));
        navigate('/pages/night_2');
        break;
      case 'night_3':
        dispatch(setLocation(undefined));
        navigate('/pages/night_3');
        break;

      default:
        break;
    }

    let timer: any = null;
    timer = setTimeout(() => dispatch(setLocation(undefined)), 10000);
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!isAuthorized || !privacyPolicyAccepted || !hasRequiredFieldsFilled) {
      navigate('/landing');
    }
  }, [isAuthorized, privacyPolicyAccepted, hasRequiredFieldsFilled, navigate]);

  if (!isEventActive(event)) {
    return <ErrorPage statusCode={error?.status || 404} err={error} />;
  }

  if (error) {
    return (
      <>
        {pushToPage && pushToPage?.slug !== slug && (
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 10000;
            `}
          >
            <PushToPageModal
              pushToPage={pushToPage}
              onClick={() => dispatch(setPushToPage(undefined))}
            />
          </div>
        )}
        <ErrorPage statusCode={error?.status || 404} err={error} />
      </>
    );
  }

  return (
    <Main>
      <Header
        ref={menuRef}
        timeline={menuTimeline}
        title={event?.name}
        liveMenu={
          livePages &&
          livePages.length > 0 && (
            <Menu
              live
              items={livePages.map((livePage) => ({
                key: livePage.id,
                label: livePage.title,
                active: livePage.id === page?.id,
              }))}
              onItemClick={(key) => {
                dispatch(setFullscreen(false));
                dispatch(closeMenu());
                navigate(`/pages/${livePages?.find((livePage) => livePage.id === key)?.slug}`);
              }}
            />
          )
        }
        mainMenu={
          otherPages &&
          otherPages.length > 0 && (
            <Menu
              items={otherPages.map((otherPage) => ({
                key: otherPage.id,
                label: otherPage.title,
                active: otherPage.id === page?.id,
              }))}
              onItemClick={(key) => {
                dispatch(setFullscreen(false));
                dispatch(closeMenu());
                navigate(`/pages/${otherPages?.find((otherPage) => otherPage.id === key)?.slug}`);
              }}
            />
          )
        }
      />
      <Content id='contentWrapper'>
        {children}
        <Transition
          appear
          in={!!announcement}
          unmountOnExit
          mountOnEnter
          addEndListener={(node, done) => {
            if (announcement)
              gsap.fromTo(
                node,
                {
                  opacity: 0,
                  x: 200,
                },
                {
                  opacity: 1,
                  duration: 0.8,
                  x: 0,
                  ease: 'elastic.inOut',
                  onComplete: done,
                },
              );
          }}
        >
          <Announcements
            ref={announcementsRef}
            css={css`
              display: ${announcement ? 'block' : 'none'};
              opacity: 0;
              transform: translateX(200px);
            `}
          >
            <Announcement
              announcement={announcement}
              onClick={() =>
                gsap.fromTo(
                  announcementsRef.current,
                  {
                    opacity: 1,
                    x: 0,
                  },
                  {
                    opacity: 0,
                    duration: 0.8,
                    x: 200,
                    ease: 'elastic.inOut',
                    onComplete: () => {
                      dispatch(setAnnouncement(undefined));
                    },
                  },
                )
              }
            />
          </Announcements>
        </Transition>
        <SideBarMenu page={page} />
        <div
          id='popupHolder'
          css={css`
            z-index: 11000;
          `}
        />
        <Transition
          appear
          in={sideContent}
          unmountOnExit
          mountOnEnter
          addEndListener={(node, done) => {
            if (sideContent)
              gsap.fromTo(
                node,
                { width: 0, opacity: 0 },
                {
                  width: window.innerWidth < 576 ? '100%' : 500,
                  opacity: 1,
                  duration: 0.8,
                  ease: 'elastic.inOut',
                  onComplete: done,
                },
              );
            else
              gsap.fromTo(
                node,
                { width: window.innerWidth < 576 ? '100%' : 500, opacity: 1 },
                {
                  width: 0,
                  opacity: 0,
                  duration: 0.8,
                  ease: 'elastic.inOut',
                  onComplete: done,
                },
              );
          }}
        >
          <div
            css={css`
              opacity: 0;
              width: 0;
              height: 100%;
              background: ${theme.colors.eventBackground};
              padding: 1em;
              box-sizing: border-box;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              z-index: 10001;
              left: 0;

              ${mediaQuery(
                'm',
                css`
                  left: unset;
                  box-shadow: -15px 0px 20px ${theme.colors.eventBackground};
                  padding: 2em;
                `,
              )}
            `}
          >
            <AgendaPanel />
          </div>
        </Transition>
      </Content>
      {pushToPage &&
        pushToPage?.slug !== slug &&
        ReactDOM.createPortal(
          <div
            css={css`
              position: absolute;
              z-index: 10000;
              top: 8em;
              right: 4em;
              width: calc(100% - 90px);
              ${mediaQuery(
                'm',
                css`
                  width: calc(100% - 11em);
                  right: 9em;
                `,
              )}
              ${mediaQuery(
                'l',
                css`
                  width: auto;
                `,
              )}
            `}
          >
            <PushToPageModal
              pushToPage={pushToPage}
              onClick={() => {
                dispatch(setPushToPage(undefined));
                if (elementFullScreen) {
                  if (document.exitFullscreen) document.exitFullscreen();
                  else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
                  else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
                  else if (document.msExitFullscreen) document.msExitFullscreen();
                  dispatch(closeElementFullScreen());
                }
              }}
            />
          </div>,
          document.querySelector('#popupHolder') ?? document.body,
        )}

      <CallOverlay />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0;
  overflow: hidden;
`;

const Announcements = styled.div`
  position: absolute;
  z-index: 999999999;
  top: 0;
  right: 4em;
  width: calc(100% - 90px);
  ${mediaQuery(
    'm',
    css`
      width: calc(100% - 11em);
      right: 9em;
    `,
  )}
  ${mediaQuery(
    'l',
    css`
      width: auto;
    `,
  )}
`;

export default PageWrapper;
