/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import PulseLoader from 'react-spinners/PulseLoader';
import { useParams } from 'react-router-dom';

import Button from '@common/components/Button';
import { useAppSelector } from '@common/store';
import { useLogin } from '@modules/auth/hooks/useLogin';
import { useSetBrowserLanguage } from '@modules/user/hooks/useBrowserLanguage';

export default function LoginToken() {
  const { t } = useTranslation();
  const theme = useTheme();
  const login = useLogin();
  const setBrowserLanguage = useSetBrowserLanguage();

  const { isLoading, error, user } = useAppSelector((state) => state.auth);
  const { loginToken } = useParams();

  useEffect(() => {
    login(loginToken as string);
  }, [login, loginToken]);

  useEffect(() => {
    if (!error || user) return;
    setBrowserLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to run this only once on error change
  }, [error]);

  const formattedError = useMemo(() => {
    if (!error) return '';
    if (error === 'vle_error_login_token_already_in_use') {
      return (
        <div
          css={css`
            padding: 10% 20%;
          `}
        >
          <h1
            css={css`
              color: #0040d6;
            `}
          >
            {t('auth.loginTokenAlreadyInUseTitle')}
          </h1>
          <h2
            css={css`
              font-weight: 300;
              color: #fff;
            `}
          >
            {t('auth.loginTokenAlreadyInUseContext')}
          </h2>
          <Button
            css={css`
              margin-top: 4% !important;
              padding: 10px 20px;
              font-weight: small;
              background-color: #14cc96;
              color: #ffffff;
            `}
            onClick={() => window?.location.reload()}
            label={t('auth.loginTokenAlreadyInUseButton')}
          />
        </div>
      );
    }
    if (error === 'vle_error_account_credentials') {
      return (
        <div
          css={css`
            padding: 10% 20%;
          `}
        >
          <h1
            css={css`
              color: #0040d6;
            `}
          >
            {t('auth.loginTokenInvalidTitle')}
          </h1>
          <h2
            css={css`
              font-weight: 300;
              color: #fff;
            `}
          >
            {t('auth.loginTokenInvalidContext')}
          </h2>
        </div>
      );
    }

    return (
      <h1
        css={css`
          color: #fff;
        `}
      >
        {t('vle_error_unknown')}
      </h1>
    );
  }, [t, error]);

  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: #01101f;
      `}
    >
      {error && !isLoading ? formattedError : <PulseLoader color={theme.colors.secondary} />}
    </div>
  );
}
