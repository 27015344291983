/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

export type DividerProps = {
  className?: string;
};

const Divider: React.FC<DividerProps> = ({ className }) => {
  const theme = useTheme();
  return (
    <div
      className={className}
      css={css`
        width: 100%;
        height: 0;
        opacity: 0.1;
        border-bottom: solid 1px ${theme.colors.textLight};
      `}
    />
  );
};

export default Divider;
