import Draggable from 'react-draggable';
import React, { useState } from 'react';

type TrendImageProps = {
  image: string;
  currentIndex: number;
  onStart: (setIndex: React.Dispatch<number>) => void;
};

export function TrendImage({ image, currentIndex, onStart }: TrendImageProps) {
  const [zIndex, setIndex] = useState<number>(currentIndex);

  return (
    <div className='trend-image'>
      {/* @ts-ignore TODO: fix me */}
      <Draggable scale={1.2} onStart={() => onStart(setIndex)}>
        <img draggable='false' alt='' className='image' style={{ zIndex }} src={image} />
      </Draggable>
    </div>
  );
}
