/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { mediaQuery } from '@common/styles/mediaQuery';
import { useSpeaker } from '@modules/schedule/hooks/useSpeaker';
import { useSpeakerSessionSettings } from '@modules/schedule/hooks/useSpeakerSessionSettings';
import {
  getStatus,
  SESSION_STATES,
  useSessionStatus,
} from '@modules/schedule/hooks/useSessionStatus';

import Heading from '@common/components/Heading';
import Body from '@common/components/Body';
import IconButton from '@common/components/IconButton';
import ButtonWithHoverLabel from '@common/components/ButtonWithHoverLabel';
import Icon from '@common/components/Icon';
import TagCloud from '@modules/event/components/TagCloud';

import { useUser } from '@modules/user/hooks/useUsers';
import { CallStatus } from '@common/transforms/user';
import type { SpeakerResource } from '@common/transforms/speaker';
import type { SessionResource } from '@common/transforms/session';
import { CallStatusChip } from '@modules/video-call/components/CallStatusChip';
import { getUserId } from '@modules/auth/hooks/useAuth';
import Pill from '@common/components/Pill';

interface BioModalProps {
  speaker: SpeakerResource;
  onClose?: () => void;
  showCustomFields?: boolean;
  showCallStatus?: boolean;
  onOpenChat?: (participantId: string) => void;
  onCallUser?: (participantId: string) => void;
  onOpenLinkedIn?: () => void;
}

const MandatoryIcon = ({ show, mobile = false }: { show: boolean; mobile?: boolean }) => {
  return (
    <Right $mobile={mobile}>
      <div
        css={css`
          margin-left: ${mobile ? '0.75em' : '0'};
        `}
      >
        {show && (
          <ButtonWithHoverLabel mandatory>
            <IconButton
              disabled
              type='scheduled'
              icon='mandatory'
              hoverIcon='mandatory'
              size={40}
            />
          </ButtonWithHoverLabel>
        )}
        {!show && !mobile && <Spacer width={40} />}
      </div>
    </Right>
  );
};

const SessionItem = ({ session }: { session: NonNullable<SessionResource> }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [sessionStatus, setSessionStatus] = useState<SESSION_STATES>(getStatus(session));

  useSessionStatus(session, setSessionStatus);
  const { duration, buttons } = useSpeakerSessionSettings(session, sessionStatus);

  return (
    <Wrapper key={session.id} sessionStatus={sessionStatus} session={session}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          width: 100%;
          max-width: 100%;
          flex-grow: 0;
          flex-shrink: 0;
          flex-wrap: wrap;

          ${mediaQuery(
            's',
            css`
              flex-wrap: nowrap;
            `,
          )}
        `}
      >
        {!!session.imageUrl?.length && <Banner src={session.imageUrl} alt='banner' />}
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            overflow: hidden;
            padding: 0;
            flex: 1;
            ${!session.imageUrl?.length && 'min-width: 100%;'}

            ${mediaQuery(
              's',
              css`
                min-width: unset;
              `,
            )}
          `}
        >
          <Title>{session.title}</Title>

          {sessionStatus !== SESSION_STATES.PASSED && (
            <MandatoryIcon mobile show={buttons.showMandatory} />
          )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            width: 100%;
            overflow: hidden;
            min-width: 0;

            ${mediaQuery(
              's',
              css`
                margin-left: 2em;
                width: fit-content;
              `,
            )}
          `}
        >
          <Left>
            {sessionStatus !== SESSION_STATES.LIVE ? (
              <Time>
                <Icon icon='clock' color={theme.colors.textLight} size={16} />
                <span
                  css={css`
                    margin-left: 5px;
                  `}
                >
                  {duration.timeslot}
                </span>
              </Time>
            ) : (
              <Pill
                highlighted
                icon='live'
                css={css`
                  margin-top: 1em;

                  ${mediaQuery(
                    's',
                    css`
                      margin-top: 0;
                    `,
                  )}
                `}
              >
                {t('live.now')}
              </Pill>
            )}
          </Left>

          <MandatoryIcon show={buttons.showMandatory && sessionStatus !== SESSION_STATES.PASSED} />
        </div>
      </div>
    </Wrapper>
  );
};

const BioModal: React.FC<BioModalProps> = ({
  speaker,
  onClose,
  showCustomFields = false,
  showCallStatus = false,
  onOpenChat,
  onCallUser,
  onOpenLinkedIn,
}) => {
  const { data: user } = useUser(speaker?.user?.id, true);
  const { data: speakerData } = useSpeaker(speaker?.id);
  const usr = user ?? speaker?.user;
  const userSessions = (usr?.speakers?.find((userSpeaker) => userSpeaker.id === speaker.id)
    ?.sessions ?? []) as SessionResource[];
  const sessions = userSessions.length ? userSessions : speakerData?.sessions ?? [];

  const handleCloseClick = () => {
    onClose?.();
  };

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Header>
          {speaker.imageUrl?.length && (
            <SpeakerBanner src={speaker.imageUrl} alt='speaker banner' />
          )}
          <HeaderTop hasImage={!!speaker.imageUrl?.length}>
            <div
              css={css`
                width: 100%;
                display: flex;
                justify-content: flex-end;
              `}
            >
              <CloseButton size={45} icon='times' onClick={handleCloseClick} />
            </div>
          </HeaderTop>
        </Header>
        <Section hideBorder>
          <TitleDetails>
            <Heading size='h2' color='textLight'>
              {speaker.name || speaker.user?.fullName || speaker.user?.firstName}
            </Heading>
            <Body color='textBody'>
              {speaker.jobTitle || speaker.user?.function} |{' '}
              {speaker.companyName || speaker.user?.function}
            </Body>
          </TitleDetails>
        </Section>

        <Section hideBorder>
          <Body
            css={css`
              color: ${theme.colors.textLight};

              > div {
                margin: 0 11px;
                line-height: 1.2;
              }
            `}
          >
            <div>{speaker.bio || speaker.user?.function}</div>
          </Body>
        </Section>

        {(user?.tags || user?.function) && (
          <Section hideBorder>
            <Heading
              size='h3'
              color='textLight'
              css={css`
                margin: 5px 11px 1em;
              `}
            >
              {t('tags_title')}
            </Heading>
            <div
              css={css`
                margin: 5px 0 22px 11px;
              `}
            >
              {user?.tags ? <TagCloud left tags={user?.tags} /> : user?.function}
            </div>
          </Section>
        )}

        <Section>
          <ButtonRow>
            {(showCustomFields || onOpenChat || onCallUser) && (
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  margin-top: 0.5em;
                  > * {
                    margin-right: 0.5em;
                  }
                `}
              >
                {onOpenChat && !!usr && (
                  <IconButton
                    onClick={() => onOpenChat(usr.id)}
                    icon='chat'
                    size={30}
                    iconProps={{
                      size: 14,
                    }}
                    round
                  />
                )}
                {!!onCallUser && !!usr && usr?.id !== getUserId() && (
                  <IconButton
                    onClick={() => onCallUser(usr.id)}
                    icon='phone'
                    size={30}
                    disabled={!usr.isOnline || usr.callStatus !== CallStatus.AVAILABLE}
                    iconProps={{
                      size: 14,
                    }}
                    round
                  />
                )}
                {usr?.linkedIn && (
                  <IconButton
                    onClick={onOpenLinkedIn}
                    icon='linkedin'
                    size={30}
                    iconProps={{
                      size: 14,
                    }}
                    round
                  />
                )}
              </div>
            )}
            {showCallStatus && !!usr && usr.callStatus !== CallStatus.AVAILABLE && (
              <CallStatusChip
                css={css`
                  margin-left: 1.5em;
                `}
                status={usr?.callStatus as CallStatus}
              />
            )}
          </ButtonRow>
        </Section>

        <Section hideBorder>
          <Heading
            size='h3'
            color='textLight'
            css={css`
              margin: 1em 11px;
            `}
          >
            {t('sessions_title')} ({sessions?.length})
          </Heading>

          {sessions?.map((session) => (
            <SessionItem key={session.id} session={session as SessionResource} />
          ))}
        </Section>
      </Content>
    </Container>
  );
};

export default BioModal;

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 650px;
  overflow: hidden;
  position: absolute;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  background-color: ${(props) => props.theme.colors.eventBackground};
  backdrop-filter: blur(${(props) => props.theme.blurs.normal});

  ${(props) =>
    mediaQuery(
      's',
      css`
        max-height: 80%;
        border-radius: ${props.theme.borderRadius.iconButton};
      `,
    )}
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

const HeaderTop = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  ${(props) =>
    props.hasImage
      ? `
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
  `
      : `
    width: 100%;
    position: relative;
  `}
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const SpeakerBanner = styled.img`
  width: 100%;
  height: 47vh;
  max-height: 320px;
  object-fit: cover;
  object-position: top;
  display: block;
`;

const Banner = styled.img`
  display: block;
  object-fit: cover;
  object-position: top;
  max-height: 320px;
  border-radius: ${(props) => props.theme.borderRadius.normal};
  width: 64px;
  height: 64px;
  flex: none;
  margin-right: 2em;
`;

const CloseButton = styled(IconButton)`
  background-color: rgba(0, 0, 0, 0.3);
  flex-basis: 1;
  flex-shrink: 0;

  margin-right: 20px;
  margin-top: 20px;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
    path {
      stroke: #000;
    }
  }
`;

const TitleDetails = styled.div`
  flex: 1;
  margin-top: 10px;
  margin-left: 11px;

  > h2 {
    margin: 0;
    line-height: 1.19;
  }

  > span {
    margin: 0;
    line-height: 1.5;
    letter-spacing: 0.16px;
  }

  ${mediaQuery(
    'm',
    css`
      margin-top: 20px;
      margin-left: 11px;
    `,
  )}
`;

const Section = styled.div<{ hideBorder?: boolean }>`
  padding: 25px 5px 5px;
  ${(props) =>
    !props.hideBorder &&
    `
        border-bottom: 1px solid ${props.theme.colors.border};
    `}

  ${mediaQuery(
    'm',
    css`
      padding: 10px 15px 15px;
    `,
  )}
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: -30px 0 11px 11px;
`;

const Title = styled.div`
  display: -webkit-box;
  max-height: 115px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: ${(props) => props.theme.fontSizes.m};
  color: ${(props) => props.theme.colors.textLight};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
`;

const Wrapper = styled.div<{ sessionStatus: SESSION_STATES; session: any }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${(props) => props.theme.borderRadius.normal};
  padding: 1em;
  margin-bottom: 1em;
  position: relative;
  backdrop-filter: blur(${(props) => props.theme.blurs.normal});
  background-color: ${(props) =>
    props.session.scheduled && props.sessionStatus !== SESSION_STATES.LIVE
      ? 'rgba(80, 201, 127, 0.16)'
      : 'rgba(255, 255, 255, 0.1)'};
  transition: all 0.2s ease-in-out;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  flex-basis: 1;
  margin-left: 0;

  ${mediaQuery(
    's',
    css`
      width: 125px;
    `,
  )}
`;

const Right = styled.div<{ $mobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${(props) => (props.$mobile ? '0' : '4em')};
  min-width: 0;
  display: ${(props) => (props.$mobile ? 'flex' : 'none')};

  ${(props) =>
    mediaQuery(
      's',
      css`
        display: ${props.$mobile ? 'none' : 'flex'};
        margin-left: 1em;
      `,
    )}
`;

const Time = styled.p`
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  font-size: ${(props) => props.theme.fontSizes.s};
  margin: 1em 0 0;

  ${mediaQuery(
    's',
    css`
      margin: 0;
    `,
  )}
`;

const Spacer = styled.div<{ width: number }>`
  width: ${({ width }): string => `${width}px`};
`;
