import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import TextareaAutosize from 'react-textarea-autosize';

type LongInputProps = {
  bullet?: boolean;
  style: object;
  width: string;
  height: string;
};

export function LongInput({ style, width, height, bullet = false }: LongInputProps) {
  const constantUuid = uuid();
  const [text, setText] = useState<string>('');

  return (
    <div className={bullet ? 'long-input-bullet' : 'long-input'} style={bullet ? { ...style } : {}}>
      <div className='container' style={!bullet ? { ...style } : {}}>
        {bullet ? (
          <>
            <label htmlFor={constantUuid}>◦</label>
            <input
              id={constantUuid}
              spellCheck={false}
              className='input'
              style={{ width, height }}
              onChange={(e) => {
                e.target.setAttribute('value', e.target.value);
              }}
            />
          </>
        ) : (
          <div
            style={{
              height,
              alignItems: 'center',
              display: 'flex',
              paddingBottom: '0.521vw',
              paddingTop: '0.521vw',
            }}
          >
            <TextareaAutosize
              className='input'
              spellCheck={false}
              maxRows={3}
              style={{ width }}
              value={text}
              onChange={(e) => {
                e.target.setAttribute('value', e.target.value);
                setText(e.target.value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
