/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';
import { MouseEvent } from 'react';

type RoundButtonProps = {
  size?: 'm' | 's';
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  stopPropagation?: boolean;
  disabled?: boolean;
};

const RoundButton: React.FC<RoundButtonProps> = ({
  size = 'm',
  children,
  onClick,
  type = 'button',
  stopPropagation = false,
  disabled = false,
}) => {
  const theme = useTheme();
  const nativeOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (stopPropagation) {
      event.stopPropagation();
    }

    if (!disabled) {
      onClick?.();
    }
  };

  return (
    <button
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.15);
        color: ${theme.colors.textLight};
        border-radius: 100%;
        width: ${size === 'm' ? '48px' : '28px'};
        height: ${size === 'm' ? '48px' : '28px'};
        border: 0;
        line-height: 1.5em;
        vertical-align: middle;
        cursor: ${disabled ? 'auto' : 'pointer'};
        opacity: ${disabled ? 0.4 : 1};
        transition: all 0.1s ease-in-out;
        padding: 0;

        &:focus {
          outline: none;
        }
        &:hover {
          ${!disabled && 'background: rgba(255, 255, 255, 0.2);'}
        }
      `}
      type={type}
      onClick={nativeOnClick}
    >
      {children}
    </button>
  );
};

export default RoundButton;
