/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Icon as IconType } from '@typing/icons';

import { DIRECTION } from '@common/utils/consts';

import getCustomIcon from './custom';

export interface IconProps extends IconType {
  icon: string;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ className, icon, ...props }) => {
  const customIcon = getCustomIcon(icon, {
    color: props.color ?? '#fff',
    direction: props.direction ?? DIRECTION.LEFT,
    size: props.size ?? 16,
  });
  if (customIcon !== null) return customIcon;
  return (
    <i
      className={`${className} icon-${icon}`}
      css={css`
        font-size: ${props.size}px;
        color: ${props.color};
      `}
    />
  );
};

export default Icon;
