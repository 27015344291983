/** @jsxImportSource @emotion/react */
import React from 'react';

import { useAppSelector } from '@common/store';

import useEvent from '@modules/event/hooks/useEvent';

import { useVideoCall } from '../hooks/useVideoCall';

import VideoCallActive from './VideoCallActive';
import VideoCallHistory from './VideoCallHistory';

export const VideoCall = () => {
  const call = useAppSelector((state) => state.videoCall.activeCall);
  const event = useEvent();
  const { data: videoCall } = useVideoCall(call?.callId);

  if (!videoCall) {
    return <VideoCallHistory videoCallEnabled={event?.videoCallEnabled!} />;
  }

  return <VideoCallActive />;
};

export default VideoCall;
