export const CloseIcon = (
  <svg
    id='Layer_1'
    width='100%'
    height='100%'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 22.17 22.17'
  >
    <line
      x1='1'
      y1='1'
      x2='21.17'
      y2='21.17'
      fill='none'
      stroke='#fff'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    />
    <line
      x1='21.17'
      y1='1'
      x2='1'
      y2='21.17'
      fill='none'
      stroke='#fff'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2'
    />
  </svg>
);
