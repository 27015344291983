import { Icon } from '@typing/icons';

const VideoCall: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.4289 16.2605C15.4287 16.7195 15.256 17.1618 14.9452 17.4995C14.6344 17.8372 14.2081 18.0463 13.7507 18.0846C12.1552 18.2656 10.5507 18.3548 8.94484 18.353C7.35063 18.3493 5.75783 18.2576 4.17374 18.0782C3.71678 18.0399 3.29096 17.831 2.98088 17.4931C2.67079 17.1553 2.49913 16.7134 2.5 16.2548V7.0939C2.49946 6.63582 2.67113 6.1938 2.98088 5.85631C3.29062 5.51882 3.71587 5.3106 4.17232 5.27194C5.74612 5.09211 7.32885 5.00131 8.91289 5C10.5299 5.00827 12.1453 5.10176 13.7523 5.28047C14.2101 5.31776 14.6369 5.52618 14.9479 5.86412C15.2589 6.20206 15.4311 6.64458 15.4303 7.10384L15.4289 16.2605Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M19.7652 6.61334L16.9077 9.07291V14.2818L19.7652 16.7406C19.9332 16.8528 20.1283 16.9168 20.33 16.9266C20.5318 16.9365 20.7324 16.892 20.9105 16.7967C21.0886 16.7015 21.2375 16.5596 21.3413 16.3863C21.4451 16.213 21.5001 16.0146 21.5 15.8126V7.54278C21.4998 7.34088 21.4449 7.14296 21.3411 6.96978C21.2373 6.7966 21.0885 6.65471 20.9105 6.55938C20.7325 6.46405 20.5319 6.41847 20.3302 6.42802C20.1285 6.43758 19.9334 6.50161 19.7652 6.61334V6.61334Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default VideoCall;
