import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Loader from '@common/components/Loader';

import { Landing } from '@common/components/Containers';
import Onboarding from '@pages/onboarding';

import useEvent from '@modules/event/hooks/useEvent';
import { usePages } from '@modules/event/hooks/usePages';
import { useAppSelector } from '@common/store';

import ErrorPage from './_error';

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthorized, isAccepted, privacyPolicyAccepted, hasRequiredFieldsFilled } =
    useAppSelector((state) => state.auth);
  const event = useEvent();
  const { data: pages, error } = usePages();

  const [isCheckAuthorizedDelayDone, setCheckAuthorizedDelayDone] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCheckAuthorizedDelayDone(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isAuthorized && isAccepted && privacyPolicyAccepted && hasRequiredFieldsFilled && pages) {
      if (event?.initialPageSlug) {
        navigate({ pathname: `/pages/${event.initialPageSlug}`, search: location.search });
      } else {
        const firstSlug = pages.find((page) => page.slug)?.slug;
        if (firstSlug) navigate({ pathname: `/pages/${firstSlug}`, search: location.search });
      }
    }
  }, [
    isAuthorized,
    isAccepted,
    privacyPolicyAccepted,
    hasRequiredFieldsFilled,
    pages,
    event,
    navigate,
    location.search,
  ]);

  if (isAuthorized && !isAccepted && privacyPolicyAccepted) return <p>Not yet accepted</p>;

  if (isCheckAuthorizedDelayDone && !isAuthorized) return <Landing />;

  if (!isCheckAuthorizedDelayDone && !isAuthorized) return <Loader />;

  if (!privacyPolicyAccepted || !hasRequiredFieldsFilled) return <Onboarding />;

  if (pages && !pages.length) return <ErrorPage err={new Error()} />;

  if (error) return <ErrorPage err={error} />;

  return <Loader />;
}
