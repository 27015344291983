import parse, { Attributes } from '@common/utils/parse';
import { transformEnumArray } from '@common/utils/transformArray';

import parseMeta from './meta';
import { parseEvent } from './event';
import { parseAdmin } from './admin';

import { PageTemplate } from './page';

const parseRelations = (attributes: Attributes) => {
  return {
    events: attributes.optional.custom('events', (event) => {
      return parse.many(event, parseEvent);
    }),
    admins: attributes.optional.custom('admins', (admin) => {
      return parse.many(admin, parseAdmin);
    }),
  };
};

export const parseOrganisation = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    name: attributes.string('name'),
    imageUrl: attributes.optional.string('imageUrl'),
    internalName: attributes.optional.string('internalName'),
    contactName: attributes.optional.string('contactName'),
    contactEmail: attributes.optional.string('contactEmail'),
    companyDetails: attributes.optional.string('companyDetails'),
    subscriptionDetails: attributes.optional.string('subscriptionDetails'),
    salesforceNumber: attributes.optional.string('salesforceNumber'),
    eventLimit: attributes.optional.number('eventLimit'),
    activeEventLimit: attributes.optional.number('activeEventLimit'),
    userLimit: attributes.optional.number('userLimit'),
    chatEnabled: attributes.boolean('chatEnabled'),
    privateChatEnabled: attributes.boolean('privateChatEnabled'),
    hostChatEnabled: attributes.boolean('hostChatEnabled'),
    videoCallsEnabled: attributes.boolean('videoCallsEnabled'),
    enabledPageTemplates: attributes.optional.custom(
      'enabledPageTemplates',
      transformEnumArray(PageTemplate),
    ),
    eventCount: attributes.optional.number('eventCount'),
    activeEventCount: attributes.optional.number('activeEventCount'),
    userCount: attributes.optional.number('userCount'),
    registrationEnabled: attributes.optional.boolean('registrationEnabled'),
    eventTemplatesEnabled: attributes.optional.boolean('eventTemplatesEnabled'),
  };
};

export const parseData = (attributes: Attributes) => {
  return {
    ...parseRelations(attributes),
    ...parseOrganisation(attributes),
  };
};

export function one(data: any) {
  return parse.one(data, parseData);
}

export function many(data: any) {
  return {
    items: parse.many(data.items, parseData),
    meta: parseMeta(data.meta),
  };
}

export type OrganisationResource = ReturnType<typeof one>;
export type Organisation = Omit<OrganisationResource, 'id'>;

export default { one, many };
