/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';

import { PageResource } from '@common/transforms/page';
import { useInfiniteSessions } from '@modules/schedule/hooks/useSessions';
import { useDebouncedValue } from '@common/hooks/useDebounce';

import type { ScheduleContext } from '..';

import Base from './base';

interface ScheduleMobileProps {
  page: PageResource;
  context: React.Context<ScheduleContext>;
  dates: {
    count: string;
    date: string;
  }[];
}

const ScheduledMobileView: React.FC<ScheduleMobileProps> = ({ context, page, dates }) => {
  const { query } = useContext(context);
  const debouncedSearchTerm = useDebouncedValue(query, 300);

  const { data, ...sessionProps } = useInfiniteSessions({ limit: 10 }, debouncedSearchTerm, true);

  return (
    <Base context={context} page={page} dates={dates} sessions={data} sessionProps={sessionProps} />
  );
};

const RegularMobileView: React.FC<ScheduleMobileProps> = ({ context, page, dates }) => {
  const { query } = useContext(context);
  const debouncedSearchTerm = useDebouncedValue(query, 300);

  const { data, ...sessionProps } = useInfiniteSessions({ limit: 10 }, debouncedSearchTerm, false);

  return (
    <Base context={context} page={page} dates={dates} sessions={data} sessionProps={sessionProps} />
  );
};

const ScheduleMobile: React.FC<ScheduleMobileProps> = ({ page, context, dates }) => {
  const { scheduled } = useContext(context);

  return scheduled ? (
    <ScheduledMobileView context={context} page={page} dates={dates} />
  ) : (
    <RegularMobileView context={context} page={page} dates={dates} />
  );
};

export default ScheduleMobile;
