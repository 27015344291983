/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { transparentize } from 'polished';

import EventBackground from '@modules/event/components/EventBackground';
import Heading from '@common/components/Heading';
import Body from '@common/components/Body';

import useEvent from '@modules/event/hooks/useEvent';
import { PageResource } from '@common/transforms/page';
import { mediaQuery } from '@common/styles/mediaQuery';

export function BasicTemplate({ page }: { page: PageResource }) {
  const event = useEvent();
  const theme = useTheme();

  return (
    <EventBackground event={event} absolute hasSidebar appearance='dark'>
      <div
        css={css`
          box-sizing: border-box;
          display: flex;
          max-width: 480px;
          flex: 1;
          flex-direction: column;
          padding: 64px 48px;
          border-radius: 16px;
          backdrop-filter: blur(${theme.blurs.normal});
          background-color: ${transparentize(0.2, theme.colors.eventBackground)};
          text-align: center;
          justify-content: center;
          align-items: center;
          margin: 1em;
          color: #fff;
        `}
      >
        <Heading size='h2'>{page.parameters.subtitle}</Heading>
        <Body
          size='m'
          css={css`
            color: ${theme.colors.textLight};

            > div > * {
              margin: 5px 0;
              line-height: 1.2;
            }
          `}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: page.parameters?.description || '' }} />
        </Body>
        {page.parameters?.iFrameUrl && (
          <div
            css={css`
              position: relative;
              overflow: hidden;
              margin-top: 1.83em;
              width: 100%;
              border: 0;
              border-radius: 12px;
              overflow: hidden;
              z-index: 1;
              height: calc((100vw - (48px * 2) - 3em) * 0.5625);

              ${mediaQuery(
                's',
                css`
                  height: calc((480px - (48px * 2)) * 0.5625);
                `,
              )}
            `}
          >
            <iframe
              title={page.title}
              src={page.parameters?.iFrameUrl}
              frameBorder='0'
              allowFullScreen
              width='100%'
              height='100%'
              allow='autoplay; fullscreen'
              css={css`
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
              `}
            />
          </div>
        )}
      </div>
    </EventBackground>
  );
}
