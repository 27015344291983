const theme = {
  colors: {
    text: '#0D0A26',
    textLight: '#FFFFFF',
    textBody: '#B6B6BE',
    border: '#b2b2b2',
    background: '#0D0A26',
    eventBackground: '#0D0A26',
    primary: '#FEDDC6',
    secondary: '#FFFFFF',
    disabled: '#25243A',
    disabledText: '#676575',
    success: '#50c97f',
    error: '#ff3c3c',
    errorText: '#852433',
    hover: {
      success: '#309C5A',
    },
    live: '#ff6464',
    liveBorder: '#D85555',
    unsubscribe: '#ff6464',
    alerts: {
      background: 'rgba(0, 0, 0, 0.5)',
      text: '#fff',
      iconDark: '#000',
      butttonDark: 'rgba(150, 150, 150, 0.4)',
    },
    buttons: {
      default: {
        background: 'rgba(255, 255, 255, 0.1)',
        color: '#FFFFFF',
        backgroundHover: 'rgba(255, 255, 255, 0.3)',
        colorHover: '#FFFFFF',
        backgroundDisabled: 'rgba(255, 255, 255, 0.05)',
        colorDisabled: '#BBBBBB',
        backgroundFocus: 'rgba(255, 255, 255, 0.8)',
        colorFocus: '#000000',
      },
      defaultDark: {
        background: 'rgba(0, 0, 0, 0.3)',
        color: '#FFFFFF',
        backgroundHover: 'rgba(255, 255, 255, 0.6)',
        colorHover: '#000000',
        backgroundDisabled: 'rgba(0, 0, 0, 0.15)',
        colorDisabled: '#BBBBBB',
        backgroundFocus: 'rgba(0, 0, 0, 0.45)',
        colorFocus: '#FFFFFF',
      },
      transparent: {
        background: 'transparent',
        color: '#FFFFFF',
        backgroundHover: 'rgba(255, 255, 255, 0.3)',
        colorHover: '#FFFFFF',
        backgroundDisabled: 'transparent',
        colorDisabled: '#BBBBBB',
        backgroundFocus: 'rgba(255, 255, 255, 0.15)',
        colorFocus: '#FFFFFF',
      },
      transparentDark: {
        background: 'transparent',
        color: '#FFFFFF',
        backgroundHover: 'rgba(255, 255, 255, 0.6)',
        colorHover: '#000000',
        backgroundDisabled: 'transparent',
        colorDisabled: '#BBBBBB',
        backgroundFocus: 'rgba(0, 0, 0, 0.45)',
        colorFocus: '#FFFFFF',
      },
      schedule: {
        background: 'rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
        backgroundHover: 'rgba(255, 255, 255, 0.3)',
        colorHover: '#FFFFFF',
        backgroundDisabled: 'rgba(0, 0, 0, 0.075)',
        colorDisabled: '#BBBBBB',
        backgroundFocus: '#FFFFFF',
        colorFocus: '#000000',
      },
      scheduled: {
        background: '#50c97f',
        color: '#FFFFFF',
        backgroundHover: '#ff6464',
        colorHover: '#FFFFFF',
      },
    },
  },
  fontSizes: {
    h1: '3.5em',
    h2: '2em',
    h3: '1.5em',
    subtitle: '1.1em',
    xs: '0.625em',
    s: '0.875em',
    m: '1em',
    mobile: {
      h1: '2.625em',
      h2: '1.5em',
      h3: '1.125em',
    },
  },
  fonts: {
    sofia: 'SofiaPro, sans-serif',
    brown: 'sans-serif',
  },
  fontWeights: {
    h1: '500',
    h2: '600',
    h3: '600',
    subtitle: '700',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
  paddings: {
    xxs: '0.1875em 0.5em 0.1875em',
    xs: '0.25em 0.75em 0.43em',
    s: '0.42em 1.25em 0.625em',
    m: '0.5em 1.375em 0.68em',
    l: '0.75em 3em 0.84em',
    input: '0.8em 1em 0.9em',
  },
  borderRadius: {
    iconButton: '0.7em',
    normal: '1em',
  },
  deviceWidth: {
    s: 576,
    m: 768,
    l: 992,
    xl: 1200,
  },
  blurs: {
    normal: '30px',
  },
};

export default theme;
