import { Icon } from '@typing/icons';

const Laptop: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 937 937'>
      <g>
        <path
          stroke={color}
          fill={color}
          d='M905.7,753.55H562.2c-3.7,0-6.7,3-6.7,6.701v0.299c0,13.801-11.2,25-25,25h-130c-13.8,0-25-11.199-25-25v-0.299
        c0-3.701-3-6.701-6.7-6.701H25.3c-13.8,0-25,11.201-25,25v39c0,13.801,11.2,25,25,25h880.4c13.8,0,25-11.199,25-25v-39
        C930.7,764.751,919.5,753.55,905.7,753.55z M852.8,798.251c0,6.799-5.5,12.398-12.399,12.398h-53.5c-6.801,0-12.4-5.5-12.4-12.398
        v-0.301c0-6.801,5.5-12.4,12.4-12.4h53.5c6.8,0,12.399,5.5,12.399,12.4V798.251z'
        />
        <path
          stroke={color}
          fill={color}
          d='M849.5,94.45h-762c-48.2,0-87.5,39.3-87.5,87.5v448.1c0,48.201,39.3,87.5,87.5,87.5h762c48.2,0,87.5-39.299,87.5-87.5
        v-448.1C937,133.75,897.7,94.45,849.5,94.45z M862,630.05c0,6.9-5.6,12.5-12.5,12.5h-762c-6.9,0-12.5-5.6-12.5-12.5v-448.1
        c0-6.9,5.6-12.5,12.5-12.5h762c6.9,0,12.5,5.6,12.5,12.5V630.05z'
        />
      </g>
    </svg>
  );
};

export default Laptop;
