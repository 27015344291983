/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import PulseLoader from 'react-spinners/PulseLoader';

type LoaderProps = {
  className?: string;
};

const Loader: React.FC<LoaderProps> = ({ className }) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex: 1;
        width: 100%;
        justify-content: center;
        align-items: center;
      `}
    >
      <PulseLoader color={theme.colors.secondary} />
    </div>
  );
};

export default Loader;
