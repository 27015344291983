import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { SessionResource } from '@common/transforms/session';
import SessionDetails from '@modules/schedule/components/SessionDetail';
import Overlay from '@common/components/Overlay';

import SessionBase from './base';

interface SessionCardProps {
  session: SessionResource;
  displayType: DISPLAY_TYPE;
  overlapping?: boolean;
  isNotified?: boolean;
  inGridView?: boolean;
  scheduled?: boolean;
  selectedDate?: Date;
}

export enum DISPLAY_TYPE {
  normal = 'normal',
  list = 'list',
  detailed = 'detailed',
  highlighted = 'highlighted',
}

const Session: React.FC<SessionCardProps> = ({ session, ...props }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      <SessionBase session={session} openModal={openModal} setOpenModal={setOpenModal} {...props} />
      {ReactDOM.createPortal(
        <Overlay closeModal={() => setOpenModal(false)} openModal={openModal}>
          <SessionDetails session={session} onClose={() => setOpenModal(false)} {...props} />
        </Overlay>,
        document.querySelector('#popupHolder') ?? document.body,
      )}
    </>
  );
};

export default Session;
