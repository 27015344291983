/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AnnouncementResource } from '@common/transforms/announcement';
import { LocationResource } from '@common/transforms/location';

export interface PushToPageResource {
  id: string;
  title: string;
  slug?: string;
  isVideoCall: boolean;
}

export interface PusherState {
  announcement?: AnnouncementResource;
  location?: LocationResource;
  pushToPage?: PushToPageResource;
}

const initialState: PusherState = {};

export const pusherSlice = createSlice({
  name: 'pusher',
  initialState,
  reducers: {
    setAnnouncement: (state, action: PayloadAction<AnnouncementResource | undefined>) => {
      state.announcement = action.payload;
    },
    setLocation: (state, action: PayloadAction<LocationResource | undefined>) => {
      state.location = action.payload;
    },
    setPushToPage: (state, action: PayloadAction<PushToPageResource | undefined>) => {
      state.pushToPage = action.payload;
    },
  },
});

export const { setAnnouncement, setLocation, setPushToPage } = pusherSlice.actions;
export default pusherSlice.reducer;
