/** @jsxImportSource @emotion/react */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import Heading from '@common/components/Heading';
import Button from '@common/components/Button';
import useEvent from '@modules/event/hooks/useEvent';

const RegisterCompleted = () => {
  const { t: translate } = useTranslation();
  const theme = useTheme();
  const event = useEvent();
  const navigate = useNavigate();

  return (
    <div>
      <div
        css={css`
          max-width: 620px;
          margin-top: 2em;
        `}
      >
        <Heading>
          {event?.autoApproval
            ? translate('registration_approved_title')
            : translate('registration_not_approved_title')}
        </Heading>
        <div
          css={css`
            margin-bottom: 3.5em;
            color: ${theme.colors.textLight};

            > div > * {
              margin: 5px 0;
              line-height: 1.2;
            }
          `}
        >
          {event?.registrationCompletedMessage ? (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: event.registrationCompletedMessage }} />
          ) : (
            <Trans
              i18nKey={
                event?.autoApproval
                  ? 'registration_approved_description'
                  : 'registration_not_approved_description'
              }
              values={{ organiser: event?.organiser, organiserContact: event?.organiserContact }}
              components={[
                <></>,
                <a
                  key='link'
                  href={`mailto:${event?.organiserContact}`}
                  css={css`
                    color: ${theme.colors.primary};
                  `}
                >
                  event?.organiser
                </a>,
              ]}
            />
          )}
        </div>

        <Button
          css={css`
            margin-top: 2em;
          `}
          label='Ok'
          onClick={() => navigate('/landing')}
        />
      </div>
    </div>
  );
};

export default RegisterCompleted;
