/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */

import { APIParseError } from './error';
import { attribute, optional, compact, safely } from './utils';

function transformId(value: any) {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return String(value);

  throw new APIParseError(`Invalid id value: ${value}`);
}

function transformString(value: any) {
  if (typeof value === 'string') return value;
  throw new APIParseError(`Invalid string value: ${value}`);
}

function transformNumber(value: any) {
  if (typeof value === 'number') return value;
  throw new APIParseError(`Invalid number value: ${value}`);
}

function transformDate(value: any) {
  if (typeof value === 'string') {
    const date = new Date(value);
    const parsed = Date.parse(value);

    if (Number.isNaN(parsed) || parsed <= 0) {
      throw new APIParseError(`Failed to parse date value of: ${value}`);
    }

    try {
      return date.toISOString();
    } catch (error) {
      throw new APIParseError(`Failed to serialize date with exception: ${error}`);
    }
  }

  throw new APIParseError(`Invalid date value: ${value}`);
}

function transformBoolean(value: any) {
  if (typeof value === 'boolean') return value;
  throw new APIParseError(`Invalid boolean value: ${value}`);
}

function transformEnum<Options extends Record<string, string>>(
  value: any,
  options: Options,
): keyof Options {
  const keys = Object.keys(options);
  const idx = keys.findIndex((key) => key in options && options[key] === value);

  if (idx !== -1) {
    return keys[idx] as keyof Options;
  }

  throw new APIParseError(`Unavailable value: ${value}`);
}

function transformObject<TransformFn extends (v: any) => any>(
  value: any,
  transformFn?: TransformFn,
): ReturnType<TransformFn> {
  if (typeof value === 'object') {
    if (transformFn) {
      return compact(transformFn(value));
    }

    return compact(value);
  }

  throw new APIParseError(`Invalid object value: ${value}`);
}

function transformCustom<TransformFn extends (v: any) => any>(
  value: any,
  transformFn: TransformFn,
): ReturnType<TransformFn> {
  return transformFn(value);
}

export function createTransformers(object: any = {}) {
  const transformers = {
    id: (name: any) => transformId(attribute(object, name)),
    string: (name: any) => transformString(attribute(object, name)),
    number: (name: any) => transformNumber(attribute(object, name)),
    boolean: (name: any) => transformBoolean(attribute(object, name)),
    date: (name: any) => transformDate(attribute(object, name)),
    enum: <Options extends Record<string, string>>(name: any, options: any) => {
      return transformEnum<Options>(attribute(object, name), options);
    },
    object: <TransformFn extends (name: any, transformFn?: TransformFn) => any>(
      name: any,
      transformFn?: TransformFn,
    ): ReturnType<TransformFn> => {
      return transformObject(attribute(object, name), transformFn);
    },
    custom: <TransformFn extends (name: any, transformFn?: TransformFn) => any>(
      name: any,
      transformFn: TransformFn,
    ): ReturnType<TransformFn> => {
      return transformCustom(attribute(object, name), transformFn);
    },
  };

  return {
    ...transformers,
    optional: {
      id: optional(transformers.id),
      string: optional(transformers.string),
      number: optional(transformers.number),
      date: optional(transformers.date),
      boolean: optional(transformers.boolean),
      enum: <Options extends Record<string, string>>(
        name: any,
        options: any,
      ): keyof Options | null | undefined => optional(transformers.enum)(name, options),
      object: <TransformFn extends (obj: any) => any>(
        name: string,
        transformFn?: TransformFn,
      ): ReturnType<TransformFn> | undefined => {
        return safely(() => transformers.object(name, transformFn));
      },
      custom: <TransformFn extends (obj: any) => any>(
        name: string,
        transformFn: TransformFn,
      ): ReturnType<TransformFn> | undefined => {
        return safely(() => transformers.custom(name, transformFn));
      },
    },
  };
}
