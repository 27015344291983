/** @jsxImportSource @emotion/react */
import { useRef, useCallback } from 'react';
import { css, useTheme } from '@emotion/react';
import copy from 'copy-to-clipboard';

import Copy from './Icon/custom/Copy';
import RoundButton from './RoundButton';

interface Props {
  readonly url: string;
}

export const UrlCopyButton = ({ url }: Props) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const copyUrl = useCallback(() => {
    inputRef.current?.focus();
    inputRef.current?.select();
    copy(url);
  }, [url, inputRef]);

  return (
    <div
      css={css`
        margin-top: auto;
        background: rgba(0, 0, 0, 0.3);
        padding: 0.6em 1em;
        border-radius: 0.5em;
        display: flex;
        flex-direction: row;
      `}
    >
      <input
        ref={inputRef}
        css={css`
          flex: 1;
          background-color: transparent;
          border: 0 none;
          box-shadow: none;
          color: ${theme.colors.textLight};
          margin-right: 1em;

          &:focus {
            outline: none;
          }
        `}
        value={url}
        readOnly
      />
      <RoundButton size='s' onClick={copyUrl}>
        <Copy color={theme.colors.textLight} size={12} />
      </RoundButton>
    </div>
  );
};
