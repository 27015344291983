/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';

import { css } from '@emotion/react';
import qs from 'qs';

import Container, { Orientation } from '@common/components/Container';
import IFrame from '@common/components/IFrame';
import BoothCard from '@modules/event/components/BoothCard';

import useEvent from '@modules/event/hooks/useEvent';
import { useLatestLocation } from '@modules/event/hooks/useLocation';

import { PageResource } from '@common/transforms/page';

import { useAppDispatch, useAppSelector } from '@common/store';
import { useSessionId } from '@modules/auth/hooks/useSessionId';
import { useStartChat } from '@modules/chat/hooks/useStartChat';
import { setLocation } from '@common/store/pusher';
import { closeOverlay } from '@common/store/sideOverlay';
import { useStartPrivateCall } from '@modules/video-call/hooks/useStartPrivateCall';
import { setOutgoingCall } from '@modules/video-call/store/videoCall';

export function VirtualVenueTemplate({ page }: { page: PageResource }) {
  const event = useEvent();
  const sessionId = useSessionId();
  const user = useAppSelector((state) => state.auth.user);
  const location = useAppSelector((state) => state.pusher.location);
  const latestLocation = useLatestLocation();
  const startChat = useStartChat();
  const dispatch = useAppDispatch();

  const onOpenChat = (userId: string) => {
    startChat(userId);
    dispatch(setLocation(undefined));
  };

  const { mutateAsync: startPrivateCall } = useStartPrivateCall();

  const call = useCallback(
    async (userId) => {
      const participant = await startPrivateCall(userId);
      dispatch(setOutgoingCall({ callId: participant.videoCallId, participantId: participant.id }));
      dispatch(closeOverlay());
      dispatch(setLocation(undefined));
    },
    [startPrivateCall, dispatch],
  );

  const createVenueUrl = () => {
    const venueUrl = page.parameters?.venueUrl;

    if (venueUrl === undefined) {
      return '';
    }

    const fetchLatestLocation =
      latestLocation && !venueUrl.includes('media-index')
        ? {
            'media-index': latestLocation,
            venueLocation: latestLocation,
          }
        : {};

    const options = qs.stringify({
      ...fetchLatestLocation,
      userToken: user?.id,
      eventId: event?.id,
      sessionId,
    });

    return `${venueUrl}${venueUrl?.match(/\?./) ? '&' : '?'}${options}`;
  };

  return (
    <Container orientation={Orientation.HORIZONTAL} scrollable={false}>
      <IFrame url={createVenueUrl()} title={page.title} extendLoader={1500} />
      {location && (
        <div
          css={css`
            position: absolute;
            right: 1.5em;
            bottom: 1.5em;
            width: 300px;
          `}
        >
          <BoothCard
            location={location}
            onOpenChat={event?.privateChatEnabled ? onOpenChat : undefined}
            onCallUser={event?.videoCallEnabled ? call : undefined}
          />
        </div>
      )}
    </Container>
  );
}
