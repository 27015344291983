/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Icon } from '@typing/icons';

const Minimize: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 300 300'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M225 37.5H75C54.2893 37.5 37.5 54.2893 37.5 75V225C37.5 245.711 54.2893 262.5 75 262.5H225C245.711 262.5 262.5 245.711 262.5 225V75C262.5 54.2893 245.711 37.5 225 37.5Z'
        stroke={color}
        strokeWidth='12.5'
      />
      <path
        d='M213.541 86.4584L161.459 138.541'
        stroke={color}
        strokeWidth='12.5'
        css={css`
          fill: transparent !important;
        `}
      />
      <path
        d='M213.541 138.541H161.459V86.4584'
        stroke={color}
        strokeWidth='12.5'
        css={css`
          fill: transparent !important;
        `}
      />
      <path
        d='M82.8125 217.188L129.688 170.312'
        stroke={color}
        strokeWidth='12.5'
        css={css`
          fill: transparent !important;
        `}
      />
      <path
        d='M82.8125 170.312H129.688V217.188'
        stroke={color}
        strokeWidth='12.5'
        css={css`
          fill: transparent !important;
        `}
      />
    </svg>
  );
};

export default Minimize;
