/** @jsxImportSource @emotion/react */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { SessionResource } from '@common/transforms/session';
import SessionCard, { DISPLAY_TYPE } from '@modules/schedule/components/Session';
import Carousel from '@common/components/Carousel';
import Heading from '@common/components/Heading';
import Loader from '@common/components/Loader';

interface ExploreViewProps {
  sessionProps?: any;
  sessions?: SessionResource[];
  useInfiniteLoading?: boolean;
}

const ExploreView: React.FC<ExploreViewProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [sentryRef] = useInfiniteScroll({
    loading: props.sessionProps?.isFetchingNextPage || props.sessionProps?.isLoading,
    hasNextPage: !!props.sessionProps?.hasNextPage,
    onLoadMore: props.sessionProps?.fetchNextPage,
    disabled: props.sessionProps?.isError,
    rootMargin: '0px 0px 400px 0px',
  });

  const liveSessions = props.sessions?.filter?.((v) => v?.live);
  const highlightedSessions = props.sessions?.filter?.((v) => v?.highlighted);

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      {!!liveSessions?.length && (
        <div
          css={css`
            padding-bottom: 3em;
            margin-bottom: 3em;
            border-bottom: 1px solid ${theme.colors.border};
          `}
        >
          <Heading size='h2' color='textLight'>
            {t('live_sessions')}
          </Heading>
          <Carousel
            itemWidth={380}
            items={liveSessions}
            renderItem={(session) => (
              <SessionCard displayType={DISPLAY_TYPE.detailed} session={session} />
            )}
            showRightIndicator={liveSessions?.length > 2}
          />
        </div>
      )}

      {!!highlightedSessions?.length && (
        <Highlighted>
          <Heading size='h2' color='textLight'>
            {t('highlighted_sessions')}
          </Heading>

          {props.useInfiniteLoading ? (
            <Carousel
              itemWidth={290}
              items={highlightedSessions ?? []}
              renderItem={(session) => (
                <SessionCard displayType={DISPLAY_TYPE.highlighted} session={session} />
              )}
              showRightIndicator={highlightedSessions ? highlightedSessions.length > 2 : false}
              fetchNextPage={props.sessionProps?.fetchNextPage}
              isFetching={props.sessionProps?.isFetchingNextPage}
            />
          ) : (
            <Carousel
              itemWidth={290}
              items={highlightedSessions ?? []}
              renderItem={(session) => (
                <SessionCard displayType={DISPLAY_TYPE.highlighted} session={session} />
              )}
              showRightIndicator={highlightedSessions ? highlightedSessions.length > 2 : false}
            />
          )}
        </Highlighted>
      )}
      <div>
        <Heading size='h2' color='textLight'>
          {t('future_sessions')}
        </Heading>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -1em;
            margin-top: -1em;
          `}
        >
          {props.sessions?.length ? (
            <>
              {props.sessions.map((session) => (
                <div
                  css={css`
                    width: 380px;
                    margin: 1em;
                  `}
                  id={session.id}
                  key={session.id}
                >
                  <SessionCard displayType={DISPLAY_TYPE.detailed} session={session} />
                </div>
              ))}
              {props.useInfiniteLoading && (
                <div
                  css={css`
                    margin-top: 4em;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                  `}
                >
                  {props.sessionProps?.isFetchingNextPage ? (
                    <Loader />
                  ) : props.sessionProps?.hasNextPage ? (
                    <div ref={sentryRef} />
                  ) : null}
                </div>
              )}
            </>
          ) : (
            <p
              css={css`
                margin: 0;
                padding: 0;
                margin-left: 1em;
                margin-top: 0.5em;
                color: ${theme.colors.textBody};
              `}
            >
              {t('no_sessions_found')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExploreView;

const Highlighted = styled.div`
  padding-bottom: 3em;
  margin-bottom: 3em;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;
