/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import AnnouncementCard from '@modules/event/components/AnnouncementCard';
import { useLastAnnouncement } from '@modules/event/hooks/useAnnouncements';
import { AnnouncementResource } from '@common/transforms/announcement';
import { mediaQuery } from '@common/styles/mediaQuery';

type AnnouncementProps = {
  announcement?: AnnouncementResource;
  onClick: () => void;
};

const Announcement: React.FC<AnnouncementProps> = ({ announcement, onClick }) => {
  const { data: lastAnnouncement } = useLastAnnouncement();

  if (
    (!announcement && !lastAnnouncement) ||
    (typeof lastAnnouncement === 'object' && Object.entries(lastAnnouncement).length === 0)
  )
    return null;
  return (
    <>
      <div>
        <AnnouncementCard
          announcement={announcement || lastAnnouncement!}
          onClick={onClick}
          css={css`
            margin-top: 0.5em;
            width: 100%;
            transition: all 0.2s;
            cursor: pointer;

            :hover {
              transform: scale(0.95);
            }

            :active {
              transform: scale(1);
            }

            ${mediaQuery(
              'l',
              css`
                max-width: 300px;
              `,
            )}
          `}
        />
      </div>
    </>
  );
};

export default Announcement;
