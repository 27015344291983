/** @jsxImportSource @emotion/react */
import React from 'react';

import EditProfile from '@modules/user/components/EditProfile';

import useProtected from '@modules/auth/hooks/useProtected';
import { useAppSelector } from '@common/store';
import { useUpdateUser } from '@modules/auth/hooks/useUpdateUser';

export default function Profile() {
  useProtected();
  const { updateUser, uploadAvatar } = useUpdateUser();
  const user = useAppSelector((state) => state.auth.user);

  return (
    <EditProfile
      user={user}
      onSubmit={(values) => updateUser(values)}
      onUploadAvatar={(file) => file && uploadAvatar(file)}
    />
  );
}
