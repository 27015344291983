export const PrintIcon = (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 31.42 28.95'
    height='100%'
  >
    <path
      d='M7.92,21.47H3.78A2.77,2.77,0,0,1,1,18.69V3.78A2.77,2.77,0,0,1,3.78,1H27.64a2.77,2.77,0,0,1,2.78,2.78V18.69a2.77,2.77,0,0,1-2.78,2.78h-5.2'
      fill='none'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <rect
      x='8.34'
      y='12.29'
      width='14.06'
      height='15.66'
      strokeWidth='2'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <line
      x1='22.98'
      y1='7.02'
      x2='25.32'
      y2='7.02'
      fill='none'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <line
      x1='12.51'
      y1='17.13'
      x2='18'
      y2='17.13'
      fill='none'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <line
      x1='12.51'
      y1='20.75'
      x2='15.76'
      y2='20.75'
      fill='none'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
  </svg>
);
