/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import Avatar from '@modules/user/components/Avatar';
import { mediaQuery } from '@common/styles/mediaQuery';
import { UserResource } from '@common/transforms/user';
import Icon from '@common/components/Icon';

type CallProps = {
  from: Partial<UserResource>;
  isConnected?: boolean;
};

const Call: React.FC<CallProps> = ({ from, isConnected = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [connected, setConnected] = useState(isConnected);

  const handleAccept = () => {
    // TODO: handle implementation
  };

  const handleDecline = () => {
    // TODO: handle implementation
  };

  const handleFullScreen = () => {
    // TODO: handle implementation
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        float: left;
        width: auto;
        background-color: ${theme.colors.alerts.background};
        backdrop-filter: blur(${theme.blurs.normal});
        border-radius: ${theme.borderRadius.iconButton};
        overflow: hidden;
        padding: 5px 10px;
      `}
    >
      <Avatar
        user={from}
        css={css`
          width: 40px;
          height: 40px;

          ${mediaQuery(
            's',
            css`
              width: 64px;
              height: 64px;
            `,
          )}
        `}
      />
      <div
        css={css`
          margin-left: 10px;
          margin-right: 10px;
          overflow: hidden;

          ${mediaQuery(
            's',
            css`
              margin-left: 20px;
              margin-right: 20px;
            `,
          )}
        `}
      >
        <p
          css={css`
            margin: 0;
            color: ${theme.colors.alerts.text};
            font-size: ${theme.fontSizes.s};
            font-weight: ${theme.fontWeights.bold};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;

            ${mediaQuery(
              's',
              css`
                font-size: ${theme.fontSizes.m};
              `,
            )}
          `}
        >
          {from.fullName}
        </p>
        <p
          css={css`
            margin: 0;
            margin-top: 5px;
            color: ${theme.colors.alerts.text};
            font-size: ${theme.fontSizes.xs};

            ${mediaQuery(
              's',
              css`
                font-size: ${theme.fontSizes.m};
              `,
            )}
          `}
        >
          {t(connected ? 'inCall' : 'isCalling')}
        </p>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;

          > button {
            width: 30px;
            height: 30px;
            margin-left: 5px;
            border: 0;
            padding: 0;
            border-radius: ${theme.borderRadius.iconButton};
            transition: 0.2s all ease-in-out;
            &:hover {
              filter: brightness(85%);
            }

            ${mediaQuery(
              's',
              css`
                width: 48px;
                height: 48px;
                margin-left: 10px;
                border-radius: ${theme.borderRadius.normal};
              `,
            )}
          }
        `}
      >
        <button
          onClick={connected ? handleFullScreen : handleAccept}
          css={css`
            background-color: ${connected ? theme.colors.alerts.butttonDark : theme.colors.success};
            cursor: pointer;

            svg {
              width: 18px;
              height: 18px;
            }

            ${mediaQuery(
              's',
              css`
                svg {
                  width: 26px;
                  height: 26px;
                }
              `,
            )}
          `}
        >
          <Icon
            icon={connected ? 'fullscreen' : 'phone'}
            color={theme.colors.alerts.text}
            size={26}
          />
        </button>
        <button
          onClick={handleDecline}
          css={css`
            background-color: ${theme.colors.error};
            cursor: pointer;

            svg {
              width: 18px;
              height: 18px;
            }

            ${mediaQuery(
              's',
              css`
                svg {
                  width: 26px;
                  height: 26px;
                }
              `,
            )}
          `}
        >
          <Icon icon='decline-call' color={theme.colors.alerts.text} size={26} />
        </button>
      </div>
    </div>
  );
};

export default Call;
