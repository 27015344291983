import { css, useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';

import useResizeObserver from 'use-resize-observer';

import Loader from '@common/components/Loader';
import { useAppDispatch, useAppSelector } from '@common/store';
import { closeMobileFullScreen, openMobileFullScreen } from '@common/store/fullscreen';

import Icon from './Icon';

type IFrameProps = {
  className?: string;
  title?: string;
  url: string;
  extendLoader?: number;
};

const IFrame: React.FC<IFrameProps> = ({ className, title, url, extendLoader = 0 }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const sidebarItem = useAppSelector((state) => state.sidebar.activeItem);

  const [showLoader, setShowLoader] = useState<boolean>(true);

  const {
    ref: container,
    width: containerWidth = 0,
    height: containerHeight = 0,
  } = useResizeObserver<HTMLDivElement>();

  const containerRatio = containerWidth / containerHeight;
  const iframeRatio = 16 / 9;
  const height = containerRatio < iframeRatio ? containerWidth / iframeRatio : containerHeight;

  useEffect(() => {
    function handelResize() {
      if (window.matchMedia('(orientation:portrait)').matches) {
        dispatch(closeMobileFullScreen());
      }
    }
    window.addEventListener('resize', handelResize);
    return () => {
      dispatch(closeMobileFullScreen());
      window.removeEventListener('resize', handelResize);
    };
  }, [dispatch]);

  return (
    <div
      ref={container}
      css={css`
        position: relative;
        width: 100%;
        flex: 1;
        height: 100%;
      `}
    >
      {showLoader && (
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
          `}
        >
          <Loader />
        </div>
      )}
      <div
        css={css`
          position: relative;
          ${mobileFullscreen && sidebarItem ? `width: 60%;` : `width: 100%;`}
          background: black;
          transition: all 0.1s ease;
          ${mobileFullscreen ? 'height: 100%;' : `border-radius: 12px; height: ${height}px;`}
          overflow: hidden;
          display: flex;
        `}
      >
        <div
          css={css`
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            margin: 5px;
            padding: 5px;
            display: none;
            background: rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(${theme.blurs.normal});
            border-radius: 12px;
            cursor: pointer;

            @media (min-aspect-ratio: 8/7) and (max-width: 900px) {
              display: block;
            }
          `}
          onClick={() => {
            if (mobileFullscreen) {
              dispatch(closeMobileFullScreen());
            } else {
              dispatch(openMobileFullScreen());
            }
          }}
        >
          <Icon icon={mobileFullscreen ? 'times' : 'fullscreen'} color='#fff' size={35} />
        </div>
        <iframe
          className={className}
          onLoad={() =>
            setTimeout(() => {
              setShowLoader(false);
            }, extendLoader)
          }
          css={css`
            border: 0;
            ${mobileFullscreen ? '' : 'border-radius: 12px;'}
            width: 100%;
            height: 100%;
            background-color: ${theme.colors.primary}50;
          `}
          allow='autoplay; fullscreen; camera *;microphone *'
          src={url}
          title={title}
        />
      </div>
    </div>
  );
};

export default IFrame;
