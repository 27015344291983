import parse, { Attributes } from '@common/utils/parse';

import { parseBreakoutRoom } from './breakoutRoom';
import { parseVideoCall } from './videoCall';
import { parseVideo } from './video';

export const parseCategory = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    title: attributes.string('title'),
    rooms: attributes.custom('rooms', (rooms) => {
      return parse.many(rooms, parseBreakoutRoom);
    }),
    videos: attributes.custom('videos', (videos) => {
      return parse.many(videos, parseVideo);
    }),
    videoCalls: attributes.custom('videoCalls', (videoCalls) => {
      return parse.many(videoCalls, parseVideoCall);
    }),
    pageId: attributes.optional.id('pageId'),
    position: attributes.optional.number('position'),
  };
};

export function one(data: any) {
  return parse.one(data, parseCategory);
}

export function many(data: any) {
  return parse.many(data, parseCategory);
}

export type PageItemCategoryResource = ReturnType<typeof one>;
export type PageItemCategory = Omit<PageItemCategoryResource, 'id'>;

export default { one, many };
