/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import stc from 'string-to-color';
import { readableColor } from 'polished';

import { UserResource } from '@common/transforms/user';
import { SpeakerResource } from '@common/transforms/speaker';
import { SessionResource } from '@common/transforms/session';

import Icon from '@common/components/Icon';
import { renderLocationIcon } from '@modules/event/utils';
import useEvent from '@modules/event/hooks/useEvent';

type AvatarProps = {
  user?: Partial<UserResource>;
  className?: string;
  size?: number;
  emptyBackground?: boolean;
  speaker?: SpeakerResource;
  session?: Partial<SessionResource>;
  showLocation?: boolean;
};

const Avatar: React.FC<AvatarProps> = ({
  user,
  speaker,
  session,
  className,
  size = 64,
  emptyBackground = false,
  showLocation = false,
}) => {
  const event = useEvent();

  const padding = emptyBackground ? size / 2 : 0;
  const initialColor = stc(user?.id?.charAt(0)?.toUpperCase() || 'random');

  const getBackground = () => {
    if (speaker?.imageUrl) return `url("${speaker.imageUrl}") no-repeat center / cover`;
    if (user?.avatarUrl && !speaker?.imageUrl)
      return `url("${user.avatarUrl}") no-repeat center / cover`;
    if (session?.imageUrl) return `url("${session.imageUrl}") no-repeat center / cover`;
    return initialColor;
  };

  const getInitials = () => {
    if (user?.avatarUrl) return null;

    if (speaker?.imageUrl) return null;

    if (session?.imageUrl) return null;

    let first;
    let last;
    let sessionTitle;

    if (speaker?.name) {
      first = speaker?.name.split(' ')[0].charAt(0).toUpperCase();
      last = speaker?.name.split(' ')[1]?.charAt(0).toUpperCase();
    }

    if (!speaker?.name && user?.firstName && user?.lastName) {
      first = user?.firstName.charAt(0).toUpperCase();
      last = user?.lastName.charAt(0).toUpperCase();
    }

    if (session?.title) {
      sessionTitle = session?.title.split(' ')[0].charAt(0).toUpperCase();
    }

    return (
      <div style={{ color: readableColor(initialColor) }}>
        {first}
        {last}
        {sessionTitle}
      </div>
    );
  };

  const calculateFontSize = () => {
    if (size >= 80) {
      return '3.2em';
    }
    if (size >= 40) {
      return '1.3em';
    }
    return '1em';
  };

  return (
    <div
      className={className}
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: ${size}px;
        height: ${size}px;
        background: ${getBackground()};
        border-radius: 100%;
        flex-shrink: 0;
        font-size: ${calculateFontSize()};
        cursor: default;
      `}
    >
      {user || speaker || session ? (
        getInitials()
      ) : (
        <Icon icon='no-pic' color='#fff' size={size - padding} />
      )}
      {event?.attendanceEnabled && showLocation && user?.attendance && (
        <div
          css={css`
            position: absolute;
            top: -10px;
            right: -10px;
            background: rgba(50, 50, 50, 0.3);
            width: 25px;
            height: 25px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Icon size={12} icon={renderLocationIcon(user.attendance)} />
        </div>
      )}
    </div>
  );
};

export default Avatar;
