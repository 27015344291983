import { useMemo } from 'react';

import Conversation from '@modules/chat/components/ChatConversation';
import { GroupChatHeader, PrivateChatHeader } from '@modules/chat/components/ChatHeader';
import List from '@modules/chat/components/ChatList';

import { useRoles, useUsers } from '@modules/user/hooks/useUsers';
import useCurrentEvent from '@modules/event/hooks/useEvent';
import {
  useChatRooms,
  useChatRoom,
  useChatRoomMessages,
  useSendChat,
  useGlobalChat,
  useSendGlobalChat,
} from '@modules/chat/hooks/useChat';

import { useOpenChat } from '@modules/chat/hooks/useOpenChat';
import { useCloseChat } from '@modules/chat/hooks/useCloseChat';

import {
  ChatTabTypes,
  setChatNotificationRoomId,
  setShowPrivateChatNotification,
} from '@modules/chat/store/chat';
import { UserRolePermission } from '@common/transforms/userRole';
import { useAppDispatch, useAppSelector } from '@common/store';

export const GroupChat = () => {
  const event = useCurrentEvent();
  const user = useAppSelector((state) => state.auth.user);
  const { data: globalChatMessages } = useGlobalChat();
  const { mutate: sendGlobalChat } = useSendGlobalChat();

  return (
    <>
      <GroupChatHeader />
      {!event?.chatEnabled ||
      !user?.role?.permissions.includes(UserRolePermission.SEND_GROUP_CHAT) ? null : (
        <Conversation
          messages={globalChatMessages}
          user={user}
          privateChatEnabled={event?.privateChatEnabled}
          onSubmit={(message) => sendGlobalChat(message)}
        />
      )}
    </>
  );
};

export const PrivateChat = () => {
  const event = useCurrentEvent();
  const user = useAppSelector((state) => state.auth.user);
  const { selectedRoom, selectedTab, chatNotificationRoomId } = useAppSelector(
    (state) => state.chat,
  );
  const { data: chatRooms } = useChatRooms();
  const { data: chatRoom } = useChatRoom(selectedRoom);
  const { data: chatRoomMessages } = useChatRoomMessages(selectedRoom);
  const { mutate: sendChat } = useSendChat(selectedRoom);
  const openChat = useOpenChat();
  const closeChat = useCloseChat();
  const dispatch = useAppDispatch();
  const { data: roles } = useRoles();
  const { data: rawUsers } = useUsers(
    '',
    [],
    roles
      ?.filter((role) => role.permissions.includes(UserRolePermission.HOST_CHAT))
      .map((role) => role.id) ?? [],
    false,
    false,
  );
  const eventHost = useMemo(() => rawUsers?.pages[0]?.items[0]?.id, [rawUsers]);

  const rooms = useMemo(() => {
    const all = chatRooms?.filter((room) => room.messages && room.messages.length > 0);
    if (
      !event?.privateChatEnabled &&
      !user?.role?.permissions.includes(UserRolePermission.HOST_CHAT)
    ) {
      const hostRoom = all?.find((room) =>
        room.participants.some((participant) => participant.id === eventHost),
      );
      return hostRoom ? [hostRoom] : [];
    }
    return all;
  }, [chatRooms, eventHost, user?.role, event?.privateChatEnabled]);

  const onOpenRoom = (roomId: string) => {
    openChat(roomId);
    if (roomId === chatNotificationRoomId) {
      dispatch(setShowPrivateChatNotification(false));
      dispatch(setChatNotificationRoomId(''));
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case ChatTabTypes.ROOMS:
        if (
          !event?.hostChatEnabled &&
          (!event?.privateChatEnabled ||
            !user?.role?.permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT))
        )
          return null;
        return (
          <List
            rooms={rooms}
            chatNotificationRoomId={chatNotificationRoomId}
            onOpenRoom={(roomId) => onOpenRoom(roomId)}
            host={eventHost}
            canOnlyChatHost={
              (!event.privateChatEnabled && event.hostChatEnabled) ||
              (!user?.role?.permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT) &&
                event.hostChatEnabled)
            }
          />
        );
      case ChatTabTypes.ROOM:
        if (
          !event?.hostChatEnabled &&
          (!event?.privateChatEnabled ||
            !user?.role?.permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT))
        )
          return null;
        return (
          <Conversation
            messages={chatRoomMessages}
            user={user}
            onSubmit={(message) => sendChat(message)}
            linksEnabled
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <PrivateChatHeader chatRoom={chatRoom} chatRooms={rooms} onCloseRoom={closeChat} />
      {renderContent()}
    </>
  );
};
