import React, { useEffect } from 'react';
import { Player, ControlBar } from 'video-react';
import Hls from 'hls.js';
import 'video-react/dist/video-react.css';

type HSLPlayerProps = {
  src: string | undefined;
  video: HTMLVideoElement | null;
};

const HLSPlayer: React.FC<HSLPlayerProps> = ({ src, video }) => {
  useEffect(() => {
    if (!video || !src) {
      return undefined;
    }
    const hls = new Hls();
    hls.loadSource(src);
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      video.play();
    });

    return () => {
      hls.destroy();
    };
  }, [video, src]);

  return (
    <Player>
      <ControlBar disableCompletely disableDefaultControls />
      <source src={src} type='application/x-mpegURL' />
    </Player>
  );
};
export default HLSPlayer;
