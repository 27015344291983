import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useErrorMessage(error: any) {
  const { t } = useTranslation();

  const errorMessage = useMemo<[string | null, string | null]>(() => {
    if (!error) return [null, null];

    const initial = (
      typeof error === 'string'
        ? error
        : error?.response?.body?.message || error?.response?.body?.error
    ) as string;

    if (!initial || initial === 'vle_error_unknown') return [null, null];

    const translated = t(initial);

    if (!translated || translated === initial) return [null, null];

    return [translated, initial];
  }, [error, t]);

  return errorMessage;
}
