/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';

import Avatar from '@modules/user/components/Avatar';
import Body from '@common/components/Body';
import { UserResource } from '@common/transforms/user';

type AttendeesIndicatorProps = {
  attendees?: Partial<UserResource>[];
  count?: number;
  size?: 'm' | 's';
  color?: 'textBody' | 'textLight' | 'primary';
  className?: string;
};

const AttendeesIndicator: React.FC<AttendeesIndicatorProps> = ({
  attendees,
  count,
  size = 'm',
  color = 'primary',
  className,
}) => {
  const { t } = useTranslation();
  const sortedAttendees = useMemo(
    () => sortBy(attendees, (attendee) => !attendee.avatarUrl),
    [attendees],
  );

  const listed = sortedAttendees.slice(0, 5);

  return (
    <div
      css={css`
        height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
      className={className}
    >
      {listed.map((attendee, index) => (
        <span
          key={attendee.id}
          css={css`
            margin-left: -0.4em;
            margin-right: ${index === listed.length - 1 ? '0.7em' : 0};

            &:first-of-type {
              margin-left: 0;
            }
          `}
        >
          <Avatar user={attendee} size={size === 's' ? 22 : 36} />
        </span>
      ))}
      <Body color={color}>
        {(count && count !== 1) || (attendees && attendees?.length !== 1)
          ? t('attendeesCount.multiple', { amount: count || attendees?.length })
          : t('attendeesCount.single')}
      </Body>
    </div>
  );
};

export default AttendeesIndicator;
