import { TrendImage } from './TrendsImage';

type TrendItemProps = {
  number: number;
  image: string;
  currentIndex: number;
  onStart: (setIndex: React.Dispatch<number>) => void;
};

export function TrendItem({ number, image, currentIndex, onStart }: TrendItemProps) {
  const offsetImges = [
    {
      number: 2,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 4,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 5,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 6,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 7,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 8,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 9,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 10,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 11,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 12,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
    {
      number: 13,
      marginTop: '',
      marginBottom: '',
      marginLeft: '',
      disabled: false,
    },
  ];

  const img = (
    <div className='trend-item'>
      {Array(1)
        .fill(0)
        .map((_, index) => {
          return (
            <TrendImage
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              image={image}
              currentIndex={currentIndex}
              onStart={(setIndex) => {
                onStart(setIndex);
              }}
            />
          );
        })}
    </div>
  );

  return (
    <div
      className='trend-item-container'
      style={{
        visibility: offsetImges.find((x) => x.number === number)?.disabled ? 'hidden' : 'visible',
        marginTop: offsetImges.find((x) => x.number === number)?.marginTop,
        marginBottom: offsetImges.find((x) => x.number === number)?.marginBottom,
        marginLeft: offsetImges.find((x) => x.number === number)?.marginLeft
          ? offsetImges.find((x) => x.number === number)?.marginLeft
          : 'auto',
      }}
    >
      <div className='trend-image-container'>{image ? img : ''}</div>
    </div>
  );
}
