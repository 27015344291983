/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Scrollable from '@common/components/Scrollable';
import Body from '@common/components/Body';
import Card from '@common/components/Card';
import ChatRoomCard from '@modules/chat/components/ChatRoomCard';
import Button from '@common/components/Button';
import { useStartChat } from '@modules/chat/hooks/useStartChat';
import { RoomResource } from '@common/transforms/room';
import { useAppSelector } from '@common/store';

type ChatListProps = {
  rooms?: RoomResource[];
  onOpenRoom?: (roomId: string) => void;
  chatNotificationRoomId?: string;
  host?: string | undefined;
  canOnlyChatHost?: boolean;
};

const ChatList: React.FC<ChatListProps> = ({
  rooms,
  onOpenRoom,
  chatNotificationRoomId,
  host,
  canOnlyChatHost,
}) => {
  const { t } = useTranslation();
  const startChat = useStartChat();
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const sidebarItem = useAppSelector((state) => state.sidebar.activeItem);

  const emptyState = (
    <Card
      css={css`
        margin-top: 3em;
        ${mobileFullscreen && sidebarItem ? '' : 'margin-left: 3em; margin-right: 3em;'}
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: 'center';
          padding: 1em;
          padding-bottom: 1.5em;
          padding-top: 1.5em;
          text-align: center;
        `}
      >
        <div
          css={css`
            font-size: 15pt;
            font-weight: 600;
            padding-bottom: 0.5em;
          `}
        >
          {t('chat_empty_title')}
        </div>
        <Body>{t('chat_empty_subtitle')}</Body>
      </div>
    </Card>
  );

  if (canOnlyChatHost && !!host) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
        `}
      >
        <div
          css={css`
            margin-top: 2rem;
            max-width: 80%;
          `}
        >
          <Button size='m' onClick={() => startChat(host)} label={t('start_host_chat_button')} />
        </div>
      </div>
    );
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
      `}
    >
      <Scrollable
        startAtBottom
        css={css`
          flex: 1;
        `}
      >
        {rooms?.length === 0 && emptyState}
        {rooms?.map((room) => {
          return (
            <ChatRoomCard
              key={room.id}
              room={room}
              unread={chatNotificationRoomId === room.id}
              onClick={() => onOpenRoom && onOpenRoom(room.id)}
            />
          );
        })}
      </Scrollable>
    </div>
  );
};

export default ChatList;
