import { Icon } from '@typing/icons';

const Wifi: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2275 4484 c-476 -48 -860 -153 -1250 -344 -336 -164 -622 -362 -877
-608 -115 -111 -148 -167 -148 -251 0 -151 121 -275 270 -275 93 -1 140 25
275 148 228 208 425 345 685 475 324 162 615 250 975 297 177 23 533 23 710 0
647 -83 1186 -336 1678 -787 119 -109 166 -133 257 -133 80 -1 145 29 201 92
68 78 86 176 49 276 -17 44 -40 72 -128 158 -522 503 -1206 830 -1957 934
-114 16 -635 28 -740 18z'
        />
        <path
          d='M2355 3424 c-571 -60 -1041 -267 -1457 -645 -86 -78 -121 -130 -138
-203 -25 -114 37 -241 145 -299 31 -17 57 -22 120 -22 101 0 127 13 260 131
161 143 305 239 476 318 523 242 1138 233 1647 -23 165 -83 291 -171 447 -311
111 -100 141 -115 240 -115 63 0 89 5 120 22 134 72 187 232 121 366 -28 59
-114 145 -261 262 -320 256 -737 436 -1155 500 -101 16 -476 28 -565 19z'
        />
        <path
          d='M2335 2365 c-169 -30 -346 -94 -480 -173 -155 -92 -304 -224 -337
-299 -28 -65 -28 -153 1 -218 44 -100 122 -155 232 -163 56 -4 75 -1 122 21
31 14 75 43 99 64 180 162 360 234 588 234 199 0 371 -59 521 -178 33 -27 80
-64 106 -85 57 -45 116 -62 191 -55 160 14 269 159 240 318 -12 68 -40 110
-125 190 -169 159 -400 277 -653 334 -104 23 -397 29 -505 10z'
        />
        <path
          d='M2448 1337 c-100 -38 -166 -96 -215 -190 -25 -47 -28 -62 -28 -152 0
-93 2 -105 33 -167 38 -77 92 -130 171 -167 48 -22 69 -26 151 -26 82 0 103 4
151 26 79 37 133 90 171 167 31 62 33 74 33 167 0 90 -3 105 -28 153 -40 75
-87 124 -159 163 -56 29 -73 33 -153 36 -59 2 -103 -1 -127 -10z'
        />
      </g>
    </svg>
  );
};

export default Wifi;
