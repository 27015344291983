import { Icon } from '@typing/icons';

const Google: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 25'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6564 10.7092H25.6995C25.8323 11.4362 25.9 12.1301 25.9 12.7934C25.9 15.148 25.395 17.25 24.3875 19.0995C23.38 20.949 21.9429 22.3954 20.0763 23.4362C18.2097 24.477 16.0697 25 13.6564 25C11.9174 25 10.2617 24.6709 8.68923 24.0153C7.11681 23.3597 5.76046 22.4719 4.62019 21.3546C3.47992 20.2372 2.57396 18.9082 1.9049 17.3673C1.23583 15.8265 0.900002 14.2041 0.900002 12.5C0.900002 10.7959 1.23583 9.17347 1.9049 7.63265C2.57396 6.09184 3.47992 4.76276 4.62019 3.64541C5.76046 2.52806 7.11681 1.64031 8.68923 0.984694C10.2617 0.329082 11.9174 0 13.6564 0C16.9783 0 19.829 1.08929 22.2111 3.27041L18.7382 6.54337C17.3766 5.25255 15.6818 4.60714 13.6564 4.60714C12.2272 4.60714 10.9073 4.95918 9.69413 5.66582C8.48097 6.3699 7.52033 7.32908 6.81222 8.53827C6.1041 9.74745 5.75005 11.0689 5.75005 12.5026C5.75005 13.9362 6.1041 15.2551 6.81222 16.4668C7.52033 17.6786 8.48097 18.6352 9.69413 19.3393C10.9073 20.0434 12.2272 20.398 13.6564 20.398C14.6197 20.398 15.5048 20.2679 16.3145 20.0077C17.1241 19.7474 17.788 19.4209 18.3086 19.0306C18.8293 18.6403 19.2823 18.1939 19.6702 17.6964C20.0581 17.1964 20.3418 16.7245 20.5267 16.2806C20.7089 15.8367 20.8339 15.4133 20.9016 15.0102H13.6564V10.7092Z'
        fill={color}
      />
    </svg>
  );
};

export default Google;
