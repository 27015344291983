/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import IconSwitch from '@common/components/IconSwitch';
import { useAppSelector } from '@common/store';
import type { SidebarItem } from '@common/store/sidebar';

import ButtonWithHoverLabel from './ButtonWithHoverLabel';

type SidebarButtonProps = {
  item: SidebarItem;
  active: boolean;
  toggle: (key: string) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SidebarButton: React.FC<SidebarButtonProps> = ({
  item,
  active,
  toggle,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const missedCalls = useAppSelector((state) => state.videoCall.missedCalls);
  const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);

  const onLabelClick = () => {
    if (active && item.key !== 'collapse') {
      setIsOpen(false);
    }
    if (!active && item.key === 'collapse') {
      setIsOpen(false);
    }
    toggle(item.key);
  };

  const renderNotificationIcon = () => {
    if (item.notificationIcon) {
      if (missedCalls > 0 && item.key === 'video_call') {
        return <MissedCalls>{missedCalls}</MissedCalls>;
      }

      return <Notification />;
    }
    return null;
  };
  return (
    <ButtonWithHoverLabel labelText={t(item.label || '')} sidebar>
      <SidebarButtonContainer $elementFullScreen={elementFullScreen}>
        <IconSwitch
          size={44}
          icon={item.icon}
          selected={active}
          onChange={() => onLabelClick()}
          inverseColors
        />
        {renderNotificationIcon()}
      </SidebarButtonContainer>
    </ButtonWithHoverLabel>
  );
};

const MissedCalls = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 24px;
  height: 20px;
  border-radius: 40%;
  background-color: ${(props) => props.theme.colors.error};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Notification = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 24px;
  height: 20px;
  border-radius: 40%;
  background-color: ${(props) => props.theme.colors.error};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SidebarButtonContainer = styled.div<{ $elementFullScreen: boolean }>`
  position: relative;
  width: 44px;
  height: 44px;
  ${(props) => (props.$elementFullScreen ? '' : 'margin-bottom: 1rem')};
`;
