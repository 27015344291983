/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import Button from '@common/components/Button';
import { mediaQuery } from '@common/styles/mediaQuery';

type Tab = {
  key: string;
  label: string;
};

type TabControlProps = {
  tabs: Tab[];
  selectedTab: string;
  onChange?: (page: string) => void;
};

const TabControl: React.FC<TabControlProps> = ({ tabs, selectedTab, onChange }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;

        ${mediaQuery(
          's',
          css`
            justify-content: flex-start;
          `,
        )}
      `}
    >
      {tabs.map((tab: Tab) => {
        return (
          <Button
            key={tab.key}
            disableMinWidth
            color='secondary'
            size='l'
            label={tab.label}
            onClick={() => onChange?.(tab.key)}
            plain
            css={css`
              font-size: ${theme.fontSizes.mobile.h2};
              padding: 0;
              ${selectedTab !== tab.key ? `opacity: 0.45;` : ''}
              &:hover {
                opacity: 1;
                color: ${theme.colors.textLight};
                background-color: transparent;
              }

              ${mediaQuery(
                's',
                css`
                  margin-right: 3em;
                  font-size: ${theme.fontSizes.h2};
                `,
              )}
            `}
          />
        );
      })}
    </div>
  );
};

export default TabControl;
