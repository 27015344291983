/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface SideOverlayState {
  showOverlay: boolean;
}

const initialState: SideOverlayState = {
  showOverlay: false,
};

export const SideOverlaySlice = createSlice({
  name: 'sideoverlay',
  initialState,
  reducers: {
    openOverlay: (state) => {
      state.showOverlay = true;
    },
    closeOverlay: (state) => {
      state.showOverlay = false;
    },
  },
});

export const { openOverlay, closeOverlay } = SideOverlaySlice.actions;
export default SideOverlaySlice.reducer;
