import { useCallback, useEffect, useState } from 'react';

import { languages } from '@config';
import useLanguage from '@common/hooks/useLanguage';
import useEvent from '@modules/event/hooks/useEvent';

export function useSetBrowserLanguage() {
  const event = useEvent();
  const { updateLanguage } = useLanguage();
  const [isSet, setIsSet] = useState(false);

  const setLanguage = useCallback(() => {
    if (isSet || !event?.languages) return;

    const browserLanguage = languages.find((lang) => {
      if (!event.languages!.includes(lang.code)) return false;

      if (lang.code === 'nl_BE') {
        if (navigator.language === 'nl-BE') return true;
        if (navigator.language.startsWith('nl')) return true;
      } else if (navigator.language.startsWith(lang.code)) {
        return true;
      }

      return false;
    });

    if (!browserLanguage) {
      setIsSet(true);
      updateLanguage(event.defaultLanguage);
      return;
    }

    setIsSet(true);
    updateLanguage(browserLanguage.code);
  }, [isSet, event, updateLanguage]);

  return setLanguage;
}

export function useBrowserLanguage() {
  const setBrowserLanguage = useSetBrowserLanguage();

  useEffect(() => {
    setBrowserLanguage();
  }, [setBrowserLanguage]);
}
