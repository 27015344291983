import { createContext, useCallback, useContext, useState, useEffect } from 'react';

import { writeEventId } from '@modules/auth/utils';
import transform, { EventResource } from '@common/transforms/event';

import fetch, { APIError } from '@common/utils/fetch';

const eventContext = createContext<{
  event: EventResource | undefined;
  error: APIError | undefined;
  setEvent: (data: EventResource) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ event: undefined, error: undefined, setEvent: () => {} });

export const EventProvider = ({ children }: { children: React.ReactNode }) => {
  const [event, setEvent] = useState<EventResource>();
  const [error, setError] = useState<APIError>();

  useEffect(() => {
    const fetchEvent = async () => {
      const { host } = window.location;
      const scope = host.split('.')[0];

      try {
        const { body } = await fetch(`/events/${scope}`);
        setEvent(transform.one(body));
        writeEventId(transform.one(body).id);
      } catch (err: any) {
        setError(err);
      }
    };
    fetchEvent();
  }, [setEvent]);

  return (
    <eventContext.Provider value={{ event, error, setEvent }}>{children}</eventContext.Provider>
  );
};

export const useEvents = () => {
  const { event, error, setEvent } = useContext(eventContext);

  const refreshEvent = useCallback(
    async (lang?: string) => {
      const { body } = await fetch(`/events/${event?.id}?lang=${lang}`);
      setEvent(transform.one(body));
    },
    [event?.id, setEvent],
  );

  return {
    event,
    error,
    refreshEvent,
  };
};

export const useEvent = () => {
  const { event } = useEvents();

  return event;
};

export const useEventId = () => {
  const event = useEvent();

  return event?.id;
};

export default useEvent;
