/** @jsxImportSource @emotion/react */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';
import { readableColor } from 'polished';

import i18n from '@common/utils/i18n';

interface DaySelectorProps {
  onClick: () => void;
  date?: string | Date;
  count?: number;
  isExplore?: boolean;
  selected?: boolean;
}

const DaySelector: React.FC<DaySelectorProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const backgroundColor = props.selected ? theme.colors.textLight : 'rgba(255, 255, 255, 0.2)';

  return (
    <button
      onClick={props.onClick}
      css={css`
        border: 0;
        background-color: ${backgroundColor};
        color: ${readableColor(backgroundColor)};
        border-radius: ${theme.borderRadius.normal};
        margin: 20px 0;
        transition: 0.3s ease-in-out all;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: ${dayjs().isSame(props.date, 'd') ? 'flex-end' : 'flex-start'};
        padding: ${theme.paddings.s};
        width: 180px;
        min-height: 6em;
        cursor: pointer;
        align-self: stretch;

        :hover {
          background-color: ${props.selected ? theme.colors.textLight : 'rgba(255, 255, 255, 0.4)'};
        }
      `}
    >
      {props.isExplore ? (
        <span
          css={css`
            margin-bottom: 10px;
            font-weight: ${theme.fontWeights.semibold};
            font-size: ${theme.fontSizes.h3};
            justify-self: flex-end;
          `}
        >
          {t('explore')}
        </span>
      ) : dayjs().isSame(props.date, 'd') ? (
        <span
          css={css`
            margin-bottom: 10px;
            font-weight: ${theme.fontWeights.semibold};
            font-size: ${theme.fontSizes.h3};
            justify-self: flex-end;
          `}
        >
          {t('today')}
        </span>
      ) : (
        <>
          <span
            css={css`
              margin-top: 13px;
              font-size: ${theme.fontSizes.m};
            `}
          >
            {dayjs(props.date)
              .toDate()
              .toLocaleDateString(i18n.language.replace('_', '-'), { weekday: 'short' })}
            {`, `}
            {dayjs(props.date)
              .toDate()
              .toLocaleDateString(i18n.language.replace('_', '-'), { month: 'long' })}
          </span>
          <span
            css={css`
              padding: 0;
              margin: 0;
              margin: 5px 0;
              font-size: ${theme.fontSizes.h3};
              font-weight: ${theme.fontWeights.semibold};
            `}
          >
            {dayjs(props.date)
              .toDate()
              .toLocaleDateString(i18n.language.replace('_', '-'), { day: '2-digit' })}
          </span>
          <span
            css={css`
              padding: 0;
              margin-bottom: 10px;
              font-size: ${theme.fontSizes.m};
            `}
          >
            {t(`sessions.${props.count ?? 0 > 1 ? 'multiple' : 'single'}`, {
              amount: props.count,
            })}
          </span>
        </>
      )}
    </button>
  );
};

export default DaySelector;
