import { Icon } from '@typing/icons';

import { DIRECTION } from '@common/utils/consts';

const Chevron: React.FC<Icon> = ({ size, color, direction }) => {
  const getChevronPath = () => {
    switch (direction) {
      case DIRECTION.UP:
        return (
          <path
            d='M20.5607 16.8107C20.0199 17.3514 19.1691 17.393 18.5807 16.9354L18.4393 16.8107L12 10.3725L5.56066 16.8107C5.01993 17.3514 4.16909 17.393 3.58065 16.9354L3.43934 16.8107C2.89861 16.2699 2.85702 15.4191 3.31456 14.8307L3.43934 14.6893L10.9393 7.18934C11.4801 6.64861 12.3309 6.60702 12.9193 7.06456L13.0607 7.18934L20.5607 14.6893C21.1464 15.2751 21.1464 16.2249 20.5607 16.8107Z'
            fill={color}
            strokeWidth={0}
          />
        );
      case DIRECTION.RIGHT:
        return (
          <path
            d='M7.18934 20.5607C6.64861 20.0199 6.60702 19.1691 7.06456 18.5807L7.18934 18.4393L13.6275 12L7.18934 5.56066C6.64861 5.01993 6.60702 4.16909 7.06456 3.58065L7.18934 3.43934C7.73007 2.89861 8.58091 2.85702 9.16935 3.31456L9.31066 3.43934L16.8107 10.9393C17.3514 11.4801 17.393 12.3309 16.9354 12.9193L16.8107 13.0607L9.31066 20.5607C8.72487 21.1464 7.77513 21.1464 7.18934 20.5607Z'
            fill={color}
            strokeWidth={0}
          />
        );
      case DIRECTION.DOWN:
        return (
          <path
            d='M3.43934 7.18934C3.98007 6.64861 4.83091 6.60702 5.41935 7.06456L5.56066 7.18934L12 13.6275L18.4393 7.18934C18.9801 6.64861 19.8309 6.60702 20.4193 7.06456L20.5607 7.18934C21.1014 7.73007 21.143 8.58091 20.6854 9.16935L20.5607 9.31066L13.0607 16.8107C12.5199 17.3514 11.6691 17.393 11.0807 16.9354L10.9393 16.8107L3.43934 9.31066C2.85355 8.72487 2.85355 7.77513 3.43934 7.18934Z'
            fill={color}
            strokeWidth={0}
          />
        );
      default:
        return (
          <path
            d='M16.8107 3.43934C17.3514 3.98007 17.393 4.83091 16.9354 5.41935L16.8107 5.56066L10.3725 12L16.8107 18.4393C17.3514 18.9801 17.393 19.8309 16.9354 20.4193L16.8107 20.5607C16.2699 21.1014 15.4191 21.143 14.8307 20.6854L14.6893 20.5607L7.18934 13.0607C6.64861 12.5199 6.60702 11.6691 7.06456 11.0807L7.18934 10.9393L14.6893 3.43934C15.2751 2.85355 16.2249 2.85355 16.8107 3.43934Z'
            fill={color}
            strokeWidth={0}
          />
        );
    }
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {getChevronPath()}
    </svg>
  );
};

export default Chevron;
