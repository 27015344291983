import parse, { Attributes } from '@common/utils/parse';

export enum VideoType {
  VIXY = 'VIXY',
  VIMEO = 'VIMEO',
  HLS = 'HLS',
  OTHER = 'OTHER',
}

export const parseVideo = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    type: attributes.enum<typeof VideoType>('type', VideoType),
    title: attributes.string('title'),
    subtitle: attributes.optional.string('subtitle'),
    description: attributes.optional.string('description'),
    duration: attributes.number('duration'),
    vixyPlayerId: attributes.optional.string('vixyPlayerId'),
    vixyEntryId: attributes.optional.string('vixyEntryId'),
    vixyPartnerId: attributes.optional.string('vixyPartnerId'),
    vimeoVideoId: attributes.optional.string('vimeoVideoId'),
    categoryId: attributes.optional.id('categoryId'),
    position: attributes.optional.number('position'),
    hlsUrl: attributes.optional.string('hlsUrl'),
    imageUrl: attributes.optional.string('imageUrl'),
    embedUrl: attributes.optional.string('embedUrl'),
  };
};

export function one(data: any) {
  return parse.one(data, parseVideo);
}

export function many(data: any) {
  return parse.many(data, parseVideo);
}

export type VideoResource = ReturnType<typeof one>;
export type Video = Omit<VideoResource, 'id'>;

export default { one, many };
