import parse from '@common/utils/parse';

export default function transform(data: any) {
  return parse.one(data, (attributes) => {
    return {
      accessToken: attributes.string('accessToken'),
      refreshToken: attributes.string('refreshToken'),
      accessTokenExpiresAt: attributes.number('accessTokenExpiresAt'),
      refreshTokenExpiresAt: attributes.number('refreshTokenExpiresAt'),
      tfaRequired: attributes.optional.boolean('2fa_required'),
    };
  });
}

export type TokenResource = ReturnType<typeof transform>;
