import { useCallback } from 'react';

import { usePusher } from '@harelpls/use-pusher';

import fetch from '@common/utils/fetch';
import { useEvent } from '@modules/event/hooks/useEvent';
import { useAppDispatch, useAppSelector } from '@common/store';

import { Beams } from '@common/hooks/useBeams';

import { setAuthorized, setAccepted, setUser, setError } from '../store/auth';

import { getAccessToken, setTokens } from './useAuth';

export function useLogout() {
  const event = useEvent();
  const dispatch = useAppDispatch();
  const accessToken = getAccessToken();
  const pusher = usePusher();
  const user = useAppSelector((state) => state.auth.user);

  return useCallback(async () => {
    if (!event) return;

    try {
      await fetch(`/auth/${event.id}/logout`, {
        method: 'DELETE',
        token: accessToken,
      });

      pusher.client?.unsubscribe?.(`private-${event.id}`);
      pusher.client?.unsubscribe?.(`private-${event.id}_${user?.id}`);

      dispatch(setAuthorized(false));
      dispatch(setAccepted(false));
      dispatch(setUser(undefined));
      setTokens({});
      Beams().then(async (client) => {
        await client.unregister();
      });
    } catch (err: any) {
      dispatch(
        setError(
          err?.cause?.response?.body?.message ||
            err?.response?.body?.message ||
            err?.response?.body?.error ||
            err?.message,
        ),
      );
    }
  }, [event, accessToken, pusher.client, user?.id, dispatch]);
}
