import { useMutation } from 'react-query';

import { getAccessToken } from '@modules/auth/hooks/useAuth';
import useEvent from '@modules/event/hooks/useEvent';
import fetch from '@common/utils/fetch';

export const useJoinCall = () => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation((participantId: string) => {
    return fetch(`/events/${event?.id}/video-calls/join`, {
      method: 'POST',
      body: { participantId },
      token: accessToken,
    });
  });
};
