/** @jsxImportSource @emotion/react */
import React, { useRef, useState, useEffect } from 'react';
import { css, useTheme } from '@emotion/react';
import { InfiniteQueryObserverResult } from 'react-query';
import Loader from 'react-spinners/PulseLoader';

import { mediaQuery } from '@common/styles/mediaQuery';
import IconButton from '@common/components/IconButton';
import Button from '@common/components/Button';
import { DIRECTION } from '@common/utils/consts';

export type CarouselProps = {
  className?: string;
  itemWidth: number;
  items: any[];
  onChange?: (index: number) => void;
  selectedIndex?: number;
  renderItem: (props: any) => React.ReactNode;
  showRightIndicator?: boolean;
  fetchNextPage?: () => Promise<InfiniteQueryObserverResult>;
  isFetching?: boolean;
};

const Carousel: React.FC<CarouselProps> = ({
  className,
  items,
  selectedIndex,
  onChange,
  renderItem,
  itemWidth,
  showRightIndicator = true,
  fetchNextPage,
  isFetching,
}) => {
  const [index, setIndex] = useState(0);
  const [isSingleScreen, setIsSingleScreen] = useState(true);
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const carouselIndex = selectedIndex ?? index;

  useEffect(() => {
    const handleResize = () =>
      setIsSingleScreen(
        (itemWidth + 50) * items?.length <= (containerRef?.current?.offsetWidth ?? 0),
      );
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [itemWidth, items?.length, containerRef]);

  const handleChange = (i: number) => {
    if (typeof onChange === 'function') {
      onChange(i);
      return;
    }
    setIndex(i);
  };

  const handleLeftClick = () => {
    if (carouselIndex < 0) handleChange(0);
    else if (carouselIndex === 0) return;
    handleChange(carouselIndex - 1);
  };
  const handleRightClick = () => {
    if (carouselIndex > items.length - 1) handleChange(items.length - 1);
    else if (carouselIndex === items.length - 2) fetchNextPage?.();
    handleChange(carouselIndex + 1);
  };

  return (
    <div
      ref={containerRef}
      className={className}
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        padding-bottom: 50px;

        ${mediaQuery(
          'm',
          css`
            padding-bottom: 0;
          `,
        )}
      `}
    >
      <div
        css={css`
          margin-left: -${isSingleScreen ? 0 : 100 * carouselIndex}%;
          display: flex;
          flex-direction: row;
          align-items: stretch;
          width: 100%;
          height: 100%;
          transition: all 0.2s ease-in-out;

          ${mediaQuery(
            'm',
            css`
              margin-left: -${isSingleScreen ? 0 : (itemWidth + 50) * (carouselIndex - 2 < 0 ? 0 : carouselIndex - 2)}px;
              width: auto;
            `,
          )}
        `}
      >
        {items.map?.((v) => (
          <div
            key={items.indexOf(v)}
            css={css`
              width: calc(100% - 1em);
              min-width: calc(100% - 1em);
              padding: 0 0.5em;
              display: flex;

              ${mediaQuery(
                'm',
                css`
                  width: ${itemWidth}px;
                  min-width: ${itemWidth}px;
                  margin: 0 25px;
                  padding: 0;

                  :first-of-type {
                    margin-left: 0;
                  }
                `,
              )}
            `}
          >
            {renderItem?.(v)}
          </div>
        ))}
        <div
          css={css`
            display: flex;
            width: ${itemWidth}px;
            justify-content: center;
            align-items: center;
          `}
        >
          {isFetching && <Loader color={theme.colors.textLight} />}
        </div>
      </div>
      {!isSingleScreen && (
        <>
          <div
            css={css`
              position: absolute;
              top: 0;
              bottom: 0;
              left: 30px;
              width: 32px;
              pointer-events: none;
              background: linear-gradient(
                to right,
                ${theme.colors.eventBackground},
                ${theme.colors.eventBackground}00
              );
              z-index: 1;
              transition: all 0.2s ease-in-out;
              opacity: 0;

              ${mediaQuery(
                'm',
                css`
                  opacity: ${carouselIndex > 1 ? 1 : 0};
                `,
              )}

              &:after {
                content: '';
                clear: both;
                position: absolute;
                z-index: 1;
                width: 30px;
                left: -30px;
                top: 0;
                bottom: 0;
                background: ${theme.colors.eventBackground};
              }
            `}
          />
          <div
            css={css`
              position: absolute;
              top: 0;
              bottom: 0;
              right: 30px;
              width: 32px;
              pointer-events: none;
              background: linear-gradient(
                to left,
                ${theme.colors.eventBackground},
                ${theme.colors.eventBackground}00
              );
              z-index: 1;
              transition: all 0.2s ease-in-out;
              opacity: 0;

              ${mediaQuery(
                'm',
                css`
                  opacity: ${carouselIndex < items.length - 1 ? 1 : 0};
                `,
              )}

              &:after {
                content: '';
                clear: both;
                position: absolute;
                width: 30px;
                right: -30px;
                top: 0;
                bottom: 0;
                z-index: 1;
                background: ${theme.colors.eventBackground};
              }
            `}
          />
          {carouselIndex > 1 && (
            <>
              <IconButton
                css={css`
                  position: absolute;
                  left: 0;
                  align-self: center;
                  z-index: 1;
                  display: none;

                  ${mediaQuery(
                    'm',
                    css`
                      display: flex;
                    `,
                  )}
                `}
                icon='arrow'
                size={45}
                round
                onClick={handleLeftClick}
                iconProps={{
                  size: 25,
                  direction: DIRECTION.LEFT,
                }}
              />
              <Button
                css={css`
                  position: absolute;
                  bottom: 0;
                  left: 0.5em;
                  width: 40%;

                  ${mediaQuery(
                    'm',
                    css`
                      display: none;
                    `,
                  )}
                `}
                label='previous'
                icon='arrow'
                disableMinWidth
                color='secondary'
                outline
                onClick={handleLeftClick}
              />
            </>
          )}
          {carouselIndex < items.length - 1 && showRightIndicator && (
            <>
              <IconButton
                css={css`
                  position: absolute;
                  right: 0;
                  align-self: center;
                  z-index: 1;
                  display: none;

                  ${mediaQuery(
                    'm',
                    css`
                      display: flex;
                    `,
                  )}
                `}
                icon='arrow'
                size={45}
                round
                onClick={handleRightClick}
                iconProps={{
                  size: 25,
                  direction: DIRECTION.RIGHT,
                }}
              />
              <Button
                css={css`
                  position: absolute;
                  bottom: 0;
                  right: 0.5em;
                  width: 40%;

                  ${mediaQuery(
                    'm',
                    css`
                      display: none;
                    `,
                  )}
                `}
                label='next'
                icon='arrow'
                iconPlacement='right'
                disableMinWidth
                color='secondary'
                outline
                onClick={handleRightClick}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Carousel;
