import { useQuery } from 'react-query';

import { useTranslation } from 'react-i18next';

import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import transformCategory from '@common/transforms/pageItemCategory';
import { useAppSelector } from '@common/store';
import { getStaleTime } from '@common/utils/staleTime';
import fetch from '@common/utils/fetch';

export const usePageItemCategories = (pageId: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['pageItemCategories', event?.id, pageId],
    async () => {
      const { body } = await fetch(
        `/events/${event?.id}/pages/${pageId}/categories?lang=${i18n.language}`,
        {
          token: accessToken,
        },
      );

      return transformCategory.many(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};
