/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Button from '@common/components/Button';

export type Filter = {
  label: string;
  key: string;
  type: 'tag' | 'role';
};

type FilterCloudProps = {
  filters: Filter[];
  selected?: Filter[];
  inRow?: boolean;
  onFilterClick?: (filter: Filter) => void;
};

const FilterCloud = ({ filters, selected, inRow, onFilterClick }: FilterCloudProps) => {
  const noGuestFilter = filters.filter((f) => f.key !== 'GUEST'); // TODO: replace with dynamic roles

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        ${inRow
          ? `
          justify-content: flex-end;
          margin-top: 0em;
          margin-right: -1.25em;
          margin-bottom: -1.25em;
        `
          : ''}

        > * {
          margin-right: 0.5em;
          margin-bottom: 1.313em;
        }
      `}
    >
      {noGuestFilter?.map((filter) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Button
            key={filter.key}
            outline={!selected?.find((t) => filter.key === t.key)}
            label={filter.label}
            size='s'
            disableMinWidth
            onClick={() => onFilterClick?.(filter)}
          />
        );
      })}
    </div>
  );
};

export default FilterCloud;
