/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
import { css, useTheme } from '@emotion/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';

import Labeled from '@common/components/Labeled';
import Button from '@common/components/Button';
import Body from '@common/components/Body';
import Heading from '@common/components/Heading';
import Input from '@common/components/Input';
import RoundButton from '@common/components/RoundButton';
import Icon from '@common/components/Icon';
import { DIRECTION } from '@common/utils/consts';
import { useAppDispatch } from '@common/store';
import { setError } from '@modules/auth/store/auth';

type LoginFormProps = {
  onSubmit: (values: { email: string; recaptchaToken: string }) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    recaptchaToken: Yup.string().required(),
  });

  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      <Heading size='h1'>{t('registration_title')}</Heading>
      <Body
        css={css`
          margin-bottom: 1.875em;
          color: ${theme.colors.textLight};
        `}
      >
        {t('registration_introduction')}
      </Body>
      <Formik
        initialValues={{ email: '', recaptchaToken: '' }}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <Form>
            <Labeled label={t('email')}>
              <Input name='email' placeholder={t('email_placeholder')} />
            </Labeled>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin: 1em 0 1.5em;
              `}
            >
              <div>
                <Icon icon='warning' size={20} color={theme.colors.textBody} />
              </div>
              <Body
                css={css`
                  margin-left: 0.5em;
                  color: ${theme.colors.textBody};
                `}
              >
                {t('login_warning')}
              </Body>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY! || '1'}
              onChange={(response) => {
                setFieldValue('recaptchaToken', response);
              }}
            />
            <div
              css={css`
                margin-top: 3.0625em;
                display: flex;
              `}
            >
              <RoundButton
                size='m'
                onClick={() => {
                  navigate('/landing');
                  dispatch(setError(undefined));
                }}
              >
                <Icon icon='arrow' direction={DIRECTION.LEFT} size={20} color='#fff' />
              </RoundButton>
              <div
                css={css`
                  width: 1.4375em;
                `}
              />
              <Button label={t('next_button')} type='submit' />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
