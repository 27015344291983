/* eslint-disable camelcase */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import it from '@translations/it.json';

import en from '@translations/en.json';
import fr from '@translations/fr.json';
import nl_BE from '@translations/nl-be.json';
import nl from '@translations/nl.json';
import de from '@translations/de.json';
import es from '@translations/es.json';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  nl_BE: {
    translation: nl_BE,
  },
  nl: {
    translation: nl,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  it: {
    translation: it,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
});

export default i18n;
