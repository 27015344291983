import Pusher from 'pusher-js';
import { PusherProviderProps } from '@harelpls/use-pusher';

if (!process.env.REACT_APP_PUSHER_APP_KEY) {
  throw new Error('Environment variable `REACT_APP_PUSHER_APP_KEY` is not set');
}

if (!process.env.REACT_APP_API_URL) {
  throw new Error('Environment variable `REACT_APP_API_URL` is not set');
}

Pusher.logToConsole = process.env.NODE_ENV !== 'production';

export const PUSHER_ANNOUNCEMENT = 'PUSHER_ANNOUNCEMENT';
export const PUSHER_BOOTH = 'PUSHER_BOOTH';
export const PUSHER_MESSAGE = 'PUSHER_MESSAGE';
export const PUSHER_GLOBAL_CHAT = 'PUSHER_GLOBAL_CHAT';
export const PUSHER_PRIVATE_CHAT = 'PUSHER_PRIVATE_CHAT';
export const PUSHER_PUSH_TO_PAGE = 'PUSHER_PUSH_TO_PAGE';
export const PUSHER_EVENT_UPDATE = 'PUSHER_EVENT_UPDATE';
export const PUSHER_SESSION_UPDATE = 'PUSHER_SESSION_UPDATE';
export const PUSHER_ANNOUNCEMENT_DELETED = 'PUSHER_ANNOUNCEMENT_DELETED';
export const PUSHER_TOGGLE_CHAT = 'PUSHER_TOGGLE_CHAT';
export const PUSHER_REDIRECT = 'PUSHER_REDIRECT';
export const PUSHER_SESSION_REMINDER = 'PUSHER_SESSION_REMINDER';
export const PUSHER_CALL_REQUEST = 'PUSHER_CALL_REQUEST';
export const PUSHER_CALL_RESPONSE = 'PUSHER_CALL_RESPONSE';
export const PUSHER_CALL_UPDATE = 'PUSHER_CALL_UPDATE';
export const PUSHER_USER_UPDATED = 'PUSHER_USER_UPDATED';
export const PUSHER_CLAP_HAND = 'PUSHER_CLAP_HAND';
export const PUSHER_OPEN_SLIDO = 'PUSHER_OPEN_SLIDO';

export const pusherConfig = (accessToken?: string): PusherProviderProps => {
  return {
    clientKey: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || 'eu',
    authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
    auth: {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  };
};
