import { Icon as IconType } from '@typing/icons';

const Chat: React.FC<IconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.90456 3.41218C9.95788 3.151 14.0381 3.16302 20.0907 3.41562C21.1583 3.46018 22 4.34086 22 5.4094V16.088C22 17.1576 21.1584 18.0386 20.0897 18.0818C19.187 18.1182 18.3281 18.1494 17.5 18.1753C17.2743 18.1821 17.3034 19.1174 17.3716 20.1924C17.4271 21.0665 16.4461 21.5253 15.7984 20.9356C14.2492 19.5251 12.8031 18.2657 12.5 18.2672C9.79792 18.2773 7.13272 18.2173 3.90736 18.0841C2.8399 18.04 2 17.1594 2 16.091V5.40478C2 4.33811 2.83888 3.45816 3.90456 3.41218Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Chat;
