/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs from 'dayjs';
import { css } from '@emotion/react';

import Card from '@common/components/Card';
import { User } from '@common/transforms/user';
import { Event } from '@common/transforms/event';
import Heading from '@common/components/Heading';
import Avatar from '@modules/user/components/Avatar';
import { mediaQuery } from '@common/styles/mediaQuery';
import RoundButton from '@common/components/RoundButton';
import Icon from '@common/components/Icon';
import { CustomFieldResource } from '@common/transforms/customField';

type RegistrationCardProps = {
  event?: Event;
  user: Partial<User>;
  customFields?: CustomFieldResource[];
};

const RegistrationCard = ({ event, user, customFields }: RegistrationCardProps) => {
  const startsAt = dayjs(event?.startsAt);
  const endsAt = dayjs(event?.endsAt);
  const eventDate = startsAt.isSame(endsAt, 'day')
    ? startsAt.format('D MMMM')
    : `${startsAt.format('D MMMM')} - ${endsAt.format('D MMMM')}`;

  return (
    <Card
      css={css`
        width: 100%;
        min-width: 280pt;
        overflow: hidden;
        align-self: flex-start;
        ${mediaQuery(
          'm',
          css`
            max-width: 400px;
          `,
        )}
      `}
    >
      {!!(event?.themeBackgroundUrl || event?.themeBackgroundColor) && (
        <div
          css={css`
            background: url(${event?.themeBackgroundUrl}) no-repeat;
            background-size: cover;
            min-height: 100pt;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: top;
          `}
        >
          <div
            css={css`
              background-color: ${event?.themeBackgroundColor};
              height: 8pt;
              width: 32pt;
              margin: 6pt auto 0;
              border-radius: 4pt;
            `}
          />
        </div>
      )}
      <div
        css={css`
          padding: 1em;
        `}
      >
        <Heading
          size='h2'
          color='textLight'
          css={css`
            margin-bottom: 0.25em;
          `}
        >
          {event?.name}
        </Heading>

        <Heading size='subtitle'>{eventDate}</Heading>

        <div style={{ padding: 'pt 0' }}>
          <div
            css={css`
              width: 100%;
              height: 0;
              opacity: 0.1;
              border: solid 1px '#FFFFFF33';
            `}
          />
        </div>
        <Avatar user={user} size={60} />
        <Heading
          size='h3'
          color='textLight'
          css={css`
            margin-top: 0.5em;
            margin-bottom: 0.25em;
          `}
        >
          {user.firstName} {user.lastName}
        </Heading>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            margin-top: 0.5em;
            > * {
              margin-right: 0.5em;
            }
          `}
        >
          {user.fields?.map((field) => {
            const customField = customFields?.find((cf) => field.customFieldId === cf.id);
            if (customField?.key === 'videolink') {
              return (
                <React.Fragment key={customField?.key}>
                  <RoundButton size='s'>
                    <Icon icon='video-call' color='#fff' size={15} />
                  </RoundButton>
                </React.Fragment>
              );
            }
            if (customField?.key === 'linkedin') {
              return (
                <React.Fragment key={customField?.key}>
                  <RoundButton size='s'>
                    <Icon icon='linkedin' color='#fff' size={15} />
                  </RoundButton>
                </React.Fragment>
              );
            }
            return null;
          })}
        </div>
        {user.fields?.map((field) => {
          const customField = customFields?.find((cf) => field.customFieldId === cf.id);
          if (customField?.key === 'videolink') {
            return null;
          }
          if (customField?.key === 'linkedin') {
            return null;
          }
          if (customField?.key === 'jobtitle') {
            return null;
          }
          if (customField?.key === 'company') {
            return null;
          }
          return (
            <Heading key={field.customFieldId} size='subtitle'>
              {field.value}
            </Heading>
          );
        })}
      </div>
    </Card>
  );
};

export default RegistrationCard;
