import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { SESSION_STATES } from '@modules/schedule/hooks/useSessionStatus';
import { useCurrentTime } from '@common/hooks/useCurrentTime';
import { convertDatesToTimeSlot, parseISO } from '@common/utils/time';

// Fix type session, dan kan any aangepast worden naar sessionresource.
// Daarmee wordt dit bestand dan gelijk aan useSessionSettings.ts.
export function useSpeakerSessionSettings(session: any, sessionStatus: SESSION_STATES) {
  const [afterEndTime, setAfterEndTime] = useState(false);

  const endTime = session.endsAt;
  const currentTime = useCurrentTime();

  useEffect(() => {
    if (dayjs(currentTime).isAfter(dayjs(endTime))) {
      setAfterEndTime(true);
    }
  }, [currentTime, endTime]);

  const sessionDuration = dayjs(session.endsAt).diff(session.startsAt, 'minutes');
  const shortDuration = sessionDuration <= 30;

  const status = {
    notStarted: sessionStatus === SESSION_STATES.NOT_STARTED,
    live: sessionStatus === SESSION_STATES.LIVE,
    passed: sessionStatus === SESSION_STATES.PASSED,
  };

  const duration = {
    timeslot: convertDatesToTimeSlot(parseISO(session.startsAt), parseISO(session.endsAt)),
    total: sessionDuration,
    short: shortDuration,
    long: !shortDuration,
  };

  const buttons = {
    showJoin: status.live,
    showLive: status.live,
    showMandatory: session.mandatory,
    showReminder: !session.mandatory && !afterEndTime,
    showAddToSchedule: !afterEndTime && !session.mandatory && !session.scheduled,
    showRemoveFromSchedule: !afterEndTime && !session.mandatory && session.scheduled,
    showOndemand: status.passed && session.onDemandUrl,
    showFinished: status.passed && !session.onDemandUrl,
  };

  return {
    status,
    duration,
    buttons,
  };
}
