import styled from '@emotion/styled';
import { Field, FieldAttributes, FormikState } from 'formik';

import FieldWrapper from '@common/components/FieldWrapper';
import Icon from '@common/components/Icon';

type CheckboxProps = {
  className?: string;
  round?: boolean;
  color?: 'primary' | 'secondary';
  size?: number;
};

const CheckboxComponent: React.FC<
  {
    field: FieldAttributes<any>;
    form: FormikState<any>;
  } & CheckboxProps
> = ({
  field,
  form: { touched, errors },
  className,
  children,
  size = 32,
  color = 'primary',
  round = false,
  ...props
}) => {
  const error = touched[field.name] && errors[field.name];

  return (
    <FieldWrapper className={className} error={!!error}>
      <Container size={size} color={color} round={round}>
        <label className='checkboxContainer'>
          {children}
          <input type='checkbox' {...field} {...props} checked={field.value === true} />
          <span className='checkmark'>
            <Icon size={24} icon='checkmark' color='#0D0A26' />
          </span>
        </label>
      </Container>
    </FieldWrapper>
  );
};

const Checkbox: React.FC<FieldAttributes<any> & CheckboxProps> = (props) => (
  <Field component={CheckboxComponent} {...props} />
);

export default Checkbox;

const Container = styled.div<{
  size: number;
  color: 'primary' | 'secondary';
  round?: boolean;
  error?: any;
}>`
  /* Customize the label (the container) */
  .checkboxContainer {
    display: block;
    position: relative;
    padding-left: ${(props) => props.size + 10}px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: ${(props) => props.size + 2}px;
    color: ${(props) => props.theme.colors.textLight};
    a {
      color: ${(props) => props.theme.colors.textBody};
      font-weight: bold;
    }
  }

  /* Hide the browser's default checkbox */
  .checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    border: solid 1px
      ${(props) => (props.error ? props.theme.colors.errorText : props.theme.colors.border)};
    border-radius: ${(props) => (props.round ? '100%' : '12px')};
    transition: 0.2s ease-in-out all;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkmark svg {
    opacity: 0;
    width: ${(props) => props.size / 1.7}px;
    height: ${(props) => props.size / 1.7}px;
    position: relative;
    z-index: 2;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    opacity: 0;
    transition: 0.2s ease-in-out all;
    z-index: 1;
  }

  /* On mouse-over, add a grey background color */
  .checkboxContainer:hover input ~ .checkmark:after {
    background-color: ${(props) => props.theme.colors[props.color ?? 'primary']};
    border-color: ${(props) => props.theme.colors[props.color ?? 'primary']};
    opacity: 0.3;
    display: block;
    left: 10%;
    top: 10%;
    border-radius: ${(props) => (props.round ? '100%' : '8px')};
    width: 80%;
    height: 80%;
  }

  .checkboxContainer:active input ~ .checkmark {
    transform: scale(0.92);
  }

  .checkboxContainer input:checked ~ .checkmark:after {
    opacity: 1;
  }

  .checkboxContainer input:checked ~ .checkmark svg {
    opacity: 1;
  }

  .checkboxContainer .checkmark:after {
    left: 10%;
    top: 10%;
    border-radius: ${(props) => (props.round ? '100%' : '8px')};
    width: 80%;
    height: 80%;
    background-color: ${(props) => props.theme.colors[props.color ?? 'primary']};
  }
`;
