import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { setFullscreen } from '@common/store/sidebar';
import { useAppDispatch } from '@common/store';

import { setActiveCall, setError } from '../store/videoCall';

import { useParticipateCall } from './useParticipateCall';

export function useJoinVideoCall() {
  const dispatch = useAppDispatch();
  const { mutateAsync: participate } = useParticipateCall();

  const joinVideoCall = useCallback(
    async (videoCallId: string) => {
      try {
        const participant = await participate(videoCallId);
        dispatch(setActiveCall({ callId: participant.videoCallId, participantId: participant.id }));
        dispatch(setFullscreen(true));
      } catch (error) {
        dispatch(
          setError(
            // @ts-ignore TODO: fix type?
            error?.response?.body?.message ||
              // @ts-ignore TODO: fix type?
              error?.response?.body?.error ||
              'generic_video_call_error',
          ),
        );
      }
    },
    [participate, dispatch],
  );

  return joinVideoCall;
}

export function useInitVideoCall() {
  const joinVideoCall = useJoinVideoCall();
  const location = useLocation();

  const query = queryString.parse(location.search);
  useEffect(() => {
    if (query.videoCall) {
      joinVideoCall(query.videoCall as string);
    }
  }, [query, joinVideoCall]);
}
