/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';

import {
  SESSION_STATES,
  getStatus,
  useSessionStatus,
} from '@modules/schedule/hooks/useSessionStatus';
import {
  useAddScheduledSession,
  useRemoveScheduledSession,
  useUpdateScheduledSession,
} from '@modules/schedule/hooks/useSessions';
import { useSessionContext } from '@modules/schedule/hooks/useSessionContext';
import { SessionResource } from '@common/transforms/session';

import SessionList from './list';
import SessionNormal from './normal';
import SessionHighlighted from './highlighted';
import SessionDetailed from './detailed';

interface SessionCardProps {
  session: SessionResource;
  displayType: DISPLAY_TYPE;
  overlapping?: boolean;
  isNotified?: boolean;
  inGridView?: boolean;
  openModal: boolean;
  selectedDate?: Date;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum DISPLAY_TYPE {
  normal = 'normal',
  list = 'list',
  detailed = 'detailed',
  highlighted = 'highlighted',
}

const SessionBase: React.FC<SessionCardProps> = ({
  session,
  displayType = DISPLAY_TYPE.normal,
  openModal,
  selectedDate,
  setOpenModal,
  ...props
}) => {
  const [sessionStatus, setSessionStatus] = useState<SESSION_STATES>(getStatus(session));
  useSessionStatus(session, setSessionStatus);
  const { joinSession } = useSessionContext();
  const { mutate: addScheduledSession } = useAddScheduledSession(session.id);
  const { mutate: updateScheduledSession } = useUpdateScheduledSession(session.id);
  const { mutate: removeScheduledSession } = useRemoveScheduledSession(session.id);

  const handleNotifyClick = () => {
    if (session?.scheduled) {
      if (session?.reminder) {
        updateScheduledSession(false);
      } else {
        updateScheduledSession(true);
      }
    } else {
      addScheduledSession(true);
    }
  };

  const handleScheduleClick = () => {
    addScheduledSession(false);
  };

  const handleRemoveScheduleClick = () => {
    removeScheduledSession();
  };

  const handleJoinClick = () => {
    joinSession(session.url);
  };

  const handleWatchClick = () => {
    joinSession(session?.onDemandUrl);
  };

  const events = {
    onNotifyClick: handleNotifyClick,
    onScheduleClick: handleScheduleClick,
    onRemoveScheduleClick: handleRemoveScheduleClick,
    onJoinClick: handleJoinClick,
    onWatchClick: handleWatchClick,
  };

  const renderContent = () => {
    // eslint-disable-next-line default-case
    switch (displayType) {
      case DISPLAY_TYPE.list:
        return (
          <SessionList
            {...props}
            {...events}
            session={session}
            sessionStatus={sessionStatus}
            selectedDate={selectedDate}
          />
        );
      case DISPLAY_TYPE.highlighted:
        return (
          <SessionHighlighted
            {...props}
            {...events}
            session={session}
            sessionStatus={sessionStatus}
          />
        );
      case DISPLAY_TYPE.detailed:
        return (
          <SessionDetailed {...props} {...events} session={session} sessionStatus={sessionStatus} />
        );
    }
    return (
      <SessionNormal
        {...props}
        {...events}
        session={session}
        sessionStatus={sessionStatus}
        selectedDate={selectedDate}
      />
    );
  };

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 100%;
        `}
        onClick={() => setOpenModal(!openModal)}
      >
        {renderContent()}
      </div>
    </>
  );
};

export default SessionBase;
