/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { CallStatus } from '@common/transforms/user';
import Icon from '@common/components/Icon';
import { useAppSelector } from '@common/store';

interface Props {
  className?: string;
  status: CallStatus;
}

export const CallStatusChip = ({ className, status }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const sidebarItem = useAppSelector((state) => state.sidebar.activeItem);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: row;
        background: rgba(255, 255, 255, 0.15);
        color: ${theme.colors.textLight};
        border-radius: 4px;
        font-size: ${theme.fontSizes.xs};
        padding: ${theme.paddings.xs};
        ${mobileFullscreen && sidebarItem ? 'margin-left: -3em !important;' : ''}
      `}
    >
      <Icon icon='ic_phone' />
      <div
        css={css`
          margin-left: 0.5em;
        `}
      >
        {mobileFullscreen && sidebarItem ? (
          <></>
        ) : (
          <>{status === CallStatus.DO_NOT_DISTURB ? t('do_not_disturb') : t('in_call')}</>
        )}
      </div>
    </div>
  );
};
