import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { useAppSelector } from '@common/store';
import fetch, { APIError } from '@common/utils/fetch';
import transform, { VideoCallResource } from '@common/transforms/videoCall';
import { getAccessToken } from '@modules/auth/hooks/useAuth';
import useEvent from '@modules/event/hooks/useEvent';

export const useVideoCall = (callId?: string, includeInactiveParticipants = false) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery<VideoCallResource, APIError>(
    ['video-call', event?.id, callId].filter(Boolean),
    async () => {
      const { body } = await fetch(
        `/events/${event?.id}/video-calls/${callId}?lang=${i18n.language}&includeInactiveParticipants=${includeInactiveParticipants}`,
        {
          token: accessToken,
        },
      );

      return transform.one(body);
    },
    {
      enabled: !!callId && isAuthorized,
    },
  );
};

export const useGetVideoCalls = () => {
  const event = useEvent();

  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['videoCalls', event?.id],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/video-calls`, {
        token: accessToken,
      });

      const videoCalls = transform.many(body);

      return videoCalls;
    },
    {
      enabled: isAuthorized,
    },
  );
};

export const useSeenMissedCalls = () => {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useMutation((participantIds: string[]) => {
    return fetch(`/events/${event?.id}/video-calls/missedSeen`, {
      method: 'POST',
      body: { participantIds },
      token: accessToken,
    });
  });
};
