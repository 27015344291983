import { Icon } from '@typing/icons';

const Search: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.3333 2C5.73096 2 2 5.73096 2 10.3333C2 14.9357 5.73096 18.6667 10.3333 18.6667C14.9357 18.6667 18.6667 14.9357 18.6667 10.3333C18.6667 5.73096 14.9357 2 10.3333 2ZM10.3333 3.66667C14.0152 3.66667 17 6.65143 17 10.3333C17 14.0152 14.0152 17 10.3333 17C6.65143 17 3.66667 14.0152 3.66667 10.3333C3.66667 6.65143 6.65143 3.66667 10.3333 3.66667Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M15.0491 15.0491C15.3495 14.7487 15.8222 14.7256 16.1491 14.9798L16.2276 15.0491L21.756 20.5774C22.0814 20.9028 22.0814 21.4305 21.756 21.7559C21.4556 22.0563 20.9829 22.0794 20.656 21.8252L20.5775 21.7559L15.0491 16.2276C14.7237 15.9022 14.7237 15.3745 15.0491 15.0491Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Search;
