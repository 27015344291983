import parse, { Attributes } from '@common/utils/parse';

export const VideoType = {
  VIXY: 'VIXY',
  VIMEO: 'VIMEO',
};

export const parseVideoProgress = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    createdAt: attributes.date('createdAt'),
    stoppedAt: attributes.optional.date('stoppedAt'),
    userId: attributes.string('userId'),
    pageId: attributes.string('pageId'),
    videoId: attributes.optional.string('videoId'),
    eventId: attributes.string('eventId'),
  };
};

export function one(data: any) {
  return parse.one(data, parseVideoProgress);
}

export function many(data: any) {
  return parse.many(data, parseVideoProgress);
}

export type VideoProgressResource = ReturnType<typeof one>;
export type VideoProgress = Omit<VideoProgressResource, 'id'>;

export default { one, many };
