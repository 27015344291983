/** @jsxImportSource @emotion/react */
/* eslint-disable no-plusplus */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import { UserSessionResource } from '@common/transforms/userSession';
import { SessionLocation, SessionResource } from '@common/transforms/session';

import Body from '@common/components/Body';
import Heading from '@common/components/Heading';

import SessionCard, { DISPLAY_TYPE } from '@modules/schedule/components/Session';
import Scrollable from '@common/components/Scrollable';
import { mediaQuery } from '@common/styles/mediaQuery';
import {
  useScheduledSessions,
  useScheduledSessionsCount,
} from '@modules/schedule/hooks/useSessions';
import { parseISO } from '@common/utils/time';
import i18n from '@common/utils/i18n';
import { closeOverlay } from '@common/store/sideOverlay';
import { useAppDispatch, useAppSelector } from '@common/store';
import { usePrevious } from '@common/hooks/usePrevious';
import Select from '@common/components/Select';
import Labeled from '@common/components/Labeled';
import { filterSessionsByLocation } from '@modules/event/utils';

interface DateRowItem {
  date: Date;
  sessions: UserSessionResource[];
}
const AgendaPanel = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [selectedLocation, setSelectedLocation] = useState<SessionLocation>(
    user?.attendance as SessionLocation,
  );
  const { data: rawSessionsData } = useScheduledSessions({ page: 1, limit: 99 });
  const scheduledSessions = useScheduledSessionsCount();
  const rawSessions = rawSessionsData?.items ?? [];
  const dayFilterRef = React.useRef<HTMLDivElement>(null);
  const activeDate: HTMLDivElement | null = document.querySelector('.activeDate');
  const prevActiveDate = usePrevious(activeDate);

  const sessions =
    rawSessions?.filter((rawSession) =>
      selectedDate
        ? dayjs(selectedDate).isBetween(rawSession.startsAt, rawSession.endsAt, 'day', '[]')
        : true,
    ) ?? [];

  // get a list of all the possible dates (with duplicates)
  const allPossibleDates: string[] = [];
  rawSessions
    .map((rawSession) => ({
      startsAt: `${new Date(parseISO(rawSession.startsAt).setHours(0, 0, 0, 0))}`,
      endsAt: `${new Date(parseISO(rawSession.endsAt).setHours(0, 0, 0, 0))}`,
    }))
    ?.forEach((rawSession) => {
      if (dayjs(rawSession.startsAt).isSame(rawSession.endsAt, 'd'))
        allPossibleDates.push(dayjs(rawSession.startsAt).toISOString());
      else
        for (
          let index = 0;
          index <= dayjs(rawSession.endsAt).diff(rawSession.startsAt, 'd');
          index++
        )
          allPossibleDates.push(dayjs(rawSession.startsAt).add(index, 'day').toISOString());
    });

  // remove all the duplicates from the list
  const dates = allPossibleDates
    .reduce<string[]>((uni, cur) => (uni.includes(cur) ? uni : [...uni, cur]), [])
    .map((date) => parseISO(date));

  const dateRows = (() => {
    const dateRow: DateRowItem[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const date of dates) {
      const obj = {
        date,
        sessions: filterSessionsByLocation(selectedLocation, sessions)!.filter(
          (session) =>
            dayjs(date).isSame(session.startsAt, 'd') ||
            dayjs(date).isSame(session.endsAt, 'd') ||
            dayjs(date).isBetween(session.startsAt, session.endsAt, 'd'),
        ),
      };
      if (obj.sessions?.length && (!selectedDate || dayjs(selectedDate).isSame(date, 'day')))
        dateRow.push(obj);
    }
    return dateRow;
  })();

  const handleLocationChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setSelectedLocation(event.target.value as SessionLocation);

  useEffect(() => {
    if (!!scheduledSessions.data && scheduledSessions.data.length === 0) dispatch(closeOverlay());
  }, [dispatch, scheduledSessions]);

  useEffect(() => {
    if (!prevActiveDate) {
      const now = new Date();
      if (dates.filter((date) => date.getDate() === now.getDate()).length > 0) {
        setSelectedDate(now);
        if (activeDate) dayFilterRef.current?.scrollTo(activeDate?.offsetLeft, 0);
      } else {
        setSelectedDate(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate]); // only to this on mount and to get activedate

  return (
    <Scrollable
      css={css`
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        z-index: 1000;

        ${mediaQuery(
          'm',
          css`
            width: 100%;
          `,
        )}
      `}
    >
      <div
        css={css`
          position: relative;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding: ${theme.paddings.s};
        `}
      >
        <Heading size='h2' color='textLight'>
          {t('my_schedule')}
        </Heading>

        <div
          css={css`
            display: flex;
            align-items: flex-start;
            margin-bottom: 0.5em;
          `}
        >
          <button
            css={css`
              margin-top: 1px;
              margin-right: 0.5em;
              background: transparent;
              border: 0;
              border-radius: 0;
              flex-shrink: 0;
              padding: 0;
            `}
            onClick={() => setSelectedDate(undefined)}
          >
            <Body
              css={css`
                color: ${selectedDate === undefined
                  ? theme.colors.textLight
                  : theme.colors.textBody};
                &:hover {
                  color: ${theme.colors.textLight};
                  cursor: pointer;
                }
              `}
            >
              {t('all')}
            </Body>
          </button>
          <div
            ref={dayFilterRef}
            css={css`
              display: flex;
              flex-wrap: nowrap;
              margin-bottom: 1em;
              width: 100%;
              overflow-x: auto;
              overflow-y: hidden;

              &::-webkit-scrollbar {
                height: 0;
              }
            `}
          >
            {dates?.map?.((day) => (
              <div
                key={dates.indexOf(day)}
                className={
                  selectedDate && dayjs(day).isSame(selectedDate, 'd') ? 'activeDate' : undefined
                }
                css={css`
                  position: relative;
                  margin-left: 1em;
                  background: transparent;
                  border: 0;
                  flex-shrink: 0;
                  border-radius: 0;
                  padding: 0;
                `}
                onClick={() => setSelectedDate(day)}
              >
                <Body
                  css={css`
                    color: ${selectedDate && dayjs(day).isSame(selectedDate, 'd')
                      ? theme.colors.textLight
                      : theme.colors.textBody};
                    &:hover {
                      color: ${theme.colors.textLight};
                      cursor: pointer;
                    }
                  `}
                >
                  {day.toLocaleDateString(i18n.language.replace('_', '-'), {
                    weekday: 'short',
                    day: '2-digit',
                  })}
                </Body>
              </div>
            ))}
          </div>
        </div>
        <Formik initialValues={{ locationSelect: selectedLocation }} onSubmit={() => {}}>
          <Labeled key='location' label={t('session_location_label')}>
            <Select
              name='locationSelect'
              options={Object.keys(SessionLocation).map((location) => ({
                label: t(`attendance_location.${location.toLowerCase()}`),
                value: location,
              }))}
              onChange={handleLocationChange}
              value={selectedLocation}
            />
          </Labeled>
        </Formik>
        {!sessions?.length && <Body>{t('no_scheduled_sessions')}</Body>}
        <div
          css={css`
            position: relative;
            width: 100%;
            margin-top: 2em;
          `}
        >
          {dateRows.map((dateRow) => (
            <div
              key={dateRows.indexOf(dateRow)}
              css={css`
                margin-bottom: 3em;
              `}
            >
              <Heading
                size='h3'
                color='textLight'
                css={css`
                  margin-bottom: 0.5em;
                `}
              >
                {dateRow.date.toLocaleDateString(i18n.language.replace('_', '-'), {
                  weekday: 'short',
                  day: '2-digit',
                })}
              </Heading>
              {filterSessionsByLocation(
                selectedLocation,
                dateRow.sessions as SessionResource[],
              )?.map((session) => (
                <div
                  key={session.id}
                  css={css`
                    margin-bottom: 15px;
                  `}
                >
                  <SessionCard
                    displayType={DISPLAY_TYPE.normal}
                    session={session as SessionResource}
                    selectedDate={dateRow.date}
                    scheduled
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Scrollable>
  );
};

export default AgendaPanel;
