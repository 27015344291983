import { opacify, readableColor, transparentize } from 'polished';

import { EventResource } from '@common/transforms/event';
import theme from '@common/styles/theme';

const eventTheme = (event?: EventResource) => {
  const safeColor = (hex: string) => {
    return (hex && hex.length === 7 && hex[0] === '#' && hex) || '#000000';
  };

  return {
    ...theme,
    colors: {
      ...theme.colors,
      buttons: {
        ...theme.colors.buttons,
        default: {
          ...theme.colors.buttons.default,
          color: safeColor(event?.themePrimaryColor || theme.colors.buttons.default.color),
          background: transparentize(
            0.7,
            safeColor(event?.themePrimaryColor || theme.colors.buttons.default.background),
          ),
          colorHover: readableColor(safeColor('#fff')), // TODO: Come up with a better solution
          backgroundHover: safeColor(
            event?.themePrimaryColor || theme.colors.buttons.default.backgroundHover,
          ),
          colorDisabled: transparentize(
            0.6,
            safeColor(event?.themePrimaryColor || theme.colors.buttons.default.colorDisabled),
          ),
          backgroundDisabled: transparentize(
            0.95,
            safeColor(event?.themePrimaryColor || theme.colors.buttons.default.backgroundDisabled),
          ),
        },
        transparent: {
          ...theme.colors.buttons.transparent,
          color: safeColor(event?.themeNormalColor || theme.colors.buttons.transparent.color),
          colorHover: safeColor(
            event?.themeNormalColor || theme.colors.buttons.transparent.colorHover,
          ),
          colorFocus: safeColor(
            event?.themeNormalColor || theme.colors.buttons.transparent.colorFocus,
          ),
        },
      },
      textLight: safeColor(event?.themeNormalColor || theme.colors.textLight),
      textBody: event?.themeNormalColor
        ? opacify(-0.3, safeColor(event?.themeNormalColor || theme.colors.textBody))
        : theme.colors.textBody,
      border: safeColor(event?.themeNormalColor || theme.colors.border),
      eventBackground: safeColor(event?.themeBackgroundColor || theme.colors.eventBackground),
      primary: safeColor(event?.themePrimaryColor || theme.colors.primary),
      secondary: safeColor(event?.themeSecondaryColor || theme.colors.secondary),
    },
  };
};

export default eventTheme;
