/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Button from '@common/components/Button';
import Heading from '@common/components/Heading';
import Chip from '@common/components/Chip';
import Divider from '@common/components/Divider';

import Avatar from '@modules/user/components/Avatar';
import { RoomResource } from '@common/transforms/room';
import { useAppDispatch, useAppSelector } from '@common/store';

import { ChatTabTypes, setSelectedTab } from '@modules/chat/store/chat';

export const GroupChatHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 1em;
        `}
      >
        <div
          css={css`
            flex: 1;
            cursor: pointer;
          `}
        >
          <Heading size='h3' color='textLight'>
            {t('group_chat')}
          </Heading>
        </div>
      </div>
      <Divider />
    </div>
  );
};

type PrivateChatHeaderProps = {
  chatRoom?: RoomResource;
  chatRooms?: RoomResource[];
  onCloseRoom?: (roomId: string) => void;
};

export const PrivateChatHeader: React.FC<PrivateChatHeaderProps> = ({
  chatRoom,
  chatRooms,
  onCloseRoom,
}) => {
  const otherParticipants =
    chatRoom?.participants?.filter((participant) => !participant?.isMe) || [];
  const firstParticipant = otherParticipants[0];

  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { selectedTab } = useAppSelector((state) => state.chat);

  const roomCount =
    chatRooms?.filter((room) => {
      return room.messages?.length !== 0;
    }).length || 0;

  const renderHeading = () => {
    if (selectedTab === 'ROOM' && chatRoom && firstParticipant) {
      return (
        <>
          {onCloseRoom && (
            <Button
              plain
              icon='arrow'
              iconProps={{
                size: 18,
              }}
              disableMinWidth
              onClick={() => onCloseRoom(chatRoom.id)}
              css={css`
                padding: 0;
                margin-right: 1em;
                &:hover {
                  background: transparent;
                }
              `}
            />
          )}
          <Heading size='h3'>{firstParticipant.firstName}</Heading>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Avatar
              user={firstParticipant}
              size={30}
              emptyBackground
              css={css`
                margin-left: 0.5em;
              `}
            />
            {firstParticipant?.isOnline && (
              <div
                css={css`
                  position: absolute;
                  margin-top: -13pt;
                  margin-left: 25pt;
                  background-color: lightgreen;
                  width: 8pt;
                  height: 8pt;
                  border-radius: 10pt;
                  border-width: 2pt;
                  border-style: solid;
                  border-color: ${theme.colors.eventBackground};
                `}
              />
            )}
          </div>
        </>
      );
    }

    return (
      <div
        onClick={() => dispatch(setSelectedTab(ChatTabTypes.ROOMS))}
        css={css`
          flex: 1;
          cursor: pointer;
        `}
      >
        <Heading size='h3' color='textLight'>
          {t('private_chats')}
          {chatRooms && chatRooms.length > 0 && (
            <Chip
              size='xxs'
              css={css`
                padding: 0.1em 0.6em;
                margin-left: 0.5em;
                font-size: 0.6em;
                padding-bottom: 0.2em;
              `}
            >
              {roomCount}
            </Chip>
          )}
        </Heading>
      </div>
    );
  };

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 1em;
        `}
      >
        {renderHeading()}
      </div>
      <Divider />
    </div>
  );
};
