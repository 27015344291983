/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import { SerializedStyles } from '@emotion/react';

import theme from './theme';

export function mediaQuery(size: 's' | 'm' | 'l' | 'xl', style: SerializedStyles): string {
  return `
    @media (min-width: ${theme.deviceWidth[size]}px) {
      ${style.styles}
    }
  `;
}
