import parse, { Attributes } from '@common/utils/parse';
import { transformInitialPagesArray, transformStringArray } from '@common/utils/transformArray';

import { parseUserRole } from './userRole';
import { parseOrganisation } from './organisation';
import type { MandatoryField } from './mandatoryField';

export const parseEvent = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    slug: attributes.string('slug'),
    name: attributes.string('name'),
    domains: attributes.custom('domains', transformStringArray),
    internalName: attributes.optional.string('internalName'),
    active: attributes.boolean('active'),
    activeFrom: attributes.date('activeFrom'),
    activeTill: attributes.date('activeTill'),
    attendanceEnabled: attributes.optional.boolean('attendanceEnabled'),
    tagline: attributes.optional.string('tagline'),
    description: attributes.optional.string('description'),
    venueUrl: attributes.optional.string('venueUrl'),
    privacyPolicyUrl: attributes.optional.string('privacyPolicyUrl'),
    maxUsers: attributes.optional.number('maxUsers'),
    autoApproval: attributes.optional.boolean('autoApproval'),
    autoApprovalEmailScopes: attributes.optional.custom(
      'autoApprovalEmailScopes',
      transformStringArray,
    ),
    singleSessionPerMagicLink: attributes.optional.boolean('singleSessionPerMagicLink'),
    sendMagicLinkOnApproval: attributes.optional.boolean('sendMagicLinkOnApproval'),
    sendWelcomeEmail: attributes.optional.boolean('sendWelcomeEmail'),
    languages: attributes.optional.custom('languages', transformStringArray),
    defaultLanguage: attributes.string('defaultLanguage'),
    organiser: attributes.optional.string('organiser'),
    organiserContact: attributes.optional.string('organiserContact'),
    googleTrackingId: attributes.optional.string('googleTrackingId'),
    avatarUploadEnabled: attributes.boolean('avatarUploadEnabled'),
    chatEnabled: attributes.boolean('chatEnabled'),
    privateChatEnabled: attributes.boolean('privateChatEnabled'),
    hostChatEnabled: attributes.boolean('hostChatEnabled'),
    videoCallEnabled: attributes.boolean('videoCallEnabled'),
    rolesEnabled: attributes.boolean('rolesEnabled'),
    attendeesInSidebarEnabled: attributes.boolean('attendeesInSidebarEnabled'),
    registrationEnabled: attributes.boolean('registrationEnabled'),
    requiredCustomFields: attributes.optional.number('requiredCustomFields'),
    loginEnabled: attributes.boolean('loginEnabled'),
    archivedAt: attributes.optional.date('archivedAt'),
    samlEnabled: attributes.optional.boolean('samlEnabled'),
    startsAt: attributes.date('startsAt'),
    endsAt: attributes.date('endsAt'),
    themeBackgroundUrl: attributes.optional.string('themeBackgroundUrl'),
    themeLogoUrl: attributes.optional.string('themeLogoUrl'),
    themeLogoEmailUrl: attributes.optional.string('themeLogoEmailUrl'),
    themeFaviconUrl: attributes.optional.string('themeFaviconUrl'),
    themeBackgroundColor: attributes.optional.string('themeBackgroundColor'),
    themePrimaryColor: attributes.optional.string('themePrimaryColor'),
    themeSecondaryColor: attributes.optional.string('themeSecondaryColor'),
    themeNormalColor: attributes.optional.string('themeNormalColor'),
    initialPages:
      attributes.optional.custom('initialPages', transformInitialPagesArray) || undefined,
    initialPageId: attributes.optional.string('initialPage.id'),
    initialPageSlug: attributes.optional.string('initialPage.slug'),
    registrationCompletedMessage: attributes.optional.string('registrationCompletedMessage'),
    organisationId: attributes.optional.string('organisation.id'),
    organisation: attributes.optional.custom('organisation', (organisation) => {
      return parse.one(organisation, parseOrganisation);
    }),
    isTemplate: attributes.optional.boolean('isTemplate'),
    isPageTemplate: attributes.optional.boolean('isPageTemplate'),
    roles: attributes.optional.custom('roles', (roles) => {
      return parse.many(roles, parseUserRole);
    }),
    rolesAllowed: attributes.optional.custom('rolesAllowed', (roles) => {
      return parse.many(roles, parseUserRole);
    }),
    mandatoryFieldOrder: attributes.optional.custom(
      'mandatoryFieldOrder',
      (data) => data,
    ) as MandatoryField[],
  };
};

export function one(data: any) {
  return parse.one(data, parseEvent);
}

export function many(data: any) {
  return parse.many(data, parseEvent);
}

export type EventResource = ReturnType<typeof one>;
export type Event = Omit<EventResource, 'id'>;

export default { one, many };
