import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import transform, { PageResource } from '@common/transforms/page';
import { getStaleTime } from '@common/utils/staleTime';
import fetch, { APIError, retry } from '@common/utils/fetch';
import { useAppSelector } from '@common/store';

export const usePage = (pageId: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery<PageResource, APIError>(
    ['page', event?.id, pageId],
    async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        const { body } = await fetch(`/events/${event?.id}/pages/${pageId}?lang=${i18n.language}`, {
          token: accessToken,
        });

        return transform.one(body);
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: !!event && isAuthorized && !!pageId,
      ...getStaleTime(0.2), // TODO: see if we can use pusher to increase stale time
    },
  );
};

export const usePages = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);
  const { i18n } = useTranslation();

  return useQuery(
    ['pages', event?.id],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/pages?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      // Refetch the data every 60 seconds
      refetchInterval: 60000,
      staleTime: 30000,
      cacheTime: 0,
      enabled: !!event && isAuthorized,
      retry,
    },
  );
};

export default { usePage, usePages };
