import parse, { Attributes } from '@common/utils/parse';

import transform from './customFieldOption';

export enum CustomFieldType {
  INPUT = 'INPUT',
  DROPDOWN = 'DROPDOWN',
}

export interface DropdownOption {
  id: string;
  key?: string;
  label?: string;
}

export const parseCustomField = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    key: attributes.string('key'),
    label: attributes.string('label'),
    position: attributes.optional.number('position'),
    placeholder: attributes.optional.string('placeholder'),
    required: attributes.boolean('required'),
    private: attributes.boolean('private'),
    type: attributes.enum<typeof CustomFieldType>('type', CustomFieldType),
    options: attributes.custom('options', (data) => transform.many(data)),
  };
};

export function one(data: any) {
  return parse.one(data, parseCustomField);
}

export function many(data: any) {
  return parse.many(data, parseCustomField);
}

export type CustomFieldResource = ReturnType<typeof one>;
export type CustomField = Omit<CustomFieldResource, 'id'>;

export default { one, many };
